import * as React from 'react';
import { observer } from 'mobx-react';
import { AlertDialog } from 'naan/primitives/modals';

import dancers from './dancers.svg';

import __ from 'core/lib/localization';
import { styled } from 'naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { alertSevereError } from '@app/notification-service';

const Wrapper = styled('div', {
  // width: '360px',
  img: {
    display: 'block',
    width: '100%',
    marginTop: '8px',
    padding: '0',
    backgroundColor: 'white',
  },
  h3: {
    textStyle: 'medium-heading',
    color: '$gray-800',
    // color: ${getColor('gray800')};
    margin: '24px 0 16px 0',
  },
});

export const NewsletterDialog = observer(({ lupaMode = true }) => {
  const { offline, userManager } = AppFactory.root;
  const { newsletterPromptEnabled, updateMailingListOptIn } = userManager;

  const optin = () => {
    updateMailingListOptIn(true).catch(error => {
      alertSevereError({ error, note: 'updateMailingListOptIn' });
    });
  };

  const optout = () => {
    updateMailingListOptIn(false).catch(error => {
      alertSevereError({ error, note: 'updateMailingListOptIn' });
    });
  };

  if (!newsletterPromptEnabled || offline) {
    return null;
  }

  return (
    <AlertDialog
      open
      body={
        <Wrapper>
          <figure>
            <img src={dancers} alt="" role="presentation" />
          </figure>
          <h3>{__('Stay in the loop', 'newsletter.stayInTheLoop')}</h3>
          <div className="content">
            {__(
              'Subscribe to the Jiveworld newsletter and be in the know about new stories, app features, promotions, and more.',
              'newsletter.subscribeToTheJiveworldNewsletter'
            )}
          </div>
        </Wrapper>
      }
      okButtonLabel={__('Yes please', 'common.yesPlease')}
      okButtonAction={optin}
      cancelButtonLabel={__('No thanks', 'common.noThanks')}
      cancelButtonAction={optout}
    />
  );
});
