// derived from masala-lib/utils

import { isEmpty } from 'lodash';
import { notEmpty } from './conditionals';
import { L12String, StringToNumber } from './util-types';

export const notEmptyOrNA = (text: string): boolean => {
  return !isEmptyOrNA(text);
};

export const isEmptyOrNA = (text: string): boolean => {
  return isEmpty(text) || isNA(text);
};

export const isNA = (text: string): boolean => {
  return !isEmpty(text) && text.toUpperCase() === 'N/A';
};

export const displayedProp = (lString: L12String): string => {
  const value = lString?.l1 || lString?.l2;
  if (isEmptyOrNA(value)) {
    return '';
  }
  return value;
};

export const extractDomainName = (url: string) => {
  if (notEmptyOrNA(url)) {
    const { hostname } = new URL(url);
    if (notEmpty(hostname)) {
      const result = hostname.replace(/^www\./, '');
      return result;
    }
  }
  return '';
};

/*
 * copied from:
 * https://stackoverflow.com/questions/263965/how-can-i-convert-a-string-to-boolean-in-javascript
 *
 * Converts a string to a bool.
 *
 * This conversion will:
 *
 *  - match 'true', 'on', or '1' as true.
 *  - ignore all white-space padding
 *  - ignore capitalization (case).
 *
 * '  tRue  ','ON', and '1   ' will all evaluate as true.
 */

// will match one and only one of the string 'true','1', or 'on' rerardless
// of capitalization and regardless off surrounding white-space.
const stringToBoolRegex = /^\s*(t|true|1|on)\s*$/i;

export const stringToBool = (s: string): boolean => {
  return stringToBoolRegex.test(s);
};

export const parseCsv = (text: string): string[] =>
  (text || '').split(',').map(_ => _.trim());

// derived from https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0?permalink_comment_id=2694461#gistcomment-2694461
export const hashCode = (s: string) => {
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  }
  return h;
};

export const listToMappedOrdinals = (list: string[]): StringToNumber => {
  const result: StringToNumber = {};
  for (let i = 0; i < list.length; i++) {
    result[list[i]] = i;
  }
  return result;
};

const KB_BYTES = 1024;
const MB_BYTES = 1024 * 1024;
const GB_BYTES = 1024 * MB_BYTES;

export const formatBytes = (bytes: number): string => {
  if (bytes === null || bytes === undefined) return '';
  if (bytes < KB_BYTES) return `${bytes}b`;
  if (bytes < MB_BYTES) return `${(bytes / KB_BYTES).toFixed(2)}kb`;
  if (bytes < GB_BYTES) return `${(bytes / MB_BYTES).toFixed(2)}mb`;
  return `${(bytes / GB_BYTES).toFixed(2)}gb`;
};
