import { BasePlayerModel } from './base-player-model';

export const enum LoadingStatus {
  // eslint-disable-next-line no-unused-vars
  UNINITIALIZED = 'UNINITIALIZED',
  // eslint-disable-next-line no-unused-vars
  LOADING = 'LOADING',
  // eslint-disable-next-line no-unused-vars
  READY = 'READY',
}

export const enum PlayerStatus {
  // eslint-disable-next-line no-unused-vars
  PLAYING = 'PLAYING',
  // eslint-disable-next-line no-unused-vars
  PENDING_PAUSE = 'PENDING_PAUSE',
  // eslint-disable-next-line no-unused-vars
  PAUSED = 'PAUSED',
}

export type PlayerModel = BasePlayerModel;
