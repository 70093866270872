import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { ChapterListItem } from '../../story/chapter-list-item';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { styled } from '@naan/stitches.config';
import { StoryHeader } from 'story/story-header';
import { StoryAction } from 'story/story-actions';
import { StoryLockedActions } from 'story/story-locked-actions';
import { ChapterCatalogData, UnitCatalogData } from '@core/models/catalog';
import { learnHomePath } from 'components/nav/path-helpers';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { StoryDetailCard } from 'components/story-detail/story-detail-card';
import { CurrentStoryProvider } from 'components/story-detail/current-story-context';
import { NotFoundScreen } from 'routes/not-found-screen';
import { StoryCompleteControls } from 'components/story-detail/story-complete-controls';
import { StoryOverflowMenu } from 'components/story-detail/story-overflow-menu';
import { useStoryDetailScrollingFx } from './scrolling-fx';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { StoryDetailCardAssignment } from 'components/stories/story-detail-card-assignment';
import { DownloadControlTemp } from 'components/stories/download-control-temp';
import { Box } from '@naan/primitives/box';
import { StoryDownloadedBadge } from 'components/stories/story-downloaded-badge';

const StoryDetailScreenStyles = styled('div', {
  position: 'relative',
  '& > .story-header-container': {
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    '@large': {
      left: '$$sidebarWidth',
    },
    zIndex: 99,
  },
  '& .unitHeader': {
    textStyle: 'small-heading',
    marginBottom: '$space$4',
    marginTop: '$space$10',
  },
  '& .unitDuration': {
    textStyle: 'small-text',
    marginLeft: '$space$2',
    color: '$colors$textSecondary',
  },
});

const CURRENT_CHAPTER_ID = 'current-chapter';
const CHAPTER_LIST_ID = 'chapter-list';

function maybeCurrentId(chapter: ChapterCatalogData) {
  if (chapter.isCurrent) {
    return { id: CURRENT_CHAPTER_ID };
  }

  return {};
}

const UnitHeader = ({ unit }: { unit: UnitCatalogData }) => {
  return (
    <div className="unitHeader">
      {unit.partLabel}
      <span className="unitDuration">{unit.durationDescription}</span>{' '}
    </div>
  );
};

export const StoryDetailScreen = observer(() => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  const navigate = useNavigate();
  const { storyManager } = AppFactory.root;
  const { slug } = useParams<{ slug: string }>();
  const story = storyManager.story(slug);
  (window as any).currentStory = story; // console convenience

  useStoryDetailScrollingFx();

  React.useEffect(() => {
    /* async */ story?.ensureCacheState();
  }, [story]);

  if (!story) {
    // return <>loading…</>;
    return <NotFoundScreen reportError={false} />;
  }

  const canGoBack = ReturnNavState.canPopFromStoryDetail();

  const assignment = story.joinedClassroomAssignment;

  const showTempDownloadControls = AppFactory.root.userManager.showUglyDevUI;

  return (
    <CurrentStoryProvider story={story}>
      <StoryDetailScreenStyles>
        {/* this DIV is required for the autoscroll to work */}
        <div id="scroll-beacon" />
        <div className="story-header-container">
          <StoryHeader
            backAction={() =>
              canGoBack ? navigate(-1) : navigate(learnHomePath())
            }
            overflowMenu={<StoryOverflowMenu story={story} />}
          />
        </div>
        <StoryDetailCard story={story} />
        <StoryDetailCardAssignment assignment={assignment} sticky={true} />
        <StoryDownloadedBadge story={story} />
        {showTempDownloadControls ? (
          <Box css={{ padding: '$space$2' }}>
            <DownloadControlTemp story={story} />
          </Box>
        ) : null}
        <StoryCompleteControls story={story} />

        {/* one of these two will be non-null */}
        <StoryAction story={story} />
        <StoryLockedActions story={story} />

        {!story.multiUnit ? <VSpacer size={4} /> : null}
        <CenterColumnLayout>
          <VStack id={CHAPTER_LIST_ID}>
            {story.units.map(unit => (
              <React.Fragment key={unit.slug}>
                {story.multiUnit ? <UnitHeader unit={unit} /> : null}
                <div>
                  {unit.chapters.map(chapter => (
                    <div
                      key={unit.slug + '_' + chapter.position}
                      {...maybeCurrentId(chapter)}
                    >
                      <ChapterListItem chapter={chapter} />
                    </div>
                  ))}
                </div>
              </React.Fragment>
            ))}
          </VStack>
          <MediumGap />
        </CenterColumnLayout>
      </StoryDetailScreenStyles>
    </CurrentStoryProvider>
  );
});
