import * as React from 'react';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager';
import { AppFactory } from '@app/app-factory';
// import useSWR from 'swr';
import { createLogger } from '@common/log';

const log = createLogger('story-debug-info');

// const fetchStoryCacheState = async (story: Story): Promise<boolean> => {
//   log.debug('fetchStoryCacheState');
//   const result = await story.isCached();
//   log.debug(`result: ${String(result)}`);
//   return result;
// };

// place holder until we implement prettier UI
export const StoryDownloadedBadge = observer(({ story }: { story: Story }) => {
  log.debug('StoryCacheStateUnguarded - render');

  const { hasAdminAccess } = AppFactory.root.userManager;
  if (!hasAdminAccess) {
    return null;
  }

  if (AppFactory.assetCacher.disabled) {
    return <>[asset cacher currently disabled]</>;
  }

  // not sure if there's any way to signal to swr when something is dirty
  // let { data: isCached, error } = useSWR<boolean>(story, fetchStoryCacheState);

  const isCached = story.isCachedMemoized;

  log.debug(`StoryCacheStateUnguarded - isCached: ${String(isCached)}`);

  if (isCached === undefined) {
    return <>...</>; // probably never visible since resolved very quickly
  } else {
    if (isCached) {
      return <>[cached]</>;
    } else {
      return <>[uncached]</>;
    }
  }
});
