import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { CouponCodeForm } from 'components/gift-coupons/coupon-code-form';
import { AppFactory } from 'app/app-factory';
import { CouponAppliedDialog } from 'components/gift-coupons/coupon-applied-dialog';

import __ from 'core/lib/localization';

const CouponDialog = observer(
  ({
    onDismiss,
    onSuccess,
  }: {
    onDismiss: () => void;
    onSuccess: (result: any) => void;
  }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          {__('Enter your code', 'account.enterYourCode')}
        </Dialog.Heading>
        <Dialog.CloseButton />
        <Dialog.Body>
          <CouponCodeForm
            onCouponCodeSuccess={onSuccess}
            placeholder={__('Coupon code', 'common.couponCode')}
            buttonLabel={__('Apply', 'common.apply')}
          />
        </Dialog.Body>
      </Dialog.Container>
    );
  }
);

export function useCouponDialog() {
  const handleCouponCodeSuccess =
    (dismissParent: () => void) => (result: any) => {
      /// we close the parent dialog before showing the coupon applied dialog
      dismissParent();
      if (result?.message) {
        AppFactory.dialogPresenter.present(onDismiss => (
          <CouponAppliedDialog onDismiss={onDismiss} message={result.message} />
        ));
      }
    };

  const handleApplyCode = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <CouponDialog
        onDismiss={onDismiss}
        onSuccess={handleCouponCodeSuccess(onDismiss)}
      />
    ));
  }, []);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('coupon')) {
      handleApplyCode();
      //clear search params
      window.history.replaceState({}, '', `${window.location.pathname}`);
    }
  }, [handleApplyCode]);

  return handleApplyCode;
}
