import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Story } from 'core/models/story-manager';
import { StudentProgress } from 'core/models/user-manager';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  '.progress-container': {
    display: 'block',
    width: '100%',
    height: '6px',
    borderRadius: '3px',
    background: 'rgba(0, 0, 0, 0.04) /* Yep, not a palette color */',
    position: 'relative',
  },

  '.progress-bar': {
    position: 'absolute',
    left: '0',
    top: '0',
    height: '6px',
    borderRadius: '3px 0 0 3px',
    background: '$colors$teal-500',
    '&.is-completed': {
      borderRadius: '3px',
    },
  },

  '.progress-text': {
    textStyle: 'small-text',
    color: '$colors$textSecondary',
  },

  '@media screen and (max-width: 549px)': {
    marginTop: '4px',
    marginBottom: '8px',
  },
});

const ProgressBar = ({
  progress,
  completed,
}: {
  progress: number;
  completed: boolean;
}) => {
  const width = `${completed ? 100 : progress * 100}%`;
  return (
    <div className="progress-container">
      <div
        className={`progress-bar ${completed ? 'is-completed' : ''}`}
        style={{ width }}
      ></div>
    </div>
  );
};

const ProgressText = ({
  chapterCount,
  completed,
  completedChapters,
}: {
  chapterCount: number;
  completed: boolean;
  completedChapters: number;
}) => {
  if (completed) {
    return (
      <span className="progress-text is-completed">
        {__('Completed', 'common.completed')}
      </span>
    );
  }

  return (
    <span className="progress-text">
      {__('%{n}/%{total} chapters complete', 'story.storyProgress', {
        n: completedChapters,
        total: chapterCount,
      })}
    </span>
  );
};

export const ProgressCell = ({
  story,
  progress,
}: {
  story: Story;
  progress: StudentProgress;
}) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });
  const { storyProgress } = progress;
  // const completed = storyProgress?.furthestPoint?.atEndOfStory ?? false;
  const completed = storyProgress?.completed ?? false;
  const { completedChapters = 0 } = storyProgress ?? {};
  const { chapterCount } = story;
  const ratio = Math.min(completedChapters / chapterCount, 1);
  return (
    <Wrapper>
      <ProgressBar progress={ratio} completed={completed} />
      {isMobile ? null : (
        <ProgressText {...{ chapterCount, completed, completedChapters }} />
      )}
    </Wrapper>
  );
};
