import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';

import { Link } from 'react-router-dom';
import { VSpacer } from 'naan/primitives/spacer';
import { VStack } from 'naan/primitives/stack';

import __ from 'core/lib/localization';
import { profileHomePath } from 'components/nav/path-helpers';

export const CouponAppliedDialog = ({
  message,
  onDismiss,
}: {
  message: string;
  onDismiss: () => void;
}) => {
  return (
    <Dialog.Container onDismiss={onDismiss} open={true}>
      <Dialog.Heading>
        {__('Coupon applied', 'coupons.couponApplied')}
      </Dialog.Heading>
      <Dialog.Body>
        <VStack>
          <VSpacer size={3} />
          {message}
          <VSpacer size={3} />
          <Dialog.LinkContainer>
            <Link to={profileHomePath()} onClick={onDismiss}>
              {__('Go to your account', 'common.goToAccount')}
            </Link>
          </Dialog.LinkContainer>
          <VSpacer size={3} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          fullWidth
          presentation={'teal'}
          onClick={onDismiss}
          label={__('OK', 'common.ok')}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};
