import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  color: '$colors$gray-800 ',
  '& span': {
    color: '$colors$textSecondary ',
  },

  '@media screen and (max-width: 549px)': {
    marginBottom: '8px',
  },
});

export const StudentTimeStudiedCell = ({
  allTimeStudiedHourMin = null,
}: {
  allTimeStudiedHourMin: string | null;
}) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return <>{allTimeStudiedHourMin}</>;
  }

  return (
    <Wrapper>
      {allTimeStudiedHourMin} <span>studied</span>
    </Wrapper>
  );
};
