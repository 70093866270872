import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { Button } from '@naan/primitives/button';
import { PlayerMode } from '@common/misc-types';
import { EyeIcon } from '@naan/icons/eye-icon';
import { EarIcon } from '@naan/icons/ear-icon';
import { ReviewIcon } from '@naan/icons/review-icon';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';

export const StudyButton = ({
  chapter,
  reviewMode = false,
  presentation = null,
  viaDetail = false, // when true, then navigate via detail screen so popping behaves as desired
}: {
  chapter: ChapterCatalogData;
  reviewMode?: boolean;
  presentation?: string | null;
  viaDetail?: boolean;
}) => {
  const { currentMillis: ms, midListen } = chapter;

  let studyIcon;
  let studyLabel;
  let studyPresentation = presentation ?? 'teal'; // overridden below if midListen or done
  let studyAction: () => void = undefined;

  const navigateToStudy = useNavigateToStudy();

  if (!chapter.isFirstListenComplete) {
    // first listen
    studyIcon = <EyeIcon />;
    studyAction = () =>
      navigateToStudy(chapter, PlayerMode.STUDY, ms, viaDetail);
    studyLabel = midListen ? 'Continue listen' : 'Begin chapter';
  } else {
    // second/third listen
    studyAction = () =>
      navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN, ms, viaDetail);

    if (reviewMode) {
      studyIcon = <ReviewIcon />;
      studyLabel = midListen ? 'Continue review' : 'Review';
    } else {
      studyIcon = <EarIcon />;
      if (!chapter.isSecondListenComplete) {
        // second listen
        studyLabel = midListen ? 'Continue listen' : 'Relisten';
      } else {
        // third listen
        studyLabel = midListen ? 'Continue listen' : 'Listen again';
        studyPresentation = 'secondary'; // 'done' is primary unless review mode or mid third listen
      }
    }
  }
  if (midListen) {
    studyPresentation = presentation ?? 'tealProgress';
  }

  return (
    <Button
      key="study"
      leftIcon={studyIcon}
      label={studyLabel}
      onClick={studyAction}
      presentation={studyPresentation as any}
      size="large"
      css={{
        $$currentProgress: midListen ? `${chapter.currentPercentage}%` : '0%',
      }}
    />
  );
};
