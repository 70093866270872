import { createLogger } from 'app/logger';
import UaParser from 'ua-parser-js';

const log = createLogger('ua-parser');

/// TODO: replace enum with `as const`
export const enum BrowserLevel {
  // eslint-disable-next-line no-unused-vars
  SUPPORTED = 'SUPPORTED',
  // eslint-disable-next-line no-unused-vars
  UNSUPPORTED = 'UNSUPPORTED',
  // eslint-disable-next-line no-unused-vars
  UNSURE = 'UNSURE',
}

export class UaService {
  private static _instance: UaParser;

  static get info() {
    if (!UaService._instance) {
      UaService._instance = new UaParser(window.navigator.userAgent);
    }
    return UaService._instance.getResult();
  }

  // convenient access to the data we care about
  static get browserInfo() {
    let name: string = 'unknown';
    let major: number;
    try {
      name = this.info?.browser?.name || 'unknown';
      major = Number(this.info?.browser?.major); // how to best handle undefined or NaN?

      // the ipad safari browser lies and clames to be Mac OS, so we only look at the browser and version.
      // except we need to treat chrome, etc on ios as if it were safari.
      if (this.info?.os?.name === 'iOS') {
        name = 'Safari';
        major = Number(this.info?.os?.version?.split('.')[0]);
      }
    } catch (e) {
      // paranoia catch, will fall back to UNSURE if we have a dereference or parse error
      log.info(`parse error: ${e}`);
    }

    return { name, major };
  }

  static get supportLevel(): string {
    if (this.unsupported) {
      return BrowserLevel.UNSUPPORTED;
    }
    if (this.supported) {
      return BrowserLevel.SUPPORTED;
    }
    return BrowserLevel.UNSURE; // versions where we wish to warn the user out of paranoia
  }

  // true for version with known issues
  static get unsupported() {
    const { name, major } = this.browserInfo;
    return (
      (name.includes('Safari') && major <= 12) ||
      (name.includes('Chrome') && major <= 86) ||
      (name.includes('Firefox') && major <= 82) // placeholder based on 2 years old
    );
  }

  // true for version we've tested and plan on forward regression testing
  static get supported() {
    const { name, major } = this.browserInfo;
    return (
      (name.includes('Safari') && major >= 15) ||
      (name.includes('Chrome') && major >= 99) || // placeholder version
      (name.includes('Firefox') && major >= 102) // placeholder version
      // flush out edge, etc
    );
  }

  // this isn't about abstraction, but encapsulation of how to interpret the browser info data
  static get shouldManuallySetScriptContainerHeight() {
    const { name, major } = this.browserInfo;
    return name.includes('Safari') && major <= 14;
  }

  // market share data may be useful https://www.stetic.com/market-share/browser/
}
