import { ChapterCatalogData } from '@core/models/catalog/chapter-catalog-data';
import { Story } from '@core/models/story-manager/story';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { AppFactory } from 'app/app-factory';
import { ReturnNavState } from './return-nav-state';

export const baseTeachPath = () => `/app/teach/${ReturnNavState.l2}`;
export const baseLearnPath = () => `/app/learn/${ReturnNavState.l2}`;

export const discoverPath = () => `${baseTeachPath()}/discover`;
export const favoritesPath = () => `${baseTeachPath()}/favorites`;
export const classroomsPath = () => `${baseTeachPath()}/classrooms`;

export const teachHomePath = () =>
  AppFactory.root.userManager.accountData.managedClassrooms?.length
    ? classroomsPath()
    : discoverPath();

type UrlOptions = {
  fragment?: string;
};

function decorateUrl(url: string, urlOptions: UrlOptions) {
  let newUrl = url;
  if (urlOptions.fragment) {
    newUrl = `${newUrl}#${urlOptions.fragment}`;
  }
  return newUrl;
}

export const learnHomePath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${baseLearnPath()}/home`, urlOptions);
export const learnStoriesPath = (urlOptions: UrlOptions = {}) =>
  decorateUrl(`${baseLearnPath()}/stories`, urlOptions);
export const learnProgressPath = () => `${baseLearnPath()}/progress`;

export const collectionsPath = () => `${baseLearnPath()}/collections`;
export const collectionDetailPath = (slug: string) =>
  `${collectionsPath()}/${slug}`;

// todo: figure out jason's fancy string match typing
export const storiesPrimaryFiltered = (filterKey: PrimaryFilterKeys | string) =>
  `${learnStoriesPath()}?primary=${filterKey}`;

export const storiesTagFiltered = (type: string, value: string) =>
  `${learnStoriesPath()}?filter_type=${type}&filter_value=${value}`;

export const learnStoryPath = (story: Story) => learnStorySlugPath(story?.slug);

export const learnStorySlugPath = (storySlug: string) =>
  `${learnStoriesPath()}/${storySlug}`;

export const discoverStoryPath = (story: Story) =>
  `${discoverPath()}/v/${story.slug}`;

// tmp
export const vocabDevPath = (story: Story) =>
  `${learnStoryPath(story)}/vocab-dev`;

export const studyBasePath = (chapter: ChapterCatalogData) =>
  `${learnStoriesPath()}/${chapter.storySlug}/study/${chapter.unitNumber}/${
    chapter.position
  }`;

export const soundbitePreviewByUrl = (url: string) =>
  `/admin/preview?url=${url}`;
export const soundbitePreviewBySlug = (slug: string) =>
  `/admin/preview?slug=${slug}`;

export const giftCouponsPath = () => '/app/profile/gift-coupons';
export const profileHomePath = () => '/app/profile/account';

// will need to update cypress tests to change the default teacher mode home path
export const homePath = () => {
  // const { classroomEnabled } = AppFactory.root.userManager;

  // if (classroomEnabled) {
  //   return teachHomePath();
  // } else {
  return learnHomePath();
  // }
};

export const defaultPath = () => {
  // const { authenticated } = AppFactory.root.userManager;
  // if (!authenticated) {
  //   return '/auth/create-account';
  // } else {
  return homePath();
  // }
};

const authPath = (opts: { path: string; after?: string }) =>
  `${opts.path}${opts.after ? `?after=${opts.after}` : ''}`;

export const createAccountPath = (opts: { after?: string } = {}) =>
  authPath({ ...opts, path: '/auth/create-account' });

export const signInPath = (opts: { after?: string } = {}) =>
  authPath({ ...opts, path: '/auth/sign-in' });

// for now we simply send anonymous users to the create account page when attempting to follow
// links to destinations which require an authenticated context
export const noAuthRedirect = createAccountPath;

// just sent beta users to the create account screen since we haven't designed the subscribe interstitial yet
// export const subscribePath = createAccountPath; // () => `/app/profile/subscribe`;

export const subscribePath = () => {
  if (AppFactory.root?.userManager?.accountData?.betaMode) {
    return createAccountPath(); // hack out for beta users until designed
  } else {
    return `/app/profile/subscribe`;
  }
};
