import * as React from 'react';
import { HStack } from 'naan/primitives/stack';
import { HSpacer } from 'naan/primitives/spacer';
import { PointsIcon } from 'naan/icons/points-icon';
import { Text } from 'naan/primitives/text';
import { colors } from 'naan/tokens/colors';

export const TotalPoints = ({ points }: { points: number }) => (
  <HStack align={'center'}>
    <PointsIcon color={colors['yellow-500']} />
    <HSpacer size={0} />
    <Text textStyle={'medium-heading'}>{points.toLocaleString()}</Text>
  </HStack>
);
