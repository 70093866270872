import React from 'react';
import { SoundbiteUI } from '../../soundbite/views/soundbite-ui';
import { ExcerptData } from '@tikka/client/catalog-types';
import { AppFactory } from 'app/app-factory';
import { appConfig } from 'app/env';
import { LoadingStatus } from '../../player/models/player-model';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import { reaction } from 'mobx';
import { FullScreenLoader } from 'components/ds/modals';
import { useLocation } from 'react-router-dom';
import { getQueryParamsFromLocation } from '@common/object-from-url-query';
import { SoundbiteEngagementStatus } from '@core/models/user-manager/soundbite-engagement';
import { observer } from 'mobx-react';
import { alertSevereError, bugsnagNotify } from '@app/notification-service';
// import { UnsupportedBrowserScreen } from 'routes/unsupported-browser-screen';
// import { UaService } from 'lib/services/ua-service';

const log = createLogger('soundbite-screen');

export const updateSettings = () => {
  const model = AppFactory.soundbiteModel;

  const { userManager } = AppFactory.root;
  const { playerSettings } = userManager.userData;

  const newSettings = {
    playbackRate: model.player.playbackRate,
    redactionMode: model.redactionMode,
    introShown: playerSettings.introShown, // confirm best way to leave this untouched
  };

  if (playerSettings.isDifferent(newSettings)) {
    log.info('updating playerSettings');
    playerSettings.setAll(newSettings);
    userManager.persistUserData().catch(bugsnagNotify);
  } else {
    log.info('playerSettings unchanged');
  }
};

const loadExcerptData = async (urlOrSlug: string) => {
  var url: string;
  if (!urlOrSlug.startsWith('http')) {
    // assume we have a slug that needs to be resolved against masala server's list of latest ingestions
    url = await resolveUrlFromSlug(urlOrSlug);
  } else {
    url = urlOrSlug;
  }

  log.info(`loadExcerptData - url: ${url}`);
  const response = await fetch(url);
  const data = (await response.json()) as ExcerptData;
  return data;
};

const resolveUrlFromSlug = async (slug: string): Promise<string> => {
  const listEndpoint = appConfig.soundbites.listEndpoint;
  log.info(`listEndpoint: ${listEndpoint}`);
  const response = await fetch(listEndpoint);
  const list = (await response.json()) as ExcerptData[];
  log.info(`list count: ${list?.length}`);

  const match = list.find(data => data.slug === slug);
  log.info(`matched: - title: ${match?.title}, url: ${match?.dataUrl}`);
  return match?.dataUrl || null;
};

// made an observer for now to react to the date adjust debug mode keys
// not sure if this is justified
export const SoundbiteScreen = observer(() => {
  // url param used for admin preview screen
  const location = useLocation();
  let { url, slug } = getQueryParamsFromLocation(location);
  let urlOrSlug = url || slug;
  const { storyManager } = AppFactory.root;
  if (!urlOrSlug) {
    // log.info(`featuredSoundbiteSlug: ${storyManager.featuredSoundbiteSlug}`);
    log.info(
      `resolved soundbite dataUrl: ${storyManager.featuredSoundbite?.dataUrl}`
    );
    urlOrSlug =
      storyManager.featuredSoundbite?.dataUrl ??
      appConfig.soundbites.demoDataUrl;
  }
  log.info(`urlOrSlug: ${urlOrSlug}`);
  const { currentDate } = AppFactory.root.storyManager;

  const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);

  // eslint-disable-next-line
  React.useEffect(() => {
    track('soundbite__page_opened'); // redundant with generic page tracking, but looks easier to explore event data vs page view date

    setDataLoaded(false);
    const model = AppFactory.playerModelHandle.newSoundbiteModel();
    model.setStatus(LoadingStatus.LOADING);
    loadExcerptData(urlOrSlug)
      .then(data => model.initFromData(data))
      .then(() => {
        // todo: better factor this
        const { playerSettings } = AppFactory.root.userManager.userData;
        model.player.setPlaybackRate(playerSettings.playbackRate);
        model.setRedactionMode(playerSettings.redactionMode);
        model.setOnloadModalNeeded(!playerSettings.introShown);
        model.setReady(); // shouldn't be needed now

        const { userManager } = AppFactory.root;
        const { soundbiteUserData } = userManager.userData;
        soundbiteUserData.recordEngagement({
          slug: model.data.slug,
          status: SoundbiteEngagementStatus.VIEWED,
        });
        userManager.handlePlayerVisited(); // hook to trigger delayed mailing list prompt

        setDataLoaded(true);

        // automatically persist settings if changed when end reached
        // important to not setup the reaction under after the model is initialized
        reaction(
          () => model.afterNotionalCompletion,
          atEnd => {
            log.debug(`notional completion reaction - atEnd: ${atEnd}`);
            if (atEnd) {
              updateSettings();
            }
          }
        );
      })
      .catch(error =>
        alertSevereError({ error, note: 'soundbite-screen - useEffect' })
      );

    return () => {
      if (dataLoaded) {
        model.dispose();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlOrSlug, currentDate]);

  if (!dataLoaded) {
    return <FullScreenLoader />;
  }

  const model = AppFactory.soundbiteModel;
  if (!model.story) {
    return <h2>missing story data</h2>;
  }
  return <SoundbiteUI />;
});

//
// note: the unsupported browser warning is not currently enabled.
// the GatedSoundbiteScreen would need to be reference from the router if desired again.
//

// /// keeping things simple: we detect the browser level and if it's not good enough,
// ///  we show the unsupported browser screen
// const shouldForceLoad = (() => {
//   try {
//     /// this was failing when cookies were disabled. That's why I rapped it in try...catch
//     return localStorage.getItem('forceLoad') === 'true';
//   } catch (e) {
//     return false;
//   }
// })();
// // we only wanted the strong gate on known unsupported browsers
// // (if we resurrect this we will likely want a softer warning for the 'unsure' category of browsers)
// const showUnsupportedGate = !shouldForceLoad && UaService.unsupported;
// function forceLoad() {
//   //
//   // todo: daniel's expectation was that dismiss state was not be permanently persisted.
//   // (just realizing he poorly worded his spec. 'don't need' and 'can' should have been 'should not' and 'should')
//   // https://linear.app/jiveworld/issue/ENG-2504/unsupported-browser-notification-implementation
//   // the approach here should be revisited if we resurrect this in the future.

//   /// we set the flag and do a hard reload
//   localStorage.setItem('forceLoad', 'true');
//   window.location.reload();
// }

// export const GatedSoundbiteScreen = () => {
//   log.debug(
//     `shouldForceLoad: ${String(shouldForceLoad)}, support level: ${
//       UaService.supportLevel
//     }`
//   );
//   if (showUnsupportedGate) {
//     return <UnsupportedBrowserScreen action={forceLoad} />;
//   } else {
//     return <SoundbiteScreen />;
//   }
// };
