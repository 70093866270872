import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { WordMembership } from 'player/models/word-membership';
import { ElementMembershipProps } from './element-props';
import cx from 'classnames';
import { paletteKey } from '../../../study/views/study-palette';

const NavStopWrapper = styled('span', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 17,
  textAlign: 'center',
  // fontSize: '1.2em',
  color: paletteKey('$$spacer__backgroundColor_unvisited'),
  position: 'relative',
  top: '-2px',

  '& svg': {
    transition: 'fill 0.15s ease-in-out, transform 0.2s ease-in-out',
    mixBlendMode: paletteKey('$$spacer__mixBlendMode'),
  },

  '&.current': {
    // color: '$colors$teal-500',
    color: paletteKey('$$spacer__backgroundColor_current'),
    '& svg': {
      transform: 'scale(1.4)',
    },
  },

  '&.visited': {
    // color: '$colors$teal-500',
    color: paletteKey('$$spacer__backgroundColor'),
  },
});

const Gap = styled('span', {
  display: 'inline-block',
  width: 5,
});

// membership & WordMembership.CURRENT_NAV_STOP;

export const Spacer = ({ membership }: ElementMembershipProps) => {
  if (membership & WordMembership.NAV_STOP) {
    return (
      <NavStopWrapper
        className={cx({
          visited: membership & WordMembership.VISITED,
          current: membership & WordMembership.CURRENT_NAV_STOP,
        })}
      >
        <svg
          width={5}
          height={5}
          viewBox="0 0 5 5"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <rect width={5} height={5} rx="2.5" fill="currentColor" />
          </g>
        </svg>
      </NavStopWrapper>
    );
  }

  return <Gap />;
};

// export default Spacer;
