import React from 'react';
import { AppFactory } from 'app/app-factory';
import { CollectionWidget } from './collection-widget';
import { FilterWidget } from './filter-widget';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { createLogger } from '@common/log';
import { namedObserver } from 'lib/named-observer';
const log = createLogger('featured-content');

export const FeaturedContent = namedObserver('FeaturedContent', () => {
  const { storyManager } = AppFactory.root;

  log.trace(
    `catalog url: ${storyManager.catalogUrl}, version: ${storyManager.version}, generated at: ${storyManager.generatedAt}`
  );
  // log.debug(
  //   `featured filters: ${JSON.stringify(storyManager.featuredFilters)}`
  // );
  // log.debug(
  //   `all filter sets: ${JSON.stringify(storyManager.featuredFilterSets)}`
  // );
  return (
    <>
      {/* // todo: omit already featured stories? */}
      {storyManager.featuredFilters.map(widgetData => (
        <React.Fragment key={widgetData.slug}>
          <FilterWidget widgetData={widgetData} />
          <MediumGap />
        </React.Fragment>
      ))}
      <CollectionWidget collection={storyManager.featuredCollection} />
    </>
  );
});
