import * as React from 'react';
import {
  AdaptiveLink,
  CustomActionLink,
  FullyStyledMarkdown,
  MarkSimple,
  PlainMarkdown,
} from '@naan/primitives/text/markdown';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { Text } from '@naan/primitives/text';
import { Box } from '@naan/primitives/box';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';

export const pathname = '/markdown';
export const navlabel = 'Markdown';
export const naanReady = true;

export const Screen = () => {
  return (
    <CenterColumnLayout>
      <Text textStyle="medium-heading">MarkSimple</Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <MarkSimple
          source={
            'This _will_ handle _italics_ without adding _extra_ elements.'
          }
        />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">PlainMarkdown (unstyled)</Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <PlainMarkdown
          source={
            // example markdown content
            'This is a paragraph with **bold** and _italics_ and `code`.\n\n And a second paragraph.\n\n * and also\n * a list\n\n 1. and a numbered list\n 1. with two items\n\n [and a link](https://example.com)'
          }
        />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">
        FullyStyledMarkdown (with predefined styles)
      </Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <FullyStyledMarkdown
          source={
            // example markdown content
            'This is a paragraph with **bold** and _italics_ and `code`.\n\n And a second paragraph.\n\n * and also\n * a list\n\n 1. and a numbered list\n 1. with two items\n\n [and a link](https://example.com)'
          }
        />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">
        PlainMarkdown with a custom AdaptiveLink component
      </Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <PlainMarkdown
          source={__(
            "This is an [internal inline link](%{link1}), and here's [an external one](%{link2}).",
            'showcase.exampleAdaptiveLinkMarkdown',
            {
              link1: '/app/learn/es/collections',
              link2: 'http://example.com',
            }
          )}
          components={{
            a: ({ node, ...props }) => <AdaptiveLink {...props} />,
          }}
        />
      </Box>
      <VSpacer size={8} />
      <Text textStyle="medium-heading">
        PlainMarkdown with a custom action link component
      </Text>
      <Box css={{ padding: 16, backgroundColor: '$black-alpha-06' }}>
        <PlainMarkdown
          source={__(
            'This is an ill advised [inline action](#)',
            'showcase.exampleAdaptiveLinkMarkdown'
          )}
          components={{
            a: ({ node, ...props }) => (
              <CustomActionLink
                {...props}
                action={() => {
                  // eslint-disable-next-line no-alert
                  alert('This is ill advised');
                }}
              />
            ),
          }}
        />
      </Box>
    </CenterColumnLayout>
  );
};

export const ShowcaseVolumePage = Screen;
