import * as React from 'react';
import { ChevronDownLargeIcon } from '@naan/icons/chevron-down-icon';
import { IconButton } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  background: '$white',
  color: '$colors$green-700',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  width: 48,
  height: 48,
  borderRadius: 24,
});

export const MinimizeButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Wrapper>
      <IconButton
        onClick={onClick}
        size="large"
        presentation="greenTransparent"
        icon={<ChevronDownLargeIcon />}
      />
    </Wrapper>
  );
};
