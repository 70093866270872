import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { StoryCollection } from '@core/models/catalog/featured-release';
import { collectionsPath } from 'components/nav/path-helpers';
import { CollectionCard } from '../collections/collection-card';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import __ from '@core/lib/localization';

export const CollectionWidget = observer(
  ({ collection }: { collection: StoryCollection }) => {
    if (!collection) return null;

    return (
      <>
        <SectionHeading
          title={__('Collections', 'dashboard.collections')}
          renderLinks={() => (
            <SectionHeadingLink to={collectionsPath()}>
              {__('Show all', 'dashboard.showAll')}
            </SectionHeadingLink>
          )}
        />
        <CollectionCard collection={collection} featured />
      </>
    );
  }
);
