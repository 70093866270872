import React from 'react';
import chroma from 'chroma-js';
import { observer } from 'mobx-react';
import { ExcerptData } from 'tikka/client/catalog-types';
import { Story } from '@core/models/story-manager/story';
import { styled } from '@naan/stitches.config';
import { ChevronDownExtraSmallIcon } from '@naan/icons/chevron-down-icon';
import { AppFactory } from 'app/app-factory';
import { SoundbiteStoryInfoModal } from './soundbite-story-info-modal';
import { track } from 'app/track';
import { MarkSimple } from '@naan/primitives/text';
import { stripUnderscores } from '@utils/content-string-utils';
import { formatDate } from '@utils/format-date';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$red: 255,
  $$green: 255,
  $$blue: 255,
  paddingTop: 56,
  position: 'relative',
  '& > .thumb': {
    position: 'relative',
    display: 'block',
    width: '100%',
    aspectRatio: 1,
  },
  '& > .text': {
    padding: '0 16px 20px 16px',
    color: '$colors$white',
    background: `
      linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
         rgba(0, 0, 0, 0) 33%
      ),
      linear-gradient(
        0deg,
        rgba($$red, $$green, $$blue, 0.8) 50%,
        rgba($$red, $$green, $$blue, 0) 85%
      )
    `,
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '& > h2': {
      textStyle: 'medium-heading',
    },

    '& > .meta': {
      all: 'unset',
      display: 'flex',
      textStyle: 'small-text',
      alignItems: 'center',
      '&:hover': {
        opacity: 0.8,
      },

      '& > .icon': {
        marginLeft: 3,
      },
    },
  },

  '@playerSmallAndUp': {
    backgroundColor: 'rgb($$red, $$green, $$blue)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '80px 0 48px 0',

    '& > .thumb': {
      width: 192,
      height: 192,
      aspectRatio: 'unset',
      filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2))',
      borderRadius: '8px',
    },

    '& > .text': {
      maxWidth: 600,
      marginTop: 12,
      padding: '0',
      alignItems: 'center',
      position: 'static',
      height: 'auto',
      background: 'none',
    },
  },
});

Wrapper.displayName = 'ScriptHeaderWrapper';

export const ScriptHeader = observer(
  ({ excerpt, story }: { excerpt: ExcerptData; story: Story }) => {
    const { storyManager } = AppFactory.root;
    const [r, g, b] = chroma(story.themeColor).rgb();
    const presentStoryInfo = React.useCallback(() => {
      track('soundbite__story_info_shown');
      AppFactory.dialogPresenter.present(onDismiss => (
        <SoundbiteStoryInfoModal
          excerpt={excerpt}
          story={story}
          onDismiss={onDismiss}
        />
      ));
    }, [excerpt, story]);

    return (
      <Wrapper
        css={{
          $$red: r,
          $$green: g,
          $$blue: b,
        }}
      >
        <img src={story.listImageUrl} alt={excerpt.title} className="thumb" />
        <div className="text">
          {/* @armando, todo: properly style this */}
          <h4>{formatDate(storyManager.currentDate)}</h4>
          <h2>
            <MarkSimple source={excerpt.title} />
          </h2>
          <button className="meta" onClick={presentStoryInfo}>
            {__(
              'from %{title} • %{countries}',
              'soundbite.fromTitleCountries',
              {
                /* beware, MarkSimple didn't work here because it munched the spacing around italics,
                   but not going to worry about it yet */
                title: stripUnderscores(story.title),
                countries: story.countries.join(', '),
              }
            )}
            <span className="icon">
              <ChevronDownExtraSmallIcon width={12} height={12} />
            </span>
          </button>
        </div>
      </Wrapper>
    );
  }
);
