const formatterWithCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const formatterNoCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const formatMoney = (number: number) => {
  const withCents = number % 1 !== 0;

  if (withCents) {
    return formatterWithCents.format(number);
  }

  return formatterNoCents.format(number);
};
