import React from 'react';
import { observer } from 'mobx-react';
import { useTitle } from 'common/hooks/use-title';
import __ from 'core/lib/localization';

import { PointsProgressChart } from 'components/ui/points-progress-chart';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { AppFactory } from '@app/app-factory';
import { SimpleStat } from 'components/stats/simple-stat';
import { colors } from '@naan/tokens/colors';
import { StreakIcon } from '@naan/icons/streak-icon';
import { TrophyIcon } from '@naan/icons/trophy-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { PageHeading } from 'components/page-heading';
import { PointsIcon } from '@naan/icons/points-icon';
import { HeadphonesIcon } from '@naan/icons/headphones-icon';
import { BookIcon } from '@naan/icons/book-icon';
import { StatGroup } from 'components/stats/stat-group';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { styled } from '@naan/stitches.config';

const ChartWrapper = styled('div', {
  display: 'flex',
  flex: 1,
});

export const PointsStats = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { totalPoints } = userData;
  return (
    <StatGroup>
      <ChartWrapper>
        <PointsProgressChart />
      </ChartWrapper>
      <SimpleStat
        icon={<PointsIcon />}
        stat={totalPoints.toString()}
        label={'Total points'}
        backgroundColor={colors['yellow-100']}
        iconColor={colors['yellow-500']}
        isLarge={true}
      />
    </StatGroup>
  );
});

export const SoundbiteStats = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const { currentStreak, longestStreak, totalCompleted } =
    userData.soundbiteStats;

  const backgroundColor = colors['orange-100'];
  const iconColor = colors['orange-500'];
  const colorStyles = { backgroundColor, iconColor };

  return (
    <StatGroup title={'Soundbites'}>
      <SimpleStat
        icon={<StreakIcon />}
        stat={`${currentStreak} days`}
        label={'Current soundbite streak'}
        {...colorStyles}
      />
      <SimpleStat
        icon={<TrophyIcon />}
        stat={`${longestStreak} days`}
        label={'Longest soundbite streak'}
        {...colorStyles}
      />
      <SimpleStat
        icon={<CheckmarkCircleIcon />}
        stat={totalCompleted.toString()}
        label={'soundbites completed'}
        {...colorStyles}
      />
    </StatGroup>
  );
});

export const StoryStats = observer(() => {
  const { userData } = AppFactory.root.userManager;

  const { totalListenedPretty, completedStories, completedChapters } =
    userData.storyStats;

  const backgroundColor = colors['teal-100'];
  const iconColor = colors['teal-500'];
  const colorStyles = { backgroundColor, iconColor };

  return (
    <StatGroup title={'Stories'}>
      <SimpleStat
        icon={<HeadphonesIcon />}
        stat={totalListenedPretty}
        label={'Listened'}
        {...colorStyles}
      />
      <SimpleStat
        icon={<BookIcon />}
        stat={completedChapters.toString()}
        label={'Chapters completed'}
        {...colorStyles}
      />
      <SimpleStat
        icon={<CheckmarkCircleIcon />}
        stat={completedStories.toString()}
        label={'Stories completed'}
        {...colorStyles}
      />
    </StatGroup>
  );
});

export const ProgressScreen = observer(() => {
  useTitle(__('My stats', 'progress.my-stats'));

  return (
    <CenterColumnLayout>
      <PageHeading title={'My stats'} />
      <PointsStats />
      <SoundbiteStats />
      <StoryStats />
      <MediumGap />
    </CenterColumnLayout>
  );
});
