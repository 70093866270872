import React from 'react';
import { AppFactory } from '@app/app-factory';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { styled } from '@naan/stitches.config';

import __ from '@core/lib/localization';
import { createAccountPath, signInPath } from 'components/nav/path-helpers';

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '12px 0',
  borderBottom: '1px solid $gray-100',
  '& a': {
    textDecoration: 'none',
  },
  '& .sign-in-link': {
    textStyle: 'small-text-bold',
    color: '$textSecondary',
    '&:hover': {
      color: '$gray-500',
    },
  },
  '& .create-account-link': {
    textStyle: 'small-text-bold',
    color: '$white',
    backgroundColor: '$blue-500',
    marginLeft: '16px',
    padding: '6px 12px',
    borderRadius: '16px',
    '&:hover': {
      backgroundColor: '$blue-700',
    },
  },
});

export const TrialDashboardNav = observer(() => {
  const { authenticated } = AppFactory.root.userManager;

  if (authenticated) {
    return null;
  }

  return (
    <Wrapper>
      <Link to={signInPath()} className="sign-in-link">
        {__('Sign in', 'auth.signIn')}
      </Link>
      <Link to={createAccountPath()} className="create-account-link">
        {__('Create account', 'auth.createAccount')}
      </Link>
    </Wrapper>
  );
});
