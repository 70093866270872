import { styled } from '@naan/stitches.config';

export const Sticky = styled('div', {
  $$smallHeaderHeight: '56px',
  $$mediumHeaderHeight: '64px',
  $$storyStatusHeight: '56px',
  $$assignmentBannerHeight: '56px',
  position: 'sticky',
  zIndex: 1,
});
