import React from 'react';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { styled } from '@naan/stitches.config';

import { AccountData } from 'core/models/user-manager';
import { formatDate } from 'utils/format-date';
import { Footer } from '../subscription-flow/footer';

import __ from '@core/lib/localization';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { PricingCards } from '../pricing-cards';

type Props = {
  accountData: AccountData;
};

const Wrapper = styled('div', {
  gridColumn: '1/-1',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: '$colors$gray-25',
  padding: '32px 16px',
  borderRadius: 12,
  '& h2': {
    textStyle: 'medium-heading',
    marginBottom: '$space$2',
  },
  '& h2 + div': {
    marginBottom: '$space$8',
    maxWidth: 632,
    textAlign: 'center',
  },
  '& .footer-container': {
    maxWidth: 632,
  },
  '@medium': {
    padding: '48px 16px',
    '& h2 + div': {
      marginBottom: '$space$10',
    },
  },
});

const PricingCardWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 632,
});

export const ExpiringSoon = ({ accountData }: Props) => {
  return (
    <>
      <Wrapper>
        <h2>
          {__('Extend your subscription', 'account.extendYourSubscription')}
        </h2>
        <Text>
          {__(
            'Your full access will expire on %{date}. Subscribe or purchase now to keep access to all Lupa content.',
            'account.yourFullAccessWillExpireOn',
            { date: formatDate(accountData.fullAccessUntil) }
          )}
        </Text>
        <PricingCardWrapper>
          <PricingCards />
        </PricingCardWrapper>
        <VSpacer size={8} />
        <div className="footer-container">
          <Footer presentation={'light'} showLinks={false} />
        </div>
      </Wrapper>
      <MediumGap />
    </>
  );
};
