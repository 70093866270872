import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { ChapterBreadcrumbs } from './chapter-breadcrumbs';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { ReviewIcon } from 'naan/icons/review-icon';
import { Button, IconButton } from '@naan/primitives/button';
import { Responsive } from '@naan/primitives/responsive';
import { CloseIcon } from '@naan/icons/close-icon';
import { MarkSimple } from '@naan/primitives/text';
import { HSpacer } from '@naan/primitives/spacer';

type ChapterListItemPresentationProps = {
  number: number;
  title: string;
  presentation?: 'current' | 'unvisited';
  durationText?: string;
  breadcrumbState?: number;
  checked?: boolean;
  buttons?: React.ReactNode;
  overflowMenu?: React.ReactNode;
  reviewAction?: () => void;
  hideLeadingLine?: boolean;
  hideTrailingLine?: boolean;
};

const ListItemContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  width: '100%',
  '@small': {
    // add top rule to all but first item
    '&:after': {
      content: '',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 44,
      right: 0,
      borderTop: '1px solid $colors$gray-100',
    },
    // TODO: is there a better way to target first child here?
    '#chapter-list > div > div:first-child &:after': {
      borderTop: 'none',
    },
  },
  '@medium': {
    //    Not sure why this was here? Seems safe to leave it as position relative (which is required for top rule positioning)
    //    position: 'unset',
    height: '80px',
    '&:after': {
      left: 52,
    },
  },
  '& .outter': {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '@medium': {
      flexDirection: 'row',
    },
  },
  '& .inner': {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    '@medium': {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
    },
    '& .breadcrumbs': {
      marginTop: '$space$4',
      marginBottom: '$space$5',
      '@medium': {
        marginTop: 'unset',
        marginBottom: 'unset',
        marginLeft: '$space$2',
      },
    },
    '& .right': {
      width: '100%',
      '@medium': {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
    },
  },
});

const PositionStyles = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: '$space$3',
  '@medium': {
    marginRight: '$space$5',
  },
  '& .line ': {
    display: 'flex',
    flex: 1,
    width: '2px',
    backgroundColor: '$colors$gray-100',
    minHeight: '16px',
  },
  '& .topLine': {
    display: 'flex',
    height: '16px',
    width: '2px',
    backgroundColor: '$colors$gray-100',
    '@medium': {
      flex: 1,
      height: 'unset',
    },
  },
  '& .topSpace': {
    display: 'flex',
    height: '16px',
    backgroundColor: '$colors$gray-100',
    '@medium': {
      flex: 1,
      height: 'unset',
    },
  },
  '& .bottomSpace ': {
    display: 'flex',
    flex: 1,
    backgroundColor: '$colors$gray-100',
    minHeight: '16px',
  },
});

const Circle = styled('div', {
  display: 'flex',
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  borderColor: '$colors$gray-400',
  color: '$colors$textSecondary',
  border: '2px solid',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'small-text-bold',
  variants: {
    presentation: {
      current: {
        borderColor: '$colors$teal-500',
        color: '$colors$teal-500',
      },
      unvisited: {
        borderColor: '$colors$gray-100',
        color: '$colors$gray-100',
      },
    },
  },
});

const Position = ({
  presentation,
  number,
  hideLeadingLine,
  hideTrailingLine,
}: {
  presentation: ChapterListItemPresentationProps['presentation'];
  number: number;
  hideLeadingLine?: boolean;
  hideTrailingLine?: boolean;
}) => (
  <PositionStyles>
    {!hideLeadingLine ? (
      <div className="topLine" />
    ) : (
      <div className="topSpace" />
    )}
    <Circle presentation={presentation}>{number}</Circle>
    {!hideTrailingLine ? (
      <div className="line" />
    ) : (
      <div className="bottomSpace" />
    )}
  </PositionStyles>
);

const TitleStyles = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '& .left': {
    paddingTop: '$space$5',
    paddingBottom: '$space$3',
    '@medium': {
      display: 'flex',
      flexDirection: 'row',
      padding: 'unset',
      alignItems: 'center',
    },
  },
  '& .title': {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& .duration': {
    textStyle: 'small-text',
    color: '$textSecondary',
    '@medium': {
      marginLeft: '$space$2',
    },
  },
  '& .right': {
    display: 'flex',
  },
  '& .checkmark': {
    paddingTop: '$space$5',
    color: '$colors$gray-400',
    '@medium': {
      paddingTop: '$space$7',
      marginLeft: '$space$4',
    },
  },
  '& .overflow': {
    paddingTop: '$space$3',
    marginRight: -8,
  },
  variants: {
    presentation: {
      current: {},
      unvisited: {
        color: '$colors$gray-100',
      },
    },
  },
});

const Title = ({
  presentation,
  title,
  durationText,
  checked,
  hideDurationTextInSmall,
  overflowMenu,
}: {
  presentation: ChapterListItemPresentationProps['presentation'];
  title: string;
  durationText: string;
  checked: boolean;
  hideDurationTextInSmall: boolean;
  overflowMenu?: React.ReactNode;
}) => (
  <TitleStyles presentation={presentation}>
    <div className="left">
      <div className="title">
        <MarkSimple source={title} />
      </div>
      <Responsive
        renderDefault={() =>
          hideDurationTextInSmall ? null : (
            <div className="duration">{durationText}</div>
          )
        }
        renderMediumAndUp={() => <div className="duration">{durationText}</div>}
      />
    </div>
    <div className="right">
      {checked ? (
        <div className="checkmark">
          <CheckmarkCircleIcon />
        </div>
      ) : null}
      {overflowMenu ? (
        <Responsive
          renderDefault={() => <div className="overflow">{overflowMenu}</div>}
          renderMediumAndUp={() => null}
        />
      ) : null}
    </div>
  </TitleStyles>
);

const ReviewActionStyle = styled('div', {
  display: 'flex',
  alignSelf: 'center',
  '& .openedRow': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const InvisibleButton = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
});

const ReviewAction = ({
  reviewAction,
  onPress,
  opened,
}: {
  reviewAction: () => void;
  onPress: () => void;
  opened: boolean;
}) => {
  const [reviewButtonHeight, setReviewButtonHeight] = React.useState(0);

  const targetRef = React.useRef();
  React.useLayoutEffect(() => {
    if (targetRef.current && opened) {
      setReviewButtonHeight(targetRef.current['offsetHeight']);
    } else {
      setReviewButtonHeight(0);
    }
  }, [opened]);

  const reviewButton = (
    <Button
      ref={targetRef}
      leftIcon={<ReviewIcon />}
      label={'Review from here'}
      onClick={reviewAction}
      presentation={'secondary'}
      size="large"
    />
  );

  return (
    <ReviewActionStyle>
      <Responsive
        renderDefault={() => (
          <>
            <InvisibleButton
              css={{ height: `calc(100% - ${reviewButtonHeight}px)` }}
              onClick={onPress}
            />{' '}
            {opened ? reviewButton : null}{' '}
          </>
        )}
        renderMediumAndUp={() =>
          opened ? (
            <div className="openedRow">
              {reviewButton}
              <HSpacer size="3" />
              <IconButton icon={<CloseIcon />} onClick={onPress} />
            </div>
          ) : (
            <IconButton icon={<ReviewIcon />} onClick={onPress} />
          )
        }
      />
    </ReviewActionStyle>
  );
};

export const ChapterListItemPresentation = ({
  number,
  title,
  presentation,
  durationText,
  breadcrumbState,
  checked,
  buttons,
  overflowMenu,
  reviewAction,
  hideLeadingLine,
  hideTrailingLine,
}: ChapterListItemPresentationProps) => {
  const [opened, setOpened] = React.useState(false);

  const hideDurationTextInSmall =
    !opened && checked && typeof reviewAction === 'function';
  return (
    <ListItemContainer>
      <Position
        presentation={presentation}
        number={number}
        hideLeadingLine={hideLeadingLine}
        hideTrailingLine={hideTrailingLine}
      />
      <div className="outter">
        <Title
          presentation={presentation}
          title={title}
          durationText={durationText}
          checked={checked}
          hideDurationTextInSmall={hideDurationTextInSmall}
          overflowMenu={overflowMenu}
        />
        <div className="inner">
          <div className="right">
            {buttons}
            <HSpacer size="3" />
            {overflowMenu ? (
              <Responsive renderMediumAndUp={() => overflowMenu} />
            ) : null}
          </div>
          {breadcrumbState ? (
            <div className="breadcrumbs">
              <ChapterBreadcrumbs breadcrumbState={breadcrumbState} />
            </div>
          ) : null}
        </div>
        {reviewAction ? (
          <ReviewAction
            reviewAction={reviewAction}
            onPress={() => setOpened(!opened)}
            opened={opened}
          />
        ) : null}
      </div>
    </ListItemContainer>
  );
};
