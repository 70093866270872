import * as React from 'react';
import { VStack } from 'naan/primitives/stack';

import {
  version,
  versionCheck,
  hash,
  curEnv,
  commit,
  commitTimestampIso,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';

import { VSpacer } from 'naan/primitives/spacer';
import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { ExternalLink } from '@naan/primitives/text/external-link';
import {
  embeddedBuildNumber,
  embeddedPlatform,
  postNativeMessage,
  setEmbeddedPlatform,
} from '@core/lib/app-util';

import { UserManager } from '@core/models/user-manager';
import {
  // attemptReregistration,
  hasServiceWorker,
  hasSwController,
  trySkipWaiting,
} from '../../pwa/window/update-manager';
import { flushWorkerLogs } from '../../pwa/window/log-relay';

import { Definition } from './components/definition';
import { MediumHeading } from '@naan/primitives/text';
import { checkForUpdate } from 'pwa/window/update-checker';
import { CRASH_TEST_MESSAGE_TYPE } from 'pwa/shared';

export const path = 'general';
export const label = 'General info';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const buildTimestampLocal = new Date(buildTimestampIso).toString();
  const commitTimestampLocal = new Date(commitTimestampIso).toString();

  // strip down for easier browsing
  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <MediumHeading>General Info</MediumHeading>
      <VSpacer size={6} />
      {hasServiceWorker() ? (
        <>
          <ActionLink onPress={() => checkForUpdate({ poll: false })}>
            [Check for update (SW)]
          </ActionLink>
          <ActionLink onPress={trySkipWaiting}>[Skip waiting]</ActionLink>{' '}
          <ActionLink onPress={async () => flushWorkerLogs()}>
            [Drain SW logs]
          </ActionLink>
          {/* <ActionLink onPress={async () => attemptReregistration()}>
            [Attempt reregister]
          </ActionLink> */}
          <ActionLink
            onPress={async () =>
              AppFactory.workbox.messageSW({ type: CRASH_TEST_MESSAGE_TYPE })
            }
          >
            [SW crash test]
          </ActionLink>
        </>
      ) : (
        <ActionLink onPress={() => checkForUpdate({ poll: false })}>
          [Check for update (version.txt)]
        </ActionLink>
      )}
      nav.sw: {String(hasServiceWorker())}, controller:{' '}
      {hasSwController()
        ? 'true (offline ready)'
        : 'false (either still installing or unsupported api)'}
      {/* <pre>{root.serviceWorkerLogs.join('\n')}</pre> */}
      <p>
        {root.serviceWorkerLogs.map((log, index) => (
          <React.Fragment key={index}>
            {log}
            <br />
          </React.Fragment>
        ))}
      </p>
      <Definition label={'package.json version'} value={version} />
      <Definition label={'version.txt'} value={versionCheck} />
      <Definition
        label={'last commit'}
        value={
          <ul>
            <li>{hash}</li>
            <li>{commit}</li>
            <li>{commitTimestampIso}</li>
            <li>{commitTimestampLocal}</li>
          </ul>
        }
      />
      <Definition
        label={'build timestamp'}
        value={
          <ul>
            <li>{buildTimestampIso}</li>
            <li>{buildTimestampLocal}</li>
          </ul>
        }
      />
      <Definition label={'Environment'} value={curEnv} />
      <Definition
        label={'Embedded Platform'}
        value={
          <>
            {String(embeddedPlatform())} - {String(embeddedBuildNumber())} -{' '}
            <ActionLink onPress={() => setEmbeddedPlatform('ios')}>
              set ios
            </ActionLink>
            {' - '}
            <ActionLink onPress={() => setEmbeddedPlatform('android')}>
              set android
            </ActionLink>{' '}
            {' - '}
            <ActionLink onPress={() => setEmbeddedPlatform(undefined)}>
              reset
            </ActionLink>{' '}
            (not reactive)
            <br />
            {embeddedPlatform() ? (
              <>
                <ActionLink
                  onPress={() => postNativeMessage({ type: 'SHOW_DEBUG' })}
                >
                  show wrapper debug screen (wip)
                </ActionLink>{' '}
                <br />
              </>
            ) : null}
            purchaseFlowDisabled: {String(userManager.purchaseFlowDisabled)}
            <br />
            <ExternalLink href="https://radioambulante.org">
              Go to radio ambulante &rarr;
            </ExternalLink>
          </>
        }
      />
    </VStack>
  );
});
