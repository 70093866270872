import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import __ from '@core/lib/localization';

const HeadingWrapper = styled('div', {
  background: '$green-600',
  color: '$white',
  padding: '$3 0',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: '12px 12px 0 0',
  '& > .title': {
    marginLeft: '$4',
    textStyle: 'small-heading',
  },
  '& > .count': {
    marginLeft: '$2',
    textStyle: 'small-text',
  },
  '& > .button': {
    marginLeft: 'auto',
    marginRight: '$1',
  },
});

export const VocabListHeading = observer(
  ({ count, onClose }: { count: number; onClose: () => void }) => {
    return (
      <HeadingWrapper>
        <span className="title">{__('Vocabulary', 'common.vocabulary')}</span>
        <span className="count">
          {__('%{count} items', 'common.countItems', { count })}
        </span>
        <div className="button">
          <Button
            onClick={onClose}
            label={__('Close', 'common.close')}
            presentation="whiteTransparent"
          />
        </div>
      </HeadingWrapper>
    );
  }
);
