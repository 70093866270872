import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { Badge } from 'naan/primitives/badge';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';

const VolumeCardMetaWrapper = styled('div', {
  marginBottom: '$2',
  lineHeight: '20px',
  '@small': {
    marginBottom: '0',
  },
});

export const VolumeCardMeta = ({ story }: { story: Story }) => {
  const isFree = story.trial;

  return (
    <VolumeCardMetaWrapper>
      {isFree ? <Badge>Free</Badge> : null}
      <Text as="span" textStyle={'small-text'} color={'textSecondary'}>
        {story.classroomDurationDescription}
      </Text>
    </VolumeCardMetaWrapper>
  );
};
