import * as React from 'react';
import * as PC from './pricing-card';
import { observer } from 'mobx-react';
import { PricingCardsWrapper } from './pricing-cards-wrapper';

export const AnonPricingCards = observer(() => {
  return (
    <>
      <PricingCardsWrapper>
        <PC.Container presentation={'anonymous'}>
          <PC.Heading>{'Monthly'}</PC.Heading>
          <PC.PriceContainer>
            <PC.PricePrefix>US</PC.PricePrefix>
            <PC.PriceAmount>$12</PC.PriceAmount>
            <PC.PriceInterval>{'/MO'}</PC.PriceInterval>
          </PC.PriceContainer>
          <PC.Description>Billed monthly</PC.Description>
        </PC.Container>
        <PC.Container presentation={'anonymous'}>
          <PC.Ribbon ribbonTitle={'Save 31%'} />
          <PC.Heading>{'1 year'}</PC.Heading>
          <PC.PriceContainer>
            <PC.PricePrefix>US</PC.PricePrefix>
            <PC.PriceAmount>$8.50</PC.PriceAmount>
            <PC.PriceInterval>{'/MO'}</PC.PriceInterval>
          </PC.PriceContainer>

          <PC.Description>
            <PC.Bold>
              <PC.Scratch className="cross">$144 </PC.Scratch>
            </PC.Bold>
            <PC.Bold>$99 </PC.Bold>
            one-time purchase
          </PC.Description>
        </PC.Container>
      </PricingCardsWrapper>
    </>
  );
});
