import React from 'react';
import { styled } from 'naan/stitches.config';
import { Text } from 'naan/primitives/text';

const maxBarHeight = 120;

const ChartContainer = styled('div', {
  $$barLabelColor: '$colors$textPrimary',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  borderTop: '1px solid $black-alpha-10',
  '& > * + *': {
    marginLeft: '$space$1',
    '@small': {
      marginLeft: '$space$2',
    },
  },
  variants: {
    zeroState: {
      true: {
        $$barLabelColor: '$colors$gray-300',
      },
    },
  },
});

const Bar = styled('div', {
  backgroundColor: '$yellow-500',
  borderRadius: '$space$1',
  marginBottom: '$space$1',
});

const BarContainer = styled('div', {
  flex: 1,
  position: 'relative',

  '& .bar-label': {
    textAlign: 'center',
    textStyle: 'tiny-text',
    color: '$$barLabelColor',
  },
});

const HighScoreWrapper = styled('div', {
  position: 'absolute',
  transform: 'translate(-50%, -100%)',
  left: '50%',
  paddingBottom: '$space$1',
});

const HighScoreText = styled(Text, {
  textAlign: 'center',
  color: '$black-alpha-50',
});

const BarSegment = ({
  dayAbbreviation,
  listeningBarHeight,
  showHighScoreLabel,
  highScore,
}: {
  dayAbbreviation: string;
  listeningBarHeight: number;
  showHighScoreLabel: boolean;
  highScore: number;
}) => (
  <BarContainer>
    {showHighScoreLabel ? (
      <HighScoreWrapper>
        <HighScoreText textStyle={'small-text-bold'}>{highScore}</HighScoreText>
      </HighScoreWrapper>
    ) : null}
    <Bar css={{ height: listeningBarHeight }} />
    <span className="bar-label">{dayAbbreviation}</span>
  </BarContainer>
);

export const BarChart = ({
  viewData,
}: {
  viewData: {
    highestDayPoints: number;
    graphData: { letter: string; points: number }[];
  };
}) => {
  const { highestDayPoints, graphData } = viewData;
  const arrPoints = graphData.map(el => el.points);
  const highScoreIndex = arrPoints.lastIndexOf(highestDayPoints);
  const isZeroState = viewData.highestDayPoints === 0;

  return (
    <ChartContainer zeroState={isZeroState}>
      {viewData.graphData.map(({ letter, points }, i) => (
        <BarSegment
          key={i}
          dayAbbreviation={letter}
          listeningBarHeight={(points / highestDayPoints) * maxBarHeight}
          showHighScoreLabel={i === highScoreIndex && !isZeroState}
          highScore={highestDayPoints}
        />
      ))}
    </ChartContainer>
  );
};
