import React from 'react';
import { VStack } from 'naan/primitives/stack';

import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { CatalogSelection } from 'components/admin/catalog-selection';

import { UserManager } from '@core/models/user-manager';
import { MediumHeading } from '@naan/primitives/text';
import { Definition } from './components/definition';

export const path = 'catalog';
export const label = 'Catalog stuff';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <MediumHeading>Catalog Stuff</MediumHeading>
      <Definition label={'Catalog (cali)'} value={<CatalogSelection />} />
    </VStack>
  );
});
