import React from 'react';
import { observer } from 'mobx-react';
import {
  PauseControlIcon,
  PlayControlIcon,
  RequestPauseControlIcon,
  SimplePauseControlIcon,
} from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';
import { createLogger } from 'app/logger';

const log = createLogger('player-controls:play-pause-button');

const SimplePlayPauseButton = observer(() => {
  const model = usePlayerModel();
  const {
    player: { transportState },
    playActionDisabled,
  } = model;

  const paused = !transportState.isPlaying;

  return (
    <ControlButton
      onClick={() => model.playPauseAction()}
      disabled={playActionDisabled}
    >
      {paused ? <PlayControlIcon /> : <SimplePauseControlIcon />}
    </ControlButton>
  );
});

const ComplexPlayPauseButton = observer(() => {
  const model = usePlayerModel();
  const {
    player: { transportState },
    pauseDurationInMs,
    currentSentencePercentagePlayedInPendingPaused: initialPercentage,
    playActionDisabled,
  } = model;
  // log.debug(`atAudioEnd: ${String(atAudioEnd)}`);

  // console.log({
  //   currentSentencePercentagePlayedInPendingPaused: initialPercentage,
  // });

  React.useEffect(() => {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.setActionHandler('pause', function () {
        log.info('activated hardware pause');
        model.pause();
      });
      navigator.mediaSession.setActionHandler('play', function () {
        log.info('activated hardware play');
        model.play();
      });

      return () => {
        navigator.mediaSession.setActionHandler('pause', null);
        navigator.mediaSession.setActionHandler('play', null);
      };
    }
  }, [model, transportState]);

  // Do something more than just pausing audio.

  const paused = !transportState.isPlaying;
  const pendingPause = transportState.pendingPause;

  return (
    <ControlButton
      onClick={() => model.playPauseAction()}
      disabled={playActionDisabled}
    >
      {paused ? (
        <PlayControlIcon />
      ) : pendingPause ? (
        <PauseControlIcon
          durationInMs={pauseDurationInMs}
          initialPorcentage={initialPercentage}
        />
      ) : (
        <RequestPauseControlIcon />
      )}
    </ControlButton>
  );
});

export const PlayPauseButton = observer(({ complex }: { complex: boolean }) => {
  if (complex) {
    return <ComplexPlayPauseButton />;
  }
  return <SimplePlayPauseButton />;
});
