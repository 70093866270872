import { ModelTreeNode, identifier, frozen } from 'ts-state-tree/tst-core';
import { ChapterCatalogData } from './chapter-catalog-data';
import { getBaseRoot } from '../app-root';
import { Notation, Chapter } from '@tikka/client/client-aliases';
import { IElement, StringToString } from '@tikka/basic-types';
import { fromIntervals } from '@tikka/intervals/intervals';
import {
  millisToMinutes,
  minutesToPrettyDuration,
} from '@core/lib/pretty-duration';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';
import { bugsnagNotify } from '@app/notification-service';
import { isBogotaVocabSlug } from '../story-manager/story';

const log = createLogger('unit-catalog-data');

// correlates to UnitCaliData masala schema
export class UnitCatalogData extends ModelTreeNode {
  static CLASS_NAME = 'UnitCatalogData' as const;

  @identifier
  slug: string = '';
  volumeSlug: string = ''; // parent ref

  unitNumber: number = 0;
  // partTitleSuffix: string = null;
  durationMillis: number = 0;

  chapters: ChapterCatalogData[] = [];
  elements: /*IElement*/ object[] = []; // data needed to display vocab from lupa web story details

  // @jason is there a good way to just stuff this data in as a plain object? ('object' didn't seem to work)
  @frozen
  bogotaVocabMigrationMap: StringToString = {};
  // bogotaVocabMigrationMap: TSTStringMap<string> = snap({});

  static create(snapshot: any) {
    return super.create(UnitCatalogData, snapshot) as UnitCatalogData;
  }

  get story() {
    const { storyManager } = getBaseRoot(this);
    if (!storyManager) return null;
    return storyManager.story(this.storySlug);
  }

  // resolve the slug of the first unit if we're in a multi-unit story
  get storySlug() {
    return this.volumeSlug;
  }

  get partLabel() {
    // return this.partSuffix ? `Part ${this.partSuffix}` : null;
    return `Part ${this.unitNumber}`; // todo: support an override / localization when needed
  }

  get chapterCount() {
    return this.chapters?.length || 0;
  }

  // todo: consider resolving during ingestion or catalog load
  get isLastUnit() {
    return this.unitNumber === this.story.unitCount + 1;
  }

  get durationMinutes(): number {
    return millisToMinutes(this.durationMillis);
  }

  get durationDescription(): string {
    const duration = minutesToPrettyDuration(this.durationMinutes);
    return duration;
  }

  // todo: figure out how to deal with the json schema gen
  get iElements(): IElement[] {
    return this.elements as IElement[];
  }

  get notations(): Notation[] {
    const chapters = this.iElements.filter(
      el => el.kind === 'CHAPTER'
    ) as Chapter[];
    const chapterIntervals = fromIntervals(
      chapters.map(ch => ({ begin: ch.address, end: ch.endAddress }))
    );
    const notations = this.iElements.filter(
      el => el.kind === 'NOTATION'
    ) as Notation[];
    for (const notation of notations) {
      notation.unit = this.unitNumber;
      notation.chapter = chapterIntervals.containing(notation.address) + 1; // transform "chapterPosition" to ChapterRef prop
    }
    return notations;
  }

  async fetchDataAndMigrateVocabSlugs(vocabSlugs: string[]): Promise<string[]> {
    try {
      const fullVolumeData =
        await AppFactory.root.storyManager.loadVolumeDataUrl(
          this.story.volumeDataUrl
        );
      const fullUnitData = fullVolumeData.unitDataBySlug(this.slug);
      return fullUnitData.migrateBogotaVocabSlugs(vocabSlugs);
    } catch (error) {
      log.error(
        `fetchDataAndMigrateVocabSlugs - error fetching data for unit: ${this.slug}`,
        error
      );
      bugsnagNotify(error as Error);
      return vocabSlugs;
    }
  }

  migrateBogotaVocabSlugs(vocabSlugs: string[]): string[] {
    const result = vocabSlugs.map(slug => this.bogotaToCaliVocabSlug(slug));
    return result;
  }

  bogotaToCaliVocabSlug(slug: string) {
    if (isBogotaVocabSlug(slug)) {
      const candidate = this.bogotaVocabMigrationMap[slug];
      if (candidate) {
        return candidate;
      }
      const candidates = this.fuzzyMatchedVocabSlugs(slug);
      if (candidates.length === 1) {
        const candidate = candidates[0];
        log.warn(
          `fuzzy matching unique for bogota slug: ${slug}, matched: ${candidate}`
        );
        return candidate;
      } else {
        log.warn(
          `fuzzy matching non-unique for bogota slug: ${slug}, matches: ${candidates.length} - ignoring`
        );
      }
    }
    return slug;
  }

  fuzzyMatchedVocabSlugs(slug: string): string[] {
    const slugWord = slug.split('-')[1];
    const result: string[] = [];
    for (const [key, value] of Object.entries(this.bogotaVocabMigrationMap)) {
      const keyWord = key.split('-')[1];
      if (slugWord === keyWord) {
        result.push(value);
      }
    }
    return result;
  }
}
