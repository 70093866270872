import * as React from 'react';

import { styled } from '@naan/stitches.config';
import { ArrowLeftIcon } from '@naan/icons/arrow-left-icon';
import { IconButton } from '@naan/primitives/button';
import { Responsive } from '@naan/primitives/responsive';
import { StoryStatusBar } from 'components/story-status/story-status-bar';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { MarkSimple } from '@naan/primitives/text';

const BackButton = ({ backAction }: { backAction: () => void }) => (
  <IconButton
    presentation={'whiteTransparent'}
    icon={<ArrowLeftIcon />}
    onClick={backAction}
  />
);

const HeaderContainer = styled('div', {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  height: '56px',
  margin:
    '0 -8px 0 -10px' /* negative margins here visually align the left/right icons with grid */,
  '@medium': {
    height: '64px',
  },
  alignItems: 'center',
  justifyContent: 'space-between',
  '& .title': {
    flex: 1,
    color: '$white',
    textAlign: 'center',
    textStyle: 'body-bold',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '@medium': {
      textStyle: 'small-heading',
      textAlign: 'left',
      marginLeft: '22px',
    },
  },
  '& .right': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  '& .overflowMenu': {
    alignSelf: 'center',
    marginLeft: 8,
  },
});

type StoryHeaderSimpleProps = {
  backAction: () => void;
  backgroundColor: string;
  title: string; //React.ReactNode;
  showTitle: boolean;
  statusBar?: React.ReactNode;
  overflowMenu: React.ReactNode;
};

export const StoryHeaderSimple = ({
  backAction,
  backgroundColor = 'currentColor',
  title,
  showTitle,
  statusBar,
  overflowMenu,
}: StoryHeaderSimpleProps) => (
  <HeaderContainer css={{ backgroundColor }}>
    <BackButton backAction={backAction} />
    {showTitle ? (
      <div className="title">
        <MarkSimple source={title} />
      </div>
    ) : null}
    <div className="right">
      <Responsive renderMediumAndUp={() => statusBar} />
      <div className="overflowMenu">{overflowMenu}</div>
    </div>
  </HeaderContainer>
);

type StoryHeaderProps = {
  backAction: () => void;
  overflowMenu: React.ReactNode;
};

export const StoryHeader = observer(
  ({ backAction, overflowMenu }: StoryHeaderProps) => {
    const showTitle = storyDetailUiModel.showTitleInHeader;
    const { themeColor, title } = useCurrentStory();
    return (
      <CenterColumnLayout backgroundColor={themeColor}>
        <StoryHeaderSimple
          backAction={backAction}
          backgroundColor={themeColor}
          title={title}
          showTitle={showTitle}
          statusBar={<StoryStatusBar />}
          overflowMenu={overflowMenu}
        />
      </CenterColumnLayout>
    );
  }
);
