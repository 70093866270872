import { nanoid } from 'nanoid';
import { LogLevel, logLevels } from '@common/log/logger-types';

const l = window.location;
const baseUrl = l.protocol + '//' + l.hostname + (l.port ? `:${l.port}` : '');
const accountUrl = `${baseUrl}/app/profile/account`;
const learnDashboardUrl = `${baseUrl}/app/learn/es/home`;

export const sessionId = nanoid(); // beware, uuid was barfing with a 'global is not defined' error

//
// base config data
//
// any supported property should have a default value provide here
//

export const config = {
  VITE_CONFIG_ENV: import.meta.env.VITE_CONFIG_ENV, // for debug reference

  appName: 'MyLupa',
  appSlug: 'myLupa',
  productFlavor: null as string, // TODO: confirm what this was intended for
  appTitle: import.meta.env.VITE_SITE_TITLE ?? '',

  forcedApiEnv: import.meta.env.VITE_DEFAULT_API_ENV ?? 'devtest',

  // endpoints for which CORS is disabled
  // needed for for the preview deploys to work, but we can't use cross domain cookie
  promiscuousApiEnvs: ['devtest', 'jfedev'],

  importApiEnv: 'prod', // used for user data migration testing
  autoImportForNewAccount: false,
  showImportAction: true, // for now true except in prod

  swCheckEnabled: true, // false implies legacy version.txt checker (except neither on DEV builds)
  updateCheckIntervalMs: 1 * 60 * 1000, // 1 min check interval for internal deployments

  // todo: reorganize all these top level flags
  simulateMissingServiceWorker: false, // override to true to test dump poll flows (ios webview)

  // no longer a config, will used automatically if swCheck disabled or unavailable (but never on dev builds)
  // legacyVersionCheckerEnabled: false,
  // legacyVersionCheckerIntervalMs: 1 * 60 * 1000, // for testing for now (only relevant to ios wrapper)

  inviteGateEnabled: true, // true except for prod
  piMenuEnabled: true, // true except beta and prod

  hideDashboardOnboarding: false, // true on beta, will hide the dashboard onboarding flow until ready for prime-time

  manifestVersion: 'proto',

  // putting this in config to it's accessible by both analytics and loggly without cross dependency
  // perhaps belongs in a dedicated source file
  sessionId,

  // volumeMode: true,

  jiveworldApiKey: {
    website: 'QowNmFgzVLu4WnattXzN', // for now, shared for all deployment environments
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786',
    enabled: !import.meta.env.DEV, // disable by default for local dev builds
  },

  invariants: {
    log: false, // simple console log, not needed when 'alert' enabled
    report: true, // to bugsnag
    alert: true, // attempt red toast
    // will possibly want to turn this on by default for dev, but want to clean up a few things first in a separate PR
    throw: false, // hard error (likely render failure) // !!import.meta.env.DEV
  },

  // enables red toast for unexpected errors
  showUnhandledErrors: true, // on by default except for lupa-prod, beta
  // include raw error message in toasts for generically handled exceptions
  showErrorDetails: true, // on by default except for lupa-prod, beta

  i18n: {
    showBrackets: false,
    forcedLocale: null as string,
    bypass: false,
  },

  google: {
    clientId:
      '903123262392-86hnsj69ds3oatf615tuf7q4vf4kajh7.apps.googleusercontent.com',
  },

  stripe: {
    publishableKey: 'pk_test_Zri5LbMMapbrkavc5yW3Z1fV00rJUj8DZw',
  },

  analytics: {
    //'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // internal testing
    segmentWriteKey: null as string, // segment is disabled if no key provided, enabled when provided
    enableSimulatedAnalytics: false,
    // this was poorly named. it's actually forcing all anonymous users to have the fixed '_anonymous_' id
    // instead of the using a default behavior of a local storage peristed unique id
    // enableAnonymous: true,
    coalesceAnonymous: true,
    eventPrefix: 'w',
    googleAnalyticsKey: null as string, //'UA-143472151-6', // set to null to disable
  },

  logging: {
    // `enableConsoleLog` when true, the logger‘s use of console.log is short-circuited
    enableConsoleLog: !!import.meta.env.DEV,

    minimumLogLevel: logLevels.debug as LogLevel,

    // note, even when enabled, the current session still needs to be activated to start sending data
    enableLoggly: true, // allow to always be enabled (but not active) by default
    logglyToken: 'f3cc9f4c-d6fe-453c-8f16-8141da497da3',
    logKeyDowns: false,
  },

  alertUnhandledErrors: false,

  website: {
    baseUrl,
    staticPath: `/assets`,
    accountUrl,
    // checkoutSuccessUrl: `${accountUrl}?checkout=success`,
    checkoutSuccessUrl: `${learnDashboardUrl}?checkout=success`,
    checkoutFailureUrl: `${accountUrl}?checkout=error`,
    // this allows sharing cookies between the service tier and website
    cookieDomain: '.jiveworld.com',

    urls: {
      marketingSite: 'https://www.lupa.app',
      downloadApp: 'https://get.lupa.app',
      // jwPressServer: 'https://resources.jiveworld.com',
      // until latest spa code deployed to production
      jwPressServer: 'https://netlify-staging--jw-resources.netlify.app',
      contactUs: 'https://www.lupa.app/contact-us',
      support: 'https://support.lupa.app',
      spa: 'https://my.lupa.app',
      facebookTeachersGroup: 'https://www.facebook.com/groups/jiveworld',
      ourMethod: 'https://www.jiveworld.com/en/our-method/',
    },
    emails: {
      feedback: 'feedback@jiveworld.com',
      support: 'support@jiveworld.com',
      educationalSales: 'sales@jiveworld.com',
    },
    emailOptions: {
      subject: 'My feedback on Lupa',
      body: "Please explain what worked, what didn't work, and what you'd like to see improved",
    },
    downloadUrls: {
      appStore:
        'https://apps.apple.com/us/app/lupa-learn-spanish-fluently/id1480744663?ls=1',
      playStore:
        'https://play.google.com/store/apps/details?id=com.jiveworld.lupa',
    },
  },

  classrooms: {
    exampleLessonPlansUrl: 'https://go.lupa.app/lupa-lessons',
  },

  soundbites: {
    demoDataUrl:
      'https://storage.googleapis.com/jw-devtest-pub/ra/ra-luna-llena-sobre-chiapas/excerpt/ra-luna-llena-sobre-chiapas-1-ra-luna-llena-sobre-chiapas-1-qlj-v5.json',
    // listEndpoint: 'https://masala-v2-server.editorial.jiveworld.com/excerpts',
    // point to devtest by default now. will probably add an url ref from the main catalog
    listEndpoint: 'https://masala-v2-server.devtest.jiveworld.com/excerpts',
    formUrl: 'https://go.lupa.app/sb-signup',
    // globalFooterLink: true, // provide handy access from preview deploys
  },

  player: {
    debug: true, // turns on the '~' debug mode by default when either study or soundbite player is loaded
    enableOldIosScrollFix: false,
  },

  masalaBaseUrl: 'https://masala.editorial.jiveworld.com',

  // allow disabling of the purchase flows when embedded to native app
  iosNoPurchase: false, // todo: flip these to server driven flags
  androidNoPurchase: false,
  forceNoPurchase: false, // simulate disallowed purchase for easier testing
};
