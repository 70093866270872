import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { msToPoints } from './listening-stats';

/**
 * ListeningLog
 *
 * holds study stats for a given day and story
 */
export class ListeningLog extends ModelTreeNode {
  static CLASS_NAME = 'ListeningLog' as const;

  static create(snapshot: any) {
    return super.create(ListeningLog, snapshot) as ListeningLog;
  }

  static mapKey(storySlug: string, date: string): string {
    return [storySlug, date].join('.');
  }

  // granularity: string = undefined; // 'daily' implied when undefined, MONTH for coalesced data
  date: string = ''; // iso date (w/ time part truncated now after migration)
  storySlug: string = '';
  listenedMillis: number = 0;
  relistenedMillis?: number; // deprecated, only needed for legacy data migration input parsing

  get mapKey(): string {
    return ListeningLog.mapKey(this.storySlug, this.date);
  }

  get points(): number {
    return msToPoints(this.listenedMillis);
  }
}
