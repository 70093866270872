import { appConfig } from 'app/env';
import * as NotificationService from 'app/notification-service';
// import Bugsnag from '@bugsnag/browser';
// import Event from '@bugsnag/core/types';
import { createLogger } from '@common/log';
import { HandleableError } from '@core/lib/errors';
import { alertLevels } from '@core/lib/errors/alert-levels';

const { ERROR, WARN /*, NONE*/ } = alertLevels;

const log = createLogger('global-error-handler');

// this is perhaps better named "bugsnagCustomHook" it’s our chance to do something with the error after
// bugsnag has done its own handling. this will even get called when Bugsnag.notify() is used
export const globalErrorHandler = (
  event: any /*Event TODO - figure out typing here - this appears to be just the plain error message string */
) => {
  // // gratuitous logging until we make sure we're sure everything we care about gets into bugsnag
  // try {
  //   log.error(`event: ${JSON.stringify(event)}`);
  // } catch (e) {
  //   console.log(`error logging error:`, e);
  // }
  // console.trace();

  let error: HandleableError = null;
  try {
    error =
      event.originalError || (event.errors ? event.errors[0] : Error(event));
  } catch (e) {
    log.error(`failed to extract error from bugsnag event`);
    return;
  }

  const alertLevel = error?.alertLevel || ERROR;
  /* the 'message' prop seems to get munged in some flows so we need to adapt to either here */
  const message =
    error?.message ?? (error as any)?.errorMessage ?? 'Unhandled error';

  log.info(`alertLevel: ${alertLevel}, message: ${message}`);

  if (appConfig.showUnhandledErrors) {
    switch (alertLevel) {
      case ERROR:
        NotificationService.alertError(message, {
          report: false, // do not re-send to bugsnag - todo: refactoring this circular API!!
        });
        break;

      case WARN:
        NotificationService.alertWarning(message);
        break;

      default:
        log.info('no toast');
    }
  }
};
