import React from 'react';
import { ContentGridInnerContainer } from 'components/global-layout';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import * as CatalogStuff from './dev-catalog';
import * as Toggles from './dev-toggles';
import * as VideoGuidesStuff from './dev-videoguides';
import * as GeneralInfoStuff from './dev-general-info';
import * as ErrorStuff from './dev-errors';
import * as UsefulLinks from './dev-links';
import * as DataDump from './dev-data-dump';

import { Navigate, NavLink, Route, Routes } from 'react-router-dom';

const screens = [
  GeneralInfoStuff,
  CatalogStuff,
  Toggles,
  VideoGuidesStuff,
  ErrorStuff,
  UsefulLinks,
  DataDump,
];

const Tabs = styled('div', {
  display: 'flex',
  padding: '24px 0',

  '& a': {
    all: 'unset',
    textStyle: 'body-bold',
    backgroundColor: '$transparent',
    color: '$textSecondary',
    px: '$space$4',
    py: '$space$2',
    borderRadius: '$space$2',
    cursor: 'pointer',
    transition: 'color 0.15s ease, opacity 0.2s ease',
    '&:hover': {
      color: '$teal-500',
    },
    '&.selected': {
      color: '$teal-600',
      backgroundColor: '$teal-50',
    },
  },

  '&:hover': {
    '& a:not(:hover)': {
      opacity: 0.5,
    },
  },
});

const Wrapper = styled('div', {
  padding: '24px 12px',
  border: '1px solid $gray-50',
  borderRadius: '$space$2',
});

export const DevToolsScreen = observer(() => {
  return (
    <ContentGridInnerContainer>
      <Tabs>
        {screens.map((screen, index) => (
          <NavLink
            key={index}
            className={({ isActive }) => (isActive ? 'selected' : '')}
            to={screen.path}
          >
            {screen.label}
          </NavLink>
        ))}
      </Tabs>
      <Wrapper>
        <Routes>
          <Route path="/" element={<Navigate to="general" />} />
          {screens.map((screen, index) => (
            <Route
              key={index}
              path={screen.path}
              element={<screen.Component />}
            />
          ))}
        </Routes>
      </Wrapper>
    </ContentGridInnerContainer>
  );
});
