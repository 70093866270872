import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { PlusExtraSmallIcon } from '@naan/icons/plus-icon';
import { MinusExtraSmallIcon } from '@naan/icons/minus-icon';
import { IconButton } from '@naan/primitives/button';
import { SnailIcon } from '@naan/icons/snail-icon';
import { usePlayerModel } from '../player-model-context';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  marginLeft: -4, // visually align speed control with script
  '& > .current': {
    textStyle: 'body-bold',
  },
});

const SnailButton = styled('button', {
  all: 'unset',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$red-500',
  width: 80,
  height: 40,
  borderRadius: '$space$2',
  '&:hover': {
    backgroundColor: '$red-50',
  },
});

export const DesktopPlaybackRateControl = observer(() => {
  const model = usePlayerModel();
  const { player } = model;
  const { transportState } = player;

  if (model.inSnailReplayMode) {
    return (
      <SnailButton
        onClick={() => model.handleSnailReplayRestore(true /*force*/)}
      >
        <SnailIcon />
      </SnailButton>
    );
  }

  return (
    <Wrapper>
      <IconButton
        size={'extraSmall'}
        presentation={'grayTransparent'}
        onClick={() => {
          player.adjustPlaybackRateAction(false);
        }}
        icon={<MinusExtraSmallIcon />}
      ></IconButton>
      <span className="current">{` ${transportState.playbackRate.toFixed(
        1
      )}x`}</span>
      <IconButton
        size={'extraSmall'}
        presentation={'grayTransparent'}
        onClick={() => {
          player.adjustPlaybackRateAction(true);
        }}
        icon={<PlusExtraSmallIcon />}
      ></IconButton>
    </Wrapper>
  );
});
