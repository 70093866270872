import * as DialogPrimitive from '@radix-ui/react-dialog';
import React from 'react';
import { styled } from 'naan/stitches.config';
// import { isFunction } from 'lodash';

import { AlertTriangleIcon } from 'naan/icons/alert-triangle-icon';
import { AlertIcon } from 'naan/icons/alert-icon';
import { InfoCircleIcon } from 'naan/icons/info-circle-icon';
import { CheckmarkCircleIcon } from 'naan/icons/checkmark-circle-icon';

const css = {
  $$textColor: '$textPrimary',
  color: '$$textColor',
  textStyle: 'small-heading',
  padding: '$space$4 0',
  margin: '0 $space$4',
  marginBottom: '0',
  display: 'flex',
  alignItems: 'center',

  '& > .icon': {
    lineHeight: 0,
    marginRight: '$space$1',
  },
  variants: {
    presentation: {
      success: {
        $$textColor: '$colors$green-600',
      },
      warning: {
        $$textColor: '$colors$yellow-700',
      },
      error: {
        $$textColor: '$colors$red-500',
      },
    },
    withBorder: {
      true: {
        borderBottom: '1px solid $colors$gray-100',
      },
    },
  },
};

const RadixHeadingStyles = styled(DialogPrimitive.Title, css as any);

const PlainHeadingStyles = styled('header', css as any);

type HeadingStylesProps = React.ComponentProps<typeof RadixHeadingStyles>;

function useCorrectIcon(type: HeadingStylesProps['presentation']) {
  const icon = React.useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoCircleIcon />;
      case 'success':
        return <CheckmarkCircleIcon />;
      case 'warning':
        return <AlertIcon />;
      case 'error':
        return <AlertTriangleIcon />;
      default:
        break;
    }
    return null;
  }, [type]);

  return icon;
}

export const Heading: React.FC<{
  type?: HeadingStylesProps['presentation'];
  withBorder?: boolean;
}> = ({ children, type, withBorder = false }) => {
  const icon = useCorrectIcon(type);

  return (
    <RadixHeadingStyles presentation={type} withBorder={withBorder}>
      {icon ? <span className="icon">{icon}</span> : null}

      {children}
    </RadixHeadingStyles>
  );
};

export const HtmlHeading: React.FC<{
  type?: HeadingStylesProps['presentation'];
  withBorder?: boolean;
}> = ({ children, type, withBorder = false }) => {
  const icon = useCorrectIcon(type);

  return (
    <PlainHeadingStyles presentation={type} withBorder={withBorder}>
      {icon ? <span className="icon">{icon}</span> : null}

      {children}
    </PlainHeadingStyles>
  );
};
