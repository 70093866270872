import * as React from 'react';
import { namedStyled } from '@naan/stitches.config';
import { PlayerViewController } from './player-view-controller';
import { observer } from 'mobx-react';
import { SpinnerIcon } from '../icons/spinner-icon';

type Props = {
  src: string;
  onEnded: () => void;
  renderPreviewCard: (playerModel: PlayerViewController) => React.ReactNode;
  renderEndCard: (playerModel: PlayerViewController) => React.ReactNode;
};

const Wrapper = namedStyled('VideoPlayerWrapper', 'div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '& > video': {
    width: '100%',
  },
  '& > .card': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  '& > .pre-card': {
    color: 'white',
    backgroundColor: '$blue-700',
    zIndex: 2,
  },
  '& > .end-card': {
    color: 'white',
    backgroundColor: '$blue-700',
    zIndex: 2,
  },
  '& > .transition-card': {
    color: 'white',
    backgroundColor: '$blue-700',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const player = new PlayerViewController();

export const VideoPlayer: React.FC<Props> = observer(
  ({ src, onEnded, renderEndCard, renderPreviewCard }) => {
    const videoElementRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
      if (videoElementRef.current) {
        player.initialize({
          url: src,
          videoElement: videoElementRef.current,
          onEnded: () => {
            onEnded();
          },
        });
      }

      return () => {
        if (player) {
          // we most likely will want to reset the player state, too
          player.mediaPlayer.reset();
        }
      };
    }, [onEnded, src]);

    return (
      <Wrapper>
        {player.isTransitioning ? (
          <div className="card transition-card">
            <SpinnerIcon />
          </div>
        ) : null}
        {player.isNotStarted ? (
          <div className="card pre-card">{renderPreviewCard(player)}</div>
        ) : null}
        {player.isEnded ? (
          <div className="card end-card">{renderEndCard(player)}</div>
        ) : null}
        <video ref={videoElementRef} controls={true} autoPlay={false} />
      </Wrapper>
    );
  }
);
