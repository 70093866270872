import * as React from 'react';

import cx from 'classnames';
import { RedactionMode } from 'player/models/redaction-modes';
import { WordMembership } from '../../../player/models/word-membership';
import { WordElementProps } from './element-props';
import { Spacer } from './spacer';
import { namedStyled } from 'naan/stitches.config';
import { stripTrailingPunctuation } from '@tikka/misc/string-utils';
import { paletteKey } from '../../../study/views/study-palette';

const WordWrapper = namedStyled('WordWrapper', 'div', {
  display: 'inline',
  lineHeight: '1.5em',
  backgroundColor: 'transparent',
  '&.selected': {
    backgroundColor: '#ffed8f',
  },
});

const WordText = namedStyled('Wordtext', 'span', {
  $$borderColor: 'transparent',
  display: 'inline-block',
  // color: '#b4d455',
  // borderBottom: '1.5px solid $$border-color)',
  '&.italic': {
    fontStyle: 'italic',
  },
});

WordText.displayName = 'WordText';

const RedactedOuter = namedStyled('RedactedOuter', 'span', {
  $$redactedBg: paletteKey('$$redacted__backgroundColor_unvisited'),
  $$textColor: paletteKey('$$word__color_default'),
  display: 'inline-block',
  color: 'transparent',
  position: 'relative',
  background: 'transparent',

  '& > .word': {
    display: 'inline-block',
    color: '$$textColor',
    opacity: 0,
    userSelect: 'none',
    // transition: 'opacity .15s, color .2s',
    // borderBottom: '1.5px solid $$border-color)',
    '&.italic': {
      fontStyle: 'italic',
    },
    '&.sic-original': {
      // fontStyle: 'italic',
      color: '$$textColor',
      '&.visited': {
        // $$textColor: paletteKey('$$word__color_sic_visited'),
        '& > .sic-asterisk': {
          color: paletteKey('$$word__color_sic_visited'),
        },
      },
    },
    '&.sic-intended': {
      fontStyle: 'italic',
      // $$textColor: paletteKey('$$word__color_sic_visited'),
      // color: '$$textColor',

      '&.visited': {
        $$textColor: paletteKey('$$word__color_sic_visited'),
      },
    },
  },

  '& > .redact-bar': {
    // transition: 'opacity 1s, background .2s,',
    opacity: 1,
    background: '$$redactedBg',
    position: 'absolute',
    height: '9px',
    width: '100%',
    left: '0px',
    borderRadius: '3px',
    top: '10px',
  },

  '&.show-word': {
    '& > .word': {
      opacity: 1,
    },
    '& > .redact-bar': {
      opacity: 0,
    },
  },

  '&.visited': {
    $$redactedBg: paletteKey('$$redacted__backgroundColor_visited'),
    $$textColor: paletteKey('$$word__color_visited'),
  },

  '&.current': {
    $$redactedBg: paletteKey('$$redacted__backgroundColor_current'),
    $$textColor: '$colors$teal-500',
  },
});

export const getSicTextTrailingPunctuation = (text = '') => {
  const withPunctuation = text;

  // todo: confirm/document exactly why we're stripping trailing punctuation here.
  text = stripTrailingPunctuation(text);

  const trailing =
    text !== withPunctuation
      ? withPunctuation.slice(text.length, withPunctuation.length)
      : '';

  return trailing;
};

export const SicContent = ({
  text: originalText,
  membership,
  sicIntended = null,
  showWord = false,
}: Pick<
  WordElementProps,
  'membership' | 'sicIntended' | 'sicStart' | 'text'
> & { showWord: boolean }) => {
  if (!sicIntended) {
    return null;
  }

  const trailing = getSicTextTrailingPunctuation(originalText);

  const sicWords = sicIntended.split(/\s+/);
  sicWords[0] = '[' + sicWords[0];
  const sicWordsLast = sicWords.length - 1;
  sicWords[sicWordsLast] = sicWords[sicWordsLast] + ']' + trailing;

  return (
    <span>
      {sicWords.map((sicWord: string) => {
        return (
          <WordWrapper key={sicWord}>
            <RedactedOuter
              className={cx({
                visited: membership & WordMembership.VISITED,
                current: membership & WordMembership.CURRENT,
                'show-word': showWord,
              })}
            >
              <span
                className={cx([
                  'word sic-intended',
                  {
                    visited: membership & WordMembership.VISITED,
                  },
                ])}
              >
                {sicWord}
              </span>
            </RedactedOuter>
            <Spacer membership={0} />
          </WordWrapper>
        );
      })}
    </span>
  );
};

export const WordPresentation = ({
  text,
  membership,
  redaction,
  sicStart = false,
  sicIntended = null,
}: WordElementProps) => {
  // let showWord = !!(membership & WordMembership.VISITED);

  // eng-2432: experimentally unredact unvisited words
  let showWord = true;

  // if (sicStart) {
  //   text = '*' + text;
  // }
  if (sicIntended) {
    // todo: confirm/document exactly why we're stripping trailing punctuation here.
    text = stripTrailingPunctuation(text);
  }

  if (!(membership & WordMembership.SIC)) {
    if (redaction === RedactionMode.SHOW_NONE) {
      showWord = false;
    } else if (redaction === RedactionMode.SHOW_ALL) {
      // showWord already always set correctly
    } else if (redaction === RedactionMode.SHOW_SOME) {
      if (
        !(
          membership & WordMembership.NOTATION ||
          membership & WordMembership.TRICKY
        ) &&
        showWord
      ) {
        showWord = false;
      }
    }
  }
  if (membership & WordMembership.SELECTED_NOTATION) {
    showWord = true;
  }

  return (
    <>
      <WordWrapper
        className={cx({
          selected: membership & WordMembership.SELECTED_NOTATION,
        })}
      >
        <RedactedOuter
          className={cx({
            visited: membership & WordMembership.VISITED,
            current: membership & WordMembership.CURRENT,
            'show-word': showWord,
          })}
        >
          <span className="redact-bar" />
          <span
            className={cx('word', {
              italic: membership & WordMembership.ITALIC,
              'sic-original': membership & WordMembership.SIC,
              visited: membership & WordMembership.VISITED,
            })}
            // style={{ '--border-color': resolveWordTheme(membership) }}
          >
            {sicStart ? <span className="sic-asterisk">*</span> : null}
            {text}
            {/* [{membership}] */}
          </span>
        </RedactedOuter>
        <Spacer membership={membership} />
      </WordWrapper>
      <SicContent
        text={text}
        membership={membership}
        sicIntended={sicIntended}
        showWord={showWord}
      />
    </>
  );
};
