//
// data layer behind he vocab list panel UI
//

import { makeObservable, observable } from 'mobx';
import invariant from '@core/lib/invariant';
import { ChapterCatalogData } from '../catalog';
import { Story } from '../story-manager';

import { createLogger } from 'app/logger';
import { Notation } from '@tikka/client/client-aliases';
import { ChapterRef } from '../user-manager/location-pointer';
// import { hasBogotaVocabSlugs } from '../story-manager/story';
const log = createLogger('vocab-list-model');

export type VocabListSection = {
  chapterData: ChapterCatalogData;
  isMultipart: boolean; // drives how the sticky headers work
  unitHeader: boolean; // true for the first chapter per unit with vocab data, drives UI unit display
  rows: VocabRowData[];
};

export class VocabRowData {
  notation: Notation;

  @observable
  remove: boolean; // this is the only state that changes during the lifespan of the panel

  constructor(notation: Notation) {
    makeObservable(this);
    this.notation = notation;
    this.remove = false;
  }

  get slug() {
    return this.notation.id;
  }

  get headword() {
    return this.notation.headword;
  }

  get hasUsageText(): boolean {
    return !!this.usageText;
  }

  get usageText() {
    return this.notation.usageText;
  }

  get note() {
    return this.notation.note;
  }

  get unitNumber() {
    return this.notation.unit;
  }

  get chapterPosition() {
    return this.notation.chapter;
  }

  toggle() {
    this.remove = !this.remove;
  }
}

export class VocabListModel {
  // UI backing data
  sections: VocabListSection[] = [];

  // data needed to resolve the vocab details
  volumeData: Story;

  constructor({
    vocabSlugs,
    volumeData,
  }: {
    vocabSlugs: string[];
    volumeData: Story;
  }) {
    this.volumeData = volumeData;
    if (volumeData) {
      // needs unit context, not worth messing with here
      // // just-in-time migration in case opened somehow before the proactive migration
      // if (hasBogotaVocabSlugs(vocabSlugs)) {
      //   log.warn('JIT migration of bogota vocab slugs');
      //   vocabSlugs = volumeData.migrateBogotaVocabSlugs(vocabSlugs);
      // }
      this.initSections(vocabSlugs);
    } else {
      log.error('no volumeData');
    }
  }

  initSections(vocabSlugs: string[]): void {
    this.sections = [];
    for (const slug of vocabSlugs) {
      const notation = this.volumeData.vocab(slug);
      if (notation) {
        const section = this.sectionForNotation(notation);
        const vocabRow = new VocabRowData(notation);
        section.rows.push(vocabRow);
      } else {
        invariant(false, `vocab slug not resolved: ${slug}`);
      }
    }
    this.sections = this.sections.sort(
      (a, b) => a.chapterData.sortingRef - b.chapterData.sortingRef
    );
    // tag the first included chapter for each unit
    let lastUnit = -1;
    for (const section of this.sections) {
      if (
        this.volumeData.multiUnit &&
        section.chapterData.unitNumber !== lastUnit
      ) {
        section.unitHeader = true;
        lastUnit = section.chapterData.unitNumber;
      }
      // sort by order of appearance
      section.rows = section.rows.sort(
        (a, b) => a.notation.address - b.notation.address
      );
    }
  }

  // reuse an existing chapter section or create new section as needed
  sectionForNotation(notation: Notation): VocabListSection {
    const chapterData = this.volumeData.chapterForPoint(
      notation as ChapterRef /* cast needed because of optional params*/
    );
    let result = this.sections.find(
      section => section.chapterData === chapterData
    );
    if (!result) {
      result = {
        chapterData,
        isMultipart: this.volumeData.multiUnit,
        unitHeader: false,
        rows: [] as VocabRowData[],
      };
      this.sections.push(result);
    }
    return result;
  }

  // used when panel closed to get the list of slugs to remove from StoryProgress data
  get removalSlugs(): string[] {
    const result: string[] = [];
    for (const section of this.sections) {
      for (const row of section.rows) {
        if (row.remove) {
          result.push(row.slug);
        }
      }
    }
    return result;
  }

  get count() {
    return this.sections.reduce((sum, section) => {
      // wondering if section deserves to be promoted to a class
      return sum + section.rows.filter(row => !row.remove).length;
    }, 0);
  }
}
