import React from 'react';
import { openUrl } from '@naan/primitives/text/external-link';
import { appConfig } from 'app/env';

// @jason, is there something more clever we can with the typing here?
const { emails, emailOptions } = appConfig.website;

// TODO: replace with forms
export const FeedbackLink: React.FC = ({ children }) => {
  const href = `mailto:${emails.feedback}?subject=${emailOptions.subject}&body=${emailOptions.body}`;
  return (
    // TODO - refactor all window.open usages
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} onClick={() => openUrl(href)}>
      {children}
    </a>
  );
};
