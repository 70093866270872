import classNames from 'classnames';
import * as React from 'react';
import { IconButtonStyled } from './icon-button-styles';

type IconButtonStyledProps = React.ComponentProps<typeof IconButtonStyled>;

type IconButtonProps = IconButtonStyledProps & {
  icon: React.ReactElement;
  disabled?: boolean;
  tabIndex?: number;
};

export const IconButton = React.forwardRef(
  (
    {
      icon,
      disabled = false,
      tabIndex = 0,
      size,
      presentation,
      ...restOfProps
    }: IconButtonProps,
    ref
  ) => {
    return (
      <IconButtonStyled
        size={size}
        presentation={presentation}
        disabled={disabled}
        tabIndex={tabIndex}
        ref={ref as any}
        {...restOfProps}
        className={classNames({ disabled })}
      >
        <span className="button-inner">{icon}</span>
      </IconButtonStyled>
    );
  }
);
