import React from 'react';
import { observer } from 'mobx-react';
import { PlanPricingCard } from '../plan-pricing-card';
import { Plan } from 'core/models/user-manager';
import { AppFactory } from '@app/app-factory';
import { PricingCardsWrapper } from './pricing-cards-wrapper';

export const PricingCards = observer(() => {
  const { accountData } = AppFactory.root.userManager;

  const plans: Plan[] = accountData.plans;

  return (
    <>
      <PricingCardsWrapper>
        {plans.map(plan => (
          <PlanPricingCard key={plan.slug} plan={plan} />
        ))}
      </PricingCardsWrapper>
    </>
  );
});
