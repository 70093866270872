import React from 'react';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { EyeIcon } from '@naan/icons/eye-icon';
import { AppFactory } from 'app/app-factory';
import { EarIcon } from '@naan/icons/ear-icon';

import __ from '@core/lib/localization';

export const StudyFromHereButton = observer(() => {
  const model = AppFactory.studyModel;
  const action = React.useCallback(() => {
    model.togglePlayerMode();

    if (model.isPlaying) {
      model.pauseAfterCurrentSentence();
    }
  }, [model]);

  if (!model.showStudyFromHere) {
    return null;
  }

  return (
    <Button
      onClick={action}
      label={__('Study from here', 'player.studyFromHere')}
      size="large"
      leftIcon={<EyeIcon />}
      presentation="grayDark"
    />
  );
});

export const ListenFromHereButton = observer(() => {
  const model = AppFactory.studyModel;
  const action = React.useCallback(() => {
    model.togglePlayerMode();
  }, [model]);

  if (!model.showListenFromHere) {
    return null;
  }

  return (
    <Button
      onClick={action}
      label={__('Resume natural listen', 'player.listenFromHere')}
      size="large"
      leftIcon={<EarIcon />}
      presentation="grayDark"
    />
  );
});
