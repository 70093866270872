import * as React from 'react';
import { toUnicodePunctuationStr } from '@utils/content-string-utils';
import ReactMarkdown from 'react-markdown';
import { AdaptiveLink } from './custom-components';

export const PlainMarkdown = ({
  source,
  ...props
}: {
  source: string;
} & Omit<React.ComponentProps<typeof ReactMarkdown>, 'children'>) => {
  const preprocessed = toUnicodePunctuationStr(source);
  return <ReactMarkdown children={preprocessed} {...props} />;
};

export const PlainMarkdownWithLinks = ({
  source,
  ...props
}: {
  source: string;
} & Omit<React.ComponentProps<typeof ReactMarkdown>, 'children'>) => {
  return (
    <PlainMarkdown
      source={source}
      {...props}
      components={{
        a: ({ node, ...props }) => <AdaptiveLink {...props} />,
      }}
    />
  );
};
