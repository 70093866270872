import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { SkipDialog } from './skip-dialog';
import { HelpletsWidget } from './helplets-widget';
import { VideoModal } from './video-modal';
import { VideoPlayerModel } from './video-player-model';

const presentSkipDialog = () => {
  AppFactory.dialogPresenter.present(onDismiss => {
    return <SkipDialog onDismiss={onDismiss} />;
  });
};

const presentVideoModal = () => {
  const { videoGuideUserData } = AppFactory.root.userManager.userData;
  const video = videoGuideUserData.featuredVideo;
  const model = new VideoPlayerModel(videoGuideUserData, video);

  AppFactory.dialogPresenter.present(onDismiss => {
    return <VideoModal model={model} onDismiss={onDismiss} />;
  });
};

// @joseph: for now I will mount the video modal automatically
// so it's not as annoying to click the preview widget to test
// there's no way to close it in this branch while is in WIP
export const FeaturedHelpletWidget = observer(() => {
  const { videoGuideUserData } = AppFactory.root.userManager.userData;
  const { featuredVideo: video } = videoGuideUserData;

  if (!video) {
    return null;
  }

  return (
    <HelpletsWidget
      video={video}
      onPlay={presentVideoModal}
      onSkip={presentSkipDialog}
    />
  );
});
