import dayjs from 'dayjs';

export const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

export const decrementDate = (isoDate: string): string => {
  return adjustDate(isoDate, -1);
};

export const adjustDate = (isoDate: string, deltaDays: number): string => {
  const millis = Date.parse(isoDate);
  if (!millis) {
    return null;
  }
  return epochMillisToIso8601Date(millis + deltaDays * MILLIS_PER_DAY);
};

export const epochMillisToIso8601Date = (millis: number): string => {
  if (!millis) {
    return null;
  } else {
    return new Date(millis).toISOString().split('T')[0]; // better way??
  }
};

export const dayjsToIsoDate = (date: dayjs.Dayjs) => date?.format('YYYY-MM-DD');
