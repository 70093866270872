import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SpinnerIcon } from '../components/ui/icons/spinner-icon';
import { createLogger } from 'app/logger';
import { AppFactory } from '@app/app-factory';
import { homePath } from 'components/nav/path-helpers';
import { embeddedMode /*, postNativeMessage*/ } from '@app/app-util';
import { alertWarningError } from '@app/notification-service';

const log = createLogger('logout-handler');

export const LogoutHandler = () => {
  log.info('render');
  const navigate = useNavigate();
  useEffect(() => {
    log.info('use-effect');

    // native logout messaging handled now by the AppWideRouteLayout

    // if (embeddedMode()) {
    //   postNativeMessage({
    //     type: 'string',
    //     payload: 'WEB-VIEW-NATIVE-SIGN-OUT',
    //   });
    //   // let fall through to attempt web layer clean-up also (not 100% sure if that executes though)
    // }

    AppFactory.root.userManager
      .logout()
      .then(() => {
        if (!embeddedMode()) {
          navigate(homePath());
        }
      })
      .catch(error => alertWarningError({ error, note: 'logout-handler' }));
  }, [navigate]);

  return <SpinnerIcon />;
};
