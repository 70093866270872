import Bugsnag from '@bugsnag/js';
import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import minibus from './minibus';
import { setContextData as setAnalyticsContextData } from 'app/track';
import {
  version,
  curEnv,
  buildTimestampIso,
  commit,
  commitTimestampIso,
  hash,
  // @ts-expect-error
} from '@jw-spa-version';
import { embeddedMode } from '@app/app-util';
import {
  appName,
  appSlug,
  embeddedBuildNumber,
  embeddedPlatform,
} from '@core/lib/app-util';
import { bugsnagEnabled } from 'bugsnag-init';

const log = createLogger('error-reporting');

const enableBugsnag = appConfig.bugsnag.enabled;

export const setReportingContext = (data: any) => {
  log.debug(
    'setReportingContext',
    JSON.stringify({
      userId: data.accountData?.userId,
      email: data.accountData?.email,
      installationId: data.userManager?.installationId,
      apiEnv: data.globalConfig?.apiEnv,
    })
  );

  data.buildInfo = {
    version,
    curEnv,
    buildTimestampIso,
    commit,
    commitTimestampIso,
    hash,
    embedded: {
      mode: embeddedMode(),
      platform: embeddedPlatform(),
      buildNumber: embeddedBuildNumber(),
    },
    // largely irrelevant now
    appSlug: appSlug(),
    appName: appName(),
  };

  const { accountData, globalConfig, installationId, buildInfo } = data;
  if (bugsnagEnabled) {
    Bugsnag.setUser(accountData.userId, accountData.email, accountData.name);
    Bugsnag.addMetadata('account', accountData);
    Bugsnag.addMetadata('globalConfig', globalConfig);
    Bugsnag.addMetadata('installationId', installationId);
    Bugsnag.addMetadata('buildInfo', buildInfo);
  }

  setAnalyticsContextData(data);
};

minibus.subscribe('SET_REPORTING_CONTEXT', setReportingContext);

export const logglyTags = () => {};

/// I don't think this is used anymore
// export const debugLog = (/* message, data */) => {};

/// I don't think this is used anymore
// export const addBreadcrumb = config => {
//   const { message = null, ...meta } = config;
//   Bugsnag.addBreadcrumb(message, meta);
// };

// todo: can this be collapsed with bugsnagNotify?
const reportExceptionWithBugsnag = (
  error: any,
  info: any = {},
  options = {}
) => {
  const {
    //handlerTag = null,
    level = 'error',
  }: any = options;

  if (!(error instanceof Error) && info.exception) {
    error = info.exception; // for backward compatibility
  }

  // revisit this!!
  Bugsnag.notify(error, event => {
    event.severity = level;
    event.addMetadata('info', info);
  });
};

let __disabledNotice = false;
export const reportException = enableBugsnag
  ? reportExceptionWithBugsnag
  : () => {
      if (!__disabledNotice) {
        log.error('Bugsnag is disabled for this build.');
        __disabledNotice = true;
      }
    };

/// I don't think this is used anymore
// export const notify = (...args) => {
//   // eslint-disable-next-line no-console
//   console.log('notify is deprecated, use notifyException instead');
//   reportException(...args);
// };
