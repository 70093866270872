import * as React from 'react';
import {
  getPlayerControls,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from './player-ui-components';
import { appConfig } from 'app/env';
import { UaService } from 'lib/services/ua-service';

/// this is just to solve a bug in iPhone iOS 14 and older
/// which prevents the ability to scroll or see the player controls

export const useOldIosScrollFix = () => {
  if (
    // conditional flow here is safe since the conditions won't change during the lifecycle of the app
    appConfig.player.enableOldIosScrollFix || // force on for devtest
    UaService.shouldManuallySetScriptContainerHeight
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useLayoutEffect(() => {
      function adjust() {
        const controls = getPlayerControls();
        const controlsHeight = controls?.offsetHeight;
        document.getElementById(
          SCRIPT_SCROLLING_CONTAINER_ID
        ).style.height = `${window.innerHeight - controlsHeight}px`;
      }

      adjust();
      window.addEventListener('resize', adjust);
      window.addEventListener('orientationchange', adjust);

      return () => {
        window.removeEventListener('resize', adjust);
        window.removeEventListener('orientationchange', adjust);
      };
    }, []);
  }
};
