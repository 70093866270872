import { runInAction } from 'mobx';
import {
  getParentOfType,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';
import { UserManager } from './user-manager';
import { bugsnagNotify } from '@app/notification-service';
// import { track } from 'app/track';

import { createLogger } from 'app/logger';
const log = createLogger('um:user-settings');

/**
 * UserSettings
 *
 * non-study view user configuration and misc data
 */
export class UserSettings extends ModelTreeNode {
  static CLASS_NAME = 'UserSettings' as const;

  static create(snapshot: any) {
    return super.create(UserSettings, snapshot) as UserSettings;
  }

  dismissedMessageSet: TSTStringMap<boolean> = snap({});
  // legacy data, automatically migrated upon load
  dismissedMessages: string[];

  showVocabListExportOption: boolean = false;

  get userManager(): UserManager {
    return getParentOfType(this, UserManager) as UserManager;
  }

  dismissMessage(key: string) {
    this.dismissedMessageSet.set(key, true);
    this.userManager.persistUserData().catch(bugsnagNotify);
  }

  messageIsDismissed(key: string) {
    return this.dismissedMessageSet.has(key);
  }

  resetMessage(key: string) {
    this.dismissedMessageSet.delete(key);
  }

  migrateListToMap(): boolean {
    if (!this.dismissedMessages) return false;

    const count = this.dismissedMessages.length;
    log.info(`migrate ${count} dismissedMessages to map`);
    runInAction(() => {
      for (const key of this.dismissedMessages) {
        this.dismissedMessageSet.set(key, true);
      }
      this.dismissedMessages = undefined;
    });
    return true;
  }

  toggleVocabListExport() {
    this.showVocabListExportOption = !this.showVocabListExportOption;
    this.userManager.persistUserData().catch(bugsnagNotify);
  }
}
