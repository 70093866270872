import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { AppFactory } from 'app/app-factory';
import { useNavigate } from 'react-router-dom';
import {
  createAccountPath,
  giftCouponsPath,
  profileHomePath,
  signInPath,
} from 'components/nav/path-helpers';

import __ from 'core/lib/localization';

const InterstitialDialog = ({
  title,
  body,
  after,
  onDismiss,
}: {
  title: string;
  body: React.ReactNode;
  after: string;
  onDismiss: () => void;
}) => {
  const navigate = useNavigate();

  const handleSignIn = React.useCallback(() => {
    onDismiss();
    navigate(signInPath({ after }));
  }, [after, navigate, onDismiss]);

  const handleCreateAccount = React.useCallback(() => {
    onDismiss();
    navigate(createAccountPath({ after }));
  }, [after, navigate, onDismiss]);

  return (
    <Dialog.Container onDismiss={onDismiss} open={true}>
      <Dialog.Heading>{title}</Dialog.Heading>
      <Dialog.Body>{body}</Dialog.Body>
      <Dialog.ButtonsContainer direction={'column'}>
        <Dialog.Button
          fullWidth
          label={__('Create account', 'auth.createAccount')}
          onClick={handleCreateAccount}
          testId="login"
          presentation={'teal'}
        />

        <Dialog.Button
          fullWidth
          label={__('Sign in', 'auth.signIn')}
          onClick={handleSignIn}
          testId="login"
          presentation={'teal'}
        />

        <Dialog.Button
          fullWidth
          label={__('Cancel', 'common.cancel')}
          onClick={onDismiss}
          testId="cancel"
          presentation={'grayLight'}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

const CouponDialog = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <InterstitialDialog
      after={profileHomePath() + '?coupon'}
      title={__('Redeem your code', 'account.redeemYourCode')}
      body={__(
        'Sign in or create an account to enter a code or redeem a coupon',
        'account.signInOrCreateAccountToEnterACodeOrRedeemACoupon'
      )}
      onDismiss={onDismiss}
    />
  );
};

const GiftDialog = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <InterstitialDialog
      after={giftCouponsPath()}
      title={__('Gift coupons', 'account.giftCoupons')}
      body={__(
        'Sign in or create an account to purchase or redeem a gift coupon',
        'account.signInOrCreateAccountToPurchaseOrRedeemAGiftCoupon'
      )}
      onDismiss={onDismiss}
    />
  );
};

export function useAnonymousCouponDialog() {
  const handleApplyCode = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <CouponDialog onDismiss={onDismiss} />
    ));
  }, []);

  return handleApplyCode;
}

export function useAnonymousGiftCouponDialog() {
  const handleApplyCode = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <GiftDialog onDismiss={onDismiss} />
    ));
  }, []);

  return handleApplyCode;
}
