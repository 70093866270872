import { appConfig } from '@app/env';
import { createLogger } from '@common/log';

const log = createLogger('keyboard-service');

const { logKeyDowns } = appConfig.logging;

function keynameForEvent(event: KeyboardEvent) {
  if (!event.code) {
    return 'unknown';
  }

  const keynameParts = [];

  if (event.altKey) {
    keynameParts.push('alt');
  }

  if (event.shiftKey) {
    keynameParts.push('shift');
  }

  if (event.ctrlKey) {
    keynameParts.push('ctrl');
  }

  if (event.metaKey) {
    keynameParts.push('meta');
  }

  keynameParts.push(
    event.code
      .replace(/^Key/, '')
      .replace(/^Arrow/, '')
      .replace(/^Digit/, '')
  );

  const keyname = keynameParts.join('+').toLowerCase();

  return keyname;
}

// converts objects like {'a,b':c} to {'a':c, 'b':c}
function denormalizeKeys(object: Record<string, any>) {
  const newObject: Record<string, any> = {};

  Object.keys(object).forEach(key => {
    const values = key.split(',');
    values.forEach(value => {
      newObject[value.trim()] = object[key];
    });
  });

  return newObject;
}

type KeyboardShortcutsSet = Record<string, Function>;

class KeyboardService {
  private shortcutSets: Map<string, KeyboardShortcutsSet> = new Map();
  private currentShortcutSetKey: string | null = null;
  // private listening = false;
  private monitorKeys = import.meta.env.MODE === 'development' ? true : false;

  constructor() {
    this.startListening();
  }

  startListening() {
    document.addEventListener('keydown', this.handleKeyDown);
    // this.listening = true;
  }

  stopListening() {
    document.removeEventListener('keydown', this.handleKeyDown);
    // this.listening = false;
  }

  handleKeyDown = (event: KeyboardEvent) => {
    const keyname = keynameForEvent(event);

    if (this.monitorKeys) {
      if (logKeyDowns) {
        log.debug('keydown', keyname);
      }
    }

    if (this.currentShortcutSetKey) {
      const shortcutSet = this.shortcutSets.get(this.currentShortcutSetKey);
      const shortcut = shortcutSet[keyname];

      if (typeof shortcut === 'function') {
        event.preventDefault();
        shortcut();
        if (logKeyDowns) {
          log.debug('keydown', shortcut.toString());
        }
      }
    }
  };

  addShortcutSet(key: string, shortcuts: KeyboardShortcutsSet) {
    const denormalizedShortcuts = denormalizeKeys(shortcuts);
    this.shortcutSets.set(key, denormalizedShortcuts);
  }

  addModalShortcut(shortcutKey: string, shortcut: Function) {
    const shortcutSet = this.getCurrentShortcutSet();

    let oldKey: null | Function = null;

    if (!shortcutSet) {
      return () => {};
    }

    if (shortcutSet[shortcutKey]) {
      oldKey = shortcutSet[shortcutKey];
    }

    shortcutSet[shortcutKey] = shortcut;

    this.overrideCurrentSet(shortcutSet);

    /// returns a function that will reset the shortcut to its previous value
    const reset = () => {
      if (oldKey) {
        shortcutSet[shortcutKey] = oldKey;
      } else {
        delete shortcutSet[shortcutKey];
      }
      this.overrideCurrentSet(shortcutSet);
    };

    return reset;
  }

  overrideCurrentSet(shortcutSet: KeyboardShortcutsSet) {
    this.shortcutSets.set(this.currentShortcutSetKey, shortcutSet);
  }

  removeShortcutSet(key: string) {
    this.shortcutSets.delete(key);
    if (this.currentShortcutSetKey === key) {
      this.currentShortcutSetKey = null;
    }
  }

  getCurrentShortcutSet() {
    return this.shortcutSets.get(this.currentShortcutSetKey);
  }

  setCurrentShortcutSet(key: string | null) {
    this.currentShortcutSetKey = key;
  }

  stopCurrentShortcutSet() {
    const oldSetKey = this.currentShortcutSetKey;
    this.currentShortcutSetKey = null;
    const reset = () => {
      this.currentShortcutSetKey = oldSetKey;
    };
    return reset;
  }
}

export const keyboardService = new KeyboardService();

(window as any).ks = keyboardService;
