import * as React from 'react';
import { VStack } from 'naan/primitives/stack';

import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { MediumHeading } from '@naan/primitives/text';

export const path = 'links';
export const label = 'Useful links';

export const Component = observer(() => {
  return (
    <VStack css={{ my: '$5' }}>
      <MediumHeading>Useful links</MediumHeading>
      <Link to="/showcase">Go to Components Showcase &rarr;</Link>
      <ExternalLink href="https://radioambulante.org">
        Go to radio ambulante &rarr;
      </ExternalLink>
    </VStack>
  );
});
