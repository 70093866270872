import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { VideoGuideEngagementStatus } from '@core/models/user-manager/video-guide-engagement';
import __ from '@core/lib/localization';

export const SkipDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const { videoGuideUserData: model } = AppFactory.root.userManager.userData;
    // assumes only presented for currently featured dashboard helplet
    const featured = model.featuredVideo;

    return (
      <Dialog.Container open={true} onDismiss={onDismiss}>
        <Dialog.Heading>
          {__('Skip video', 'dashboard.skipVideo')}
        </Dialog.Heading>
        <Dialog.Body>
          <p>
            {__(
              'We recommend watching this video before you work with the stories. But if you want to skip, you can always get back to it from your account menu.',
              'dashboard.skipVideo'
            )}
          </p>
        </Dialog.Body>
        <Dialog.ButtonsContainer direction={'column'}>
          <Dialog.Button
            fullWidth
            presentation={'teal'}
            label={__('Skip this video', 'dashboard.skipVideo')}
            onClick={() => {
              model.recordEngagement({
                slug: featured.slug,
                status: VideoGuideEngagementStatus.SKIPPED,
              });
              onDismiss();
            }}
          />
          {model.hasNext ? (
            <Dialog.Button
              fullWidth
              presentation={'redLight'}
              onClick={() => {
                model.skipAllOnboarding();
                onDismiss();
              }}
              label={__('Skip all video guides', 'dashboard.skipAllVideo')}
            />
          ) : null}
          <Dialog.Button
            fullWidth
            presentation={'grayLight'}
            label={__('Cancel', 'dashboard.cancel')}
            onClick={() => onDismiss()}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
