import { ElementId, ELTOf, TimedElement } from '../basic-types';
import { AudioTransport, TransportState } from './audio-transport';

// // TODO change adjustPlaybackRateAction param to enum?

const NORMAL_PLAYBACK_RATE_SELECTION = 4;

export class StructuredPlayer {
  audioTransport: AudioTransport;
  transportState: TransportState;
  audioMarkers: Map<string, number>;
  playbackRateValues: number[];
  playbackRateSelect: number;

  constructor(
    audioTransport0: AudioTransport,
    transportState0: TransportState
  ) {
    this.audioTransport = audioTransport0;
    this.transportState = transportState0;
    this.audioMarkers = new Map();
    this.playbackRateValues = [0.6, 0.7, 0.8, 0.9, 1.0];
    this.playbackRateSelect = NORMAL_PLAYBACK_RATE_SELECTION;
  }

  setElements(elements: ELTOf<TimedElement>) {
    this.setAudioMarkers(
      new Map(elements.values.map(el => [el.id as string, el.time]))
    );
  }

  setAudioMarkers(markers: Map<string, number>) {
    this.audioMarkers = markers;
  }

  getAudioPosition() {
    return this.audioTransport.audioPosition;
  }

  play() {
    this.audioTransport.play();
  }

  playForward() {
    this.play();
  }

  // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind
  pause(keepPauseAfter = false) {
    this.audioTransport.pause(keepPauseAfter);
  }

  // beware 'keepPauseAfter' is intertwined with undesired chaat auto-rewind
  seek(position: number, keepPauseAfter = false) {
    this.audioTransport.seek(position, keepPauseAfter);
  }

  rewind() {
    if (this.transportState.isPlaying) {
      this.pause();
    } else {
      this.audioTransport.rewind();
      if (!this.transportState.isPlaying) {
        this.play();
      }
    }
  }

  // beware 'keepPauseAfter' must be 'true' to avoid undesired chaat auto-rewind to previous start of play behavior
  pauseThenPlayAt(delay: number, position: number, keepPauseAfter = false) {
    // this.audioTransport.pause(keepPauseAfter);
    // this.seek(position, keepPauseAfter);
    // setTimeout(() => this.play(), delay);
    this.audioTransport.pauseThenPlayAt(delay, position, keepPauseAfter);
  }

  togglePlay() {
    if (this.transportState.isPlaying) {
      this.pause(false);
    } else {
      this.playForward();
    }
  }

  seekMarker(marker: string) {
    this.seek(this.audioMarkers.get(marker), false);
  }
  seekElement(elementId: ElementId) {
    this.seekMarker(elementId as string);
  }

  playMarker(marker: string) {
    this.seekMarker(marker);
    this.play();
  }

  playElement(elementId: ElementId) {
    this.playMarker(elementId as string);
  }

  adjustPlaybackRateAction(direction: boolean) {
    const select = direction
      ? this.playbackRateSelect + 1
      : this.playbackRateSelect - 1;
    if (select >= 0 && select < this.playbackRateValues.length) {
      this.playbackRateSelect = select;
      this.audioTransport.setPlaybackRate(
        this.playbackRateValues[this.playbackRateSelect]
      );
    }
  }

  adjustPlaybackRate(change: number) {
    let select = this.playbackRateSelect + change;
    if (select < 0) {
      select = 0;
    }
    if (select >= this.playbackRateValues.length) {
      select = this.playbackRateValues.length - 1;
    }
    this.playbackRateSelect = select;
    this.audioTransport.setPlaybackRate(
      this.playbackRateValues[this.playbackRateSelect]
    );
  }

  setPlaybackRate(rate: number) {
    const select = this.playbackRateValues.findIndex(
      (value: number) => value === rate
    );
    if (select >= 0 && select < this.playbackRateValues.length) {
      this.playbackRateSelect = select;
      this.audioTransport.setPlaybackRate(
        this.playbackRateValues[this.playbackRateSelect]
      );
    }
  }

  get playbackRate() {
    // @jason, better for this to use local state or delegate to the audioTransport?
    return this.playbackRateValues[this.playbackRateSelect];
  }

  setNormalPlaybackRate() {
    this.setPlaybackRate(1.0);
  }

  setKerningData(points: number[], pauses: number[]) {
    this.audioTransport.setKerningData(points, pauses);
  }

  setKerningUseVolumeRamp(value: boolean) {
    this.audioTransport.setKerningUseVolumeRamp(value);
  }

  kerningEnable(enable: boolean) {
    this.audioTransport.kerningEnable(enable);
  }
}
