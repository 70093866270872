import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { BarChart } from '../bar-chart';
import { TotalPoints } from '../total-points';
import { Text } from 'naan/primitives/text';
import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { VSpacer } from 'naan/primitives/spacer';
import { BarchartZeroState } from './bar-chart-zero-state';
import __ from 'core/lib/localization';

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  width: '100%',
  minHeight: 280,
  flexDirection: 'column',
  justifyContent: 'space-between',
  borderRadius: '8px',
  backgroundColor: '$gray-25',
  padding: '$space$5 $space$6 $space$4 $space$6',
  '& > .zero-message': {
    position: 'absolute',
    inset: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'fdlex-start',
    textStyle: 'small-heading',
    color: '$colors$blue-600',
    '& a': {
      color: '$colors$teal-500',
    },
  },
});

const Header = styled('div', {
  marginBottom: '$space$10',
});

export const PointsProgressChart = observer(() => {
  const { userData } = AppFactory.root.userManager;
  const viewData = userData.statsChartData;
  const showZeroState = viewData.highestDayPoints === 0;

  return (
    <Container>
      <Header>
        <Text textStyle={'small-heading'}>
          {__('Last 14 days', 'progress.twoWeeksProgressTitle')}
        </Text>
        <VSpacer size={1} />
        <TotalPoints points={viewData.totalPoints} />
      </Header>
      {showZeroState ? (
        <div className="zero-message">
          <BarchartZeroState />
        </div>
      ) : null}

      <BarChart viewData={viewData} />
    </Container>
  );
});
