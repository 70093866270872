import {
  applySnapshot,
  getParentOfType,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';
import { UserManager } from '.';
import { getBaseRoot } from '../app-root';
import { StoryManager } from '../story-manager';
import {
  maxStatus,
  SoundbiteEngagement,
  SoundbiteEngagementStatus,
} from './soundbite-engagement';
import { decrementDate } from '@utils/date-utils';
import { createLogger } from '@common/log';
import { bugsnagNotify } from '@app/notification-service';
import { runInAction } from 'mobx';

const log = createLogger('soundbite-user-data');

/**
 * SoundbiteUserData
 *
 * soundbite specific client managed data
 */
export class SoundbiteUserData extends ModelTreeNode {
  static CLASS_NAME = 'SoundbiteUserData' as const;

  static create(snapshot: any) {
    return super.create(SoundbiteUserData, snapshot) as SoundbiteUserData;
  }

  engagementMap: TSTStringMap<SoundbiteEngagement> = snap({});
  // legacy
  engagements: SoundbiteEngagement[];

  get userManager(): UserManager {
    return getParentOfType(this, UserManager);
  }

  get storyManager(): StoryManager {
    return getBaseRoot(this)?.storyManager;
  }

  get engagementList(): SoundbiteEngagement[] {
    return Array.from(this.engagementMap.values());
  }

  get hasEngaged() {
    return this.engagementMap.size > 0;
  }

  recordEngagement({
    slug,
    status,
  }: {
    slug: string;
    status: SoundbiteEngagementStatus;
  }) {
    const engagement = this.engagementBySlug(slug, { ensure: true });

    const newStatus = maxStatus(engagement.engagementStatus, status);
    if (newStatus === engagement.engagementStatus) {
      // don't persist if unchanged
      log.debug(
        `recordEngagement - ${slug}, ${status} - status unchanged (was ${engagement.engagementStatus})`
      );
      return;
    }
    log.debug(
      `recordEngagement - ${slug}, ${status} - updated (was ${engagement.engagementStatus})`
    );

    const soundbiteDate = this.storyManager.soundbiteReleaseDateForSlug(slug);
    const timestamp = new Date().toISOString();

    applySnapshot(engagement, {
      soundbiteSlug: engagement.soundbiteSlug, // beware, current applySnapshot api requires all values be explicitly included
      soundbiteDate,
      engagementStatus: newStatus,
      timestamp,
    });
    this.userManager.persistUserData().catch(bugsnagNotify); // execute async
  }

  engagementBySlug(
    slug: string,
    { ensure }: { ensure: boolean }
  ): SoundbiteEngagement {
    let result = this.engagementMap.get(slug);
    if (!result && ensure) {
      result = SoundbiteEngagement.create({ soundbiteSlug: slug });
      this.engagementMap.set(slug, result);
    }
    return result;
  }

  engagementByDate(date: string): SoundbiteEngagement {
    const result = this.engagementList.find(
      engagement => engagement.soundbiteDate === date
    );
    return result;
  }

  // todo: consider persisting in date order so no need to sort
  get completedByDate(): SoundbiteEngagement[] {
    return this.completed.sort(
      (a, b) => b.soundbiteDate.localeCompare(a.soundbiteDate) // desc
    );
  }

  get completed(): SoundbiteEngagement[] {
    const { currentDate } = this.storyManager;
    const result = this.engagementList
      .filter(item => item.completed)
      .filter(a => a.soundbiteDate) // filter out bad data which would be fatal
      .filter(a => a.soundbiteDate <= currentDate); // filter forward data when current date overridden
    return result;
  }

  get currentStreak(): number {
    let date = this.storyManager.currentDate;
    let engagement = this.engagementByDate(date);
    if (engagement?.engagementStatus !== SoundbiteEngagementStatus.REVEALED) {
      date = decrementDate(date);
    }
    let count = 0;
    for (;;) {
      engagement = this.engagementByDate(date);
      if (engagement?.engagementStatus !== SoundbiteEngagementStatus.REVEALED) {
        break;
      }
      count++;
      date = decrementDate(date);
    }
    return count;
  }

  get longestStreak(): number {
    const list = this.completedByDate;
    if (list.length === 0) {
      return 0;
    }
    let date = '2037-12-31';
    let streak = 0;
    for (const engagement of list) {
      date = decrementDate(date);
      if (engagement.soundbiteDate === date) {
        streak++;
      } else {
        date = engagement.soundbiteDate;
        streak = 1;
      }
    }

    return streak;
  }

  get totalCompleted(): number {
    const completed = this.engagementList.filter(item => item.completed);
    return completed.length;
  }

  async resetAllData() {
    applySnapshot(this, {});
    await this.userManager.persistUserData();
  }

  migrateListToMap(): boolean {
    if (!this.engagements) return false;

    log.info(`migrate ${this.engagements.length} engagements to map`);
    runInAction(() => {
      for (const engagement of this.engagements) {
        this.engagementMap.set(engagement.soundbiteSlug, engagement);
      }
      this.engagements = undefined;
    });
    return true;
  }
}
