import { styled } from '@naan/stitches.config';
import * as React from 'react';

const Wrapper = styled('footer', {
  paddingBottom: '$space$6',
  '& .patents': {
    textStyle: 'tiny-text',
    color: '$gray-300',
    textAlign: 'center',
  },
});

export const DashboardFooter = () => {
  return (
    <Wrapper>
      <p className="patents">Patent Nos. 10,984,667; 11,551,568 B2;</p>
    </Wrapper>
  );
};
