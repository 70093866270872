/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { styled } from '@naan/stitches.config';
import classnames from 'classnames';
import { Classroom } from 'core/models/user-manager';
import { AlertTriangleIcon } from '@naan/icons/alert-triangle-icon';
import { LicenceIcon } from '@naan/icons/licence-icon';
import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  padding: '3px 6px',
  borderRadius: '4px',
  border: '1px solid $colors$gray-100',
  color: '$colors$textSecondary',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& .icon': {
    color: '$colors$green-500',
    marginRight: '6px',
  },

  '& .duration-description': {
    '&.is-expired:': {
      color: '$colors$red-500',
    },
  },

  '& .number': {
    fontWeight: '600',
    color: '$colors$textSecondary',
    '&.is-oversubscribed': {
      color: '$colors$red-500',
    },

    '&.is-active': {
      color: '$colors$green-500',
    },
  },

  '& .vr': {
    height: '16px',
    width: '1px',
    background: '$colors$gray-100',
    margin: '0 8px',
  },

  '& .triangle-icon': {
    color: '$colors$red-500,',
    marginLeft: '4px',
  },
});

export const Seats = ({ classroom }: { classroom: Classroom }) => {
  const { license } = classroom;

  return (
    <Wrapper>
      <div className="icon">
        <LicenceIcon />
      </div>
      <div
        className={`duration-description ${
          license.isExpired ? 'is-expired' : ''
        }`}
      >
        {license.licenseDescription}
      </div>
      <span className="vr" />
      <>
        {__('Seats', 'classroom.seats')}:&nbsp;
        <span
          className={classnames([
            'number',
            {
              'is-oversubscribed': license.isOverSubscribed,
              'is-active': license.isActive,
            },
          ])}
        >
          {license.seatsDescription}
        </span>
        {license.isOverSubscribed ? (
          <span className="triangle-icon">
            <AlertTriangleIcon />
          </span>
        ) : null}
      </>
    </Wrapper>
  );
};
