import * as React from 'react';
import { styled } from '@naan/stitches.config';

const Container = styled('div', {
  $$height: '224px',
  $$gap: '16px',
  $$iconSize: '72px',
  $$columns: 3,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '$space$8 $space$10 $space$8 $space$10',
  backgroundColor: '$$backgroundColor',
  borderRadius: '8px',
  variants: {
    isLarge: {
      true: {
        $$height: '296px',
        $$columns: 2,
        '@medium': {
          $$iconSize: '96px',
        },
      },
    },
  },
  '@medium': {
    py: 'unset',
    justifyContent: 'center',
    $$gap: '24px',
    width: 'calc((100% - ($$gap * ($$columns - 1))) / $$columns)',
    height: '$$height',
  },
  '& > .icon svg': {
    width: '$$iconSize',
    height: '$$iconSize',
    color: '$$iconColor',
  },
  '& > .stat': {
    textStyle: 'medium-heading',
    marginTop: '$space$2',
    marginBottom: '$space$1',
  },
  '& > .label': {
    textStyle: 'small-caps',
    color: '$black-alpha-50',
    textAlign: 'center',
  },
});

export const SimpleStat = ({
  icon,
  iconColor,
  stat,
  label,
  backgroundColor,
  isLarge = false,
}: {
  icon: React.ReactNode;
  iconColor: string;
  stat: string;
  label: string;
  backgroundColor: string;
  isLarge?: boolean;
}) => {
  return (
    <Container
      css={{ $$iconColor: iconColor, $$backgroundColor: backgroundColor }}
      isLarge={isLarge}
    >
      <div className="icon">{icon}</div>
      <div className="stat">{stat}</div>
      <div className="label">{label}</div>
    </Container>
  );
};
