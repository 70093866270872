// experimental state to drive profile layout back navigation
// shouldn't need to be reactive, so just using a global object

// reset by the /teach and /learn layouts
export const ReturnNavState = {
  appMode: 'learn', // learn / teach
  l2: 'es',
  storyListVisited: false, // if never visited, then we can't safely pop back from story detail view and instead navigate explicitly to the dashboard
  storyDetailVisited: false, // if neither list or detail visited, then we can't safely pop back from study view and similarly nav to dashboard

  // @aramando, thoughts about nested these here or top level exports?
  canPopFromStoryDetail: () => {
    return ReturnNavState.storyListVisited;
  },

  canPopFromStudy: () => {
    return ReturnNavState.storyListVisited || ReturnNavState.storyDetailVisited;
  },

  canPopFromSubscribe: () => {
    // todo: give this more thought
    return ReturnNavState.storyListVisited || ReturnNavState.storyDetailVisited;
  },

  // todo: should probably refactor this into a single class instance at this point.
  reset: () => {
    ReturnNavState.appMode = 'learn';
    ReturnNavState.l2 = 'es';
    ReturnNavState.storyListVisited = false;
    ReturnNavState.storyDetailVisited = false;
  },
};

export const classroomPortalMode = () => {
  return ReturnNavState.appMode === 'teach';
};
