import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { reaction } from 'mobx';
import { PlayerControls } from 'player/views/player-controls';
import { useModelSetup } from 'player/views/player-ui-model-setup';
import {
  PlayerUIConfig,
  usePlayerUIConfig,
} from 'player/views/player-ui-config';
import { Sentence, SpeakerLabel } from 'player/views/player-ui-elements';
import {
  PlayerUIBody,
  PLAYER_CONTROLS_ID,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from 'player/views/player-ui-components';
import {
  LayoutContainer,
  ScriptOuterContainer,
  ScriptInnerContainer,
} from './sb-layout';

import { SbHeader } from './sb-header';
import { ScriptHeader } from './info/script-header';
import { Question } from './info/question';
import { Answer } from './info/answer';
import { SbToolbar } from './sb-toolbar';
import { SbFinalCard } from './sb-final-card';
import { SoundbiteScrollObserver, scrollTo } from 'soundbite/fx/scrolling';
import { DebugOverlay } from 'player/views/player-ui-elements/debug-overlay';
import { useOldIosScrollFix } from 'player/views/use-old-ios-scroll-fix';
import { VSpacer } from '@naan/primitives/spacer';
import { useReaction } from '@common/hooks/use-reaction';
import { toggleClassname } from '@utils/toggle-class';
import { useAudioPauseOnBackground } from '@common/hooks/use-audio-pause-on-background';
import { ControlsContainer } from 'study/views/study-layout';

// import { createLogger } from 'app/logger';
// const log = createLogger('soundbite-ui');

const elementTypeMap: { [index: string]: any } = {
  PARAGRAPH: SpeakerLabel,
  SENTENCE: Sentence,
};

const config: PlayerUIConfig = { elementTypeMap };

export const SoundbiteUI = React.memo(() => {
  // log.debug('SoundbiteUI - render');
  const model = AppFactory.soundbiteModel;

  usePlayerUIConfig(config); // injects flavor specific behavior into player-ui-components
  useModelSetup(); // setups up membership reconcilers and reaction behaviors shared with both players
  useOldIosScrollFix();

  const observingContainerRef = React.useRef<HTMLDivElement>(null);
  const scriptContainerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /// this is for the answer observer
    const scrollObserver = new SoundbiteScrollObserver();
    scrollObserver.observeElement(observingContainerRef.current);

    /// we need to use a reaction to manually update teh showing-translation class
    /// if we use react for it, we will replace the whole DOM underneath and lose
    /// other places where the DOM is updated directly.
    /// This is a non-react way to do things, but it works.
    const reactionDisposer = reaction(
      () => model.translationsShown,
      () => {
        if (model.translationsShown) {
          scrollTo(scriptContainerRef.current);
          /// on small devices we need to wait for the scroll to finish before we add the class
          /// but on desktop it adds an undesirable delay, so we avoid it
          const timeout = window.matchMedia('(max-width: 550px)').matches
            ? 300
            : 0;

          window.setTimeout(() => {
            scriptContainerRef.current?.classList.add('showing-translation');
            // this is to give time for the scroll to happen
            // so the user can see the translation sliding-in
            // but it's not perfect is the answer is too long
          }, timeout);
        } else {
          scriptContainerRef.current?.classList.remove('showing-translation');
        }
      },
      { fireImmediately: true }
    );

    return () => {
      reactionDisposer();
      scrollObserver.unobserve();
    };
  }, [model.translationsShown]);

  useReaction(
    () => model.fluentListenMode,
    () => {
      toggleClassname(
        containerRef.current,
        'listen-mode',
        model.fluentListenMode
      );
    }
  );

  useReaction(
    () => model.isPlaying,
    () => {
      const scriptContainer = document.getElementById(
        SCRIPT_SCROLLING_CONTAINER_ID
      );
      toggleClassname(scriptContainer, 'playing', model.isPlaying);
    }
  );

  useAudioPauseOnBackground();

  return (
    <>
      <LayoutContainer ref={containerRef}>
        <SbHeader story={model.story} />

        <ScriptOuterContainer id={SCRIPT_SCROLLING_CONTAINER_ID}>
          <ScriptHeader excerpt={model.excerptData} story={model.story} />
          <div ref={observingContainerRef}>
            <Question excerpt={model.excerptData} />
          </div>
          <SbToolbar excerpt={model.excerptData} />
          <ScriptInnerContainer ref={scriptContainerRef}>
            <PlayerUIBody />
          </ScriptInnerContainer>
          <VSpacer size="6" />
          <Answer excerpt={model.excerptData} />
          <SbFinalCard />
        </ScriptOuterContainer>
        <ControlsContainer id={PLAYER_CONTROLS_ID}>
          <PlayerControls mode={'soundbite'} />
        </ControlsContainer>
        <DebugOverlay />
      </LayoutContainer>
    </>
  );
});
