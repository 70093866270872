import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { OverflowIcon } from '@naan/icons/overflow-icon';
import { IconButton } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { observer } from 'mobx-react';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';

export const Unvisited = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const overflowMenu = (
      <Menu
        trigger={
          <IconButton presentation="grayTransparent" icon={<OverflowIcon />} />
        }
      >
        <MenuItem label="Skip to chapter" action={() => chapter.unlock()} />
      </Menu>
    );

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        presentation={'unvisited'}
        overflowMenu={overflowMenu}
      />
    );
  }
);
