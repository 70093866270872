import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { Button } from '@naan/primitives/button';
import { CheckmarkHeavyIcon } from '@naan/icons/checkmark-heavy-icon';

export const DoneButton = ({ chapter }: { chapter: ChapterCatalogData }) => {
  if (!chapter.isSecondListenComplete || !chapter.isFurthest) {
    return null;
  }

  const doneAction = () => {
    chapter.markComplete();
  };
  return (
    <Button
      key="done"
      leftIcon={<CheckmarkHeavyIcon />}
      label={'Done'}
      onClick={doneAction}
      presentation={chapter.midListen ? 'secondary' : 'teal'}
      size="large"
    />
  );
};
