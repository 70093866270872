import React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { observer } from 'mobx-react';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { useNavigate, useParams } from 'react-router';
import { AppFactory } from 'app/app-factory';
import { TeacherVolumeCardHeaderButtons } from 'components/volume-layouts/volume-card-header-controls';
import {
  BackButton,
  // PageHeading,
  PageHeadingContainer,
} from 'components/page-heading';
import { useMachine } from '@xstate/react';
import { assignmentMachine } from 'components/classrooms/assignment-dialog/assignment-machine';
import { AssignmentDialog } from 'components/classrooms/assignment-dialog';
import { Resources } from 'components/discover/volume';
import { VSpacer } from 'naan/primitives/spacer';
import { NotFoundScreen } from './not-found-screen';
import { Divider } from 'naan/primitives/divider';
import { Story } from 'core/models/story-manager';
import { VolumePage } from 'components/volume-layouts';
import { useFavoriteToggle } from 'lib/hooks/use-favorite-toggle';
import { HeaderContainer } from 'components/header/header-container';
import { HStack } from 'naan/primitives/stack';
import { useLocation } from 'react-router-dom';
import { getFirstScrollingParent } from 'lib/scroll-utils';

import __ from 'core/lib/localization';
import { discoverPath, favoritesPath } from 'components/nav/path-helpers';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { DatesVoicesCredits } from 'components/story-detail/story-voices-credits';

export const VolumeDetailScreen = observer(() => {
  const { slug } = useParams<{ slug: string }>();
  const { storyManager } = AppFactory.root;
  const [state, send] = useMachine(assignmentMachine);
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [parent = ''] = pathname.match(/favorites|discover/);

  const assignToClassHandler = React.useCallback(
    (story: Story) => () => send({ type: 'OPEN', unitSlug: story.slug }),
    [send]
  );

  const story = storyManager.story(slug);

  useTitle(story?.title ?? '');

  React.useLayoutEffect(() => {
    const scrollingContainer = getFirstScrollingParent(
      document.getElementById('scroll-beacon')
    );
    if (scrollingContainer) {
      scrollingContainer.scrollTo({ top: 0, behavior: 'instant' });
    }
  }, []);

  if (!story) {
    // TODO: render 404
    return (
      <NotFoundScreen title={__('Story not found', 'volume.storyNotFound')} />
    );
  }

  const goBackLabel =
    parent === 'discover'
      ? __('Discover', 'common.ucfDiscover')
      : __('Favorites', 'common.ucfFavorites');

  const backtoPath = parent === 'discover' ? discoverPath() : favoritesPath();

  const { favoriteToggle } = useFavoriteToggle(story);

  return (
    <>
      <span id="scroll-beacon"></span>
      <CenterColumnLayout>
        <ContentGridInnerContainer>
          <HStack></HStack>
          <HeaderContainer>
            <PageHeadingContainer size="compact">
              <BackButton
                label={goBackLabel}
                action={() => navigate(backtoPath, { replace: true })}
              />
              <TeacherVolumeCardHeaderButtons
                onFavorite={favoriteToggle}
                onClassAssignment={assignToClassHandler(story)}
                isFavorited={story.isClassroomFavorited}
                badgeCount={story.assignCount}
              />
            </PageHeadingContainer>
            <Divider />
          </HeaderContainer>
          <ContentGrid className="x-content-grid">
            <VolumePage story={story} />
            <VSpacer size={14} />
            <Resources story={story} />
            <VSpacer size={14} />
            <DatesVoicesCredits story={story} />
            <VSpacer size={14} />
          </ContentGrid>
        </ContentGridInnerContainer>
      </CenterColumnLayout>
      <AssignmentDialog
        state={state}
        onClose={() => {
          send({ type: 'CLOSE' });
        }}
        onSelectClass={(classId: string) => {
          send({ type: 'SELECT_CLASS', classId });
        }}
        onCreateClass={() => {
          send({ type: 'SHOW_NEW_CLASS' });
        }}
      />
    </>
  );
});

export default VolumeDetailScreen;
