import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { groupBy } from 'lodash';
import { observer } from 'mobx-react';
import { useFiltering } from 'common/filtering/use-filtering';
import { FullyStyledMarkdown, Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { VSpacer } from 'naan/primitives/spacer';
import { Divider } from 'naan/primitives/divider';
import { createLogger } from 'app/logger';
import { UnitsList } from 'components/ui/units-list';
import { AppFactory } from 'app/app-factory';
import { appConfig } from 'app/env';

import __ from 'core/lib/localization';

const log = createLogger('filtered-units');

const discoverGroups = {
  FREE: 'FREE',
  WITH_GUIDES: 'WITH_GUIDES',
  PREMIUM: 'PREMIUM',
};

function discoverGroupBy(unit: any) {
  if (unit.trial === true) {
    return discoverGroups.FREE;
  }
  if (unit.activityGuideUrl) {
    return discoverGroups.WITH_GUIDES;
  }

  return discoverGroups.PREMIUM;
}

const Link = styled('button', {
  all: 'unset',
  color: '$teal-500',
  textStyle: 'small-text-bold',
  textDecoration: 'none',
  cursor: 'pointer',
});

export const LicenseLearnMoreDialog = ({
  onDismiss,
}: {
  onDismiss: () => void;
}) => {
  const markdown = __(
    `Most stories aren't accessible in the Lupa app with a trial account.
Students can purchase their own subscription by visiting my.lupa.app on the web,
or from inside the app.

Alternatively you may purchase a multi-seat license for your class.
For pricing, email us at [%{displayEmail}](mailto:%{email})`,
    'classroom.licenseLearnMoreAlertText',
    {
      email: appConfig.website.emails.educationalSales,
      // replace hyphen with non-breaking equivalent to prevent ugly layout issue
      displayEmail: appConfig.website.emails.educationalSales,
    }
  );

  return (
    <Dialog.Container onDismiss={onDismiss} open={true}>
      <Dialog.Heading>Lupa licenses</Dialog.Heading>
      <Dialog.Body>
        <FullyStyledMarkdown source={markdown} />
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          onClick={onDismiss}
          label={__('Ok', 'common.ok')}
          presentation="teal"
          fullWidth
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

const PremiumDescription = () => {
  const handleLearnMore = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <LicenseLearnMoreDialog onDismiss={onDismiss} />
    ));
  }, []);

  return (
    <>
      <Text textStyle="small-text" color="textSecondary">
        <i>
          Accessible to paid subscribers and students in a licensed Lupa
          Classroom.{' '}
          <Link onClick={handleLearnMore} data-test-id="learn-more">
            Learn more
          </Link>
        </i>
      </Text>
    </>
  );
};

const FreeHeading = observer(() => {
  return (
    <>
      <VSpacer size={8} />
      <Text textStyle="medium-heading" data-test-id="free-stories-title">
        {__('Free stories', 'discover.freeStories')}
      </Text>
      <VSpacer size={4} />
      <Divider />
    </>
  );
});

const PremiumHeading = observer(() => {
  return (
    <>
      <VSpacer size={8} />
      <Text textStyle="medium-heading" data-test-id="premium-stories-title">
        {__('Premium stories', 'discover.premiumStories')}
      </Text>
      <VSpacer size={1} />
      <PremiumDescription />
      <VSpacer size={4} />
      <Divider />
    </>
  );
});

export const FilteredUnits = observer(() => {
  const { filteredUnits } = useFiltering();
  const grouped = React.useMemo(() => {
    log.info(`inside memo - filteredUnits count: ${filteredUnits?.length}`);
    return groupBy(filteredUnits, discoverGroupBy);
  }, [filteredUnits]);

  const anyPremiumContent =
    grouped[discoverGroups.WITH_GUIDES] || grouped[discoverGroups.PREMIUM];

  return (
    <>
      {grouped[discoverGroups.FREE] ? (
        <>
          <FreeHeading />
          <UnitsList units={grouped[discoverGroups.FREE]} />
        </>
      ) : null}
      {anyPremiumContent ? <PremiumHeading /> : null}
      {grouped[discoverGroups.WITH_GUIDES] ? (
        <UnitsList units={grouped[discoverGroups.WITH_GUIDES]} />
      ) : null}
      {grouped[discoverGroups.PREMIUM] ? (
        <UnitsList units={grouped[discoverGroups.PREMIUM]} />
      ) : null}
    </>
  );
});
