import * as React from 'react';
import {
  ResponsiveGridLayout,
  ResponsiveNavContainer,
  ResponsiveMainContainer,
} from 'components/global-layout';
import { LearnNavigation } from 'components/nav/learn-navigation';
import { GlobalMessageWrapper } from './global-message-wrapper';
import { useParams } from 'react-router-dom';
import { ReturnNavState } from 'components/nav/return-nav-state';

export const LearnLayout: React.FC = ({ children }) => {
  const { l2 } = useParams<{ l2: string }>();

  // capture state for when returning from account screen
  ReturnNavState.appMode = 'learn';
  ReturnNavState.l2 = l2;

  return (
    <>
      <ResponsiveGridLayout className="x-global-grid">
        <ResponsiveMainContainer>
          <GlobalMessageWrapper>{children}</GlobalMessageWrapper>
        </ResponsiveMainContainer>
        <ResponsiveNavContainer>
          <LearnNavigation />
        </ResponsiveNavContainer>
      </ResponsiveGridLayout>
    </>
  );
};
