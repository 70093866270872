import * as React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { MaximizeButton } from 'study/views/notations/maximize-button';
import { useReaction } from '@common/hooks/use-reaction';
import { toggleClassname } from '@utils/toggle-class';
import { scrollToCurrentLine } from 'study/views/fx/scrolling';
import { NotationControls } from 'study/views/notations/notation-controls';
import { AppFactory } from '@app/app-factory';
import { isStudyModel } from 'player/models/player-model-handle';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  position: 'absolute',
  // inset: 0,
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  width: '100%',
  // background: '$yellow-500',
  // translate: '0 -100%',

  '& > .inner': {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    margin: '0 auto',
    // background: '$yellow-300',
    width: 'min(600px, calc(100% - 32px))',
    // translate: '0 calc(-100% - 16px)',

    '& > .control': {
      display: 'flex',
      translate: '0 calc(-100% - 16px)',
      transition: 'translate 0.3s ease-out',
      '&.centered': {
        justifyContent: 'center',
      },
      '&.hidden': {
        transition: 'translate 0.2s ease-out',
        translate: '0 16px',
      },
    },
  },
});

export const FloatingUi = observer(() => {
  const model = AppFactory.playerModel;
  if (!isStudyModel(model)) {
    // not relevant to soundbite player (but currently included by the shared player controls)
    return null;
  }
  const minimizeRef = React.useRef<HTMLDivElement>(null);
  const replayRef = React.useRef<HTMLDivElement>(null);
  const n = model.notationCount;
  // log.debug('render - notationCount', n);

  useReaction(
    () => model.expandNotationsButtonShown,
    show => {
      toggleClassname(minimizeRef.current, 'hidden', !show);
    }
  );
  useReaction(
    () => model.replayButtonsShown,
    show => {
      toggleClassname(replayRef.current, 'hidden', !show);
    }
  );

  return (
    <Wrapper id="floating-ui">
      <div className="inner">
        <div className="control" ref={minimizeRef}>
          <MaximizeButton
            n={n}
            onClick={() => {
              model.expandNotationPanel();
              scrollToCurrentLine();
            }}
          />
        </div>
        <div className="control centered" ref={replayRef}>
          <NotationControls
            replay={() => model.replayCurrentSentence()}
            snailReplay={() => model.snailReplayCurrentSentence()}
          />
        </div>
      </div>
    </Wrapper>
  );
});
