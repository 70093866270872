import * as React from 'react';
import { styled } from 'naan/stitches.config';
import image from './images/coming-soon.png';

import __ from '@core/lib/localization';

const Wrapper = styled('div', {
  $$imgWidth: '83px',
  $$imgHeight: '172px',
  background: '#8C9AE1', // @frankharrison is this a one-off color?
  borderRadius: '$space$3',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto $$imgWidth',
  overflow: 'hidden',
  width: '100%',

  '& > .copy': {
    padding: '16px 0 16px 16px',
    color: 'white',
    alignSelf: 'center',
    '& > .badge': {
      display: 'inline-block',
      textStyle: 'small-caps',
      backgroundColor: '#4356AC',
      color: 'white',
      padding: '2px 6px',
      marginBottom: '8px',
    },
    '& > .title': {
      textStyle: 'medium-heading',
      color: 'white',
      marginBottom: '4px',
    },
    '& > .description': {
      textStyle: 'small-text',
    },
  },

  '& > img': {
    width: '$$imgWidth',
    height: '$$imgHeight',
  },

  '@medium': {
    $$imgWidth: '135px',
    $$imgHeight: '280px',
    '& .copy': {
      padding: '24px 0 24px 24px',
      '& > .description': {
        textStyle: 'body',
      },
    },
  },
});

export const ComingSoonWidget = () => {
  return (
    <Wrapper>
      <div className="copy">
        <span className="badge">Coming Soon</span>
        <h3 className="title">Soundbites</h3>
        <p className="description">
          {__(
            'Daily mini-lessons based on delicious language found in our stories',
            'dashboard.daiilyMiniLessonsBasedOnDelicious'
          )}
        </p>
      </div>

      <img src={image} alt="Coming Soon" />
    </Wrapper>
  );
};
