import { Story } from '@core/models/story-manager';
import * as React from 'react';

const CurrentStoryContext = React.createContext<Story>({} as any);

export const CurrentStoryProvider: React.FC<{ story: Story }> = ({
  story,
  children,
}) => {
  return (
    <CurrentStoryContext.Provider value={story}>
      {children}
    </CurrentStoryContext.Provider>
  );
};

export const useCurrentStory = () => React.useContext(CurrentStoryContext);
