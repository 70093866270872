import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { styled } from '@naan/stitches.config';
import { StudyButton } from './study-button';
import { DoneButton } from './done-button';

export * from './resume-study-button';

const ChapterButtonsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '& > * + *': {
    marginTop: '$space$2',
  },
  '& > *': {
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  '@small': {
    display: 'flex',
    flexDirection: 'row',
    '& > * + *': {
      marginTop: 'unset',
      marginLeft: '$space$2',
    },
    '& > *': {
      maxWidth: '300px',
    },
  },
});

export const ChapterButtons = ({
  chapter,
  viaDetail, // when true, the push the story detail page on the location stack before the study page
}: {
  chapter: ChapterCatalogData;
  viaDetail: boolean;
}) => {
  const reviewMode: boolean = !chapter.isFurthest;
  return (
    <ChapterButtonsContainer>
      <StudyButton
        chapter={chapter}
        reviewMode={reviewMode}
        viaDetail={viaDetail}
      />
      <DoneButton chapter={chapter} />
    </ChapterButtonsContainer>
  );
};
