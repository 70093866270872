import {
  BasePlayerModel,
  PlayerType,
  TranslationButtonState,
} from 'player/models/base-player-model';
import { ExcerptData } from '@tikka/client/catalog-types';
import { createLogger } from 'app/logger';
import { makeObservable, observable } from 'mobx';
import { LoadingStatus } from 'player/models/player-model';
import { Speaker } from '@core/models/catalog/speaker';
import { Story } from '@core/models/story-manager/story';
import { AppFactory } from 'app/app-factory';
import { SoundbiteEngagementStatus } from '@core/models/user-manager/soundbite-engagement';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('soundbite-model');

export enum AnswerState {
  // eslint-disable-next-line no-unused-vars
  initial,
  // eslint-disable-next-line no-unused-vars
  disabled,
  // eslint-disable-next-line no-unused-vars
  unrevealed,
  // eslint-disable-next-line no-unused-vars
  revealed,
}

export class SoundbiteModel extends BasePlayerModel {
  playerType = PlayerType.SOUNDBITE;

  story: Story;
  @observable.ref answerRevealed = false; // once revealed, then always stays revealed

  constructor() {
    super();
    makeObservable(this);
  }

  async initFromData(data: ExcerptData) {
    log.debug('initFromData');
    this.forceDisablePlayback = true; // enabled once script actions are scrolled into view
    this.answerRevealed = false;
    await this.initFromPlayerData(data);

    const { storyManager } = AppFactory.root;
    log.info(`data.volumeSlug: ${data.volumeSlug}`);
    // if (data.volumeSlug) {
    //   // this.story = storyManager.story(data.volumeSlug);
    //   this.story = storyManager.storyForVolumeOrUnitSlug(
    //     data.volumeSlug || data.unitSlug
    //   );
    //   log.info(
    //     `resolved volume slug story: ${this.story?.title}, image url: ${this.story?.listImageUrl}`
    //   );
    // }
    // if (!this.story) {

    if (data.volumeDataUrl) {
      log.info('loading volumeDataUrl: ', data.volumeDataUrl);
      this.story = await storyManager.loadVolumeDataUrl(data.volumeDataUrl);
    } else {
      log.info('missing volumeDataUrl - resolving from storyManager');
      // fallback until all soundbites reingested
      // this.story = storyManager.storyForUnitSlug(this.excerptData.unitSlug); // confirm unit vs volume slug
      this.story = storyManager.storyForVolumeOrUnitSlug(
        data.volumeSlug || data.unitSlug
      );
      log.info(
        `resolved volume slug story: ${this.story?.title}, image url: ${this.story?.listImageUrl}`
      );

      if (!this.story) {
        log.info('anonymous mode');
        // await AppFactory.root.userManager.skipAuth();
        this.story = storyManager.storyForUnitSlug(this.excerptData.unitSlug); // confirm unit vs volume slug
      }
    }
    // }
    log.debug('themeColor: ', this.story?.themeColor);

    // don't set ready here. need to let the parent screen complete the initial state
    // this.setReady();

    // this.speakerMap = {};
    // for (const speaker of this.studyData.speakers) {
    //   this.speakerMap[speaker.label.l2] = speaker;
    // }

    log.debug('initFromData complete');
  }

  async resetSession() {
    this.setStatus(LoadingStatus.UNINITIALIZED);
    return this.initFromData(this.excerptData);
  }

  get excerptData(): ExcerptData {
    return this.data as ExcerptData;
  }

  get dataSourceUrl(): string {
    return this.excerptData.dataUrl;
  }

  // latest decision is to completely omit speaker labels for the soundbite player
  resolveSpeaker(label: string): Speaker {
    return null;
  }

  // resolveSpeaker(label: string): Speaker {
  //   if (!label) {
  //     return null;
  //   }
  //   if (this.story) {
  //     return this.story.resolveSpeaker(label);
  //   } else {
  //     log.error(`missing speaker data for label: ${label}`);
  //     return null;
  //   }
  // }

  dismissIntro() {
    this.setOnloadModalNeeded(false);

    const { userManager } = AppFactory.root;
    userManager.userData.playerSettings.setIntroShown(true);
    // still need to suppress network animation for some operations, but this should only affected signed in users testing try-soundbites
    userManager.persistUserData().catch(bugsnagNotify);
  }

  play() {
    super.play();
    // @jason, better approach here?
    const { soundbiteUserData } = AppFactory.root.userManager.userData;
    soundbiteUserData.recordEngagement({
      slug: this.data.slug,
      status: SoundbiteEngagementStatus.STARTED,
    });
  }

  handleChangeMilestoneStatus() {
    // must be processed first because the base version updates completionReached
    if (this.afterNotionalCompletion && !this.completionReached) {
      // @jason, better approach here?
      const { soundbiteUserData } = AppFactory.root.userManager.userData;
      soundbiteUserData.recordEngagement({
        slug: this.data.slug,
        status: SoundbiteEngagementStatus.FINISHED,
      });
    }
    super.handleChangeMilestoneStatus();
  }

  revealAnswer() {
    this.answerRevealed = true;
    this.track('answer_revealed');
    const { soundbiteUserData } = AppFactory.root.userManager.userData;
    soundbiteUserData.recordEngagement({
      slug: this.data.slug,
      status: SoundbiteEngagementStatus.REVEALED,
    });
  }

  get answerState(): AnswerState {
    // log.info(`answer state: `, touch);
    if (this.answerRevealed) {
      return AnswerState.revealed;
    }
    if (this.completionReached) {
      return AnswerState.unrevealed;
    }
    if (this.hasBeenPlayed) {
      return AnswerState.disabled;
    }
    return AnswerState.initial;
  }

  // no-op needed to satisfy currently shared keyboard controls
  togglePlayerMode() {}

  get fluentListenMode(): boolean {
    return false;
  }

  get complexPlayActionEnabled(): boolean {
    return false;
  }

  get playActionDisabled(): boolean {
    return this.forceDisablePlayback;
  }

  playPauseAction() {
    this.simplePlayPauseAction();
  }

  // control never shown for soundbite player
  get backToFurthestUI(): boolean {
    return false;
  }

  // not needed for soundbite player
  get progressBarUI(): boolean {
    return false;
  }

  get translationButtonState(): TranslationButtonState {
    if (this.answerState === AnswerState.revealed) {
      return TranslationButtonState.enabled;
    } else {
      return TranslationButtonState.disabled;
    }
  }

  // disables ability to click on an unvisited sentence
  get skipForwardAllowed(): boolean {
    return false;
  }

  get metricsPrefix(): string {
    return 'soundbite';
  }
}
