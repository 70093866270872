import dayjs from 'dayjs';
import { pick, sum } from 'lodash';

import { createLogger } from 'app/logger';

import __ from 'core/lib/localization';
import { AssistSettings } from './assist-settings';
import { ListeningLog } from './listening-log';
import { ListeningStats } from './listening-stats';
import { UserSettings } from './user-settings';
import {
  applySnapshot,
  ModelTreeNode,
  snap,
  TSTStringMap,
  volatile,
} from 'ts-state-tree/tst-core';
import { Root } from '../root';
import { StoryProgress } from './story-progress';
import { getBaseRoot } from '../app-root';
import { ClassroomUserData } from './classroom-user-data';
import { PlayerSettings } from './player-settings';
import {
  millisToMinutes,
  minutesToPrettyDuration,
} from '@core/lib/pretty-duration';
import { computed, runInAction } from 'mobx';
import { SoundbiteUserData } from './soundbite-user-data';
import { VideoGuideUserData } from './video-guide-user-data';
import { dayjsToIsoDate } from '@utils/date-utils';
import { alertWarningError, bugsnagNotify } from '@app/notification-service';
// import { AppFactory } from '@app/app-factory';

const logger = createLogger('um:user-data');

export const getStatsFromLogs = (logs: ListeningLog[]) => {
  // log.debug('getStatsFromLogs', logs);
  return ListeningStats.create(
    logs.reduce(
      (acc, curr) => {
        return {
          // todo: clean up naming dissidence between logs and stats
          millisListened: acc.millisListened + curr.listenedMillis,
          // millisRelistened: acc.millisRelistened + curr.relistenedMillis,
        };
      },
      {
        millisListened: 0,
        // millisRelistened: 0,
      }
    )
  );
};

/**
 * UserData
 *
 * conceptual owner of the client-side mutable user state which needs to get synced to/from the server
 * note the 'storyProgress's now live under the storyManager trunk, but get spliced during the
 * sync process.
 */
export class UserData extends ModelTreeNode {
  static CLASS_NAME = 'UserData' as const;

  static create(snapshot: any) {
    return super.create(UserData, snapshot) as UserData;
  }

  // reference convenience when browsing firestore
  _docId: string;
  _userId: string;
  _email: string;
  _name: string;

  listeningLogMap: TSTStringMap<ListeningLog> = snap({});
  listeningLogs: ListeningLog[]; // legacy data structure

  storyProgressMap: TSTStringMap<StoryProgress> = snap({});
  storyProgresses: StoryProgress[]; // legacy data to be migrated as encountered

  // dummy reference to include in schema gen.
  // todo: figure out if this should even be a tst model
  @volatile
  listeningStats: ListeningStats = null;

  playerSettings: PlayerSettings = snap({});
  // legacy bogota settings, only looked at during initial import to playerSettings
  assistSettings: AssistSettings = snap({});

  userSettings: UserSettings = snap({});

  classroom: ClassroomUserData = snap({});

  // soundbite interaction data and business logic
  soundbiteUserData: SoundbiteUserData = snap({});

  // helplets interaction data and business logic
  videoGuideUserData: VideoGuideUserData = snap({});

  // last rails server managed sync data
  // not relevant to firestore sync
  lastSyncedVersion: number = -2; // need an invalid initial number so we can detect once data has been loaded

  // todo: should move up to UserManager
  // when true, turn on links and theme preview from story list over to masala
  masalaAdmin: boolean = false; // TODO: rename and move to user manager

  // reflects if an initial destructive import has been performed
  // (from the account screen or automatic new user import)
  destructiveImportPerformed: boolean = false;

  // will be set to true the first time a player screen is opened while
  // accountData.mailingListPromptNeeded is true, which will then trigger
  // the prompt next time the dashboard is visited
  mailingListPromptEnabled: boolean = false;

  get root(): Root {
    return getBaseRoot(this);
  }

  mirrorReferenceAccountData() {
    const { accountData } = this.root.userManager;
    this._docId = accountData.userDataUuid;
    this._userId = accountData.userId;
    this._email = accountData.email;
    this._name = accountData.name;
  }

  // used to patch local data after sync out
  setLastSyncVersion(version: number) {
    this.lastSyncedVersion = version;
  }

  storyProgress(slug: string): StoryProgress {
    // return this.storyProgresses.find(progress => {
    //   return progress.slug === slug;
    // });

    return this.storyProgressMap.get(slug);
  }

  get storyProgressList(): StoryProgress[] /*IterableIterator<StoryProgress> doesn't support filter, etc */ {
    return Array.from(this.storyProgressMap.values());
  }

  ensureStoryProgress(slug: string): StoryProgress {
    let progress = this.storyProgress(slug);
    if (!progress) {
      progress = StoryProgress.create({ slug });
      // log.info(`creating StoryProgress(${slug}): ${progress.stringify}`);
      // this.storyProgresses.push(progress);
      this.storyProgressMap.set(slug, progress);
    }
    return progress;
  }

  get listeningLogList(): ListeningLog[] {
    return Array.from(this.listeningLogMap.values());
  }

  addListeningLog({
    storySlug,
    millis,
    date,
  }: {
    storySlug: string;
    millis: number;
    date?: string;
  }) {
    logger.debug(`addListeningLog, millis: ${millis}}`);

    // const currentISODate = dayjs().startOf('day').toISOString();
    if (!date) {
      date = this.root.storyManager.currentDate;
    }

    const mapKey = ListeningLog.mapKey(storySlug, date);

    const log = this.listeningLogMap.get(mapKey);

    if (log) {
      log.listenedMillis += millis;
    } else {
      // TODO this should not be done instead change in firestore will eventually apply
      // this is currently still needed because of race condition between the update invoke and the all-data persist
      // will revisit targetted updates once everything else is stable
      this.listeningLogMap.set(
        mapKey,
        ListeningLog.create({
          date,
          storySlug,
          listenedMillis: millis,
        })
      );

      // // TODO note this is an async operation being called in a non async function
      // AppFactory.firestoreInvoker.updateUserData(`listeningLogMap.${mapKey}`, {
      //   date,
      //   storySlug,
      //   listenedMillis: millis,
      // });
    }
  }

  // the simple list to map conversions which can be safely rechecked on every cold start for now
  migrateSimpleSchemaChanges(): boolean {
    try {
      const userSettingsChanged = this.userSettings.migrateListToMap();
      const classroomChanged = this.classroom.migrateListToMap();
      const soundbiteDataChanged = this.soundbiteUserData.migrateListToMap();
      return userSettingsChanged || classroomChanged || soundbiteDataChanged;
    } catch (error) {
      // paranoia
      alertWarningError({ error, note: 'UserData.migrateSimpleSchemaChanges' });
      return false;
    }
  }

  migrateBogotaAssistSettings() {
    this.playerSettings.setPlaybackRate(this.assistSettings.speed);
    this.playerSettings.setRedactionMode(this.assistSettings.caliRedactionMode);
  }

  async migrateBogotaUserData() {
    this.migrateSimpleSchemaChanges();
    this.migrateBogotaAssistSettings();
    await this.migrateStoryProgresses();
    await this.migrateListeningLogs();
  }

  async migrateListeningLogs(): Promise<boolean> {
    if (!this.listeningLogs || this.listeningLogs.length === 0) {
      logger.debug(`migrateListeningLogs - no legacy data`);
      return false;
    }

    logger.info(`migrateStoryProgresses - legacy data found...`);
    this.transformListeningLogListToMap();
    await this.root.userManager.persistUserData(); // consider not persisting anything until end
  }

  // listeningLog(storySlug: string, date: string): ListeningLog {
  //   return this.listeningLogMap.get(ListeningLog.mapKey(storySlug, date));
  // }

  transformListeningLogListToMap() {
    if (!this.listeningLogs) return; // paranoia
    const { storyManager } = this.root;
    // for now nuke current data
    // TODO: merge?
    runInAction(() => {
      applySnapshot(this.listeningLogMap, {});
      for (const log of this.listeningLogs) {
        const storySlug = storyManager.storySlugForUnitSlug(log.storySlug); // flatten unit logs to story level
        const date = log.date.split('T')[0]; // truncate the undesired time
        // we no longer care about distinguishing first listen and relisten in the charts or data, so just flatten during migration
        const millis = log.listenedMillis + (log.relistenedMillis || 0);
        // this will handle the logic to merge listen data for multiple story parts from same day
        this.addListeningLog({ storySlug, date, millis });
      }
      applySnapshot(this.listeningLogs, undefined);
    });
  }

  createRandomListeningLog() {
    const { storyManager, userManager } = this.root;
    const today = dayjs(storyManager.currentDate).startOf('day');
    const fifteenDaysAgo = dayjs(today).subtract(15, 'days');

    const dates = [];
    let currentDate = dayjs(fifteenDaysAgo);
    while (currentDate <= today) {
      dates.push(dayjs(currentDate));
      currentDate = currentDate.add(1, 'days');
    }

    const getRandomStorySlug = () => {
      const { availableStories } = storyManager;
      const randomIndex = Math.floor(Math.random() * availableStories.length);
      return availableStories[randomIndex].slug;
    };

    dates.forEach(date => {
      const listenedMillis = 6000 + Math.random() * 1000 * 6000;
      // const relistenedMillis = 6000 + Math.random() * 1000 * 6000;
      const isoDate = dayjsToIsoDate(date);

      const slug = getRandomStorySlug();
      this.listeningLogMap.set(
        ListeningLog.mapKey(slug, isoDate),
        ListeningLog.create({
          date: isoDate,
          storySlug: slug,
          listenedMillis,
          // relistenedMillis,
        })
      );
    });

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    userManager.persistUserData().catch(error => alertWarningError({ error }));
  }

  get hasInProgress() {
    return this.storyProgressList.some(progress => progress.inProgress);
  }

  // @computed
  get totalMillis() {
    return sum(this.listeningLogList.map(log => log.listenedMillis));
  }

  get totalPoints() {
    return sum(this.listeningLogList.map(log => log.points));
  }

  get totalListenedPretty() {
    return minutesToPrettyDuration(millisToMinutes(this.totalMillis));
  }

  storyListeningStats(slug: string): ListeningStats {
    return getStatsFromLogs(this.storyListeningLogs(slug));
  }

  storyListeningLogs(slug: string): ListeningLog[] {
    return this.listeningLogList.filter(log => log.storySlug === slug);
  }

  get statsChartData() {
    const list = this.listeningLogList;
    // const today = dayjs().startOf('day');
    const today = dayjs(this.root.storyManager.currentDate);

    const filterLogsByDate = (logs: ListeningLog[], date: dayjs.Dayjs) => {
      return logs.filter(log => dayjs(log.date) >= date); // TODO: confirm this still works as desired with truncated date strings
    };

    const sevenDaysAgo = dayjs(today).subtract(7, 'days');
    const fifteenDaysAgo = dayjs(today).subtract(13, 'days');

    const data15Days = filterLogsByDate(list, fifteenDaysAgo);
    const data7Days = filterLogsByDate(data15Days, sevenDaysAgo);

    const getPointsByDay = (
      logs: ListeningLog[],
      startDate: dayjs.Dayjs,
      endDate: dayjs.Dayjs
    ) => {
      let currentDate = dayjs(startDate);
      const dates = [];
      while (currentDate <= endDate) {
        dates.push(dayjs(currentDate));
        currentDate = currentDate.add(1, 'days');
      }

      const dayInitials = __('SMTWTFS', 'global.dayInitials');
      const daysAbbreviations = dayInitials.split('');
      return dates.map(date => ({
        letter: daysAbbreviations[date.day()],
        points: logs.reduce(
          (acc, log) =>
            dayjs(log.date).isSame(date, 'day') ? acc + log.points : acc,
          0
        ),
      }));
    };

    const graphData = getPointsByDay(data15Days, fifteenDaysAgo, today);
    const highestDayPoints = Math.max(...graphData.map(p => p.points));
    const totalPoints = graphData.reduce((acc, day) => acc + day.points, 0);

    return {
      graphData,
      highestDayPoints,
      totalPoints, // 14 day total (not all time)
      lastSevenStats: getStatsFromLogs(data7Days),
      allTimeStats: getStatsFromLogs(list),
      allTimePoints: this.totalPoints,
    };
  }

  // data needed for the "Stories" section of the "My Stats" page
  @computed
  get storyStats() {
    return {
      totalListenedPretty: this.totalListenedPretty,
      completedChapters: this.completedChapters,
      completedStories: this.completedStories,
    };
  }

  // data needed for the "Soundbites" section of the "My Stats" page
  @computed
  get soundbiteStats(): {
    currentStreak: number;
    longestStreak: number;
    totalCompleted: number;
  } {
    return pick(this.soundbiteUserData, [
      'currentStreak',
      'longestStreak',
      'totalCompleted',
    ]);
  }

  get currentSoundbiteStreak(): number {
    return 4; // todo
  }

  get longestSoundbiteStreak(): number {
    return 7; // todo
  }

  get totalSoundbitesCompleted(): number {
    return 21; // todo
  }

  get completedStories(): number {
    return this.validProgresses.reduce(
      (acc, progress) => acc + (progress.completed ? 1 : 0),
      0
    );
  }

  get completedChapters(): number {
    return this.validProgresses.reduce(
      (acc, progress) => acc + progress.completedChapters,
      0
    );
  }

  async toggleMasalaAdmin() {
    this.masalaAdmin = !this.masalaAdmin;
    await this.root.userManager.persistUserData(); // async
  }

  async updateImportPerformed(value: boolean) {
    this.destructiveImportPerformed = value;
    return this.root.userManager.persistUserData();
  }

  async updateMailingListPromptEnabled(value: boolean) {
    this.mailingListPromptEnabled = value;
    return this.root.userManager.persistUserData();
  }

  // convert from array to map, remove empty, update status, merge unit progress into volume, migrate vocab slugs
  async migrateStoryProgresses(): Promise<boolean> {
    const hasListData =
      !!this.storyProgresses && this.storyProgresses.length > 0;
    if (hasListData) {
      logger.info(`migrateStoryProgresses - legacy list data found...`);
      await this.transformStoryProgressListToMap();
      await this.root.userManager.persistUserData();
      return true;
    } else {
      logger.debug(`migrateStoryProgresses - skipping, no legacy list data`);
      return false;
    }
  }

  async transformStoryProgressListToMap() {
    if (!this.storyProgresses) return; // paranoia
    const { storyManager } = this.root;

    // can't use runInAction since we have await's

    // nuke data in current UserData instance. potentially merging handled by UserManager.importUserData
    applySnapshot(this.storyProgressMap, {});
    for (const unitProgress of this.storyProgresses) {
      if (unitProgress.notEmpty) {
        try {
          await unitProgress.transformUnitProgress();

          const story = storyManager.storyForUnitSlug(unitProgress.slug);
          if (story) {
            // can't user story.progress because that will create in the wrong UserData
            const headProgress = this.ensureStoryProgress(story.slug);
            headProgress.mergeProgressData(unitProgress);
          } else {
            logger.warn(
              `transformStoryProgressListToMap - story not found for unit slug: ${unitProgress.slug}`
            );
            this.storyProgressMap.set(unitProgress.slug, unitProgress);
          }
        } catch (error) {
          logger.error(
            `transformStoryProgressListToMap - error migrating or unit slug: ${unitProgress.slug}`,
            error
          );
          bugsnagNotify(error as Error);
        }
      }
      // else simply ignore empty progress records in legacy schema
    }
    applySnapshot(this.storyProgresses, undefined);
  }

  async pruneEmptyProgresses(): Promise<number> {
    const list = this.storyProgressList;
    const startingCount = list.length;
    const removalSlugs = list
      .filter(progress => progress.isEmpty)
      .map(progress => progress.slug);
    logger.info(
      `trimEmptyProgresses - original count: ${startingCount}, empty: ${removalSlugs.length}`
    );
    await this.removeProgressSlugs(removalSlugs);
    return removalSlugs.length;
  }

  async pruneOrphanedProgresses() {
    const list = this.storyProgressList;
    const startingCount = list.length;
    const removalSlugs = list
      .filter(progress => !(progress.hasVolumeSlug || progress.hasUnitSlug))
      .map(progress => progress.slug);
    logger.info(
      `trimOrphanedProgresses - original count: ${startingCount}, removal count: ${removalSlugs.length}`
    );
    await this.removeProgressSlugs(removalSlugs);
  }

  async removeProgressSlugs(slugs: string[]) {
    runInAction(() => {
      for (const slug of slugs) {
        this.storyProgressMap.delete(slug);
      }
    });
    await this.root.userManager.persistUserData();
  }

  async resetAllData() {
    logger.info('resetAllData');
    applySnapshot(this, {});
    await this.root.userManager.persistUserData();
  }

  async resetAllProgresses() {
    logger.info('resetAllProgresses');
    // applySnapshot(this.storyProgresses, []);
    applySnapshot(this.storyProgressMap, {});
    await this.root.userManager.persistUserData();
  }

  async resetAllListeningLogs() {
    logger.info('resetAllListeningLogs');
    applySnapshot(this.listeningLogMap, {});
    await this.root.userManager.persistUserData();
  }

  get emptyProgresses() {
    return this.storyProgressList.filter(progress => progress.isEmpty);
  }

  get validProgresses() {
    return this.storyProgressList.filter(progress => progress.valid);
  }

  get orphanedProgresses() {
    return this.storyProgressList.filter(progress => progress.orphaned);
  }

  get relevantProgresses() {
    return this.storyProgressList.filter(
      progress => progress.valid && progress.notEmpty
    );
  }
  // todo: migrate unit progresses to volume slugs

  get unitProgresses() {
    return this.storyProgressList.filter(progress => progress.hasUnitSlug);
  }

  get mergeNeededProgresses() {
    return this.storyProgressList.filter(progress => progress.needsMerge);
  }

  // get vocabMigrationNeeded(): boolean {
  //   return this.vocabMigrationNeededProgresses.length > 0;
  // }

  // get vocabMigrationNeededProgresses() {
  //   return this.storyProgressList.filter(
  //     progress => progress.needsVocabMigration
  //   );
  // }

  get progressesWithBogotaVocab() {
    return this.storyProgressList.filter(
      progress => progress.hasBogotaVocabSlugs
    );
  }

  // given an already normalized/migrated UserData structure, merge into current tree
  // will keep greater of listening logs that share the same date/story
  // will use the later of progress pointers and merge selected vocab

  // attempted to process raw snapshot data, but bowels of the merge logic needs full TST models
  // async mergeInProgressData({
  //   listeningLogMap,
  //   storyProgressMap,
  // }: {
  //   listeningLogMap: { [index: string]: ListeningLog }; // TSTStringMap<ListeningLog>;
  //   storyProgressMap: { [index: string]: StoryProgress }; //TSTStringMap<StoryProgress>;
  // }) {

  async mergeInProgressData(sourceData: UserData) {
    logger.info(`mergeInProgressData`);

    runInAction(() => {
      logger.info(`log count: ${sourceData.listeningLogMap.size}`);
      for (const log of sourceData.listeningLogList) {
        const existing = this.listeningLogMap.get(log.mapKey);
        if (existing && existing.listenedMillis >= log.listenedMillis) {
          logger.debug(
            `${log.mapKey}, ${log.listenedMillis} <= ${existing.listenedMillis} - preserving`
          );
        } else {
          if (existing) {
            logger.debug(
              `${log.mapKey}, ${log.listenedMillis} > ${existing.listenedMillis} - replacing `
            );
          } else {
            logger.debug(`${log.mapKey}, ${log.listenedMillis} - adding`);
          }
          this.listeningLogMap.set(log.mapKey, log);
        }
      }

      logger.info(`progress count: ${sourceData.storyProgressMap.size}`);
      for (const progress of sourceData.storyProgressList) {
        const existing = this.storyProgressMap.get(progress.slug);
        if (existing) {
          logger.debug(`merging into existing: ${progress.slug}`);
          existing.mergeProgressData(progress);
        } else {
          logger.debug(`ading new: ${progress.slug}`);
          this.storyProgressMap.set(progress.slug, progress);
        }
      }
    });
  }
}

//
// attic
//

// /*0 and -1 magic values will be assigned to this variable as follows,
//    0: user accept the request to review
//   -1: user decline the  request to review
//   see the spec for more details :
//   https://jiveworld.slite.com/app/channels/ptvqrYJErL/notes/ODyxK9uJfe
//   */
// numCompletedForReviewCta: number = INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA;

// lastAttemptedPurchase: AttemptedPurchase = null;
// lastAttemptedPendingPurchase: AttemptedPurchase = null;
// lastViewedFeaturedReleaseReleaseDate: string = null;

//
// mostly old native support
//

// setVolumeCurrentUnitNumber(slug: string, number: number) {
//   logger.debug(`setVolumeCurrentUnitNumber(${slug}, ${number})`);
//   return this.currentUnits.set(slug, number);
// }

// postponedReviewCta() {
//   this.numCompletedForReviewCta *= 2;
// }

// acceptedReviewCta() {
//   this.numCompletedForReviewCta = 0;
// }

// declinedReviewCta() {
//   this.numCompletedForReviewCta = -1;
// }

// setLastAttemptedPurchase({
//   purchaseType,
//   contentSlug,
// }: {
//   purchaseType: string;
//   contentSlug: string;
// }) {
//   this.lastAttemptedPurchase = AttemptedPurchase.create({
//     purchaseType,
//     contentSlug,
//   });
// }

// clearLastAttemptedPurchase() {
//   this.lastAttemptedPurchase = null;
// }

// setLastAttemptedPendingPurchase({
//   purchaseType,
//   contentSlug,
// }: {
//   purchaseType: string;
//   contentSlug: string;
// }) {
//   this.lastAttemptedPendingPurchase = AttemptedPurchase.create({
//     purchaseType,
//     contentSlug,
//   });
// }

// clearLastAttemptedPendingPurchase() {
//   this.lastAttemptedPendingPurchase = null;
// }

// setLastViewedFeaturedRelease(featuredRelease: StoryCollection) {
//   if (
//     featuredRelease &&
//     featuredRelease.releaseDate !== this.lastViewedFeaturedReleaseReleaseDate
//   ) {
//     this.lastViewedFeaturedReleaseReleaseDate = featuredRelease.releaseDate;
//   }
// }

// clearLastViewedFeaturedRelease() {
//   this.lastViewedFeaturedReleaseReleaseDate = null;
// }

// get showReviewCta(): boolean {
//   const { storyManager } = this.root;
//   if (!storyManager) return false;
//   const numCompletedStories = storyManager.completed.length;
//   return (
//     this.numCompletedForReviewCta > 0 &&
//     numCompletedStories >= this.numCompletedForReviewCta
//   );
// }

// get showReviewCtaMenuItem(): boolean {
//   const { userManager, storyManager } = this.root;
//   if (!userManager || !storyManager) return false;
//   const numCompletedStories = storyManager.completed.length;
//   const hasFullAccess = userManager.accountData.fullAccess;
//   return (
//     hasFullAccess &&
//     numCompletedStories >= INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA
//   );
// }

// get featuredReleaseViewed(): boolean {
//   const { storyManager } = this.root;
//   if (!storyManager) return false;
//   const currentFeaturedRelease = storyManager.latestCollection;
//   const hasViewed =
//     currentFeaturedRelease &&
//     currentFeaturedRelease.releaseDate ===
//       this.lastViewedFeaturedReleaseReleaseDate;
//   return hasViewed;
// }
