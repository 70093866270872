// import { localizedProperty } from 'core/lib/localization-helper';
import { listToMappedOrdinals } from '@utils/string-utils';
import { ModelTreeNode } from 'ts-state-tree/tst-core';

// this is the order the credits should be displayed by
const ORDERED_CREDIT_LABELS = [
  'producedBy',
  'coproducedWith',
  'executiveProducer',
  'directedBy',
  'createdBy',
  'host',
  'guest',
  'storyBy',
  'researchBy',
  'editedBy',
  'originalMusicBy',
  'soundDesignBy',
  'artworkBy',
  'photoBy',
  'factCheckingBy',
  'productionAssistant',
  'sponsoredBy',
  'producedIn',
];

const CREDIT_LABEL_ORDINALS = listToMappedOrdinals(ORDERED_CREDIT_LABELS);

// cali catalog format is very simple now
// assume labels will be localized during ingestion
export class Credit extends ModelTreeNode {
  static CLASS_NAME = 'Credit' as const;

  static create(snapshot: any) {
    return super.create(Credit, snapshot) as Credit;
  }

  name: string = '';
  label: string = '';
  labelSlug: string = '';

  public static sort(list: Credit[]): Credit[] {
    // const ordinals = list.map(a => a.sortOrdinal);
    return list.slice().sort((a, b) => a.sortOrdinal - b.sortOrdinal);
  }

  get sortOrdinal(): number {
    return CREDIT_LABEL_ORDINALS[this.labelSlug] ?? 999;
  }

  // get label() {
  //   return localizedProperty(this, 'label');
  // }
}
