import { AppFactory } from '@app/app-factory';
import { createLogger } from '@app/logger';
import { alertWarningError, bugsnagNotify } from '@app/notification-service';
import { FLUSH_LOGS_MESSAGE_TYPE, RELAYED_LOGS_MESSAGE_TYPE } from 'pwa/shared';
import { hasServiceWorker } from './update-manager';

const log = createLogger('log-relay');

const workerLog = createLogger('sw:relay');

export const flushWorkerLogs = async () => {
  try {
    if (!hasServiceWorker()) {
      log.warn('flushWorkerLogs - no serviceWorker ignoring');
      return;
    }
    const serviceWorker = navigator.serviceWorker;

    ensureSubscribed(serviceWorker);

    // this api didn't work on ios pwa
    // serviceWorker.controller.postMessage({
    //   type: FLUSH_LOGS_MESSAGE_TYPE,
    // });
    AppFactory.workbox
      .messageSW({ type: FLUSH_LOGS_MESSAGE_TYPE })
      .catch(bugsnagNotify);
  } catch (error) {
    alertWarningError({ error, info: 'flushWorkerLogs' });
  }
};

let subscribedToMessages = false;

const ensureSubscribed = (serviceWorker: ServiceWorkerContainer) => {
  if (!subscribedToMessages) {
    log.info(`attempting to subscribe to serviceWorker.onmessage events`);
    try {
      serviceWorker.onmessage = event => {
        if (event.data && event.data.type === RELAYED_LOGS_MESSAGE_TYPE) {
          const logs = event.data.logs ?? [];
          workerLog.debug('\n' + logs.join('\n'));
          AppFactory.root.serviceWorkerLogs = logs; // displayed on dev-tools
        }
      };
    } catch (error) {
      alertWarningError({ error, info: 'ensureSubscribed' });
    }
    subscribedToMessages = true;
  }
};
