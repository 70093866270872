import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog/chapter-catalog-data';
import { ChapterBreadcrumbs } from '../../story/chapter-breadcrumbs';
import { ChapterButtons } from 'story/chapter-buttons';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { MarkSimple } from '@naan/primitives/text';
// import { Responsive } from '@naan/primitives/responsive';

// import {
//   columnGap,
//   thumbnailSize,
// } from 'components/volume-layouts/volume-card-layout';

const ChapterCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '$gray-25',
  padding: '12px 16px',
  borderRadius: 8,
  marginBottom: 16,

  '@medium': {
    padding: '16px 16px 16px 24px',
    borderRadius: 12,
  },

  '& > .crumbs': {
    all: 'unset',
    cursor: 'pointer',
    marginLeft: 'auto',
    '@medium': {
      marginLeft: 16,
    },
  },
  '& > .actions': {
    marginLeft: 'auto',
    paddingLeft: 16,
    display: 'none',
    '@medium': {
      display: 'block',
    },
  },
});

const InfoWrapper = styled(Link, {
  all: 'unset',
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'row',
  alignItems: 'center',
  '& > .title': {
    textStyle: 'body-bold',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    overflow: 'hidden',
  },
  '& > .duration': {
    whiteSpace: 'nowrap',
    textStyle: 'small-text',
    color: '$textSecondary',
    marginLeft: 8,
    display: 'none',
    '@medium': {
      display: 'block',
    },
  },
});

const Info = observer(
  ({
    permalink,
    title,
    duration,
  }: {
    title: string;
    duration: string;
    permalink: string;
  }) => {
    return (
      <InfoWrapper to={permalink}>
        <div className="title">
          <MarkSimple source={title} />
        </div>
        <div className="duration">{duration}</div>
      </InfoWrapper>
    );
  }
);

export const StoryChapterCard = observer(
  ({
    chapter,
    viaDetail = false,
    permalink,
  }: {
    chapter: ChapterCatalogData;
    viaDetail: boolean;
    permalink: string;
  }) => {
    return (
      <ChapterCardWrapper>
        <Info
          title={chapter.title}
          duration={chapter.prettyDuration}
          permalink={permalink}
        />
        <Link className="crumbs" to={permalink}>
          <ChapterBreadcrumbs
            breadcrumbState={chapter.currentPoint.iteration}
            compact
          />
        </Link>
        <div className="actions">
          <ChapterButtons chapter={chapter} viaDetail={viaDetail} />
        </div>
      </ChapterCardWrapper>
    );
  }
);
