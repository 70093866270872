import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { CheckmarkHeavySmallIcon } from '@naan/icons/checkmark-heavy-icon';
import classNames from 'classnames';

const MAX_BREADCRUMBS = 3;

const BreadcrumbContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  cursor: 'default',
  // '& > .breadcrumb': {
  //   display: 'none',
  //   '&.current': {
  //     display: 'flex',
  //   },
  //   '@small': {
  //     display: 'flex',
  //   },
  // },

  '& > * + *': {
    marginLeft: '$space$2',
  },

  variants: {
    compact: {
      true: {
        '& > .breadcrumb': {
          display: 'none',
          '&.current': {
            display: 'flex',
          },
          '@small': {
            display: 'flex',
          },
        },
      },
    },
  },
});

const Breadcrumb = styled('div', {
  $$color: '$colors$gray-100',
  color: '$$color',
  borderColor: '$$color',
  display: 'flex',
  borderRadius: '50%',
  borderWidth: '1px',
  borderStyle: 'solid',
  width: '20px',
  height: '20px',
  textAlign: 'center',
  textStyle: 'tiny-text',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',

  '&.visited': {
    $$color: '$colors$gray-400',
  },

  '&.current': {
    $$color: '$colors$teal-500',
  },
});

const ChapterBreadcrumb = ({
  location,
  breadcrumbState,
}: {
  location: number;
  breadcrumbState: number;
}) => {
  return (
    <Breadcrumb
      className={classNames('breadcrumb', {
        current: location === breadcrumbState,
        visited: location < breadcrumbState,
      })}
    >
      {location === MAX_BREADCRUMBS ? (
        <CheckmarkHeavySmallIcon width={12} height={12} />
      ) : (
        location
      )}
    </Breadcrumb>
  );
};

export const ChapterBreadcrumbs = ({
  breadcrumbState,
  compact,
}: {
  breadcrumbState: number;
  compact?: boolean;
}) => (
  <BreadcrumbContainer compact={compact}>
    <ChapterBreadcrumb location={1} breadcrumbState={breadcrumbState} />
    <ChapterBreadcrumb location={2} breadcrumbState={breadcrumbState} />
    <ChapterBreadcrumb location={3} breadcrumbState={breadcrumbState} />
  </BreadcrumbContainer>
);
