import Dayjs from 'dayjs';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import __ from 'core/lib/localization';
import { isEmpty } from 'lodash';

import {
  applySnapshot,
  getParentOfType,
  ModelTreeNode,
} from 'ts-state-tree/tst-core';
import { Classroom } from './classroom';
import { StudentProgress } from './student-progress';
import { Root } from '../root';
import { getBaseRoot } from '../app-root';
import { Story } from '../story-manager';
import { createLogger } from 'app/logger';
import { ApiInvoker } from 'core/services/api-invoker';
import { AppFactory } from '@app/app-factory';

const log = createLogger('story');

Dayjs.extend(localizedFormat); // only do this once?

/// WHY? this is a magic date that the server understands as an empty date.
export const EMPTY_DATE = '1970-01-01';

export class Assignment extends ModelTreeNode {
  static CLASS_NAME = 'Assignment' as const;

  static create(snapshot: any) {
    return super.create(Assignment, snapshot) as Assignment;
  }

  episodeSlug: string = null; // unit slug for legacy data
  dueDate: string = ''; // iso8601 formatted date string
  details: string = null; // ad hoc teacher notes
  studentProgresses: StudentProgress[] = [];

  get root(): Root {
    return getBaseRoot(this);
  }

  get apiInvoker(): ApiInvoker {
    return this.root.apiInvoker;
  }

  async updateProps(props: any) {
    const classroom = getParentOfType(this, Classroom);
    await classroom?.updateAssignmentProps(this.episodeSlug, props);
    return { message: 'Updated' }; // todo: localize
  }

  // the UI calls it "note". The API calls it "details". This helps with the cognitive dissonance.
  setNote(details: string) {
    return this.updateProps({ details });
  }

  setDueDate(dueDate: string) {
    return this.updateProps({ dueDate });
  }

  resetDueDate() {
    return this.setDueDate(EMPTY_DATE);
  }

  get classroom(): Classroom {
    // return getParent(this); -- beware, the direct parent was an array
    const result = getParentOfType(this, Classroom);
    return result;
  }

  get story(): Story {
    const { storyManager } = getBaseRoot(this);
    // need adaptive lookup to handle legacy data
    return storyManager.storyForVolumeOrUnitSlug(this.episodeSlug);
  }

  get displayDueDate() {
    if (!this.dueDate) {
      return __('No due date', 'clasrroom.noDueDate');
    }

    const date = dayjs(this.dueDate);
    if (date.isValid) {
      return date.format('MMM DD, YYYY');
    }
    return this.dueDate;
  }

  get displayDueDateShort() {
    if (this.dueDate) {
      const date = dayjs(this.dueDate);
      if (date.isValid) {
        return date.format('MMM D');
      }
      return this.dueDate;
    }
    return null;
  }

  get displayNote() {
    return isEmpty(this.details)
      ? __('No note', 'clasrroom.noNote')
      : this.details;
  }

  get isPastDueDate() {
    // const yesterday = dayjs().subtract(1, 'day');
    const currentDate = AppFactory.root.storyManager.currentDate;
    const yesterday = dayjs(currentDate).subtract(1, 'day');
    return dayjs(this.dueDate).isBefore(yesterday);
  }

  // moved to app-helpers
  // get navLinkUrl() {
  //   return `/${this.classroom.navLinkUrl}/assignments/${this.episodeSlug}`;
  // }

  // lazily populate the full assignment data into the main state tree.
  // used when visiting the assignment detail screen
  async fetchProgressData(): Promise<void> {
    log.info('fetchProgressData');
    const data = await this.apiInvoker.get(
      `classrooms/${this.classroom.id}/assignments/${this.episodeSlug}`,
      {}
    );
    log.info(`fetched assignment data: ${JSON.stringify(data)}`);
    // beware, this apply is triggering premature getters before
    // the parent hierarchy is fully wired up
    applySnapshot(this, data);
  }
}
