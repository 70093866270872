import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { HStack, VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { useCouponDialog } from './use-coupon-dialog';
import { useAnonymousCouponDialog } from './use-anonymous-coupon-dialog';

import __ from 'core/lib/localization';

export const CouponButton = observer(
  ({
    label = __(
      'Have a classroom, coupon or promo code?',
      'account.haveACoupon'
    ),
    presentation = 'tealTransparent',
  }: Partial<
    Pick<React.ComponentProps<typeof Button>, 'label' | 'presentation'>
  >) => {
    const handleApplyCode = useCouponDialog();
    return (
      <>
        <VStack>
          <VSpacer size={6} />
          <HStack justify="center">
            <Button
              label={label}
              onClick={handleApplyCode}
              testId="redeem-code"
              presentation={presentation}
              css={{ textDecoration: 'underline' }}
            />
          </HStack>
        </VStack>
      </>
    );
  }
);

export const PlainCouponLink = observer(
  ({
    label = __(
      'Have a classroom, coupon or promo code?',
      'account.haveACoupon'
    ),
  }: {
    label?: string;
  }) => {
    const handleApplyCode = useCouponDialog();
    const handleClick = React.useCallback(
      e => {
        e.preventDefault();
        handleApplyCode();
      },
      [handleApplyCode]
    );

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" onClick={handleClick}>
        {label}
      </a>
    );
  }
);

export const AnonymousPlainCouponLink = observer(
  ({
    label = __(
      'Have a classroom, coupon or promo code?',
      'account.haveACoupon'
    ),
  }: {
    label?: string;
  }) => {
    const handleApplyCode = useAnonymousCouponDialog();
    const handleClick = React.useCallback(
      e => {
        e.preventDefault();
        handleApplyCode();
      },
      [handleApplyCode]
    );

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" onClick={handleClick}>
        {label}
      </a>
    );
  }
);
