import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { keyframes, styled } from 'naan/stitches.config';
import { Scroller, CloseButton, Overlay } from 'naan/primitives/modals';
import { Divider } from '../divider';
import { VSpacer } from '../spacer';
import { MarkSimple } from '../text';

const Root = DialogPrimitive.Root;
const Portal = DialogPrimitive.Portal;

const Heading = styled('div', {
  paddingTop: '$space$4',
  px: '$space$4',
  textStyle: 'small-heading',
  '@medium': {
    textStyle: 'medium-heading',
    px: '$space$6',
  },
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.75)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const Content = styled(DialogPrimitive.Content, {
  $$maxWidth: '960px',
  backgroundColor: '$white',
  borderRadius: 12,
  boxShadow: `0px 1px 4px $colors$black-alpha-20`,
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(calc(100% - 32px), $$maxWidth)',
  maxHeight: 'calc(100% - 32px)',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 0.5s cubic-bezier(0.16, 1, 0.3, 1)`,
    willChange: 'transform',
  },
  '&:focus': { outline: 'none' },
});

const ScrollerWrapper = styled(Scroller, {
  px: '$space$4',
  '@medium': {
    px: '$space$6',
  },
});

export const Modal = ({
  heading,
  children,
  trigger,
  onDismiss,
}: {
  heading?: string;
  children?: React.ReactNode;
  trigger?: React.ReactNode;
  onDismiss?: () => void;
}) => {
  return (
    <Root onOpenChange={onDismiss}>
      <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>
      <Portal>
        <Overlay css={{ zIndex: 1001 }}>
          <Content>
            <Heading>
              <MarkSimple source={heading} />
              <CloseButton />
              <VSpacer size={4} />
              <Divider />
            </Heading>
            <ScrollerWrapper>{children}</ScrollerWrapper>
          </Content>
        </Overlay>
      </Portal>
    </Root>
  );
};
