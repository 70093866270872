import dashjs, { MediaPlayerClass } from 'dashjs';
import { makeObservable, observable, action } from 'mobx';
import { createLogger } from '@common/log';

const log = createLogger('player-model');

export const VideoStates = {
  NOT_STARTED: 'NOT_STARTED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  ENDED: 'ENDED',
  TRANSITIONING: 'TRANSITIONING',
} as const;

export type TVideoStates = typeof VideoStates[keyof typeof VideoStates];

export class PlayerViewController {
  state: TVideoStates = VideoStates.NOT_STARTED;
  videoElement: HTMLVideoElement | null = null;
  mediaPlayer: MediaPlayerClass | null = null;
  canPlay = false;
  initialized: boolean = false;

  constructor() {
    this.initialized = false;
    makeObservable(this, {
      state: observable,
      setState: action,
      canPlay: observable,
      setCanPlay: action,
      startTransitioning: action,
    });

    (window as any).vplayer = this;
  }

  initialize({
    videoElement,
    url,
    onEnded,
  }: {
    url: string;
    videoElement: HTMLVideoElement;
    onEnded: () => void;
  }) {
    if (this.initialized) {
      throw new Error('Cannot initialize player twice. Uninitialize first.');
    }

    this.state = VideoStates.NOT_STARTED;
    this.canPlay = false;

    this.videoElement = videoElement;

    this.mediaPlayer = dashjs.MediaPlayer().create();
    this.mediaPlayer.initialize(
      videoElement,
      url,
      // autoplay
      false
    );

    this.mediaPlayer.on('playbackStarted', () => {
      log.info('PLAYING');
      this.setState(VideoStates.PLAYING);
    });

    this.mediaPlayer.on('playbackPaused', () => {
      log.info('PAUSED');
      this.setState(VideoStates.PAUSED);
    });

    this.mediaPlayer.on('playbackEnded', () => {
      log.info('ENDED');
      onEnded();
      this.setState(VideoStates.ENDED);
    });

    this.videoElement.addEventListener('canplay', this.onCanPlay);

    this.videoElement.addEventListener('waiting', this.onWaiting);
  }

  onCanPlay = () => {
    this.setCanPlay(true);
  };

  onWaiting = () => {
    this.setCanPlay(false);
  };

  setState(state: TVideoStates) {
    this.state = state;
  }

  setCanPlay(canPlay: boolean) {
    this.canPlay = canPlay;
  }

  get isNotStarted() {
    return this.state === VideoStates.NOT_STARTED;
  }

  get isPlaying() {
    return this.state === VideoStates.PLAYING;
  }

  get isPaused() {
    return this.state === VideoStates.PAUSED;
  }

  get isEnded() {
    return this.state === VideoStates.ENDED;
  }
  get isTransitioning() {
    return this.state === VideoStates.TRANSITIONING;
  }

  startTransitioning() {
    this.setState(VideoStates.TRANSITIONING);
  }

  reset() {
    if (this.initialized) {
      this.mediaPlayer.reset();
      this.videoElement.removeEventListener('canplay', this.onCanPlay);
      this.videoElement.removeEventListener('waiting', this.onWaiting);
      this.initialized = false;
      this.state = VideoStates.NOT_STARTED;
    }
  }
}
