import { ValidationError } from './validation-error';
import { alertLevels } from './alert-levels';
import { APIError } from './api-error';
import { HandleableError } from './handleable-error';

/**
 * Handles either an error instance or:
 * @param type - server provided error class name
 * @param message
 * @param key - corresponding form field name if a validation style error
 * @returns {GenericError}
 */
export const createError = (
  errorData:
    | Error
    | Partial<{ type: string; message: string; key: string; code: string }>
) => {
  if (errorData instanceof Error) {
    return errorData;
  }
  const { type, message: rawMessage, key, code } = errorData;

  const message = rawMessage || String(errorData);

  // type is the server error class for api error responses
  // we didn't seem to be using the actual value before other than to drive
  // the construction flavors here
  switch (type) {
    case undefined: {
      // right now the server will send {"error":"message"} style responses if there's a grape level validation error
      /// I don't know how to resolve this, so I'll just pass it through
      return new HandleableError(message, {
        alertLevel: alertLevels.ERROR,
        report: false,
      });
    }

    case 'ValidationError':
      return new ValidationError({ message, key, code });

    default:
      return new APIError({ type, message, key });
  }
};

export const GENERIC_SEVERE_ERROR_MESSAGE =
  'Unexpected error, please contact customer service if issue persists';

// export const createSevereError = ({
//   message,
//   error,
//   note,
// }: {
//   message?: string;
//   error?: Error; // cause
//   note?: string; // internal note for logging
// }) => {
//   message = message || GENERIC_SEVERE_ERROR_MESSAGE;
//   return new HandleableError(message, {
//     alertLevel: alertLevels.ERROR,
//     report: true,
//     context: { error, note },
//   });
// };
