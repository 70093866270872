import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { PlayerMode } from '@common/misc-types';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';

export const Completed = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    const navigateToStudy = useNavigateToStudy();

    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        durationText={chapter.prettyDuration}
        reviewAction={() => {
          chapter.review();
          navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN);
        }}
        checked={true}
      />
    );
  }
);
