import * as React from 'react';
import * as Dialog from 'naan/primitives/modals';
import { observer } from 'mobx-react';
import { PlainMarkdown, Text } from 'naan/primitives/text';
import { Button } from 'naan/primitives/button';
import { useTitle } from 'common/hooks/use-title';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { useComboForm } from 'common/hooks/use-comboform';
import { emailSchema } from 'core/lib/validation-rules';
import { AppFactory } from 'app/app-factory';
import { Label } from 'naan/primitives/forms/label';
import { Input } from 'naan/primitives/input';
import { CatchAllFormErrors } from 'naan/primitives/forms/catch-all-form-error';
import { FormError } from 'naan/primitives/forms';
import { omit } from 'lodash';
import { AuthInlineLink } from 'components/auth/auth-inline-link';
import { AuthHeading } from 'components/auth/auth-heading';

import __ from 'core/lib/localization';
import { signInPath } from 'components/nav/path-helpers';
import { alertSevereError } from '@app/notification-service';

const ResetSuccessDialog = ({
  email,
  onDismiss,
  onResend,
}: {
  email: string;
  onDismiss: () => void;
  onResend: () => void;
}) => {
  const handleResend = React.useCallback(() => {
    onDismiss();
    onResend();
  }, [onDismiss, onResend]);

  return (
    <Dialog.Container open={true} onDismiss={onDismiss}>
      <Dialog.Heading type="success">Email sent</Dialog.Heading>
      <Dialog.Body>
        <VStack>
          <VSpacer size={3} />
          <PlainMarkdown
            source={__(
              'Check your **%{email}** inbox for instructions from us on how to reset your password.',
              'auth.checkYourEmail',
              { email }
            )}
          />
          <VSpacer size={3} />
        </VStack>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction="column">
        <Dialog.Button
          presentation={'secondary'}
          label={'Resend Email'}
          onClick={handleResend}
        />
        <Dialog.Button
          presentation={'teal'}
          label={'Close'}
          data-test-id="okButton"
          onClick={onDismiss}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
};

type FormInput = {
  email: string;
};

export const ResetPasswordScreen = observer(() => {
  useTitle('Reset password');
  const { userManager } = AppFactory.root;

  React.useEffect(() => {
    userManager
      .logout()
      .catch(error =>
        alertSevereError({ error, note: 'reset-password - logout' })
      );
  }, [userManager]);

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({ validationSchema: emailSchema });

  const handleResetPassword = React.useCallback(
    ({ email }: FormInput) => {
      clearValidationErrors();
      userManager.sendPasswordReset(email, true).then(() => {
        AppFactory.dialogPresenter.present(onDismiss => (
          <ResetSuccessDialog
            email={email}
            onDismiss={onDismiss}
            onResend={() => {
              userManager
                .sendPasswordReset(email, true)
                .then(() => {
                  AppFactory.toastService.open({
                    message: 'Email resent',
                    type: 'success',
                  });
                })
                .catch(error =>
                  alertSevereError({
                    error,
                    note: 'reset-password - sendPasswordReset',
                  })
                );
            }}
          />
        ));
      }, handleValidationErrors);
    },
    [handleValidationErrors, userManager, clearValidationErrors]
  );

  return (
    <VStack>
      <AuthHeading>{__('Reset password', 'common.resetPassword')}</AuthHeading>
      <Text
        textStyle="body"
        color="textSecondary"
        css={{
          textAlign: 'center',
          marginTop: '-24px',
        }}
      >
        {__(
          'Enter your email address to request a password reset',
          'auth.enterYourEmailAddressToRequest'
        )}
      </Text>
      <VSpacer size={6} />
      <form onSubmit={handleSubmit(handleResetPassword)} noValidate>
        <CatchAllFormErrors errors={omit(errors, ['email'])} />
        <Label htmlFor="email">{__('Email', 'auth.email')}</Label>
        <VSpacer size={2} />
        <Input
          name={'email'}
          data-test-id={'email-address'}
          type="text"
          autoComplete="off"
          {...register('email')}
        />
        <FormError error={errors['email']} data-test-id="email-error-message" />
        <VSpacer size={6} />
        <div style={{ width: '100%', display: 'flex' }}>
          <Button
            label={__('Reset password', 'common.resetPassword')}
            presentation="teal"
            size="large"
            fullWidth
          />
        </div>
        <VSpacer size={6} />
        <AuthInlineLink
          text={__('[Return to sign in](%{url})', 'auth.returnToSignIn', {
            url: signInPath(),
          })}
        />
      </form>
    </VStack>
  );
});
