import { styled } from '@naan/stitches.config';

export const SectionBreak = styled('div', {
  height: 40,
  '@small': {
    height: 56,
  },
  '@medium': {
    height: 80,
  },
});

export const MediumGap = styled('div', {
  height: 40,
  '@small': {
    height: 56,
  },
  '@medium': {
    height: 56,
  },
});

export const LargeGap = styled('div', {
  height: 40,
  '@small': {
    height: 56,
  },
  '@medium': {
    height: 80,
  },
});

export const SectionEnds = styled('div', {
  height: 8,
  '@small': {
    height: 16,
  },
});
