import * as React from 'react';
import { ChevronDownIcon } from 'naan/icons/chevron-down-icon';
import { IconButton } from '@naan/primitives/button';
import { useReturnFromStudy } from 'lib/hooks/use-navigate-to-study';

export const CloseControl = () => {
  const returnFromStudy = useReturnFromStudy();

  return (
    <IconButton
      onClick={returnFromStudy}
      icon={<ChevronDownIcon />}
      presentation="whiteTransparent"
    />
  );
};
