import React from 'react';
import { observer } from 'mobx-react';
import { MarkSimple, PlainMarkdown } from '@naan/primitives/text';
import { SoundbiteCatalogData } from '@core/models/story-manager/story-manager';
import { AppFactory } from '@app/app-factory';
import { VSpacer } from '@naan/primitives/spacer';
import { useNavigate } from 'react-router-dom';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ArrowRightSmallIcon } from '@naan/icons/arrow-right-icon';
import { Responsive } from '@naan/primitives/responsive';
import { ComingSoonWidget } from './coming-soon-widget';

const WidgetContainer = styled('div', {
  position: 'relative',
  $$thumbSize: '72px',
  borderRadius: '8px',
  padding: '$space$3 $space$4 $space$4 $space$4',
  '@medium': {
    padding: '$space$5 $space$6 $space$6 $space$6',
  },
  '@extraLarge': {
    $$thumbSize: '116px',
  },
  '& > .widget-header': {
    color: '$white',
    textStyle: 'small-caps',
    paddingBottom: '$space$3',
    '@medium': {
      paddingBottom: '15px', //The slightly quirky 15px margin below the header compensates for the 1px rule below it, to ensure that the final height is 280px
    },
    borderBottom: '1px solid $white-alpha-30',
  },
  '& > .widget-body': {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '$space$3',
    '@medium': {
      marginTop: '$space$4',
    },
    '& > .widget-body-text': {
      color: '$white',
      textStyle: 'small-text',
      $$lineHeight: '20px', // this assumes small-text style
      '& > .single-line': {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
      },
      '& > .title': {
        textStyle: 'small-heading',
      },
      '& > .prompt': {
        $$numberOfLines: 2,
        '@medium': {
          $$numberOfLines: 3,
        },
        overflow: 'hidden',
        maxHeight: 'calc($$lineHeight *  $$numberOfLines)',
        textOverflow: 'ellipsis',
        /// this next lines are not standard, so it may not work in all browsers
        /// or stop working on some point. they are being used to render ... at the end
        // of text. the height limit set above will work regardless of these props
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '$$numberOfLines',
      },
    },
    '& > .thumbnail': {
      marginLeft: '$space$4',
      marginTop: '$space$1',
      '& img': {
        width: '$$thumbSize',
        height: '$$thumbSize',
        aspectRatio: 1,
        borderRadius: '8px',
      },
    },
  },
});

const InvisibleButton = styled('div', {
  position: 'absolute',
  inset: 0,
  cursor: 'pointer',
});

export const SoundbiteWidget = observer(
  // observer probably not needed here
  ({ soundbite }: { soundbite: SoundbiteCatalogData }) => {
    const navigate = useNavigate();

    if (!soundbite) {
      return <ComingSoonWidget />;
    }

    const story = AppFactory.root.storyManager.story(soundbite.volumeSlug);
    if (!story) return null; // should figure out why this happens sometimes

    const country = story.countries?.join(', ') || '';
    const navigateToSoundbite = () => navigate('/daily-soundbite');

    return (
      <WidgetContainer css={{ backgroundColor: story.themeColor }}>
        <div className="widget-header">Today’s Soundbite</div>
        <div className="widget-body">
          <div className="widget-body-text">
            <div className="title single-line">
              <MarkSimple source={soundbite.title} />
            </div>
            <VSpacer size={1} />
            <div className="single-line">
              <MarkSimple source={`from ${story.title} • ${country}`} />
            </div>
            <VSpacer size={3} />
            <div className="prompt">
              <PlainMarkdown source={soundbite.prompt} />
            </div>
          </div>
          <div className="thumbnail">
            <img src={story.listImageUrl} alt="" />
          </div>
        </div>
        <Responsive
          renderDefault={() => (
            <InvisibleButton onClick={navigateToSoundbite} />
          )}
          renderMediumAndUp={() => (
            <>
              <VSpacer size={4} />{' '}
              <Button
                label="Listen now"
                onClick={navigateToSoundbite}
                size={'large'}
                presentation={'whiteDarken'}
                rightIcon={<ArrowRightSmallIcon />}
              />
            </>
          )}
        />
      </WidgetContainer>
    );
  }
);
