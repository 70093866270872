import * as React from 'react';
import { VideoModalCard } from '../video-modal-card';
import { namedStyled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { namedObserver } from 'lib/named-observer';

import __ from '@core/lib/localization';

import lotusPortrait from './svg/lotus-portrait.svg';
import lotusLandscape from './svg/lotus-landscape.svg';
import { EndCardProps } from './end-card-props';

const Wrapper = namedStyled('EndCardWrapper', VideoModalCard, {
  $$image: '',
  backgroundColor: '$blue-700',
  display: 'flex',
  alignItems: 'center',
  padding: '$space$4',
  '@medium': {
    padding: '$space$10',
  },

  '&.enlightenment-card': {
    backgroundImage: `url(${lotusPortrait})`,
    '@medium': {
      backgroundImage: `url(${lotusLandscape})`,
      imageSize: 'cover',
    },
  },

  '& .copy': {
    textAlign: 'center',
    width: '100%',
    textStyle: 'medium-heading',
    color: '$colors$white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > strong': {
      display: 'block',
      color: '$colors$pink-300',
      fontWeight: 'inherit',
    },
    '& > .yellow': {
      display: 'block',
      color: '$colors$yellow-300',
    },
  },

  '& .replay-container': {
    position: 'absolute',
    left: 16,
    bottom: 16,
  },
  '& .close-container': {
    position: 'absolute',
    right: 16,
    bottom: 16,
  },
});

export const EnlightenmentCard = namedObserver(
  'EnlightmentCard',
  ({ player, model, onDismiss }: EndCardProps) => {
    return (
      <Wrapper className="enlightenment-card" onTop>
        <div className="copy">
          <span>
            {__(
              'You’ve achieved Jiveworld enlightenment',
              'dashboard.youveAchievedJiveworldEnlightenment'
            )}
          </span>
          <span className="yellow">
            {__('Excelente ¡Vamos!', 'dashboard.excelenteVamos')}
          </span>
        </div>
        <div className="close-container">
          <Button
            onClick={() => {
              onDismiss();
            }}
            label={__('Close', 'dashboard.close')}
            presentation={'blue'}
            size="large"
          />
        </div>
        <div className="replay-container">
          <Button
            onClick={player.mediaPlayer.play}
            label={__('Replay', 'dashboard.replay')}
            presentation={'whiteDarken'}
            size="large"
            leftIcon={<ReplayIcon />}
          />
        </div>
      </Wrapper>
    );
  }
);
