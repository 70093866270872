import React from 'react';
import { observer } from 'mobx-react';
import { styled } from 'naan/stitches.config';
import __ from 'core/lib/localization';
import { Button } from 'naan/primitives/button';
import { VSpacer } from 'naan/primitives/spacer';
import { Text } from 'naan/primitives/text';
import { AppFactory } from '@app/app-factory';

const TableNote = styled('div', {
  background: '$gray-25',
  borderRadius: '8px',
  padding: '$space$4',
  marginTop: '$space$5',
  whiteSpace: 'normal',
});

const NoBreak = styled('span', {
  wordBreak: 'break-all',
});

const ButtonsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column-reverse',

  '& > * + *': {
    margin: '$space$2 0 0 0',
  },

  '@small': {
    flexDirection: 'row',
    '& > * + *': {
      margin: '0 0 0 auto',
    },
  },
});

export const PendingEmailNote = observer(() => {
  const { userManager } = AppFactory.root;
  const { accountData } = userManager;
  const showNote =
    accountData.unconfirmedEmail; /*  || accountData.emailConfirmationRequired */

  if (!showNote) {
    return null;
  }

  if (accountData.unconfirmedEmail) {
    return (
      <TableNote>
        <Text textStyle={'small-text'}>
          {__('Pending verification', 'emailNote.pendingVerification')}
          <br />
          <NoBreak as={'strong'}>{accountData.unconfirmedEmail}</NoBreak>{' '}
        </Text>
        <VSpacer size={2} />
        <Text textStyle={'small-text'}>
          {__('We’ve sent an email to', 'emailNote.weSentAnEmail')}{' '}
          <NoBreak>{accountData.unconfirmedEmail}</NoBreak>{' '}
          {__(
            'with a link to confirm this email change.',
            'emailNote.withLinkToConfirm'
          )}{' '}
        </Text>
        <VSpacer size={4} />
        <ButtonsWrapper>
          <Button
            onClick={userManager.cancelPendingEmailChange}
            label={__('Cancel', 'common.cancel')}
            size={'small'}
            presentation="cancel"
          />

          <Button
            onClick={userManager.resendEmailConfirmation}
            label={__('Resend email', 'emailNote.resendEmail')}
            size={'small'}
            presentation="secondary"
          />
        </ButtonsWrapper>
      </TableNote>
    );
  }

  /// This is not a feature anymore
  // if (accountData.emailConfirmationRequired) {
  //   return (
  //     <TableNote>
  //       <p>
  //         {__('Pending verification', 'emailNote.pendingVerification')}
  //         <br />
  //         <NoBreak as={'strong'}>{accountData.unconfirmedEmail}</NoBreak>{' '}
  //       </p>
  //       <p>
  //         {__('We’ve sent an email to', 'emailNote.weSentAnEmail')}{' '}
  //         <NoBreak>{accountData.unconfirmedEmail}</NoBreak>{' '}
  //         {__(
  //           'with a link to verify your account.',
  //           'emailNote.withLinkToVerify'
  //         )}{' '}
  //       </p>
  //       <Button
  //         onClick={prevent(userManager.resendEmailConfirmation) as any}
  //         presentation="text-only"
  //         label={__('Resend email', 'emailNote.resendEmail')}
  //         css={{ marginLeft: '-$$px' }}
  //       ></Button>
  //     </TableNote>
  //   );
  // }

  return null;
});
