import React from 'react';
import { AppFactory } from '@app/app-factory';
import { Responsive } from '@naan/primitives/responsive';
import { PointsProgressChart } from 'components/ui/points-progress-chart';
import { SoundbiteWidget } from './soundbite-widget';
import { styled } from '@naan/stitches.config';
import { useNavigate } from 'react-router-dom';
import { learnProgressPath } from 'components/nav/path-helpers';
// import { NewToLupaWidget } from './new-to-lupa-widget';
import { createLogger } from 'app/logger';
import { observer } from 'mobx-react';
import { FeaturedHelpletWidget } from './helplets-widget';
import { namedObserver } from 'lib/named-observer';

const log = createLogger('soundbite-widget-group');

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space$6',
  '@medium': {
    flexDirection: 'row',
    gap: '$space$6',
  },
});

const SoundbiteWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  '@medium': {
    width: 'calc(50% - $space$3)',
  },
});

// const WhyLupaWrapper = styled('div', {
//   all: 'unset',
//   display: 'flex',
//   width: 'calc(50% - $space$3)',
// });

const ChartWrapper = styled('div', {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  width: 'calc(50% - $space$3)',
});

/**
 * ChartContainer is a wrapper around the chart that allows us to
 * intercept clicks on the chart and navigate to the progress page.
 * This is necessary because nesting a Link inside a Link is not
 * allowed.
 */
const ChartContainer: React.FC = namedObserver(
  'chartContainer',
  ({ children }) => {
    const navigate = useNavigate();
    const handleClick = (e: any) => {
      // ignore clicks on internal links
      if (e.target.tagName === 'A') {
        return;
      }

      // open in new tab if middle click or ctrl/cmd click
      if (e.button === 1 || e.ctrlKey || e.metaKey) {
        window.open(learnProgressPath(), '_blank');
        return;
      }

      navigate(learnProgressPath());
    };
    return <ChartWrapper onClick={handleClick}>{children}</ChartWrapper>;
  }
);

// dashboard row with soundbite along with either helplets or stats
export const SoundbiteEtAlWidgetGroup = observer(() => {
  const { storyManager, userManager } = AppFactory.root;

  // tickle so that we rerender when date advances
  const { currentDate } = AppFactory.root.storyManager;
  log.debug(`render, date: ${currentDate}`);

  const { featuredVideo } = userManager.userData.videoGuideUserData;

  const soundbite = storyManager.featuredSoundbite;

  if (featuredVideo) {
    return (
      <Container>
        <SoundbiteWrapper>
          <FeaturedHelpletWidget />
        </SoundbiteWrapper>
        <SoundbiteWrapper>
          <SoundbiteWidget soundbite={soundbite} />
        </SoundbiteWrapper>
      </Container>
    );
  } else {
    return (
      <Container>
        <SoundbiteWrapper>
          <SoundbiteWidget soundbite={soundbite} />
        </SoundbiteWrapper>
        <Responsive
          renderMediumAndUp={() => (
            <ChartContainer>
              <PointsProgressChart />
            </ChartContainer>
          )}
        />
      </Container>
    );
  }
});
