import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { IconButton } from '@naan/primitives/button';
import { CloseIcon } from '@naan/icons/close-icon';
import { ArrowLeftExtraSmallIcon } from '@naan/icons/arrow-left-icon';
import { ArrowRightExtraSmallIcon } from '@naan/icons/arrow-right-icon';
import { ArrowUpExtraSmallIcon } from '@naan/icons/arrow-up-icon';
import { ArrowDownExtraSmallIcon } from '@naan/icons/arrow-down-icon';
import { PlusExtraSmallIcon } from '@naan/icons/plus-icon';
import { MinusExtraSmallIcon } from '@naan/icons/minus-icon';
import { VSpacer } from '@naan/primitives/spacer';
import { HelpCircleIcon } from '@naan/icons/help-circle-icon';
import { IconButtonStyled } from '@naan/primitives/button/icon-button-styles';
import { keyboardService } from 'lib/services/keyboard-service';

import __ from '@core/lib/localization';

const Container = styled('div', {
  $$backgroundColor: '$colors$gray-25',
  $$headerBorderColor: '$colors$gray-100',
  $$headerTextColor: '$colors$textPrimary',
  $$sectionTitleColor: '$colors$textSecondary',
  $$descriptionColor: '$colors$textPrimary',
  $$shortcutColor: '$colors$textSecondary',
  $$shortcutBorderColor: '$colors$black-alpha-10',

  background: '$$backgroundColor',

  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  width: 360,
  zIndex: 10,
  display: 'grid',
  transition: 'transform 0.3s ease-in-out',

  transform: 'translateX(-100%)',

  '&.open': {
    transform: 'translateX(0%)',
  },

  '&.listen ': {
    $$backgroundColor: '$colors$gray-800',
    $$headerBorderColor: '$colors$gray-700',
    $$headerTextColor: '$colors$white',
    $$sectionTitleColor: '$colors$gray-300',
    $$descriptionColor: '$colors$white',
    $$shortcutColor: '$colors$gray-300',
    $$shortcutBorderColor: '$colors$gray-700',
  },
});

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: '56px 1fr',
  overflow: 'hidden',
  // flexDirection: 'column',

  '& > .header': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 24px',
    height: 56,
    borderBottom: '1px solid $$headerBorderColor',
    color: '$$headerTextColor',
    '& > .title': {
      textStyle: 'small-text-bold',
    },
    '& > button': {
      marginRight: -12,
    },
  },

  '& > .scroller': {
    overflow: 'auto',
    '& > .section': {
      padding: '0 24px',
      '& > .title': {
        margin: '24px 0 16px',
        textStyle: 'small-caps',
        color: '$$sectionTitleColor',
      },
      '& > .shortcut': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '8px',
        '& > .description': {
          color: '$$descriptionColor',
          textStyle: 'small-text',
          marginRight: 'auto',
        },
        '& > .key': {
          color: '$$shortcutColor',
          textStyle: 'small-text-bold',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '5px 10px',
          borderRadius: 8,
          border: '1px solid $$shortcutBorderColor',
          '&:has(svg)': {
            padding: '7px',
          },
          '& + &': {
            marginLeft: 8,
          },
        },
      },
    },
  },
});

const HELP_OVERLAY_ID = 'help-overlay';

let resetEscapeListener: (() => void) | null = null;

export function openKeyboardOverlay() {
  const overlay = document.getElementById(HELP_OVERLAY_ID);
  if (overlay) {
    overlay.classList.add('open');
    resetEscapeListener = keyboardService.addModalShortcut('escape', () =>
      closeKeyboardOverlay()
    );
  }
}

export function closeKeyboardOverlay() {
  const overlay = document.getElementById(HELP_OVERLAY_ID);
  if (overlay) {
    overlay.classList.remove('open');
    if (resetEscapeListener) {
      resetEscapeListener();
      resetEscapeListener = null;
    }
  }
}

export function toggleKeyboardOverlay() {
  const overlay = document.getElementById(HELP_OVERLAY_ID);
  if (overlay?.classList.contains('open')) {
    closeKeyboardOverlay();
  } else {
    openKeyboardOverlay();
  }
}

// (window as any).openKeyboardOVerlay = openKeyboardOverlay;
// (window as any).closeKeyboardOverlay = closeKeyboardOverlay;

const ShortcutItem = ({
  title,
  shortcut,
}: {
  title: string;
  shortcut: string | React.ReactNode[];
}) => {
  return (
    <div className="shortcut">
      <span className="description">{title}</span>
      {Array.isArray(shortcut) ? (
        shortcut.map(s => <kbd className="key">{s}</kbd>)
      ) : (
        <kbd className="key">{shortcut}</kbd>
      )}
    </div>
  );
};

const ShortcutSection: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <div className="section">
      <h5 className="title">{title}</h5>
      {children}
    </div>
  );
};

export const KeyboardHelpButton = () => {
  return (
    <IconButtonStyled
      presentation={'grayTransparent'}
      as="div"
      onClick={openKeyboardOverlay}
    >
      <span className="button-inner">
        <HelpCircleIcon />
      </span>
    </IconButtonStyled>
  );
};

const StudyShortcuts = () => {
  return (
    <>
      <ShortcutSection title={__('Mode', 'common.mode')}>
        <ShortcutItem
          title={__('Switch to Listen mode', 'common.switchToListenMode')}
          shortcut="Shift + Tab"
        />
      </ShortcutSection>

      <ShortcutSection title={__('Playback', 'common.playback')}>
        <ShortcutItem
          title={__(
            'Play > delayed pause > pause',
            'common.playDelayedPausePause'
          )}
          shortcut="Space"
        />
        <ShortcutItem
          title={__('Cancel delayed pause', 'common.cancelDelayedPause')}
          shortcut="Esc"
        />

        <ShortcutItem
          title={__('Rewind/fast-forward', 'common.rewindSlashFastForward')}
          shortcut={[<ArrowLeftExtraSmallIcon />, <ArrowRightExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__(
            'Previous/next sentence',
            'common.previousSlashNextSentence'
          )}
          shortcut={[<ArrowUpExtraSmallIcon />, <ArrowDownExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__('Adjust speed', 'common.adjustSpeed')}
          shortcut={[<PlusExtraSmallIcon />, <MinusExtraSmallIcon />]}
        />
      </ShortcutSection>

      <ShortcutSection title={__('View', 'common.view')}>
        <ShortcutItem
          title={__('Reveal current sentence', 'common.revealCurrentSentence')}
          shortcut="Delete"
        />
        <ShortcutItem
          title={__('Show/hide translation', 'common.showSlashHideTranslation')}
          shortcut="Tab"
        />
      </ShortcutSection>

      <ShortcutSection title={__('When paused', 'common.whenPaused')}>
        <ShortcutItem title={__('Replay', 'common.replay')} shortcut="Enter" />
        <ShortcutItem
          title={__('Slow replay', 'common.slowReplay')}
          shortcut="Shift + Enter"
        />
        <ShortcutItem
          title={__('Show/hide vocabulary', 'common.showSlashHideVocabulary')}
          shortcut="V"
        />
      </ShortcutSection>

      <ShortcutSection title={__('Help', 'common.help')}>
        <ShortcutItem
          title={__('Hide/show this panel', 'common.hideSlashShowThisPanel')}
          shortcut="?"
        />
      </ShortcutSection>
    </>
  );
};

const ListenShortcuts = () => {
  return (
    <>
      <ShortcutSection title={__('Mode', 'common.mode')}>
        <ShortcutItem
          title={__('Switch to Study mode', 'common.switchToStudyMode')}
          shortcut="Shift + Tab"
        />
      </ShortcutSection>

      <ShortcutSection title={__('Playback', 'common.playback')}>
        <ShortcutItem
          title={__('Play/pause', 'common.playSlashPause')}
          shortcut="Space"
        />
        <ShortcutItem
          title={__('Rewind/fast-forward', 'common.rewindSlashFastForward')}
          shortcut={[<ArrowLeftExtraSmallIcon />, <ArrowRightExtraSmallIcon />]}
        />

        <ShortcutItem
          title={__(
            'Previous/next sentence',
            'common.previousSlashNextSentence'
          )}
          shortcut={[<ArrowUpExtraSmallIcon />, <ArrowDownExtraSmallIcon />]}
        />
      </ShortcutSection>

      <ShortcutSection title={__('Help', 'common.help')}>
        <ShortcutItem
          title={__('Hide/show this panel', 'common.hideSlashShowThisPanel')}
          shortcut="?"
        />
      </ShortcutSection>
    </>
  );
};

export const KeyboardHelpOverlay = ({ mode }: { mode: 'study' | 'listen' }) => {
  return (
    <Container id={HELP_OVERLAY_ID} className={mode}>
      <Wrapper>
        <header className="header">
          <h4 className="title">
            {__('Keyboard Shortcuts', 'common.keyboardShortcuts')}
          </h4>
          <IconButton icon={<CloseIcon />} onClick={closeKeyboardOverlay} />
        </header>
        <div className="scroller">
          {mode === 'study' ? <StudyShortcuts /> : <ListenShortcuts />}
          <VSpacer size={6} />
        </div>
      </Wrapper>
    </Container>
  );
};
