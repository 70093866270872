import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { END_OF_STORY_CHAPTER } from '@core/lib/constants/vars';

import { createLogger } from 'app/logger';
const log = createLogger('location-pointer');

const pad = (n: number, length: number = 3) => String(n).padStart(length, '0');

export type ChapterRef = { unit: number; chapter: number };

/**
 * LocationPointer
 *
 * study position within an story.
 * two instances for each story: currentPoint, furthestPoint
 */
export class LocationPointer extends ModelTreeNode {
  static CLASS_NAME = 'LocationPointer' as const;

  static create(snapshot: any) {
    return super.create(LocationPointer, snapshot) as LocationPointer;
  }

  unit: number = 1;
  chapter: number = 1;
  // 1: first listen, 2: relisten, >=3: done
  iteration: number = 1;
  millisPlayed: number = 0; //reflects address, not actual play counter. - Perhaps rename to just "millis"?

  get isEmpty(): boolean {
    return !this.played;
  }

  get played(): boolean {
    return (
      this.unit > 1 ||
      this.chapter > 1 ||
      this.iteration > 1 ||
      this.millisPlayed > 0
    );
  }

  get sortableString(): string {
    return `${pad(this.unit)}-${pad(this.chapter)}-${pad(this.iteration)}:${pad(
      this.millisPlayed,
      7
    )}`;
  }

  get relistenIteration(): boolean {
    return this.iteration >= 2;
  }

  get completedIteration(): boolean {
    return this.iteration >= 3;
  }

  setChapterRef(chapterRef: ChapterRef) {
    // todo: more concise way to perform this?
    this.unit = chapterRef.unit;
    this.chapter = chapterRef.chapter;
  }

  // arbitrarily reset the iteration. used to reconcile state after deep linking into non-current chapter
  setIteration(interation: number) {
    this.iteration = interation;
    this.millisPlayed = 0;
  }

  setMillisPlayed(millis: number) {
    this.millisPlayed = millis;
  }

  incrementIteration() {
    this.iteration = this.iteration + 1;
    this.millisPlayed = 0;
  }

  isLessThan(other: LocationPointer): boolean {
    return this.sortableString < other.sortableString;
  }

  matchesChapter(chapterRef: ChapterRef): boolean {
    return this.unit === chapterRef.unit && this.chapter === chapterRef.chapter;
  }

  beforeChapter(chapterRef: ChapterRef): boolean {
    return (
      this.unit < chapterRef.unit ||
      (this.unit === chapterRef.unit && this.chapter < chapterRef.chapter)
    );
  }

  // clean up edge case around when just one part is complete
  // mutates given pointer as needed
  normalizePointer({
    unitCount,
    slug,
  }: {
    unitCount: number;
    slug: string;
  }): boolean {
    if (this.unit < unitCount && this.chapter === END_OF_STORY_CHAPTER) {
      // log.info(`normalizePointer(${this.slug}) - bumping to next unit`);
      this.unit++;
      this.chapter = 1;
      log.warn(`${slug} - normalized end of unit pointer`);
      return true;
    }
    return false;
  }
}
