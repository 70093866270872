import * as React from 'react';
import * as RichMessage from 'components/ui/rich-message';

import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { PlainMarkdown } from '@naan/primitives/text';
import { createAccountPath } from 'components/nav/path-helpers';

import __ from '@core/lib/localization';

const TextContent = styled('div', {
  '& a': {
    color: 'inherit',
  },
});

const BetaMessage = observer(() => {
  return (
    <RichMessage.Container>
      <RichMessage.Body>
        <RichMessage.Title>
          {__('Welcome to the Lupa Web Beta', 'beta.welcomeToTheLupaWebBeta')}
        </RichMessage.Title>
        <TextContent>
          <PlainMarkdown
            source={__(
              'We are excited to share with you the next version of Lupa. [Create your free account](%{url}) to unlock the full Lupa catalogue.',
              'beta.weAreExcitedToShareWithYouTheNextVersionOfLupa',
              { url: createAccountPath() }
            )}
          />
        </TextContent>
      </RichMessage.Body>
    </RichMessage.Container>
  );
});

const NonBetaMessage = observer(() => {
  return (
    <RichMessage.Container>
      <RichMessage.Body>
        <TextContent>
          {__(
            'Here are a couple of our favorite stories for you to try out. Dive in, and learn to listen like a native.',
            'dashboard.trialStoriesBanner'
          )}
        </TextContent>
      </RichMessage.Body>
    </RichMessage.Container>
  );
});

export const TrialWelcomeMessage = observer(() => {
  const { userManager } = AppFactory.root;
  const { betaMode } = userManager.accountData;

  if (!userManager.showTrialMessage) {
    return null;
  }

  if (betaMode) {
    return <BetaMessage />;
  } else {
    return <NonBetaMessage />;
  }
});
