import React from 'react';
import { observer } from 'mobx-react';
import { keyframes, styled } from '@naan/stitches.config';
import { NotationsList } from './notations-list';
// import { NotationControls } from './notation-controls';
import { reaction } from 'mobx';
import { StudyModel } from 'study/models/study-model';
import {
  adjustTopPosForNotationPanel,
  scrollToCurrentLine,
  // lineScrollObserver,
} from '../fx/scrolling';

import { MinimizeButton } from './minimize-button';
import { keyboardService } from 'lib/services/keyboard-service';

const slideIn = keyframes({
  '0%': { transform: 'translateY(100%)' },
  '100%': { transform: 'translateY(0)' },
});

const Wrapper = styled('div', {
  // '--top-notation-pos': '0',
  $$panelMinHeight: '276px',
  padding: '0',
  margin: '0',
  border: 'none',
  boxSizing: 'border-box',
  position: 'sticky',
  zIndex: '1',
  top: 'var(--top-notation-pos)',

  '& > .inner': {
    transform: 'translateY(100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    background: '#fff',
    position: 'absolute',
    top: '8px',
    left: '-9999px',
    right: '-9999px',
    padding: '0 9999px',
    boxShadow: '0px 0px 8px rgb(0 0 0 / 10%)',
    transition: 'all .3s',
    // height: '100%',
    minHeight: '$$panelMinHeight',

    '& > .minimize-button': {
      marginTop: 'auto',
      position: 'sticky',
      bottom: '0',
      padding: '16px 0 16px 8px',
      '@widerThanScript': {
        paddingLeft: 0,
      },
    },

    // TODO: Commenting this out until we implement the more compact playerShort notation style
    // '@playerShort': {
    //   $$panelMinHeight: '228px',
    // },
  },

  '& > .inner.visible': {
    transform: 'translateY(0%)',
    animation: `${slideIn} 300ms linear`,
    '&.minimized': {
      animation: 'none',
      transform: 'translateY(100%)',
    },
  },
});

export const NotationsListPanel = observer(
  ({ model }: { model: StudyModel }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    React.useLayoutEffect(() => {
      /// we measure and set thje notation panel top position as soon as it is mounted
      adjustTopPosForNotationPanel();
      scrollToCurrentLine();
    }, []);

    /// this toggles the panel whenever the selected notation changes
    React.useEffect(() => {
      return reaction(
        () => model.selectedNotationElement,
        () => {
          // console.log('',model.selectedNotationElement);
          if (model.selectedNotationElement) {
            ref.current?.classList.remove('visible');
          } else {
            ref.current?.classList.add('visible');
          }
        },
        { fireImmediately: true }
      );
    }, [model]);

    /// this toggles the panel whenever the selected notation changes
    React.useEffect(() => {
      return reaction(
        () => model.notationPanelExpanded,
        expanded => {
          // console.log('',model.selectedNotationElement);
          if (expanded) {
            ref.current?.classList.remove('minimized');
            const resetEscapeListener = keyboardService.addModalShortcut(
              'escape',
              () => {
                model.collapseNotationPanel();
                resetEscapeListener?.();
              }
            );
          } else {
            ref.current?.classList.add('minimized');
          }
        },
        { fireImmediately: true }
      );
    }, [model]);

    /// this toggles the panel whenever the sentence goes off-screen or comes back on-screen
    // React.useEffect(() => {
    //   return reaction(
    //     () => lineScrollObserver.isCurrentSentenceVisible,
    //     () => {
    //       // console.log('',model.selectedNotationElement);
    //       if (lineScrollObserver.isCurrentSentenceVisible) {
    //         ref.current?.classList.remove('hidden');
    //       } else {
    //         ref.current?.classList.add('hidden');
    //       }
    //     },
    //     { fireImmediately: true }
    //   );
    // }, [model]);

    return (
      <Wrapper>
        <div className="inner" id="NOTATION_LIST_PANEL" ref={ref}>
          <NotationsList
            notations={model.augmentedNotations}
            progress={model.chapter.story.progress}
          />

          <div className="minimize-button">
            <MinimizeButton onClick={() => model.collapseNotationPanel()} />
            {/* <NotationControls
              replay={() => model.replayCurrentSentence()}
              snailReplay={() => model.snailReplayCurrentSentence()}
            /> */}
          </div>
        </div>
      </Wrapper>
    );
  }
);
