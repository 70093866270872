import React from 'react';
import { observer } from 'mobx-react';
import { StoryCard } from 'components/stories/story-card';
import { Story } from '@core/models/story-manager/story';
import { AppFactory } from '@app/app-factory';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';
import { Divider } from '@naan/primitives/divider';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import __ from '@core/lib/localization';

export const LastQueuedWidget = observer(({ story }: { story: Story }) => {
  if (!story) return null;

  const { storyManager } = AppFactory.root;
  const queuedCount = storyManager.queued.length;
  const queuedPath = storiesPrimaryFiltered(PrimaryFilterKeys.QUEUED);

  return (
    <>
      <SectionHeading
        title={__('Next up', 'dashboard.nextUp')}
        renderLinks={() => (
          <>
            {queuedCount > 0 ? (
              <SectionHeadingLink to={queuedPath}>
                Study later queue
              </SectionHeadingLink>
            ) : null}
          </>
        )}
      />
      <Divider />
      <StoryCard story={story} truncateDescription={true} />
    </>
  );
});
