/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { LicenseAccessWarningTip } from './license-access-warning-tip';
import { Classroom } from 'core/models/user-manager';
import { styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flex: 'row',
  alignItems: 'center',
  textStyle: 'small-text',
  color: '$colors$textSecondary',

  '&.no-students': {
    marginLeft: '8px',
  },

  '& .vr': {
    height: '16px',
    width: '1px',
    background: '$colors$gray-100',
    margin: '0 8px',
  },
});

export const NoLicense = ({ classroom }: { classroom: Classroom }) => {
  if (classroom.needsAccessWarning) {
    return (
      <LicenseAccessWarningTip>
        <Wrapper>
          {__('No license', 'classroom.noLicense')}
          <span className="vr" />
          {__(
            'Students without full access: %{trialStudentCount}',
            'classroom.studentsWithoutFullAccess',
            { trialStudentCount: classroom.trialStudentCount }
          )}
        </Wrapper>
      </LicenseAccessWarningTip>
    );
  } else {
    return (
      <Wrapper className="no-students">
        {__('No license', 'classroom.noLicense')}
      </Wrapper>
    );
  }
};
