import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';

import { useKeyboardBinding } from 'common/hooks/use-keyboard-binding';
import { instructions } from 'lib/strings/en/classroom-student-instructions';
import { useCopyPowers } from 'common/hooks/use-copy-powers';
import { appConfig } from 'app/env';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { PlainMarkdown, Text } from 'naan/primitives/text';
import { Divider } from 'naan/primitives/divider';
import { interpolateString } from './interpolate-string';
import __ from 'core/lib/localization';
import { Button } from '@naan/primitives/button';

const HiddenTextArea = styled('textarea', {
  position: 'absolute',
  left: '0',
  top: '0',
  border: 'none',
  padding: 'none',
  opacity: '0',
});

const Invitation = styled('div', {
  backgroundColor: '$gray-50',
  padding: 16,

  '& h1': {
    textStyle: 'body',
    fontWeight: 600,
    marginBottom: 16,
  },

  '& p': {
    textStyle: 'body',
    marginBottom: 16,
  },

  '& a': {
    textStyle: 'body',
    color: '$colors$teal-500',
    textDecoration: 'underline',
  },
  '& li': {
    textIndent: '0',
    marginLeft: '1em',
    marginBottom: '16px',
  },
});

export const ClassroomShareModal = observer(
  ({ classroom, onDismiss }: { classroom: any; onDismiss: any }) => {
    const { ref, active: copying, copyAction } = useCopyPowers();

    useKeyboardBinding('Escape', onDismiss);

    const invitation = interpolateString(instructions, {
      invitationUrl: `${appConfig.website.baseUrl}/students/join/${classroom.code}`,
      lupaUrl: appConfig.website.urls.downloadApp,
      className: classroom.label,
      code: classroom.code,
    });

    return (
      <Dialog.Container
        open={true}
        onDismiss={onDismiss}
        css={{ $$maxWidth: 'min(100%, 960px)' }}
      >
        <Dialog.CloseButton />
        <Dialog.Heading>
          <VStack>
            <Text textStyle={'medium-heading'}>
              {__(
                'Instructions for students',
                'classroom.instructionsForStudents'
              )}
            </Text>
            <VSpacer size={4} />
          </VStack>
        </Dialog.Heading>
        <Divider />
        <Dialog.Scroller>
          <Dialog.Body>
            <Text textStyle="small-text" color="textSecondary">
              {__(
                'You can paste the text below and share by email, Canvas etc.',
                'classrooms.invitationDialogInstructions'
              )}
            </Text>
            <VSpacer size={6} />
            <Invitation>
              <PlainMarkdown source={invitation}></PlainMarkdown>
            </Invitation>
            <HiddenTextArea
              ref={ref as any}
              value={invitation}
              className="hidden"
              readOnly
            />
          </Dialog.Body>
        </Dialog.Scroller>
        <VStack align={'center'} justify={'center'} css={{ padding: 16 }}>
          <div>
            <Button
              label={
                copying
                  ? __('Copied to clipboard', 'common.copiedToClipboard')
                  : __('Copy to clipboard', 'common.copyToClipboard')
              }
              presentation={'teal'}
              onClick={copyAction}
              size="small"
            />
          </div>
        </VStack>
      </Dialog.Container>
    );
  }
);
