import React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { CurrentDateSpinner } from 'components/admin/current-date-dev-ui';
import { HStack, VStack } from '@naan/primitives/stack';
import { Button } from '@naan/primitives/button';
import { Menu, MenuItem } from '@naan/primitives/menus';
import { Box } from '@naan/primitives/box';
import { Divider } from '@naan/primitives/divider';
import { RetryIcon } from '@naan/icons/retry-icon';
import { GearIcon } from '@naan/icons/gear-icon';
import { Text } from '@naan/primitives/text';
import { useNavigate } from 'react-router-dom';
//@ts-expect-error
import { version } from '@jw-spa-version';
import { CheckmarkCircleSmallIcon } from '@naan/icons/checkmark-circle-icon';
import { CloseSmallIcon } from '@naan/icons/close-icon';
import { HSpacer } from '@naan/primitives/spacer';

export const PiMenu = observer(() => {
  const navigate = useNavigate();

  const { root } = AppFactory;
  const { hasAdminAccess } = root.userManager;
  if (!hasAdminAccess) return null;

  const { firestoreInvoker } = AppFactory;

  const listening = firestoreInvoker.isListening;

  return (
    <Box
      css={{
        position: 'fixed',
        right: 12,
        top: 12,
        opacity: 0.15,
        '&:hover': { opacity: 1 },
        '@medium': {
          right: 12,
          bottom: 12,
          top: 'auto',
        },
      }}
    >
      <Menu trigger={<Button label="π" />}>
        <MenuItem
          action={() => window.location.reload()}
          label="Reload"
          leftIcon={<RetryIcon />}
        />
        <MenuItem
          action={() => {
            // window.open('/app/profile/dev-tools');
            navigate('/app/profile/dev-tools');
          }}
          label="Dev tools"
          leftIcon={<GearIcon />}
          rightIcon={<Text color="black-alpha-30">...</Text>}
        />
        <VStack>
          <Box css={{ padding: 12 }}>
            <CurrentDateSpinner />
          </Box>
          <Divider />
          <HStack css={{ padding: 12 }} align="center">
            {listening ? (
              <CheckmarkCircleSmallIcon color="var(--colors-green-500)" />
            ) : (
              <CloseSmallIcon color="var(--colors-red-500)" />
            )}
            <HSpacer size={1} />
            Firestore {listening ? '' : 'not'} listening
          </HStack>
          <Divider />
          <Box css={{ padding: 12 }}>
            {root.offline ? 'Offline' : 'Online'}
            <br />
            Version: {version}
          </Box>
        </VStack>
      </Menu>
    </Box>
  );
});
