import { GoogleAnalytics } from 'common/analytics/adapters/ga-analytics';
import { SegmentAnalytics } from 'common/analytics/adapters/segment-analytics';
import { SimulatedAnalytics } from 'common/analytics/adapters/sim-analytics';
import { AnalyticsManager } from 'common/analytics/analytics-manager';
import { DialogPresenter } from 'common/dialog-presenter';
import { MessageService } from 'common/notifications/message-service';
import { ToastService } from 'common/notifications/toast-service';
import { AppRoot } from '@core/models/app-root';
import { PlayerModel } from 'player/models/player-model';
import { PlayerModelHandle } from 'player/models/player-model-handle';
import { SoundbiteModel } from '../soundbite/models/soundbite-model';
import { StudyModel } from '../study/models/study-model';
import { WakeLock } from '@core/lib/wake-lock';
import { FirestoreInvoker } from '@core/services/firestore-invoker';
import { AssetCacher } from 'lib/asset-cacher';
import { AppStateCacher } from 'lib/app-state-cacher';
import { GlobalDataSync } from '@core/services/global-data-sync';
import { Workbox } from 'workbox-window';

export class AppFactory {
  private static _root: AppRoot;

  // public static createRoot(): AppRoot {
  //   return AppRoot.create({});
  // }

  public static get root(): AppRoot {
    // if (!AppFactory._root) {
    //   AppFactory._root = AppFactory.createRoot();
    // }
    return AppFactory._root;
  }
  public static setRoot(root: AppRoot) {
    this._root = root;
  }

  private static _playerModelHandle: PlayerModelHandle;

  public static get playerModelHandle(): PlayerModelHandle {
    if (!AppFactory._playerModelHandle) {
      AppFactory._playerModelHandle = new PlayerModelHandle();
    }
    return AppFactory._playerModelHandle;
  }

  public static newStudyModel(): StudyModel {
    return AppFactory.playerModelHandle.newStudyModel();
  }

  public static newSoundbiteModel(): SoundbiteModel {
    return AppFactory.playerModelHandle.newSoundbiteModel();
  }

  public static get studyModel(): StudyModel {
    return AppFactory.playerModelHandle.studyModel;
  }

  public static get soundbiteModel(): SoundbiteModel {
    return AppFactory.playerModelHandle.soundbiteModel;
  }

  public static get playerModel(): PlayerModel {
    return AppFactory.playerModelHandle.model;
  }

  // probably rename to UserDataSync
  public static firestoreInvoker: FirestoreInvoker;

  public static setFirestoreInvoker(invoker: FirestoreInvoker) {
    this.firestoreInvoker = invoker;
  }

  // WIP -  not yet used
  public static globalDataSync: GlobalDataSync;

  public static setGlobalDataSync(sync: GlobalDataSync) {
    this.globalDataSync = sync;
  }

  public static workbox: Workbox;

  public static setWorkbox(workbox: Workbox) {
    this.workbox = workbox;
  }

  public static assetCacher: AssetCacher;

  // needs to be assigned during AppRoot init startup because the creation is async
  public static setAssetCacher(cacher: AssetCacher) {
    this.assetCacher = cacher;
  }

  public static appStateCacher: AppStateCacher;

  // needs to be assigned during AppRoot init startup because the creation is async
  public static setAppStateCacher(cacher: AppStateCacher) {
    this.appStateCacher = cacher;
  }

  private static _wakeLock: WakeLock;

  public static get wakeLock(): WakeLock {
    if (!this._wakeLock) {
      this._wakeLock = new WakeLock();
    }
    return this._wakeLock;
  }

  private static _analyticsManager: AnalyticsManager;

  public static createAnalyticsManager(): AnalyticsManager {
    const result = new AnalyticsManager();
    result.addAdapter(new SimulatedAnalytics());
    result.addAdapter(new GoogleAnalytics());
    result.addAdapter(new SegmentAnalytics());
    return result;
  }

  public static get analyticsManager(): AnalyticsManager {
    if (!AppFactory._analyticsManager) {
      AppFactory._analyticsManager = AppFactory.createAnalyticsManager();
    }
    return AppFactory._analyticsManager;
  }

  public static messageService = MessageService;
  public static dialogPresenter = DialogPresenter;
  public static toastService = ToastService;
}

(window as any)._app_ = AppFactory;
(window as any).appFactory = AppFactory; // more intuitive and copy/paste friendly
