import React from 'react';

import { AppFactory } from 'app/app-factory';
import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
import { FilteringProvider } from 'common/filtering/filtering-provider';
// import { TeacherResources } from 'components/teacher-resources';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
// import { OldHeaderContainer } from 'components/header';
import {
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
import { NoFavorites } from 'components/classrooms/favorites/no-favorites';
import { UnitsList } from 'components/ui/units-list';
import { Divider } from 'naan/primitives/divider';
import { Text } from 'naan/primitives/text';
import __ from 'core/lib/localization';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';

const LayoutWrapper = styled('section', {
  gridColumn: '1/-1',
});

export const FavoritesScreen = observer(() => {
  useTitle(__('Favorites', 'favorites.favorites'));
  const location = useLocation();

  const {
    storyManager: { favoritedUnits: favorites },
  } = AppFactory.root;

  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <FilteringProvider>
        <CenterColumnLayout>
          <ContentGridInnerContainer>
            <PageHeading
              title={__('Favorites', 'favorites.favorites')}
              renderAccessory={() => (
                <Text textStyle="body-bold" color="textSecondary">
                  {__(
                    { one: '1 story', other: '{{count}} stories' } as any,
                    'discover.nUnits',
                    { count: favorites.length }
                  )}
                </Text>
              )}
            />
            <Divider />
            <ContentGrid>
              <LayoutWrapper>
                {favorites.length > 0 ? (
                  <UnitsList units={favorites} />
                ) : (
                  <NoFavorites />
                )}
              </LayoutWrapper>
            </ContentGrid>
          </ContentGridInnerContainer>
        </CenterColumnLayout>
      </FilteringProvider>
      {/* <TeacherResources /> */}
    </>
  );
});
