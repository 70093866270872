// import { nanoid } from 'nanoid';
import { createLogger } from 'app/logger';
import { appConfig } from 'app/env';
import { getProductVersion } from 'app/platform';
import { AnalyticsAdapter } from './analytics-adapter';

const log = createLogger('analytics');

export const EMPTY_USER_ID = '_empty_'; // default initializer for UserManager. paranoid avoidance of falsy value

export const anonymousUserId = '_anonymous_';
// const sessionId = nanoid(); // beware, uuid was barfing with a 'global is not defined' error
const { sessionId } = appConfig;

export class AnalyticsManager {
  private adapters: AnalyticsAdapter[] = [];
  private contextData: Record<string, any> = {};

  constructor() {
    // bind all methods to this
    this.gatherProperties = this.gatherProperties.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.trackEventInAdapters = this.trackEventInAdapters.bind(this);
    this.trackPageInAdapters = this.trackPageInAdapters.bind(this);
    this.identifyInAdapters = this.identifyInAdapters.bind(this);
    this.setContextData = this.setContextData.bind(this);
  }

  get userId(): string | null {
    return this.contextData.accountData?.userId ?? null;
  }

  get hasUserId(): boolean {
    return (
      !!this.contextData.accountData?.userId &&
      this.contextData.accountData?.userId !== EMPTY_USER_ID
    );
  }

  public addAdapter(service: AnalyticsAdapter) {
    this.adapters.push(service);
  }

  public trackEvent(eventName: string, eventProperties: any = {}) {
    const properties = this.gatherProperties(eventProperties);
    this.trackEventInAdapters(eventName, properties);
  }

  public trackPage = (pageName: string, pageData?: any) => {
    const properties = this.gatherProperties(pageData);
    this.trackPageInAdapters(pageName, properties);
  };

  public startTimeEvent(eventName: string, eventProperties?: any) {
    const start = new Date().getTime();
    return (extraProperties = {}) => {
      const end = new Date().getTime();
      const duration = end - start;
      const props = {
        ...eventProperties,
        ...extraProperties,
        $duration: duration,
      };
      this.trackEvent(eventName, props);
    };
  }

  public autoTrackBefore<T>(
    fn: (...args: T[]) => void,
    eventName: string,
    ...eventArgs: any[]
  ) {
    return (...args: T[]) => {
      this.trackEvent(eventName, ...eventArgs);
      fn(...args);
    };
  }

  public autoTrackAfter<T>(
    fn: (...args: T[]) => void,
    eventName: string,
    ...eventArgs: any[]
  ) {
    return (...args: T[]) => {
      fn(...args);
      this.trackEvent(eventName, ...eventArgs);
    };
  }

  // TODO: check exactly what context data we require
  public setContextData(data: any) {
    this.contextData = data;

    log.trace(
      'setContextData',
      JSON.stringify({
        userId: data.accountData?.userId,
        email: data.accountData?.email,
        installationId: data.installationId,
        apiEnv: data.globalConfig?.apiEnv,
        // data,
      })
    );

    if (this.hasUserId) {
      this.identifyInAdapters(this.userId);
    } else if (appConfig.analytics.coalesceAnonymous) {
      this.identifyInAdapters(anonymousUserId); // flattens all anonymous traffic into a single id to reduce costs
    } else {
      // allow default behavior of uniquely tracked anonymous users
      log.debug('allowing anonymous reporting context');
      // todo: understand GA anonymous metrics
    }
  }

  private identifyInAdapters(userId: string) {
    log.debug('identifyInAdapters', userId);
    this.adapters.forEach(service => {
      if (!service.isEnabled) {
        return;
      }
      service.identify(userId);
    });
  }

  private trackPageInAdapters(page: string, pageData?: any) {
    this.adapters.forEach(service => {
      if (!service.isEnabled) {
        return;
      }
      service.page(page, pageData);
    });
  }

  private trackEventInAdapters(eventName: string, data?: any) {
    this.adapters.forEach(service => {
      if (!service.isEnabled) {
        return;
      }
      service.track(eventName, data);
    });
  }

  public gatherProperties(eventProperties: any = {}) {
    const date = Date.now();
    // const releaseChannel = 'dev_build'; // this is not
    const properties = {
      email: this.contextData?.accountData?.email, // redundant data, but makes live event feed easier to browse
      server_env: this.contextData?.globalConfig?.apiEnv,
      config_env: appConfig.VITE_CONFIG_ENV,
      appSlug: appConfig.appSlug,
      installation_id: this.contextData?.installationId,
      user_agent: window.navigator.userAgent,
      session_id: sessionId,
      $ae_session: sessionId,
      event_timestamp: date,
      // Note, the proper Mixpanel key here should be $app_version_string, but this value seems to get
      // stomped by Segment even when we provide here, so using a more distinctly named key for our purposes.
      jw_app_version: getProductVersion(),
      product_flavor: appConfig.productFlavor, // @armando, do you recall the intention of this?
      ...eventProperties,
    };
    if (!this.hasUserId) {
      properties['anonymous_track'] = true;
    }

    // this hack didn't work probably because we start reporting before segment is fully initialized
    // // piggyback the segment anonymous id, so we can uniquely report within google analytics
    // const segmentAnalytics = (window as any).analytics;
    // if (segmentAnalytics && isFunction(segmentAnalytics.user)) {
    //   const segmentUser = segmentAnalytics.user();
    //   if (segmentUser && isFunction(segmentUser.anonymousId)) {
    //     const segmentAnonymousId = segmentUser.anonymousId();
    //     properties['ajs_anonymous_id'] = segmentAnonymousId;
    //     log.debug('segmentAnonymousId: ', segmentAnonymousId);
    //   }
    // } else {
    //   log.debug('analytics.user undefined');
    // }

    return properties;
  }
}
