import deepmerge from 'deepmerge';
import { config as baseConfig } from './base';
import { logLevels } from '@common/log/logger-types';

// my.preprod.jiveworld.app
//
// nps deploy.my.preprod
//

export const config = deepmerge(baseConfig, {
  forcedApiEnv: 'preprod',
  autoImportForNewAccount: true,

  i18n: {
    forcedLocale: 'en',
  },

  analytics: {
    segmentWriteKey: 'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // lupa-spa (testing)
    // property: jiveworld preprod, stream name: my.preprod.jiveworld.com stream
    googleAnalyticsKey: 'G-0ELVMT9KDM',
  },

  logging: {
    enableConsoleLog: true,
    // minimumLogLevel: logLevels.info,
    minimumLogLevel: logLevels.debug,
    enableLoggly: true,
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786',
    enabled: true,
  },

  website: {
    urls: {
      marketingSite: 'https://lupa.preprod.jiveworld.com',
    },
  },

  player: {
    debug: false, // turns off the '~' debug mode by default
  },

  soundbites: {
    listEndpoint: 'https://masala-v2-server.editorial.jiveworld.com/excerpts',
    // other props inherited from env.js
  },
});
