import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import __ from '@core/lib/localization';

export const ResetStoryDialog = observer(
  ({ onReset, onDismiss }: { onReset: () => void; onDismiss: () => void }) => {
    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>{__('Reset story', 'story.resetStory')}</Dialog.Heading>
        <Dialog.Body>
          {__(
            "This will mark all chapters as unlistened and will remove any words in your vocab list for this story, but won't affect your progress statistics.",
            'story.resetStoryDialogBody'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'common.cancel')}
            presentation="grayTransparent"
          />
          <Dialog.Button
            onClick={onReset}
            label={__('Reset', 'common.reset')}
            presentation="teal"
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
