import * as React from 'react';
import { useRadio } from 'common/hooks/use-radio';
import { CountriesFilterPanel } from './countries-filter-panel';
import { TopicsFilterPanel } from './topics-filter-panel';
import { FilterAndSearchSection } from './filter-and-search-section';
import { styled } from 'naan/stitches.config';
import { SortingSection } from './sorting-section';
import { ThemesFilterPanel } from './themes-filter-panel';
import { useFiltering } from 'common/filtering/use-filtering';
import { Responsive } from 'naan/primitives/responsive';

const filterTabIds = ['country', 'topic', 'theme'] as const;
export type TFilterTabsIds = typeof filterTabIds[number];

const FiltersUiContainer = styled('div', {
  borderBottom: '1px solid',
  borderColor: '$gray-100',
  marginTop: -16,

  '@medium': {
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'row',
    position: 'sticky',
    top: -1,
    background: '$white',
    zIndex: '999',
    maxWidth: '960px',
    margin: '-24px auto 0',
  },

  variants: {
    sticky: {
      true: {
        position: 'sticky',
        top: -1,
        zIndex: 2,
      },
    },
  },
});

export const FiltersUi = () => {
  const { filter } = useFiltering();

  const filterTabs = useRadio<TFilterTabsIds>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (filter) {
        if (filter.queryType === 'countries') {
          filterTabs.setCurrentTab('country');
        } else if (filter.queryType === 'topics') {
          filterTabs.setCurrentTab('topic');
        } else if (filter.queryType === 'ap' || filter.queryType === 'ib') {
          filterTabs.setCurrentTab('theme');
        }
      }
    }, 300);
  }, [filter, filterTabs]);

  return (
    <>
      <Responsive
        renderDefault={() => (
          <>
            <FiltersUiContainer sticky>
              <FilterAndSearchSection radio={filterTabs} />
            </FiltersUiContainer>
            <SortingSection />
          </>
        )}
        renderMediumAndUp={() => {
          return (
            <FiltersUiContainer>
              <FilterAndSearchSection radio={filterTabs} />
              <SortingSection />
            </FiltersUiContainer>
          );
        }}
      />
      <CountriesFilterPanel show={filterTabs.isCurrent('country')} />
      <TopicsFilterPanel show={filterTabs.isCurrent('topic')} />
      <ThemesFilterPanel show={filterTabs.isCurrent('theme')} />
    </>
  );
};
