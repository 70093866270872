import * as React from 'react';
import { observer } from 'mobx-react';
import { VSpacer } from '@naan/primitives/spacer';
import { AppFactory } from '@app/app-factory';
import { PurchaseFlowDisabled } from 'components/account/account-cta/purchase-flow-disabled';
import { AnonymousFlow } from 'components/account/subscription-flow/anonymous-flow';
import { AuthenticatedFlow } from 'components/account/subscription-flow/authenticated-flow';
import { SubscriptionLayout } from 'routes/layouts/subscription-layout';
import { Footer } from 'components/account/subscription-flow/footer';
import __ from 'core/lib/localization';
import { profileHomePath } from 'components/nav/path-helpers';
import { Navigate } from 'react-router-dom';

export const SubscribeScreen = observer(() => {
  const { userManager } = AppFactory.root;
  const { authenticated, purchaseFlowDisabled, accountData } = userManager;
  const { fullAccess } = accountData;

  if (fullAccess) {
    return <Navigate to={profileHomePath()} replace />;
  }

  if (purchaseFlowDisabled) {
    return <PurchaseFlowDisabled />;
  }

  return (
    <SubscriptionLayout>
      <h3 className="title">
        {__('Unlock all Lupa stories', 'subscribe.unlockAllLupaStories')}
      </h3>
      {authenticated ? <AuthenticatedFlow /> : <AnonymousFlow />}
      <VSpacer size="8" />
      <Footer />
    </SubscriptionLayout>
  );
});
