import * as React from 'react';
import { AdaptiveLink, PlainMarkdown } from 'naan/primitives/text';
import { FormWrapper } from 'components/gift-coupons/coupon-code-form-widget';
import { createAccountPath, signInPath } from 'components/nav/path-helpers';
import { styled } from 'naan/stitches.config';
import __ from 'core/lib/localization';

const Wrapper = styled(FormWrapper, {
  a: {
    color: '$colors$teal-500',
  },
});

export const AnonymousCouponCodeBlock = () => {
  const after = window.location.pathname;
  return (
    <Wrapper>
      <h3 className="title">
        {__('Joining a Lupa classroom?', 'auth.joiningLupaClassroom')}
      </h3>
      <h4>
        <PlainMarkdown
          components={{
            a: ({ node, ...props }) => <AdaptiveLink {...props} />,
          }}
          source={__(
            '[Sign in](%{signInUrl}) or [create an account](%{createAccountUrl}) to enter the code your teacher gave you',
            'auth.signInOrCreateAccountToEnterCodeYourTeacherGaveYou',
            {
              signInUrl: signInPath({ after }),
              createAccountUrl: createAccountPath({ after }),
            }
          )}
        />
      </h4>
    </Wrapper>
  );
};
