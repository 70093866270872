import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { PlainMarkdown } from './plain-markdown';

/**
 * We have to redefine styles here because there are very annoying global styles
 */
const Wrapper = styled('div', {
  '& p': {
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  '& a': {
    color: '$teal-500',
    textDecoration: 'underline',
  },

  '& li': {
    textIndent: '0',
    marginLeft: '1em',
    marginBottom: '16px',
  },

  '& ul': {
    listStyleType: 'disc',
    li: {
      listStyleType: 'disc',
    },
  },

  '& ol': {
    listStyleType: 'decimal',
    li: {
      listStyleType: 'decimal',
    },
  },

  variants: {
    textStyle: {
      normal: {
        textStyle: 'body',
        '& strong': {
          textStyle: 'body-bold',
        },
      },
      serif: {
        textStyle: 'serif',
        '& strong': {
          textStyle: 'serif-bold',
        },
      },
    },
  },
  defaultVariants: {
    textStyle: 'normal',
  },
});

type WrapperProps = React.ComponentProps<typeof Wrapper>;

export const FullyStyledMarkdown = ({
  source,
  ...props
}: WrapperProps & { source: string }) => {
  return (
    <Wrapper {...props}>
      <PlainMarkdown source={source} />
    </Wrapper>
  );
};
