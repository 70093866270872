/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from '@naan/primitives/text/external-link';

function isExternalLink(url: string): boolean {
  return url.startsWith('http');
}

// @armando, should perhaps we use `AdaptiveLink` more widly?
export const AdaptiveLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
> = props => {
  if (isExternalLink(props.href)) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return (
      <ExternalLink href={props.href} {...props} className="external-link" />
    );
  }
  return <Link to={props.href} {...props} className="internal-link" />;
};

export const CustomActionLink: React.FC<
  React.AnchorHTMLAttributes<HTMLAnchorElement> & { action: () => void }
> = ({ action, ...props }) => {
  return (
    <a
      {...props}
      onClick={e => {
        e.preventDefault();
        action();
      }}
    />
  );
};
