import { computed } from 'mobx';
import {
  applySnapshot,
  ModelTreeNode,
  volatile,
  snap,
  Bindery,
  getSnapshot,
} from 'ts-state-tree/tst-core';
import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
// import * as platform from 'app/platform';

import minibus from 'common/minibus';
import invariant from 'core/lib/invariant';

import { AccountData } from './account-data';
import { Root } from '../root';
import { ApiInvoker } from 'core/services/api-invoker';
import { Classroom } from './classroom';
import { License } from './license';
import { Student } from './student';
import { StudentProgress } from './student-progress';
import { Assignment } from './assignment';
import { Plan } from './plan';
import { UserData } from './user-data';
import { AssistSettings } from './assist-settings';
import { ListeningLog } from './listening-log';
import { ListeningStats } from './listening-stats';
import { LocationPointer } from './location-pointer';
import { PurchasedCoupon } from './purchased-coupon';
import { StoryProgress } from './story-progress';
import { UserSettings } from './user-settings';
import { PaymentData } from './payment-data';
import { ValidationError } from 'core/lib/errors';
import __ from 'core/lib/localization';
import { getBaseRoot } from '../app-root';
import { ClassroomUserData } from './classroom-user-data';
import { normalizedEqual } from 'utils/util';
import { PlayerSettings } from './player-settings';
import { StringToString } from '@utils/util-types';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { SoundbiteUserData } from './soundbite-user-data';
import { SoundbiteEngagement } from './soundbite-engagement';
import { embeddedBuildNumber, embeddedPlatform } from '@core/lib/app-util';
import { isEmpty, pick } from 'lodash';
import { VideoGuideEngagement } from './video-guide-engagement';
import { VideoGuideUserData } from './video-guide-user-data';
import { AppFactory } from '@app/app-factory';
import { alertWarningError, bugsnagNotify } from '@app/notification-service';
import { notEmpty } from '@utils/conditionals';
import { isNetworkError } from '@core/lib/error-handling';

const log = createLogger('user-manager');

export const USER_TOKEN_COOKIE_KEY = 'jw-user-token';
const LOCAL_DATA_CACHE_KEY = 'user-manager'; // locally persisted user manager state

// const { ERROR, WARN } = alertLevels;

// @armando, jason, do you think it's worth defining types for these result structure
// or just declare inline and let typescript infer from there?
// export type UpdateProfileFieldResult = { status: string; message: string };

// better home for this?
// declare global {
//   interface Window {
//     ReactNativeWebView: any;
//     embeddedPlatform: string;
//   }
// }

export class UserManager extends ModelTreeNode {
  static CLASS_NAME = 'UserManager' as const;

  static create(snapshot: any = {}): UserManager {
    return super.create(UserManager, snapshot) as UserManager;
  }

  static bindModels(bindery: Bindery): void {
    bindery.bind(AccountData);
    bindery.bind(Assignment);
    bindery.bind(AssistSettings);
    // bindery.bind(AttemptedPurchase);
    bindery.bind(Classroom);
    bindery.bind(License);
    bindery.bind(ListeningLog);
    bindery.bind(ListeningStats);
    bindery.bind(LocationPointer);
    bindery.bind(PaymentData);
    bindery.bind(Plan);
    bindery.bind(PlayerSettings);
    bindery.bind(PurchasedCoupon);
    bindery.bind(StoryProgress);
    bindery.bind(StudentProgress);
    bindery.bind(Student);
    bindery.bind(UserData);
    bindery.bind(ClassroomUserData);
    bindery.bind(SoundbiteUserData);
    bindery.bind(SoundbiteEngagement);
    bindery.bind(UserManager);
    bindery.bind(UserSettings);
    bindery.bind(VideoGuideUserData);
    bindery.bind(VideoGuideEngagement);
    // bindery.bind(Vocab);
  }

  token?: string = null;
  installationId?: string = null; // local storage persisted ui used for anonymous metrics
  // authSkipped: boolean = false; // local-only 'anonymous' mode
  // anonymousUsage: boolean = false; // set 'true' upon 'skipAuth'. used to drive 'go back' vs 'skip' in auth footer
  accountData: AccountData = snap({});

  // user account data managed by server - immutable by client except via api calls to server
  // lastSyncedVersion: number = -1; // client side version of the accountData value as of the most recent sync
  lastSyncCheck: number; // timestamp from last account data sync attempt
  userDataRefreshedAt: number = 0; // Date.now timestamp of last inbound user data. used to drive 'dataReady' state

  userData: UserData = snap({}); //  data to be synced to/from server

  // transient data using during the legacy data import
  @volatile
  migrationUserData: UserData;

  // expose the dev menu even when anonymous
  forceAdminAccess: boolean = false;

  // enables dashboard opt-in dialog
  get newsletterPromptEnabled() {
    return (
      this.authenticated &&
      this.accountData.mailingListPromptNeeded &&
      this.userData.mailingListPromptEnabled
    );
  }

  // hook to update state related to mailing list prompt if needed when study or soundbite player is visited
  handlePlayerVisited() {
    if (
      this.accountData.mailingListPromptNeeded &&
      !this.userData.mailingListPromptEnabled
    ) {
      this.userData
        .updateMailingListPromptEnabled(true)
        .catch(error => bugsnagNotify(error));
    }
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  get apiInvoker(): ApiInvoker {
    return this.root.apiInvoker;
  }

  get authenticated(): boolean {
    return !!this.token;
  }

  // delay painting dashboard until we have loaded both account and user data
  get dataReady(): boolean {
    return !!this.accountData.email && this.userDataRefreshedAt > 0;
  }

  get loggedInAndReady(): boolean {
    return this.authenticated && this.dataReady;
  }

  // tried to use this to drive loading indicator just after logging in, but didn't seem to work
  get loadingUserData(): boolean {
    return this.authenticated && !this.dataReady;
  }

  setForceAdminAccess(value: boolean) {
    this.forceAdminAccess = value;
  }

  get hasAdminAccess(): boolean {
    return this.accountData.showFutureStories || this.forceAdminAccess;
  }

  get showUglyDevUI(): boolean {
    return this.userData.masalaAdmin;
  }

  get hasNeverListened(): boolean {
    return this.userData.statsChartData.totalPoints === 0;
  }

  setInstallationId(uuid: string) {
    this.installationId = uuid;
  }

  get reportingContextData(): object {
    // return {
    //   authenticated: this.authenticated,
    //   installationId: this.installationId,
    //   hasAdminAccess: this.hasAdminAccess,
    //   userDataRefreshedAt: this.userDataRefreshedAt, // might be stale but pass along what we had
    // };
    return pick(this, [
      'authenticated',
      'installationId',
      'hasAdminAccess',
      'purchaseFlowDisabled',
      'classroomEnabled',
      'userDataRefreshedAt',
    ]);
  }

  get purchaseFlowDisabled(): boolean {
    // We need to use this logic to hide any reference to gift coupon purchase and redeem codes. May be this should be moved to account data.
    const platform = embeddedPlatform();
    if (
      platform === 'ios' &&
      !isEmpty(this.accountData.debugBuildNumber) &&
      this.accountData.debugBuildNumber === embeddedBuildNumber()
    ) {
      return true;
    }
    return (
      (platform === 'ios' && appConfig.iosNoPurchase) ||
      (platform === 'android' && appConfig.androidNoPurchase) ||
      appConfig.forceNoPurchase
    );
  }

  async login(email: string, password: string): Promise<void> {
    log.info(`login(${email})`);
    track('preauth__email_log_in', { email });

    if (this.authenticated) {
      invariant(
        false,
        `login - unexpectedly already authenticated - token: ${this.token}`
      );
      await this.reset();
    }

    const userCredentials = {
      email,
      password,
    };

    const result = await this.apiInvoker.post(
      'users/auth',
      {},
      { bodyData: userCredentials, networkIndicator: true } // network indicator not yet honored with signed out layout
    );

    log.debug(`auth result: ${JSON.stringify(result)}`);

    await this.applyAuthentication(result.userToken);
    // await this.checkWelcomeUrl();

    this.postAuthenticate();

    /// subscribed to by spa to update cookies
    minibus.emit('LOGIN_COMPLETE', this);
  }

  /**
   * handle the social logins. will create a user on the fly if needed
   *
   * provider codes:
   *   'google' - google oauth
   *   'facebook' - not yet supported
   *   'mock:[name]' - fake mode for test harness
   */
  async omniauth(provider: string, token: string): Promise<void> {
    const anonymousId = this.installationId;

    log.info(`omniauth(${token})`);

    if (this.authenticated) {
      invariant(
        false,
        `omniauth - unexpectedly already authenticated - token: ${this.token}`
      );
      await this.reset();
    }

    // 'mock' used by mst-web-proto
    if (provider === 'google' || provider === 'mock') {
      track('preauth__google_auth');
    } else {
      invariant(
        provider === 'google',
        'Currently support only google omniauth, missing branch'
      );
      track('preauth__unexpected_auth');
    }

    const authParams = {
      provider,
      token,
      anonymous_id: anonymousId,
    };

    const result = await this.apiInvoker.post('users/omniauth', authParams);
    await this.applyAuthentication(result.userToken);
    // await this.checkWelcomeUrl();

    /// subscribed to by spa to update cookies
    minibus.emit('LOGIN_COMPLETE', this);

    this.postAuthenticate();
    // yield self.syncToServer(); // todo: only sync for new account once we better distinguish
  }

  /**
   * pretends to login via google and create new account if needed with
   * given first name if not already in system
   */
  async mockOmniauth(email: string, name: string): Promise<void> {
    await this.omniauth('mock', `${email}|${name}`);
  }

  async signup(credentials = {}): Promise<void> {
    const anonymousId = this.installationId;

    log.info('create account', credentials);

    // should match the untouched server version
    this.userData.setLastSyncVersion(0); // revisit, needed to navigation to dashboard after signup
    const anonymousUserData = this.userData.snapshot;

    if (this.authenticated) {
      invariant(
        false,
        `signup - unexpectedly already authenticated - token: ${this.token}`
      );
      await this.reset();
    }

    track('preauth__email_sign_up', credentials);

    const result = await this.apiInvoker.post(
      'users/signup',
      {},
      {
        bodyData: {
          ...credentials,
          anonymous_id: anonymousId,
        },
        networkIndicator: true,
      }
    );

    await this.applyAuthentication(result.userToken);

    // reapply after the saved anonymous progress after the authentication
    applySnapshot(this.userData, anonymousUserData);

    if (appConfig.autoImportForNewAccount) {
      await this.importUserData({ merge: false });
    }

    // subscribed to by spa to update cookies
    minibus.emit('LOGIN_COMPLETE', this);

    await this.persistUserData(); // ensure initial user data sent to firestore

    this.postAuthenticate();
  }

  // async skipAuth() {
  //   log.info(`skipAuth`);

  //   // self.$track('preauth__skip_auth', { anonymousId });

  //   /// important to _not_ reset here to allow reskipping after resumeAuth
  //   /// we need to trust that our state is reset upon a normal logout
  //   // self.reset();

  //   // this.anonymousUsage = true;
  //   // this.authSkipped = true;

  //   return this.applyAuthentication(null);
  // }

  async logout(): Promise<void> {
    log.info('logout');
    // await this.reset();
    await this.applyAuthentication(null);
  }

  /**
   * Like login, except that it takes a token instead of email/password
   * Used for deep links (native wrapper, email links) or server cookie auth
   */
  async autoLogin(token: string): Promise<void> {
    log.info(`autoLogin(${token})`);

    if (this.authenticated) {
      invariant(
        false,
        `autoLogin - unexpectedly already authenticated - token: ${this.token}`
      );
      await this.reset();
    }

    await this.applyAuthentication(token);
    // await this.checkWelcomeUrl();

    this.postAuthenticate();

    // Emit an event.
    minibus.emit('LOGIN_COMPLETE', this);
  }

  // normal startup flow when we have locally persisted state
  async initWithLocalData() {
    log.info(
      `initWithLocalRootData - token: ${this.token}, ac.email: ${this.accountData?.email}`
    );
    // log.info(
    //   `lastSyncVersion - ac: ${this.accountData?.lastSyncedVersion}, um: ${this.lastSyncedVersion}`
    // );
    log.info(
      `catalogUrl - ac: ${this.accountData?.catalogUrl}, sm: ${this.root?.storyManager?.catalogUrl}`
    );
    try {
      await this.syncFromServer({ updateDependents: true });
    } catch (error) {
      if (isNetworkError(error as Error)) {
        log.warn(
          `initWithLocalData.syncFromServer network error: ${error} - ignoring`
        );
      } else {
        throw error;
      }
    }

    this.postAuthenticate();

    // not sure if relevant
    minibus.emit('LOGIN_COMPLETE', this);
  }

  async anonymousInit() {
    log.info('anonymousInit');
    // const data = await this.fetchAccountData();
    // await this.applyNewAccountData(data);
    await this.applyAuthentication(null);
  }

  resetLocalUserData() {
    applySnapshot(this.userData, {});
    this.persistLocal().catch(error =>
      alertWarningError({ error, note: 'um.resetLocalUseData' })
    );
  }

  // sync account datra from rails server when made visible if not checked within given time
  // (default 5 min)
  async refreshAccountDataIfStale(minimumMills: number = 5 * 60 * 1000) {
    if (Date.now() > this.lastSyncCheck + minimumMills) {
      await this.syncFromServer({ updateDependents: false });
    } else {
      log.debug(
        `refreshAccountDataIfStale - skipped (${
          Date.now() - this.lastSyncCheck
        }ms)`
      );
    }
  }

  // sync account data and potentially migrate legacy user data from rails server
  // called during startup when local root store data is used
  async syncFromServer({
    updateDependents,
  }: {
    updateDependents: boolean;
  }): Promise<void> {
    log.info(`refreshAccountData, local token: ${this.token}`);

    this.lastSyncCheck = Date.now();
    const data = (await this.fetchAccountData()) as AccountData;
    await this.applyNewAccountData(data, updateDependents);
  }

  async reset(): Promise<void> {
    log.info('reset');
    await this.applyAuthentication(null);

    // // this.anonymousUsage = false;
    // // self.loggedInAndReady = false;

    // // note, need to preserve deferredNavigation and pendingPaymentSelection
    // // through new authentication, so can't reset here.

    // applySnapshot(this, {
    //   token: null,
    //   accountData: {},
    //   // lastSyncedVersion: -2, // paranoia to avoid match
    //   userData: {},
    // });
    // // avoid bleeding the welcome message from the previous state
    // // self.whatsNewUrl = null;
    // // fetch pre auth account data
    // await this.refreshPreauthAccountData();
    // await this.setServerCookieUserToken(null);
  }

  // used for both logging and and out (token=null for logout)
  async applyAuthentication(token: string): Promise<void> {
    this.token = token;
    this.root.apiInvoker.setAuthToken(token);

    if (!this.authenticated) {
      this.stopListen(); // stop listening asap when logging out since we have awaits below

      applySnapshot(this, {
        token: null,
        accountData: {},
        lastSyncedAt: 0,
        // lastSyncedVersion: -2, // paranoia to avoid match
        userData: {},
      });
    }

    // should never be fatal
    this.setServerCookieUserToken(token).catch(bugsnagNotify);

    const data = (await this.fetchAccountData()) as AccountData;
    await this.applyNewAccountData(data, true);

    if (this.authenticated) {
      const dirty = this.userData.migrateSimpleSchemaChanges();
      if (dirty) {
        log.info(`persisting simple schema updates`);
        await this.persistUserData();
      }

      this.startListen();
    }
  }

  async setServerCookieUserToken(token: string): Promise<void> {
    // attempt to save to both local cache and server cookie since neither are guaranteed to work in all cases
    await AppFactory.appStateCacher.storeObject(USER_TOKEN_COOKIE_KEY, token);
    await this.setServerCookie(USER_TOKEN_COOKIE_KEY, token);
  }

  async getServerCookieUserToken(): Promise<string> {
    const localToken = await AppFactory.appStateCacher.fetchObject<string>(
      USER_TOKEN_COOKIE_KEY
    );
    if (notEmpty(localToken)) {
      return localToken;
    }
    try {
      const result = await this.getServerCookie(USER_TOKEN_COOKIE_KEY);
      return result;
    } catch (error) {
      if (isNetworkError(error as Error)) {
        log.warn(`getServerCookie network error: ${error} - ignoring`);
        return null;
      }
      throw error;
    }
  }

  // async authenticateFromServerCookie(): boolean {
  // }

  resetAuthentication() {
    this.token = null;
  }

  async fetchAccountData(): Promise<AccountData> {
    return await this.apiInvoker.get('users/account', {
      ts: new Date().getTime(), // ensure not cached
    });
  }

  /**
   * used to update server provided config data before logging in
   */
  async refreshPreauthAccountData(): Promise<void> {
    log.info(`refreshPreauthAccountData`);
    const data = await this.fetchAccountData();
    applySnapshot(this.accountData, data);
  }

  /**
   * updates the memory state with freshly received account data from the server.
   * shared helper method invoked from the various places that we receive an
   * accountData response.
   *
   * updateDependents - if false, then skip the catalog and userData updates.
   *   currently necessary when updating the profile info. can hopefully
   *   figure out how to make safe.
   *
   * (private)
   */
  async applyNewAccountData(
    data: AccountData,
    updateDependents: boolean
  ): Promise<void> {
    log.info(`applyNewAccountData, updateDeps: ${updateDependents}`);
    // trust now that this operation is robust
    applySnapshot(this.accountData, data);
    this.root.setReportingContext();

    AppFactory.firestoreInvoker.setUserDataUuid(this.accountData.userDataUuid);

    if (updateDependents) {
      if (this.authenticated) {
        log.debug(
          `pre firestore fetch ud.lsv: ${this.userData.lastSyncedVersion}`
        );
        // important to fetch the lastSyncVersion before accountData is fetched from the rails server
        const loadedFirestoreData = await this.fetchFirestoreUserData();
        log.debug(
          `post firestore fetch ud.lsv: ${this.userData.lastSyncedVersion}`
        );

        if (!loadedFirestoreData) {
          log.info(
            'firestore data not found - checking for legacy rails sync data'
          );
          // only relevant now to legacy user data migration from rails to firestore
          // await this.ensureLegacySyncedVersion(data.lastSyncedVersion);
          await this.fetchLegacyUserData();
        }
      }

      const { catalogUrl } = this.accountData;
      // log.debug(
      //   `account catalogUrl: ${catalogUrl}, smurl: ${this.root.storyManager.catalogUrl}`
      // );
      // todo: sync catalog url via firestore
      await this.root.storyManager.ensureCatalogUrl(catalogUrl);
    }
    this.persistLocal().catch(error =>
      alertWarningError({ error, note: 'um.applyNewAccountData' })
    );
  }

  //
  // user data sync
  //
  // /**
  //  * BEWARE this code was somehow stomping the new user data upon the next reload in some cases
  //  *
  //  * checks if our local user data's last sync basis matches the provided version
  //  * (presumably as provided within the server's accountData result).
  //  * if there's a mismatch then fetch the latest client data from the server.
  //  * (private)
  //  */
  // async ensureLegacySyncedVersion(version: number) {
  //   /// the versions initialize to '0' now, so we should never trigger an
  //   /// initial sync unless there'a a >1 version coming back from the server
  //   log.debug(
  //     `ensureLegacySyncedVersion - old um: ${this.lastSyncedVersion}, old ud: ${this.userData.lastSyncedVersion} / new:${version})`
  //   );
  //   // beware,
  //   if (this.userData.lastSyncedVersion !== version) {
  //     log.info(
  //       `legacy - lastSyncedVersion mismatch (old um: ${this.lastSyncedVersion}, old ud: ${this.userData.lastSyncedVersion} / new:${version}) - syncing`
  //     );
  //     await this.fetchLegacyUserData();
  //   } else {
  //     log.info(`legacy - lastSyncedVersion matched (${version}) - not syncing`);
  //   }
  // }

  async fetchFirestoreUserData(): Promise<boolean> {
    const data = await AppFactory.firestoreInvoker.getUserDataSnapshot();
    if (data) {
      applySnapshot(this.userData, data);
      this.setLastSynced();
      this.persistLocal().catch(error =>
        alertWarningError({ error, note: 'um-fetchFirestoreUserData' })
      ); // likely redundant in some cases
      return true;
    } else {
      log.info(`fetchFirestoreUserData - no data`);
      return false;
    }
  }

  // fetches latest user data from the rails server which we don't expect to be updated
  // beyond the initial migration to firestore unless old clients are still running
  async fetchLegacyUserData() {
    if (!this.authenticated) {
      log.info(`fetchLegacyUserData - skippedAuth`);
      return;
    }

    log.info(`fetchLegacyUserData`);
    const data = await this.apiInvoker.get('users/data', {
      ts: new Date().getTime(), // ensure not cached
    });
    log.debug(`server fetched data: ${JSON.stringify(data)}`);

    // a server bug was previously cause the data to be null
    invariant(data, 'users/data should not return null');
    if (!data) {
      return;
    }

    // this.userData = UserData.create(data); <-- this usage is _wrong_ will not correctly wire the parent
    // applySnapshot(this, { userData: UserData.create(data) }); <-- somehow, this flavor was nuking our logged in state. @jason any idea why?

    applySnapshot(this.userData, data);
    await this.userData.migrateBogotaUserData();
    // });
    log.info(
      `legacy fetched userData.lastSyncedVersion: ${String(
        data.lastSyncedVersion
      )}`
    );

    this.setLastSynced();
    this.userData.lastSyncedVersion = data.lastSyncedVersion ?? -5; // the UserData version is what matters now
    // this.lastSyncedVersion = this.userData.lastSyncedVersion; // can probably ditch this version of the version
    await this.persistUserData(); // save out to firestore - TODO: think more about if we should wait here or not
    // /*async*/ this.root.storyManager.ensureCacheState(); // was too aggressive
  }

  // drives 'dataReady'
  setLastSynced() {
    this.userDataRefreshedAt = Date.now();
  }

  // needs more thought
  async persistUserData() {
    if (this.authenticated) {
      // await this.syncToServer();
      await this.syncAllToFirestore();
    } else {
      log.info('persistUserData - not autheticated - using local storage');
      this.persistLocal().catch(error =>
        alertWarningError({ error, note: 'um-persistUserData' })
      );
    }
  }

  async syncAllToFirestore() {
    // todo: will we still want to support an anonymous mode?
    if (!this.authenticated) {
      log.info(`syncAllToFirestore - skippedAuth}`);
      return;
    }

    log.info(`syncAllToFirestore`);
    // TODO should not use persistLocal after configure Firestore caching
    this.persistLocal().catch(error =>
      alertWarningError({ error, note: 'um-syncAllToFirestore' })
    ); // make sure all data saved locally regardless of sync

    this.userData.mirrorReferenceAccountData();
    const userDataSnapshot = this.userData.snapshot;
    await AppFactory.firestoreInvoker.saveUserDataSnapshot(userDataSnapshot);
  }

  startListen() {
    AppFactory.firestoreInvoker.startListen((userDataSnapshot: any) => {
      log.debug(`firestore listen - received user data snapshot`);
      log.debug(
        `import performed: ${String(this.userData.destructiveImportPerformed)}`
      );
      applySnapshot(this.userData, userDataSnapshot);
      this.setLastSynced();
    });
  }

  stopListen() {
    AppFactory.firestoreInvoker.stopListen();
  }

  async persistLocal() {
    // const payload = this.stringify;
    // log.info(`persistLocal - ${payload?.length} bytes`);
    log.info(`persistLocal`);
    // return this.root.storeLocalUserData(payload);
    const data = this.snapshot;
    return AppFactory.appStateCacher.storeObject(LOCAL_DATA_CACHE_KEY, data);
  }

  async resetLocalData() {
    await AppFactory.appStateCacher.remove(LOCAL_DATA_CACHE_KEY);
    applySnapshot(this, {});
  }

  async loadLocal() {
    // const data = await this.root.loadLocalUserData();
    const data = await AppFactory.appStateCacher.fetchObject(
      LOCAL_DATA_CACHE_KEY
    );
    if (data) {
      applySnapshot(this, data);
      this.root.apiInvoker.setAuthToken(this.token);
      // this.firestoreUserDataInvoker.setAuthToken(this.token);
      AppFactory.firestoreInvoker.setUserDataUuid(
        this.accountData.userDataUuid
      );
      this.startListen(); // will be ignored when anonymous

      log.info(
        `loadLocal - email: ${this.accountData?.email}, playbackRate: ${this.userData?.playerSettings?.playbackRate}`
      );
    } else {
      log.info('loadLocal - no data');
    }
  }

  async setServerCookie(key: string, value: string) {
    await this.apiInvoker.api('users/set_cookie', null, {
      // additional fetch params
      method: 'POST',
      body: JSON.stringify({
        key,
        value,
      }),
    });
  }

  async getServerCookie(key: string) {
    const result = await this.apiInvoker.get('users/get_cookie', {
      key,
    });
    return result?.value;
  }

  //
  // account page operations
  //

  updateEmail(newEmail: string) {
    return this.updateProfileField('email', newEmail);
  }

  updateName(newName: string) {
    return this.updateProfileField('name', newName);
  }

  /**
   * note, we no longer that the old password is confirmed
   */
  updatePassword(newPassword: string) {
    return this.updateProfileField('password', newPassword);
  }

  updateSchoolName(newName: string) {
    return this.updateProfileField('school_name', newName);
  }

  // dev screen convenience
  async toggleClassroomActivation() {
    if (this.accountData.classroomEnabled) {
      await this.updateSchoolName('n/a');
    } else {
      await this.updateSchoolName('abc high');
    }
  }

  get classroomEnabled() {
    return this.accountData.classroomEnabled;
  }

  /**
   * Send update of name, email or password to server
   */
  async updateProfileField(
    key: string,
    value: string
  ): Promise<{ status: string; message: string } /*UpdateProfileFieldResult*/> {
    // const { message, accountData } = await this.apiInvoker.post(
    const result = await this.apiInvoker.post(
      'users/update_field',
      {
        key,
        value,
      },
      { networkIndicator: true }
    );
    const { accountData } = result;

    await this.applyNewAccountData(accountData, false);

    return result;
  }

  async toggleMailingListOptIn() {
    return this.updateMailingListOptIn(!this.accountData.mailingListOptIn);
  }

  async updateMailingListOptIn(value: boolean) {
    await this.updatePreference('mailing_list_opt_in', value);
    return this.userData.updateMailingListPromptEnabled(false);
  }

  /**
   * Update mailing list opt-in/out preference (key=mailing_list_opt_in, value=[boolean])
   * And potentially other server managed attributes in the future)
   */
  async updatePreference(key: string, value: any) {
    const result = await this.apiInvoker.post('users/update_preference', {
      key,
      value,
    });
    const { accountData } = result;

    await this.applyNewAccountData(accountData, false);
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
  }

  async sendPasswordReset(email: string, hardValidation = false) {
    track('preauth__request_password_reset', { email });
    const endpoint = 'users/send_password_reset';
    const result = await this.apiInvoker.post(
      endpoint,
      { hard_validation: hardValidation ? 'true' : 'false' },
      {
        bodyData: {
          email,
        },
        networkIndicator: true,
      }
    );

    return { ...result, success: true, key: 'sendPasswordReset' };

    //   self.$notifications.notifySuccess(result.message);
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
    // }
  }

  async resetPasswordByToken(token: string, newPassword: string) {
    const data = {
      reset_password_token: token,
      password: newPassword,
    };

    const result = await this.apiInvoker.post(
      'users/reset_password_by_token',
      {},
      { bodyData: data, networkIndicator: true }
    );

    return { ...result, success: true, key: 'resetPassword' };
  }

  async resendEmailConfirmation() {
    track('account__resend_email_confirmation');
    const result = await this.apiInvoker.post(
      'users/send_confirmation_instructions',
      {},
      { networkIndicator: true }
    );
    return result;
  }

  async cancelPendingEmailChange() {
    track('account__cancel_pending_email_change');
    const result = await this.apiInvoker.post(
      'users/cancel_pending_email_change',
      {}
    );
    const { accountData } = result;
    await this.applyNewAccountData(accountData, false);
    return result;
  }

  /**
   * Assign a catalog slug for a user. Used by catalog selection hidden menu.
   */

  async updateCatalogSlug(slug: string) {
    // const key = v4CatalogMode() ? 'catalog_v4_slug' : 'catalog_v3_slug';
    const key = 'catalog_v4_slug';

    const result = await this.apiInvoker.post('users/update_field', {
      key,
      value: slug,
    });

    const { accountData } = result;

    return this.applyNewAccountData(accountData, true);
  }

  async applyCoupon(code: string) {
    const specialCouponResult = await this.handleSpecialCoupons(code);
    if (specialCouponResult !== false) {
      return specialCouponResult;
    }

    track('account__redeem_coupon');

    const result = await this.apiInvoker.post(
      'users/apply_coupon',
      {
        code,
      },
      { networkIndicator: true }
    );

    const { accountData, ...extraParams } = result;
    log.debug(`extra params: ${extraParams}`);

    // there's no other way for mst to communicate this {messageKey, daysLeft} variables to the UI
    // getRoot(self).setFlash(extraParams);
    await this.applyNewAccountData(accountData, false);
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async handleSpecialCoupons(code: string) {
    if (code === 'crash2') {
      (code as any).crashTest();
    }

    track('account__redeem_coupon');
    // todo: figure out a better place to put back a test hook for unexpected crashes
    invariant(code !== 'invariant', 'invariant failure test');
    if (code === 'crash') {
      (code as any).crashTest();
    }
    if (code === 'crash3') {
      // eslint-disable-next-line no-throw-literal
      throw 'crash3';
    }
    // if (code === 'warn') {
    //   notifications.alertWarning('this is a test warning alert');
    //   return;
    // }
    // if (code === 'error') {
    //   notifications.alertError('this is a test error alert');
    //   return;
    // }
    if (code === 'netfail') {
      await fetch('http://foo.bar');
    }
    if (code === 'debug') {
      await this.apiInvoker.api(
        'users/debug',
        null, // query param
        {
          // additional fetch params
          method: 'POST',
          body: JSON.stringify({
            data: this.root.stringify,
          }),
        }
      );
      // notifications.notifySuccess('Debug data captured');
      return;
    }
    // if (code === 'remove-all-assets') {
    //   await this.root.downloadManager.removeAllAssets();
    //   return;
    // }

    if (code === '$success') {
      return { message: 'This went well. Hurrah.' };
    }
    if (code === '$error') {
      // root.setValidationError({
      //   key: 'code',
      //   message: 'this is a test error',
      // });
      return true;
    }

    return false;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async sendCouponInstructions(code: string) {
    log.info('sendCouponInstructions');
    const result = await this.apiInvoker.post(
      'users/send_coupon_instructions',
      { code }
    );
    return result;
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
  }

  async initiateCheckout(plan: Plan, urls: string[]) {
    const { checkoutSuccessUrl: successUrl, checkoutFailureUrl: failureUrl } =
      appConfig.website;
    log.info(
      `initiateCheckout - successUrl: ${successUrl}, failureUrl: ${failureUrl}`
    );
    const result = await this.apiInvoker.post<{
      interstitialMessageKey: string;
      stripeSessionId: string;
      successMessageKey: string;
    }>(
      'users/initiate_checkout',
      {
        planSlug: plan.slug,
        successUrl,
        failureUrl,
      },
      { networkIndicator: true }
    );

    // const stripe = await StripeLoader.instance.load();

    // stripe.redirectToCheckout({ sessionId: result.stripeSessionId });

    // console.log(result);

    return result;

    // // store the result in memory so it can be accessed by the UI
    // self.setCheckoutResult(result);

    // @joseph I think it's cleaner to let the UI pickup after getting the result.
    // because under some conditions we need the user input before procceeding
    // with the Stripe checkout process
  }

  async createStripePortalSession(returnUrl: string) {
    log.info(`createStripePortalSession`);

    if (!returnUrl) {
      returnUrl = appConfig.website.accountUrl;
    }

    const result = await this.apiInvoker.post<{
      url: string;
    }>('users/create_stripe_portal_session', {
      return_url: returnUrl,
    });

    // console.log(result);
    return result;
  }

  async cancelAutoRenew({ ignoreError = false } = {}) {
    log.info(`cancelAutoRenew - ignoreError: ${ignoreError}`);
    track('account__cancel_auto_renew');
    const result = await this.apiInvoker.post('users/cancel_auto_renew', {
      ignoreError,
    });
    const { /*message,*/ accountData } = result;

    await this.applyNewAccountData(accountData, false);
    return result;
  }

  async exportVocab(slug: string) {
    // TODO??
    const result = await this.apiInvoker.post('users/send_vocab', {
      slug,
    });
    const { message } = result;
    log.debug(`message: ${message}`);

    // self.$notifications.notifySuccess(message);
    // } catch (error) {
    //   safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
    // }
    return result;
  }

  async closeAccount() {
    const result = await this.apiInvoker.post(
      'users/close_account',
      {},
      { networkIndicator: true }
    );
    const { message } = result;
    log.debug(`message: ${message}`);

    // be sure to purge current state to prevent bleeding into new signup
    await this.logout(); //todo: false /*force*/, true /*skipSync*/);
  }

  async importUserData({
    apiEnv,
    email,
    merge = false,
  }: {
    apiEnv?: string;
    email?: string;
    merge?: boolean; // when false, overwrite; when true merged in listening logs and progress
  }): Promise<boolean> {
    if (!apiEnv) {
      apiEnv = appConfig.importApiEnv;
    }
    if (!email) {
      email = this.accountData.email;
    }
    log.info(`importUserData - apiEnv: ${apiEnv}, email: ${email}`);

    const importInvoker = new ApiInvoker({
      apiEnv,
      authToken: null,
    });

    // if (appConfig.jfe) throw Error('unexpected');

    this.userData.soundbiteUserData.migrateListToMap(); // should have already been run, but ensure
    const preservedSoundbiteData = this.userData.soundbiteUserData.snapshot;

    const rawData = await importInvoker.get('users/data_by_email', {
      email,
      ts: new Date().getTime(), // ensure not cached
    });
    log.debug(
      `importUserData - rawData listening logs`,
      rawData?.listeningLogs?.length
    );

    if (rawData) {
      this.migrationUserData = UserData.create(rawData);
      await this.migrationUserData.migrateBogotaUserData(); // will normalize into new schema
      // @jason would be really nice to figure out the snapshot typing, i got burned not realizing this was just 'any'
      const migratedData = UserData.create(getSnapshot(this.migrationUserData));
      this.migrationUserData = null;

      if (merge) {
        // beware, not sure if this is reliable or not
        log.info(`using experimental 'merge' import option`);
        await this.userData.mergeInProgressData(migratedData);
      } else {
        log.info(`overwriting userData with migrated data`);
        applySnapshot(this.userData, migratedData);
        this.userData.destructiveImportPerformed = true;
      }
      applySnapshot(this.userData.soundbiteUserData, preservedSoundbiteData);
      await this.persistUserData();
      return true;
    } else {
      log.error(`failed to import data for email: ${email}`);
      return false;
    }
  }

  async transplantUserData({
    fromEnv,
    fromEmail,
    toEnv,
    toToken,
  }: {
    fromEnv: string;
    fromEmail: string;
    toEnv: string;
    toToken: string;
  }) {
    if (!fromEnv || !fromEmail || !toEnv || !toToken) {
      throw Error('missing param(s)');
    }

    const fromInvoker = new ApiInvoker({
      apiEnv: fromEnv,
      authToken: null,
    });

    const toInvoker = new ApiInvoker({
      apiEnv: toEnv,
      authToken: toToken,
    });

    const data = await fromInvoker.get('users/data_by_email', {
      email: fromEmail,
      ts: new Date().getTime(), // ensure not cached
    });

    const payload = JSON.stringify(data);

    await toInvoker.api('users/data', null, {
      method: 'POST',
      body: JSON.stringify({
        client_data: payload,
      }),
    });
    // log.info(
    //   `post user data result lsv: ${resultAccountData?.lastSyncedVersion}`
    // );
  }

  //
  // story list support
  //

  @computed
  get primaryFilterLabels() {
    const result: StringToString = {
      // these correlate to PrimaryFilterKeys
      all: __('All stories', 'stories.filters.all'),
      queued: __('Study later', 'stories.filters.studyLater'),
      inProgress: __('In progress', 'stories.filters.inProgress'),
      completed: __('Complete', 'stories.filters.complete'),
    };
    this.accountData.joinedClassrooms.forEach(classroom => {
      result[classroom.filterKey] = classroom.label;
    });
    return result;
  }

  primaryFilterLabel(key: string) {
    return this.primaryFilterLabels[key];
  }

  //
  // classroom portal support
  //

  validateClassroomLabelAvailable(label: string) {
    if (this.classroomLabelExists(label)) {
      log.info('Classroom already exists', label);
      throw new ValidationError({
        key: 'classroom',
        message: __('Class name already exists', 'userManager.classroomExists'),
      });
    }
  }

  classroomLabelExists(label: string) {
    const existingClass = this.accountData.managedClassrooms.find(classroom => {
      return (
        normalizedEqual(classroom.label, label) && classroom.archived === false
      );
    });

    return !!existingClass;
  }

  async createClassroom(label: string) {
    label = label?.trim(); // quietly ignore any leading/trailing whitespace
    this.validateClassroomLabelAvailable(label);
    log.info('will create Classroom', label);

    const result = await this.apiInvoker.post<{
      classroom?: Classroom;
      message: string;
      accountData: any;
    }>('classrooms', { label }, { networkIndicator: true });

    const {
      message,
      // messageKey,
      accountData,
    } = result;
    log.debug(`message: ${message}`);

    await this.applyNewAccountData(accountData, false);
    if (accountData && accountData.managedClassrooms) {
      // todo: have the server explicitly return the new classroom
      const classroom =
        accountData.managedClassrooms[accountData.managedClassrooms.length - 1];
      result.classroom = classroom;
    }
    return result;
  }

  async clearClassroomPortalWelcome() {
    // optimistic update
    //TODO this.accountData.setValue('classroomPortalWelcomePending', false);

    // now, do the persistent update
    const { accountData } = await this.apiInvoker.post(
      'users/clear_classroom_portal_welcome',
      {}
    );
    await this.applyNewAccountData(accountData, false);
  }

  get showTrialMessage() {
    return (
      !this.accountData.fullAccess &&
      !this.userData.userSettings.messageIsDismissed('trial-message')
    );
  }

  dismissTrialMessage() {
    this.userData.userSettings.dismissMessage('trial-message');
  }

  get showLearnMessage() {
    return (
      !this.classroomEnabled && // never show to teachers
      !this.userData.userSettings.messageIsDismissed('learn-message')
    );
  }

  dismissLearnMessage() {
    this.userData.userSettings.dismissMessage('learn-message');
  }

  get showTeacherMessage() {
    return (
      this.classroomEnabled &&
      !this.userData.userSettings.messageIsDismissed('teacher-message')
    );
  }

  dismissTeacherMessage() {
    this.userData.userSettings.dismissMessage('teacher-message');
  }

  //
  // legacy api which need rethinking
  //

  // used to handle resuming purchase flow if needed
  postAuthenticate() {
    log.debug('ReturnNavState.reset');
    ReturnNavState.reset();
  }

  async fetchCatalogSlugs(): Promise<string[]> {
    const slugs = await this.apiInvoker.get<string[]>('channels/slugs', {
      mode: 'v4',
    });
    return slugs;
  }
}
