import * as React from 'react';
import { namedObserver } from 'lib/named-observer';
import { SimpleEndCard } from './simple-end-card';
import { NextCard } from './next-card';
import { EnlightenmentCard } from './enlightenment-card';
import { EndCardProps } from './end-card-props';

export const EndCard = namedObserver(
  'EndCard',
  ({ player, model, onDismiss }: EndCardProps) => {
    // locked state, even when onboarding becomes newly complete
    if (model.onboardingMode) {
      if (model.hasNext) {
        return <NextCard player={player} model={model} onDismiss={onDismiss} />;
      } else {
        return (
          <EnlightenmentCard
            player={player}
            model={model}
            onDismiss={onDismiss}
          />
        );
      }
    } else {
      return (
        <SimpleEndCard player={player} model={model} onDismiss={onDismiss} />
      );
    }
  }
);
