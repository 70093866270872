import * as React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { observer } from 'mobx-react';

import { AppFactory } from '@app/app-factory';
import { CollectionCard } from '../../components/learn/collections/collection-card';
import { styled } from '@naan/stitches.config';
import { PageHeading } from 'components/page-heading';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { VSpacer } from '@naan/primitives/spacer';
import { DeepScrollRestoration } from 'lib/scroll-utils/deep-scroll-restoration';
import { useLocation } from 'react-router-dom';

import __ from 'core/lib/localization';

const CollectionsWrapper = styled('div', {
  // display: 'flex',
  // flexDirection: 'column',

  '@medium': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
    gridGap: '24px',
  },
});

export const CollectionListScreen = observer(() => {
  useTitle(__('Collections', 'nav.collections'));
  const { storyManager } = AppFactory.root;
  const { collections } = storyManager;
  const location = useLocation();
  return (
    <>
      <DeepScrollRestoration pageKey={location.pathname} />
      <CenterColumnLayout>
        <PageHeading title={__('Collections', 'dashboard.collections')} />
        <CollectionsWrapper>
          {collections.map(collection => (
            <div>
              <CollectionCard collection={collection} />
              <VSpacer size={12} />
            </div>
          ))}
        </CollectionsWrapper>
      </CenterColumnLayout>
    </>
  );
});
