import { useEffect } from 'react';
import { reaction } from 'mobx';
// import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { usePlayerModel } from 'player/views/player-model-context';
import {
  scrollToCurrentLine,
  // scrollToLine,
} from '../../study/views/fx/scrolling';
import {
  CreateMembershipReconciler,
  MembershipReconciler,
} from '@tikka/membership-reconciliation/membership-reconciler';
import { createMembershipEnumFuncs } from '@tikka/membership-reconciliation/membership-enum-funcs';
import { WordMembership } from 'player/models/word-membership';
import { renderStylesForDomId } from '@tikka/membership-reconciliation/dom-styles';
import { createLogger } from '@app/logger';

const log = createLogger('player-ui-model-setup');

const membershipContext = {
  wordMembershipReconciler: null as MembershipReconciler,
  lineMembershipReconciler: null as MembershipReconciler,
  getMemberships: null as (id: string) => number,
};

export const initializeStandardMembershipContext = () => {
  log.debug('initializeStandardMembershipContext');
  const membershipFuncs = createMembershipEnumFuncs(WordMembership);

  membershipContext.wordMembershipReconciler = CreateMembershipReconciler(
    membershipFuncs.setMemberships
  );

  membershipContext.lineMembershipReconciler =
    CreateMembershipReconciler(renderStylesForDomId);

  membershipContext.getMemberships = membershipFuncs.getMemberships;

  return membershipContext;
};

export const useWordMembership = () => {
  return membershipContext?.getMemberships;
};

export const useLineReconciler = (): MembershipReconciler => {
  return membershipContext?.lineMembershipReconciler;
};

export const useModelSetup = () => {
  const model = usePlayerModel();
  const membershipContext = initializeStandardMembershipContext();

  const {
    wordMembershipReconciler,
    lineMembershipReconciler,
    // @jason, @armando, would you prefer this to be named Passage.. Speaker.. or SpeakerLabel..?
  } = membershipContext;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const disposers: (() => void)[] = [];

  disposers.push(
    reaction(
      () => model.wordMembershipLists,
      () => {
        wordMembershipReconciler.reconcileMembershipLists(
          '',
          model.wordMembershipLists
        );
      },
      { fireImmediately: true }
    )
  );

  disposers.push(
    reaction(
      () => model.lineMembershipLists,
      () => {
        lineMembershipReconciler.reconcileMembershipLists(
          '',
          model.lineMembershipLists
        );
        const currentSentence =
          model.lineMembershipLists.get('currentLine')?.elements.values[0] ??
          null;
        if (currentSentence) {
          // const elementDomId = elementIdToDomId(null, currentSentence.id);
          // scrollToLine(document.getElementById(elementDomId));
          scrollToCurrentLine();
        }
      },
      { fireImmediately: true }
    )
  );

  useEffect(() => {
    return () => {
      // teardown
      membershipContext.lineMembershipReconciler = null;
      membershipContext.getMemberships = null;
      for (const disposer of disposers) {
        disposer();
      }
      // disposers = [];
      disposers.length = 0;
    };
  }, [disposers, membershipContext]);
};
