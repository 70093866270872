import {
  applySnapshot,
  getParentOfType,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';
import { UserManager } from '.';
import { getBaseRoot } from '../app-root';
import { StoryManager } from '../story-manager';
import { createLogger } from '@common/log';
import {
  VideoGuideEngagement,
  VideoGuideEngagementStatus,
} from './video-guide-engagement';
import { VideoGuide } from '../catalog/video-guide';
import { bugsnagNotify } from '@app/notification-service';
import { appConfig } from 'app/env';

const log = createLogger('video-guide-user-data');

/**
 * VideoGuideUserData
 *
 * helplet specific client managed data
 */
export class VideoGuideUserData extends ModelTreeNode {
  static CLASS_NAME = 'VideoGuideUserData' as const;

  static create(snapshot: any) {
    return super.create(VideoGuideUserData, snapshot) as VideoGuideUserData;
  }

  engagementMap: TSTStringMap<VideoGuideEngagement> = snap({});

  get userManager(): UserManager {
    return getParentOfType(this, UserManager);
  }

  get storyManager(): StoryManager {
    return getBaseRoot(this)?.storyManager;
  }

  get engagements(): VideoGuideEngagement[] {
    return Array.from(this.engagementMap.values());
  }

  // will need expanded logic once we have other categories
  get onboardingComplete(): boolean {
    return !this.featuredVideo;
  }

  get featuredVideo(): VideoGuide {
    if (appConfig.hideDashboardOnboarding) {
      return null; // hide from beta testers until ready
    }

    const result = this.storyManager.videoGuides.find(guide => {
      const engagement = this.engagementBySlug(guide.slug, { ensure: false });
      return !engagement?.completed;
    });
    return result;
  }

  recordEngagement({
    slug,
    status,
    persist = true,
  }: {
    slug: string;
    status: VideoGuideEngagementStatus;
    persist?: boolean;
  }) {
    const engagement = this.engagementBySlug(slug, { ensure: true });

    log.debug(
      `recordEngagement - ${slug}, ${status} - updated (was ${engagement.status})`
    );

    const timestamp = new Date().toISOString();

    applySnapshot(engagement, {
      slug: engagement.slug, // beware, current applySnapshot api requires all values be explicitly included
      status,
      timestamp,
    });

    if (persist) {
      this.userManager.persistUserData().catch(bugsnagNotify); // async
    }
  }

  engagementBySlug(
    slug: string,
    { ensure }: { ensure: boolean }
  ): VideoGuideEngagement {
    let result = this.engagements.find(engagement => engagement.slug === slug);
    if (!result && ensure) {
      result = VideoGuideEngagement.create({ slug: slug });
      this.engagementMap.set(slug, result);
    }
    return result;
  }

  statusBySlug(slug: string): VideoGuideEngagementStatus {
    const engagement = this.engagementBySlug(slug, { ensure: false });
    return engagement?.status || VideoGuideEngagementStatus.UNPLAYED;
  }

  skipAllOnboarding() {
    for (;;) {
      let next = this.featuredVideo;
      if (next) {
        this.recordEngagement({
          slug: next.slug,
          status: VideoGuideEngagementStatus.SKIPPED,
          persist: false,
        });
      } else {
        break;
      }
    }
    this.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  // ordered list of videos which will be shown on the dashboard when not yet played/skipped
  get featuredVideos(): VideoGuide[] {
    return this.storyManager.videoGuides.filter(video => video.featured);
  }

  // ordered list of the onboarding sequence of videos
  get onboardingVideos(): VideoGuide[] {
    return this.storyManager.videoGuides.filter(video => video.onboarding);
  }

  get onboardingProgressPercentage(): number {
    const progress = this.onboardingCompletedCount / this.onboardingTotalCount;
    return Math.round(progress * 100);
  }

  get onboardingCompletedCount(): number {
    // in future will need to filter engagements to onboarding videos
    const completed = this.engagements.filter(item => item.completed);
    return completed.length;
  }

  // total number of onboarding videos
  // this will need to be more complex once we have non-onboarding videos
  get onboardingTotalCount(): number {
    return this.onboardingVideos?.length ?? 0;
  }

  get nextOnboardingVideo(): VideoGuide {
    const videos = this.onboardingVideos;
    const current = this.featuredVideo;
    if (current.onboarding) {
      const next = videos[current.position /*-1+1*/]; // assuming 1 based position values
      return next;
    } else {
      return null;
    }
  }

  get hasNext(): boolean {
    return !!this.nextOnboardingVideo;
  }

  async resetAllData() {
    applySnapshot(this, {});
    await this.userManager.persistUserData();
  }
}
