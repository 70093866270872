import * as React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { appConfig } from 'app/env';
import { globalErrorHandler } from './global-error-handler';

// eslint-disable-next-line no-console
console.log('bugsnag-init.ts');

(window as any).Bugsnag = Bugsnag;

export const bugsnagEnabled = appConfig.bugsnag.enabled;

if (bugsnagEnabled) {
  // eslint-disable-next-line no-console
  console.log('Bugsnag.start');
  Bugsnag.start({
    apiKey: appConfig.bugsnag.apiKey,
    plugins: [new BugsnagPluginReact(React)],
    onError: globalErrorHandler,
  });
} else {
  window.onerror = globalErrorHandler; // revisit this someday, API doesn't quite match
}
