import { observable } from 'mobx';
import {
  Bindery,
  getRoot,
  getSnapshot,
  ModelTreeNode,
  snap,
  volatile,
} from 'ts-state-tree/tst-core';

import minibus from 'common/minibus';

import { objectFromUrlQuery } from 'common/object-from-url-query';
import { bindery } from 'ts-state-tree/tst-core';
import { nanoid } from 'nanoid';
import { stringToBool } from '@utils/string-utils';
import * as loggly from 'legacylib/loggly';
// import { NetworkError } from '@core/lib/errors';
import { alertWarningError } from 'app/notification-service';
import { AssetCacher } from 'lib/asset-cacher';
import { AppFactory } from '@app/app-factory';
import { AppStateCacher } from 'lib/app-state-cacher';
import { appConfig } from 'app/env';
import { track } from '@app/track';
import { ApiInvoker } from '../services/api-invoker';
import { UserManager } from './user-manager/user-manager';
import { GlobalConfig } from './global-config';
import { StoryManager } from './story-manager';
import { alertSevereError } from '@app/notification-service';
import tstSchema from '@app/tst-schema.json';
import { createLogger } from 'app/logger';
// import { GlobalData } from '@core/services/global-data-sync';
// import { checkForUpdate } from 'pwa/window/update-manager';

const log = createLogger('root');
// const READY = 'ready'; //  magic status
// const STARTUP_FAILURE = 'startup-failure'; // todo: make these an enum
// const OFFLINE = 'offline';

/**
 * removes the query string from the location bar address
 */
const clearQuery = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

const INSTALLATION_UUID_KEY = 'installation-uuid'; // persisted anonymous id
const DEBUG_FLAG_KEY = 'debug-flag'; // when true, force on debug UI
const LOGGLY_ENABLED_KEY = 'loggly-flag'; // when true, enable loggly debugging on startup
const INVITE_CODE_KEY = 'invite-code'; // the already validated code, when set just bypass the invite gate

const { inviteGateEnabled } = appConfig;

export class AppRoot extends ModelTreeNode {
  static CLASS_NAME = 'AppRoot' as const;

  private static bound: boolean = false;

  public static create(
    snapshot: any = {} /*, dependencies: any = {}*/
  ): AppRoot {
    AppRoot.ensureBound();
    const model = super.create(AppRoot, snapshot) as AppRoot;
    return model;
  }

  public static ensureBound(): void {
    if (!AppRoot.bound) {
      AppRoot.bound = true;
      bindery.mergeSchemaDefinitions(tstSchema as any);
      bindery.bind(AppRoot);
      this.bindModels(bindery);
      bindery.compileBindings();
    }
  }

  protected static bindModels(bindery: Bindery): void {
    // assume app's Root model will be bound within app level code
    UserManager.bindModels(bindery);
    StoryManager.bindModels(bindery);
    // Catalog.bindModels(bindery);
    bindery.bind(GlobalConfig);
  }

  @volatile
  @observable.ref // @observable.not
  apiInvoker: ApiInvoker; // delegate for rails server REST API invocations

  userManager: UserManager = snap({});
  globalConfig: GlobalConfig = snap({});
  validatedInviteCode: string;

  // unified now with the catalog data
  storyManager: StoryManager = snap({});

  @volatile
  offline: boolean = false;

  // hack to make it easy to see the mostly recently consumed logs
  @volatile
  serviceWorkerLogs: string[] = [];

  async appInitialize() {
    log.debug('appInitialize');
    this.apiInvoker = new ApiInvoker({
      apiEnv: this.globalConfig.apiEnv,
      authToken: this.userManager.token,
      appInstallAttribution: this.globalConfig.appInstallAttribution, // not sure if relevant
    });

    // try {
    await this.initState();
    // } catch (error) {
    //   alertSevereError({ error, note: 'base-root - initState' });
    // }
    log.info('root store created');
  }

  /**
   * automatically called after creation
   */
  async initState(): Promise<void> {
    log.debug(`initState`);

    // capture what we can as early as possible
    this.setReportingContext();

    // try {
    // this.setStatus('initializing');

    // could possibly store these into a volatile instance prop for convenience,
    // but i'm paranoid of json parser headaches
    const appStateCacher = await AppStateCacher.create('jw:app-state');
    AppFactory.setAppStateCacher(appStateCacher);

    await this.ensureInstallationId();
    this.setReportingContext(); // update with installation id

    if (inviteGateEnabled) {
      try {
        this.validatedInviteCode = await appStateCacher.fetchObject<string>(
          INVITE_CODE_KEY
        );
      } catch (error) {
        alertWarningError({
          error,
          note: 'app-root.initState - invite code',
        });
      }
    }

    try {
      await this.storyManager.loadLocal();
    } catch (error) {
      // log.info('error loading local catalog data', error);
      alertWarningError({
        error,
        note: 'root.initState - storyManager.loadLocal',
      });
    }

    try {
      await this.userManager.loadLocal();
    } catch (error) {
      // log.info('error loading local user data', error);
      alertWarningError({
        error,
        note: 'root.initState - userManager.loadLocal',
      });
    }
    // should unify the persistence of the various local state data and isolate from userManager
    // but for now need to restore after userManager.loadLocal
    await this.ensureInstallationId();

    this.setReportingContext(); // update with user info when available locally

    const { forceError } = await this.handleQueryParams();

    try {
      if (this.userManager.authenticated) {
        await this.userManager.initWithLocalData();
      } else {
        // attempt from server cookie if available
        await this.authFromStoredToken();
      }
    } catch (error) {
      // not sure if should be severe or warning
      alertSevereError({ error, note: 'root.initState - userManager.init' });
      // log.info('init auth error', error);
      // NotificationService.alertError(`Unexpected auth init error`);
      this.userManager.resetAuthentication();
    }

    if (!this.userManager.authenticated) {
      if (this.userManager.accountData.catalogUrl) {
        log.debug(
          `anonymous reload - keeping existing local state - catalogUrl: ${this.userManager.accountData.catalogV4Url}`
        );
      } else {
        await this.userManager.anonymousInit();
      }

      log.info('initState - anonymous reporting context');
      this.setReportingContext(); // setup anonymous context when not auto-logged in
    }

    // simple schema updates checked here and by userManager.applyAuthentication
    try {
      const dirty = this.userManager.userData.migrateSimpleSchemaChanges();
      if (dirty) {
        log.info(`persisting simple schema updates`);
        await this.userManager.persistUserData();
      }
    } catch (error) {
      alertWarningError({
        error,
        note: 'root.init - migrateSimpleSchemaChanges',
      });
    }

    try {
      const debugMode =
        appConfig.piMenuEnabled ??
        (await appStateCacher.fetchObject<boolean>(DEBUG_FLAG_KEY));
      if (debugMode) {
        this.userManager.setForceAdminAccess(debugMode);
      }
      const logglyEnabled = await this.loadLogglyEnabled();
      if (logglyEnabled) {
        loggly.activate();
      }
    } catch (error) {
      log.info('error loading local debug flag', error);
      alertWarningError({ error, note: 'root.init - debug/loggly' });
    }

    if (forceError) {
      throw Error('Debugging: forceError triggered');
    }

    // delay the asset cacher init until we hopefully have more reporting context
    AppFactory.setAssetCacher(await AssetCacher.create('jw:story-assets'));

    // this.setReady();
    // needed to trigger new soundbite each midnight
    this.storyManager.refreshDateAtMidnight();

    // roll back the caching aggressiveness until we ensure stability
    // /*async*/ this.storyManager.ensureCacheState();
    // } catch (error) {
    //   if (error instanceof NetworkError) {
    //     this.setOffline();
    //   } else {
    //     alertWarningError({ error, note: 'root.initState' });
    //     this.setStartupFailure();
    //   }
    // }
  }

  /**
   * if there's a query var in the url like
   * `token=eyJabc123.eyJabc123456`
   * it will grab that and store it locally
   */
  async handleQueryParams() {
    const {
      token = null,
      invite = undefined,
      debug = undefined,
      forceError = undefined,
    } = objectFromUrlQuery();
    const { appStateCacher } = AppFactory;

    if (token) {
      log.info(`url token: ${token}`);

      if (token !== this.userManager.token) {
        log.warn(
          `location token mismatched from local store data - resetting local store`
        );
        // todo: should perhaps move this logic back up to the main appInit function
        log.debug(`saving token into cookie: ${token}`);
        await this.userManager.setServerCookieUserToken(token);
        const confirmSaved = await this.userManager.getServerCookieUserToken();
        log.debug('refetched server cookie token: ${confirmSaved');
        if (confirmSaved !== token) {
          log.error(`beware failed to save server cookie`);
        }
        await this.userManager.resetLocalData();
      }
    }
    if (debug !== undefined) {
      // this.storeDebugFlag(stringToBool(debug));
      await appStateCacher.storeObject(DEBUG_FLAG_KEY, stringToBool(debug));
    }
    if (invite !== undefined) {
      await this.validateInvite(invite);
    }
    // if (forceError !== undefined) {
    //   this.setForceError(stringToBool(forceError));
    // }
    if (token || invite || debug || forceError !== undefined) {
      clearQuery();
    }
    return { token, invite, debug, forceError };
  }

  /**
   * if we have a locally stored token we use that to log the user in.
   * only expected now the first time a user loads the new site
   */
  async authFromStoredToken() {
    const serverCookieToken = await this.userManager.getServerCookieUserToken();
    if (serverCookieToken) {
      try {
        log.info(`auto login with server cookie user token`);
        await this.userManager.autoLogin(serverCookieToken);
        return;
      } catch (error) {
        alertWarningError({ error, note: 'root.authFromStoredToken' });
        await this.userManager.reset();
      }
    }

    // we'll be moving prod to jiveworld.app, so we'll lose our local storage regardless
    // // should only be relevant for initial lupa web roll-out
    // if (this.storedToken) {
    //   try {
    //     log.info(`auto login with stored token`);
    //     await this.userManager.autoLogin(this.storedToken);
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.log('ERROR', error);
    //     bugsnagNotify(error as Error);
    //     await this.userManager.reset();
    //   }
    // }
    // this.setStatus('ready');
  }

  // /**
  //  * stores @token locally
  //  */
  // storeToken(token: string) {
  //   if (!storage) {
  //     log.debug('storage not available');
  //     return;
  //   }
  //   if (token !== null) {
  //     log.debug('saving storedToken');
  //     storage?.setItem(tokenKey, token);
  //   } else {
  //     log.debug('removing storedToken');
  //     this.clearStoredToken();
  //   }
  // }

  // /**
  //  * Clear stored token
  //  */
  // async clearStoredToken() {
  //   // if (!storage) {
  //   //   log.debug('storage not available');
  //   //   return;
  //   // }
  //   log.debug('removing storedToken');
  //   // storage?.removeItem(tokenKey);
  //   return AppFactory.appStateCacher.remove(tokenKey);
  // }

  /**
   * getter to easy access the stored token
   * makes it implementation agnostic
   */
  // get storedToken() {
  //   return storage?.getItem(tokenKey) ?? null;
  // }

  // roll our own persisted anonymous id because our analytics stuff is a f!@#$ mess
  async ensureInstallationId(): Promise<string> {
    if (this.userManager.installationId) return;

    const { appStateCacher } = AppFactory;

    // let uuid = storage?.getItem(installationIdKey) ?? null;
    let uuid =
      (await appStateCacher.fetchObject<string>(INSTALLATION_UUID_KEY)) ?? null;
    if (!uuid) {
      try {
        uuid = nanoid();
        log.debug('allocating installationId: ', uuid);
        // storage?.setItem(installationIdKey, uuid);
        await appStateCacher.storeObject(INSTALLATION_UUID_KEY, uuid);
      } catch (error) {
        alertWarningError({ error, note: 'appRoot.ensureInstallationId' });
      }
    }
    this.userManager.setInstallationId(uuid);
    return uuid;
  }

  get inviteNeeded(): boolean {
    const { inviteGateEnabled } = appConfig;

    return (
      inviteGateEnabled &&
      !this.validatedInviteCode &&
      !this.userManager.authenticated
      // !this.userManager.validatedInviteCode // legacy state
    );
  }

  async validateInvite(code: string) {
    track('account__validate_invite', { code });

    const result = await this.apiInvoker.post<{
      status: string;
      code: string;
      message: string;
    }>(
      'users/validate_invite',
      {
        code,
      },
      { networkIndicator: true }
    );

    log.info(`validate invite result: ${JSON.stringify(result)}`);
    this.validatedInviteCode = result.code;
    await AppFactory.appStateCacher.storeObject(
      INVITE_CODE_KEY,
      this.validatedInviteCode
    );
    // .catch(error => alertWarningError({ error, note: 'um.validateInvite' }));
    return result;
  }

  async resetInviteCode() {
    this.validatedInviteCode = null;
    await AppFactory.appStateCacher.remove(INVITE_CODE_KEY);
  }

  async storeLogglyEnabled(value: boolean) {
    return AppFactory.appStateCacher.storeObject(LOGGLY_ENABLED_KEY, value);
  }

  async loadLogglyEnabled(): Promise<boolean> {
    const result = await AppFactory.appStateCacher.fetchObject<boolean>(
      LOGGLY_ENABLED_KEY
    );
    return result ?? false;
  }
  // end from app-root

  get loadingData() {
    return this.apiInvoker.loadingData;
    // tried to use this to drive loading indicator just after logging in, but didn't seem to work
    // (this.userManager.authenticated && !this.userManager.loggedInAndReady)
  }

  //
  // WIP
  //
  // startGlobalDataListen() {
  //   AppFactory.globalDataSync.startListen((snapshot: GlobalData) => {
  //     log.debug(
  //       `global data listen - received snapshot: ${JSON.stringify(snap)}`
  //     );
  //     // simply always trigger the update check
  //     checkForUpdate().catch(error => alertWarningError({ error }));

  //     const { catalogUrl } = snapshot;
  //     if (catalogUrl) {
  //       this.storyManager
  //         .ensureCatalogUrl(catalogUrl)
  //         .catch(error => alertWarningError({ error }));
  //     }
  //   });
  // }

  // stopGlobalDataListen() {
  //   AppFactory.globalDataSync.stopListen();
  // }

  setReportingContext() {
    // todo: better way to turn the snapshot into a POJSO?
    // without the stringify/parse dance. my apiEnv patch below had no effect
    const globalConfigData = JSON.parse(
      JSON.stringify(getSnapshot(this.globalConfig))
    );
    // hack because apiEnv is a view instead of serialized property
    globalConfigData.apiEnv = this.globalConfig.apiEnv;

    const data: any = {
      // installationId: this.userManager.installationId,
      globalConfig: globalConfigData,
      userManager: this.userManager.reportingContextData,
      accountData: this.userManager.accountData.reportingContextData,
    };
    // data.accountData = getSnapshot(this.userManager.accountData);

    log.info(`setReportingContext`, data?.accountData?.email);

    log.debug(`minibus.emit SET_REPORTING_CONTEXT`);
    // this is synchronous and already subscribed by the time this executes
    // invokes error-reporting.setErrorReportingContext(data)
    // will update both bugsnag and analytics contexts
    minibus.emit('SET_REPORTING_CONTEXT', data);
  }
}

export interface FlashObj {
  messageKey: string;
}

export const getBaseRoot = (node: any): AppRoot => {
  const root = getRoot(node);
  if (root && root instanceof AppRoot) {
    return root;
  } else {
    // need fallback for soundbite stories loaded outside of catalog
    log.debug('using AppFactory.root');
    return AppFactory.root;
  }
};

if (window.location?.search?.includes('force-embedded=t')) {
  window.embeddedPlatform = 'ios';
}

if (window.location?.search?.includes('force-death=t')) {
  throw Error('testing hard error during module imports');
}
