import React from 'react';
import { ErrorBoundary } from 'components/error-boundary';

import * as ShowcaseHeaders from './pages/showcase-headings';
// import * as ShowcaseCode from './pages/showcase-code';
import * as ShowcaseTextStyles from './pages/showcase-text-styles';
import * as ShowcaseButtons from './pages/showcase-buttons';
import * as ShowcaseIconButtons from './pages/showcase-icon-buttons';
import * as ShowcaseSwitchButton from './pages/showcase-switch-button';
import * as ShowcaseMenus from './pages/showcase-menus';
import * as ShowcaseTooltips from './pages/showcase-tooltips';
import * as ShowcaseIcons from './pages/showcase-icons';
// import * as ShowcaseResponsiveInput from './pages/showcase-responsive-input';
import * as ShowcaseTags from './pages/showcase-tags';
// import * as VolumeCards from './pages/showcase-volume-cards';
// import * as VolumePage from './pages/showcase-volume-page';
import * as ShowcaseLayout from './pages/showcase-layout';
import * as ShowcaseStickyHeader from './pages/showcase-sticky-header';
import * as ShowcaseColumnLayout from './pages/showcase-column-layout';
import * as Toasts from './pages/showcase-toasts';
import * as Markdown from './pages/showcase-markdown';
import * as RichMessage from './pages/showcase-rich-message';
import * as KeyboardOverlay from './pages/showcase-keyboard-shortcuts';

import { Route, Link, Routes } from 'react-router-dom';
// import { includeProp, getColor } from '../../legacylib/theme/utils';
import { useSwitch } from '@naan/hooks/use-switch';
import { ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import { ArrowRightSmallIcon } from 'naan/icons/arrow-right-icon';

// import cx from 'classnames';

import { styled } from 'naan/stitches.config';

const Sidebar = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'flex-start',
  transform: 'translateX(-300px)',
  transitionProperty: 'transform',
  zIndex: 1,

  '& > nav': {
    height: '100vh',
    overflow: 'auto',
    width: 300,
    backgroundColor: '$black-alpha-90',
    display: 'flex',
    flexDirection: 'column',

    '& > a': {
      padding: '1rem',
      color: '$white-alpha-60',
      textStyle: 'body-bold',
      textDecoration: 'none',
      '&:hover': {
        color: '$white-alpha-100',
      },
    },
  },

  variants: {
    open: {
      true: {
        transform: 'translateX(0px)',
      },
    },
  },
});

type TShowcaseRoute = {
  Screen: () => JSX.Element;
  pathname: string;
  naanReady?: boolean;
  navlabel?: string;
};

const routes: TShowcaseRoute[] = [
  RichMessage,
  Markdown,
  KeyboardOverlay,
  ShowcaseTextStyles,
  ShowcaseIcons,
  ShowcaseHeaders,
  ShowcaseButtons,
  ShowcaseIconButtons,
  ShowcaseSwitchButton,
  ShowcaseMenus,
  ShowcaseTooltips,
  ShowcaseTags,
  ShowcaseLayout,
  ShowcaseColumnLayout,
  ShowcaseStickyHeader,
  Toasts,
];

export const ShowcaseScreen = () => {
  const [sidebar, { toggle }] = useSwitch(false);
  // let { path } = useRouteMatch();
  const path = '/showcase';

  // const root = AppFactory.root; // experimentally ensure creation before volume screen visited

  return (
    <>
      <ErrorBoundary>
        <Routes>
          {routes.map(route => (
            <Route
              key={route.pathname}
              path={route.pathname}
              element={
                <ErrorBoundary>
                  <route.Screen />
                </ErrorBoundary>
              }
            />
          ))}
        </Routes>
      </ErrorBoundary>
      <Sidebar open={sidebar}>
        <nav>
          {routes.map(route => (
            <Link
              key={`${path}${route.pathname}`}
              to={`${path}${route.pathname}`}
              onClick={toggle}
            >
              {route.navlabel}
              {route.naanReady ? ' 🆕' : null}
            </Link>
          ))}
        </nav>
        <button className="toggle" onClick={toggle}>
          {sidebar ? <ArrowLeftSmallIcon /> : <ArrowRightSmallIcon />}
        </button>
      </Sidebar>
    </>
  );
};
