import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Text } from '@naan/primitives/text';
import __ from '@core/lib/localization';
import { Link } from 'react-router-dom';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import { PrimaryFilterKeys } from '@core/models/story-manager/story-manager';

const Container = styled('div', {
  py: '$space$4',
  color: '$textSecondary',
});

const ShowAllLink = styled(Link, {
  color: '$textSecondary',
});

export const ZeroSearchResults = () => (
  <Container>
    <Text textStyle={'small-heading'}>
      {__('No results', 'search.noResults')}
    </Text>
    <div>
      Try another search term or{' '}
      <span>
        <ShowAllLink to={storiesPrimaryFiltered(PrimaryFilterKeys.ALL)}>
          show all stories
        </ShowAllLink>
      </span>
    </div>
  </Container>
);
