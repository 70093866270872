import { keyboardService } from 'lib/services/keyboard-service';
import { isStudyModel } from '../../models/player-model-handle';
import { PlayerModel } from 'player/models/player-model';
import React from 'react';
import { AppFactory } from '@app/app-factory';
import { toggleKeyboardOverlay } from 'components/ui/keyboard-help-overlay';
import { scrollToCurrentLine } from 'study/views/fx/scrolling';

export function usePlayerKeyboardControls(model: PlayerModel) {
  React.useEffect(() => {
    keyboardService.addShortcutSet('player', {
      space: () => model.playPauseAction(),
      escape: () => model.cancelPendingPause(),
      left: () => model.rewind(),
      right: () => model.forward(),
      up: () => model.seekPreviousLine(),
      down: () => model.seekNextLine(),
      r: () => model.replayCurrentSentence(),
      s: () => model.snailReplayCurrentSentence(),
      'equal, shift+equal, numpadadd': () => {
        model.player.adjustPlaybackRateAction(true);
      },
      'minus, shift+minus, numpadsubtract': () =>
        model.player.adjustPlaybackRateAction(false),
      'delete, backspace': () => model.toggleCurrentSentenceRedaction(),
      enter: () => model.replayCurrentSentence(),
      'shift+enter': () => model.snailReplayCurrentSentence(),
      'shift+tab': () => {
        if (isStudyModel(model)) {
          model.togglePlayerMode();
        }
      },
      tab: () => model.toggleTranslations(),
      // slash: () => model.toggleHelpOverlay(),
      'shift+backquote': () => model.toggleDebugMode(),
      o: () => model.toggleDebugOverlay(),
      q: () => model.debugReset(),
      i: () => model.debugToggleOnloadModal(),
      z: () => model.debugSeekToEnd(),
      x: () => model.debugSeekToFinalCard(),
      h: () => model.debugNavBack1(),
      l: () => model.debugNavForward1(),
      k: () => model.debugNavBack25(),
      j: () => model.debugNavForward25(),
      v: () => {
        if (isStudyModel(model)) {
          model.toggleNotationPanel();
          scrollToCurrentLine();
        }
      },
      'shift+period': () => AppFactory.root.storyManager.adjustCurrentDate(1),
      'shift+comma': () => AppFactory.root.storyManager.adjustCurrentDate(-1),
      'slash, shift+slash': () => toggleKeyboardOverlay(),
      'shift+r': () => window.location.reload(),
    });
    keyboardService.setCurrentShortcutSet('player');

    return () => {
      keyboardService.removeShortcutSet('player');
    };
  });
}
