import { action, makeObservable, observable } from 'mobx';

/// Lifter up the state because there was a rerender that was ruining the scrolling effect
class StoryDetailUiModel {
  public showTitleInHeader = true;

  constructor() {
    makeObservable(this, {
      showTitleInHeader: observable,
      setShowTitleInHeader: action,
    });
  }

  public setShowTitleInHeader(showTitleInHeader: boolean) {
    this.showTitleInHeader = showTitleInHeader;
  }
}

export const storyDetailUiModel = new StoryDetailUiModel();
