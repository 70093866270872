import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { useReaction } from '@common/hooks/use-reaction';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';
import { FurthestNotCurrent } from './furthest-not-current';
import { CurrentNotFurthest } from './current-not-furthest';
import { CurrentAndFurthest } from './current-and-furthest';
import { Completed } from './completed';
import { Unvisited } from './unvisited';
import { Locked } from './locked';

export const ChapterListItem = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    /// this reaction will scroll to the current chapter when it changes
    useReaction(
      () => chapter.isCurrent,
      isCurrent => {
        if (isCurrent) {
          setTimeout(() => {
            scrollToCurrentChapter(true);
          }, 300);
        }
      }
    );

    // temporary short-circuit of catalog data without jwnext ingestion
    if (!chapter.playerDataUrl) {
      return (
        <ChapterListItemPresentationWrapper
          chapter={chapter}
          number={chapter.position}
          title={chapter.title}
        />
      );
    }

    if (chapter.unstarted) {
      return <Locked chapter={chapter} />;
    }

    if (chapter.isCurrent) {
      if (chapter.isFurthest) {
        return <CurrentAndFurthest chapter={chapter} />;
      } else {
        return <CurrentNotFurthest chapter={chapter} />;
      }
    } else {
      // not current
      if (chapter.isFurthest) {
        return <FurthestNotCurrent chapter={chapter} />;
      }
      if (chapter.isCompleted) {
        return <Completed chapter={chapter} />;
      }
      return <Unvisited chapter={chapter} />;
    }
  }
);
