import deepmerge from 'deepmerge';
import { config as baseConfig } from './my-preprod';

//
// staging.jiveworld.app
//
// npm start deploy.staging
//

export const config = deepmerge(baseConfig, {
  // pwaCheckIntervalMs: 1 * 60 * 1000, // 1 min check interval for devtest and preview deploys
  // showUnhandledErrors: true,

  // TODO: support cross domain cookies with new TLD
  // cookieDomain: '.jiveworld.app',

  // TODO - deploy new marketing site
  website: {
    urls: {
      marketingSite: 'https://staging.jiveworld.com',
    },
  },
});
