import React from 'react';
import { styled } from 'naan/stitches.config';
import { ExternalLink } from '@naan/primitives/text/external-link';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  '& a': {
    color: 'inherit',
    textDecoration: 'underline',
  },
});

export const AuthSmallPrint = () => {
  return (
    <Wrapper>
      By creating an account, you accept our:
      <br />
      <ExternalLink href="https://www.jiveworld.com/terms-of-service">
        Terms&nbsp;of&nbsp;Service
      </ExternalLink>
      {', '}
      <ExternalLink href="https://www.jiveworld.com/privacy">
        Privacy&nbsp;Policy
      </ExternalLink>
      {', '}
      <ExternalLink href="https://www.jiveworld.com/eula">
        User&nbsp;License&nbsp;Agreement
      </ExternalLink>
    </Wrapper>
  );
};
