import { appConfig } from 'app/env';
import { createLogger } from '@common/log';

const log = createLogger('app-util');

export const appSlug = () => appConfig.appSlug;

export const jwMode = () => appSlug() === 'jw'; // wondery
export const eslBetaMode = () => appSlug() === 'esl'; // bolero
export const lupaMode = () => !jwMode(); // a bit confusing, but should also be true for the eslbeta build for now

// export const v4CatalogMode = () => jwMode();

export const appName = () => appConfig.appName;

// export const simulateIap = () => appConfig.simulateIap;

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ReactNativeWebView: any;
    embeddedPlatform: string;
    embeddedBuildNumber: string;
  }
}

// const w = window; // as Window; // any

export const embeddedPlatform = () => window.embeddedPlatform;
export const embeddedBuildNumber = () => window.embeddedBuildNumber;

export const embeddedMode = () => {
  return /(ios|android)/.test(embeddedPlatform());
};

// dev tools support
export const setEmbeddedPlatform = (platform: string) => {
  window.embeddedPlatform = platform;
};

export const postNativeMessage = ({
  type,
  payload,
  data,
}: {
  type: string;
  payload?: any;
  data?: any;
}) => {
  log.info(`postNativeMessage: ${type}, ${payload}, ${JSON.stringify(data)}`);
  if (!window.ReactNativeWebView) {
    log.error(`ReactNativeWebView unexpectedly missing`);
  } else {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        type,
        payload,
        data,
      })
    );
  }
};
