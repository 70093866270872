import React from 'react';
import useSWRImmutable from 'swr/immutable';
// @*ts-expect-error
// import { versionCheck } from '@jw-spa-version';
// import { legacyPollForUpdate } from 'lib/legacy-update-checker';
import { useSyncOnForeground } from '@common/hooks/use-sync-on-foreground';
import {
  version,
  curEnv,
  commit,
  buildTimestampIso,
  // @ts-expect-error
} from '@jw-spa-version';
import { SpaRoutes } from './routes';
import { firestoreDb } from '@core/services/firebase-init';
import { FirestoreInvokerImpl } from '@core/services/firestore-invoker-impl';
import { AppRoot } from '@core/models/app-root';
import { AppFactory } from '@app/app-factory';
import { alertSevereError } from '@app/notification-service';
import { FullScreenError } from 'components/error-boundary';
import { FullScreenLoader } from 'components/ds/modals';
import { createLogger } from '@common/log';
import { useOfflineAwareness } from '@common/hooks/use-offline-awareness';
// import { GlobalDataSyncImpl } from '@core/services/global-data-sync-impl';
// import { getLegacyCheckerEnabled } from 'index';

const log = createLogger('app');

const buildTimestampLocal = new Date(buildTimestampIso).toString();

log.info(`${curEnv}; ${version}; ${commit}; ${buildTimestampLocal}`);

const initializeAppRoot = async (dummy: string): Promise<AppRoot> => {
  log.info('initializeAppRoot');
  if (AppFactory.firestoreInvoker) {
    log.error('firestoreInvoker unexpectedly already initialized');
  } else {
    // make sure firestore is instantiated before the AppRoot
    const firestoreInvoker = new FirestoreInvokerImpl({ db: firestoreDb });
    AppFactory.setFirestoreInvoker(firestoreInvoker);
  }

  //
  // WIP
  //
  // if (AppFactory.globalDataSync) {
  //   log.error('globalDataSync unexpectedly already initialized');
  // } else {
  //   const sync = new GlobalDataSyncImpl({
  //     db: firestoreDb,
  //     deploymentEnv: curEnv,
  //   });
  //   AppFactory.setGlobalDataSync(sync);
  // }

  if (AppFactory.root) {
    log.error('app root unexpectedly already initialized');
  } else {
    const root = AppRoot.create({});
    await root.appInitialize();
    AppFactory.setRoot(root);
    (window as any).root = root;
  }
  return AppFactory.root;
};

export const App: React.FC = () => {
  // without the 'immutable' flavor SWR was refreshing the data upon on evey tab focus
  let { data: root, error } = useSWRImmutable('run-once', initializeAppRoot);

  if (error) {
    alertSevereError({ error, note: 'AppRootWrapper' });
    return <FullScreenError />;
  }

  if (!root) {
    return <FullScreenLoader />;
  }

  return <AppBody />;
};

const AppBody = () => {
  useOfflineAwareness();
  useSyncOnForeground();

  return <SpaRoutes />;
};

export default App;
