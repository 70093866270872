import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { Root } from '../root';
import { getBaseRoot } from '../app-root';
import { VideoGuideEngagementStatus } from '../user-manager/video-guide-engagement';

export class VideoGuide extends ModelTreeNode {
  static CLASS_NAME = 'VideoGuide' as const;

  static create(snapshot: any) {
    return super.create(VideoGuide, snapshot) as VideoGuide;
  }

  slug: string = ''; // identifier
  title: string = null;

  videoIdLandscape: string; // Wistia id
  imageUrlLandscape: string; // raw GCS hosted path
  videoIdPortrait: string;
  imageUrlPortrait: string;
  durationSeconds: number;
  position: number; // sorting already handled server side right now

  get root(): Root {
    return getBaseRoot(this);
  }

  // for now assume all videos are onboarding and dashboard featured
  get featured(): boolean {
    return true;
  }

  get onboarding(): boolean {
    return true;
  }

  get engagementStatus(): VideoGuideEngagementStatus {
    return this.root.userManager.userData.videoGuideUserData.statusBySlug(
      this.slug
    );
  }

  get completed(): boolean {
    return this.engagementStatus !== VideoGuideEngagementStatus.UNPLAYED;
  }
}
