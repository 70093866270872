import * as React from 'react';
import { Text } from 'naan/primitives/text';
import { styled } from 'naan/stitches.config';
import { Story } from 'core/models/story-manager';
import { VSpacer } from '@naan/primitives/spacer';

const StoryCardMetaWrapper = styled('div', {
  marginBottom: '$1',
  lineHeight: '20px',
  textStyle: 'small-text',
  '& > * + *': {
    '&::before': {
      // content: '"•"',
      marginLeft: '$space$1',
      marginRight: '$space$1',
      color: '$gray-200',
      cursor: 'default',
    },
  },
});

export const StoryCardMeta = ({ story }: { story: Story }) => {
  const currentChapter = story.progressMayBeNull?.currentChapter;
  const metaText = currentChapter
    ? currentChapter.partChapterLabel
    : story.durationDescription;

  return (
    <StoryCardMetaWrapper>
      <Text as="span" textStyle={'small-text'} color={'textSecondary'}>
        {metaText}
      </Text>
      {currentChapter ? <VSpacer size="3" /> : null}
    </StoryCardMetaWrapper>
  );
};
