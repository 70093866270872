import * as React from 'react';
// import { EnsureAuth } from 'components/ensure-auth';
import { Outlet } from 'react-router-dom';
import { FullScreenLoader } from 'components/ds/modals';
import { LoadingScreen } from 'components/ui/loading-screen';
// import { usePageTracker } from 'lib/hooks/use-page-tracker';
import { useQueryMessageDispatcher } from 'components/account/use-query-message-dispatcher';
// import { EnsureReady } from 'components/ensure-ready';

export const SignedInRouteLayout = ({
  /// this allows us to have the signed layout, but different design layout
  component: Component = null /*required now*/,
}) => {
  // usePageTracker();
  useQueryMessageDispatcher();

  return (
    <>
      {/* <EnsureReady> */}
      {/* <EnsureAuth> */}
      <Component>
        <React.Suspense fallback={<FullScreenLoader />}>
          <Outlet />
        </React.Suspense>
      </Component>
      {/* </EnsureAuth> */}
      {/* </EnsureReady> */}
      <LoadingScreen />
    </>
  );
};
