import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { learnProgressPath } from 'components/nav/path-helpers';
import { PageHeading } from 'components/page-heading';
import { TotalPoints } from 'components/ui/total-points';
import { styled } from '@naan/stitches.config';
import __ from '@core/lib/localization';
import { isEmpty } from 'lodash';

const PointsLink = styled('a', {
  textDecoration: 'none',
});

export const LearnDashboardHeading = observer(() => {
  const { userManager } = AppFactory.root;
  const { accountData, userData } = userManager;

  const title =
    // todo: make sure accountData properly reset when logged out
    userManager.authenticated && !isEmpty(accountData.name)
      ? __('Hola, %{name}', 'dashboard.holaName', {
          name: accountData.name,
        })
      : __('¡Hola!', 'dashboard.hola');

  // for some reason it's important to reference userData.totalPoints here.
  // when inlined into the component params, it wasn't reacting after logout.
  const totalPoints = userData.totalPoints;

  return (
    <PageHeading
      title={title}
      renderAccessory={() => (
        <PointsLink href={learnProgressPath()}>
          <TotalPoints points={totalPoints} />
        </PointsLink>
      )}
      showAccessoryInSmall={true}
    />
  );
});
