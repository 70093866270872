import * as React from 'react';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';
import { Text } from '@naan/primitives/text';
import { Assignment } from '@core/models/user-manager/assignment';
import { notEmpty } from '@utils/conditionals';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { AppFactory } from '@app/app-factory';
import { AssignmentDetailsDialog } from './assignment-details-dialog';
import { CenterColumnLayout } from '../layouts/center-column-layout';
import { Sticky } from '../story-detail/sticky.styles';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: '$yellow-150',
  padding: '$space$3 0 $space$3 0',
  borderRadius: '8px',
  marginBottom: '$space$4',
  justifyContent: 'space-between',
  '@medium': {
    justifyContent: 'flex-start',
  },
  '& > .details-button': {
    '@medium': {
      marginLeft: '$space$4',
    },
  },
  variants: {
    sticky: {
      true: {
        marginBottom: 0,
        backgroundColor: '$white',
      },
    },
  },
});

const StickyWrapper = styled(Sticky, {
  borderBottom: '1px solid $gray-100',
  backgroundColor: '$white',
  top: '$$smallHeaderHeight',
  '@medium': {
    top: '$$mediumHeaderHeight',
  },
  variants: {
    belowStoryStatus: {
      true: {
        top: 'calc($$smallHeaderHeight + $$storyStatusHeight)',
        '@medium': {
          top: '$$mediumHeaderHeight',
        },
      },
    },
  },
});

export const StoryDetailCardAssignment = observer(
  ({ assignment, sticky }: { assignment: Assignment; sticky?: boolean }) => {
    if (isNil(assignment)) return null;

    const story = assignment.story;
    const { progress } = story;

    const assignmentDateText = notEmpty(assignment.displayDueDateShort)
      ? `Due ${assignment.displayDueDateShort}`
      : null;
    const assignmentInfo = notEmpty(assignmentDateText)
      ? assignmentDateText + ' • ' + assignment.classroom?.label
      : assignment.classroom?.label;
    const showDetailsButton = notEmpty(assignment.details);

    const showDetails = React.useCallback(() => {
      AppFactory.dialogPresenter.present(onDismiss => (
        <AssignmentDetailsDialog
          assignment={assignment}
          onDismiss={onDismiss}
        />
      ));
    }, [assignment]);

    const StickyWrapperIfNeeded = observer(
      ({ children }: { children: any }) => {
        return sticky ? (
          <StickyWrapper belowStoryStatus={progress.started}>
            <CenterColumnLayout>{children}</CenterColumnLayout>
          </StickyWrapper>
        ) : (
          children
        );
      }
    );

    return (
      <StickyWrapperIfNeeded>
        <Container sticky={sticky}>
          <Text
            textStyle={'body-bold'}
            color={assignment.isPastDueDate ? 'textSecondary' : 'textPrimary'}
          >
            {assignmentInfo}
          </Text>
          {showDetailsButton ? (
            <div className="details-button">
              <Button
                label="Details"
                onClick={showDetails}
                presentation={assignment.isPastDueDate ? 'grayLight' : 'yellow'}
                size={'small'}
              />
            </div>
          ) : null}
        </Container>
      </StickyWrapperIfNeeded>
    );
  }
);
