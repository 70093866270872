import * as React from 'react';

import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { Button } from 'naan/primitives/button';
import { FormError } from 'naan/primitives/forms/form-error';
import { VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { AuthHeading } from 'components/auth/auth-heading';
import { useComboForm } from 'common/hooks/use-comboform';
import { Label } from 'naan/primitives/forms/label';
import { Input } from 'naan/primitives/input/input';
import { FNAME_MAX_CHAR_LIMIT } from 'core/lib/constants/vars';
import { CatchAllFormErrors } from '@naan/primitives/forms/catch-all-form-error';
import { omit } from 'lodash';
import { ToastService } from '@common/notifications/toast-service';
import { Box } from '@naan/primitives/box';

import __ from 'core/lib/localization';

// const SmallPrint = styled('div', {
//   textStyle: 'small-text',
//   color: '$textSecondary',
//   '& a': {
//     color: '$teal-500',
//   },
// });

type FormInput = {
  code: string;
};

export const InviteGate = observer(() => {
  const { root } = AppFactory;

  const {
    register,
    handleSubmit,
    errors,
    clearValidationErrors,
    handleValidationErrors,
  } = useComboForm<FormInput>({});

  const handleValidate = React.useCallback(
    ({ code }: FormInput) => {
      clearValidationErrors();
      root.validateInvite(code).then(result => {
        ToastService.open({
          message: result.message,
          type: 'success',
        });
      }, handleValidationErrors);
    },
    [root, clearValidationErrors, handleValidationErrors]
  );

  return (
    <Box
      css={{
        margin:
          'env(safe-area-inset-top) auto env(safe-area-inset-bottom) auto',
        paddingTop: 104,
        paddingBottom: 104,
        width: 'min( 400px, calc(100% - 32px) )',
        '@medium': {
          paddingTop: 80,
          paddingBottom: 88,
        },
      }}
    >
      <VStack>
        <AuthHeading>
          <Text textStyle="extra-large-heading">Lupa Web Beta</Text>
        </AuthHeading>
        <form onSubmit={handleSubmit(handleValidate)}>
          <VStack>
            <Label htmlFor="name">
              {__('Invitation code', 'auth.inviteCode')}
            </Label>
            <VSpacer size={2} />

            <Input
              name={'code'}
              type="text"
              {...register('code')}
              required
              maxLength={FNAME_MAX_CHAR_LIMIT}
            />
            <FormError error={errors['code']} />

            <VSpacer size={6} />
            {/* // make sure invite code error is shown if mismatch between client config and server config */}
            {/* // consider always including, but making the invite code conditionaly omitted */}
            <CatchAllFormErrors
              errors={omit(errors, ['code'])}
              data-test-id="error-message"
            />

            <div style={{ width: '100%', display: 'flex' }}>
              <Button
                label={__('Validate', 'auth.validate')}
                onClick={handleSubmit as any}
                presentation="teal"
                size="large"
                css={{ flex: 1 }}
              />
            </div>
          </VStack>
        </form>

        {/* <LargeGap /> */}
        {/* <AuthSmallPrint /> */}
      </VStack>
    </Box>
  );
});
