import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Illustration } from './illustration';
import { IconButton } from 'naan/primitives/button';
import { CloseIcon } from 'naan/icons/close-icon';
import { useWelcomeMessage } from './use-welcome-message';
import { observer } from 'mobx-react';

const WelcomeMessageWrapper = styled('div', {
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  background: '$black-alpha-20',
  padding: '$space$6',
  zIndex: 2000,
  '@large': {
    position: 'static',
    padding: 0,
    pt: '$space$6',
    background: '$transparent',
  },
  '@extraLarge': {
    margin: '0 auto',
    width: '960px',
  },
});

const WelcomeMessageBox = styled('div', {
  background: '$yellow-200',
  borderRadius: '$space$3',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'calc($space$1 * 64)',
  overflow: 'hidden',

  '@medium': {
    overflow: 'auto',
  },

  '@large': {
    paddingRight: '50%',
  },
  '& > .copy': {
    padding: '$space$3',
    overflow: 'hidden',
    '@large': {
      padding: '$space$6',
    },
  },
  '& > .button-container': {
    position: 'absolute',
    right: '$space$1',
    top: '$space$1',
  },
  h2: {
    all: 'unset',
    textStyle: 'small-heading',
    '@large': {
      textStyle: 'medium-heading',
    },
  },
  '& p': {
    marginTop: '$space$3',
  },
  '& > svg': {
    // all: 'unset',

    '@large': {
      position: 'absolute',
      right: '16px',
      bottom: '0',
      maxWidth: '50%',
    },
  },
});

export const TeachWelcomeMessage = observer(() => {
  // hook now handles the newsletter prompt guard
  const { show, onClose } = useWelcomeMessage();

  if (!show) {
    return null;
  }

  return (
    <WelcomeMessageWrapper>
      <WelcomeMessageBox>
        <div className="copy" data-test-id="classroom-banner">
          <h2>Welcome to Lupa Classrooms</h2>
          <div>
            <p>
              Browse the stories below, and when you find one you’d like to
              teach with, click Assign to class. We offer two free stories, so
              you can get started with your students quickly and without any
              cost.
            </p>
            {/* <p>
              And don’t forget to download the app – Lupa is currently
              mobile-only. Visit get.lupa.app on your mobile device.
            </p> */}
          </div>
        </div>
        <div className="button-container">
          <IconButton
            aria-label="dismiss welcome message"
            icon={<CloseIcon />}
            css={{
              color: '$yellow-800',
            }}
            onClick={onClose}
          />
        </div>
        <Illustration />
      </WelcomeMessageBox>
    </WelcomeMessageWrapper>
  );
});
