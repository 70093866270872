import { compact, pick } from 'lodash';

import { createLogger } from 'app/logger';
import {
  applySnapshot,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';

import {
  END_OF_STORY_CHAPTER,
  END_OF_VOLUME_UNIT,
} from 'core/lib/constants/vars';

import { ChapterRef, LocationPointer } from './location-pointer';
import { Story } from '../story-manager';
import { Root } from '../root';
import { /*IObservableArray,*/ runInAction } from 'mobx';
import { ListeningStats } from './listening-stats';
import { getBaseRoot } from '../app-root';
import { ChapterCatalogData, UnitCatalogData } from '../catalog';
import { ListeningLog } from './listening-log';
import { hasBogotaVocabSlugs } from '../story-manager/story';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('um:story-progress');

// the manually managed story states which are exclusive from each other
export const enum StoryState {
  // eslint-disable-next-line no-unused-vars
  UNQUEUED = 'UNQUEUED',
  // eslint-disable-next-line no-unused-vars
  QUEUED = 'QUEUED',
  // eslint-disable-next-line no-unused-vars
  STARTED = 'STARTED', // assigned when 'Begin studying' performed; spans both "in progress" and "completed"
}

/**
 * StoryProgress
 *
 * holds user's listening progress of a particular story
 */
export class StoryProgress extends ModelTreeNode {
  static CLASS_NAME = 'StoryProgress' as const;

  static create(snapshot: any) {
    return super.create(StoryProgress, snapshot) as StoryProgress;
  }

  slug: string;
  storyState: StoryState; // the manually managed story states which are exclusive from each other
  currentPoint: LocationPointer = snap({});
  furthestPoint: LocationPointer = snap({});

  vocabMap: TSTStringMap<boolean> = snap({}); // conceptually a set, holds notation id's
  vocabs?: string[]; // deprecated, legacy schema; word group slugs, i.e. "765-cruzar" (millis/100 + first word)

  lastListened: number = 0; // millis since epoch

  // counter used to prevent duplicate logging of session listening stats
  // at the start of a session, the next counter is fetched
  // when recording progress the fetched counter will be compared to this
  // if greater, then record and update the persisted counter
  // if less or equal, then ignore
  lastRecordedSessionCounter: number = 0;

  get root(): Root {
    return getBaseRoot(this);
  }

  get story(): Story {
    const { storyManager } = this.root;
    if (!storyManager) return null;
    return storyManager.story(this.slug);
  }

  get currentUnit(): UnitCatalogData {
    // if (this.currentPoint.atEndOfStory) {
    if (this.completed) {
      return null;
    }
    const unitNumber = this.currentPoint.unit;
    return this.story.unitDataByNumber(unitNumber);
  }

  get currentChapter(): ChapterCatalogData {
    if (this.storyState === StoryState.STARTED) {
      return this.story?.chapterForPoint(this.currentPoint);
    } else {
      return null;
    }
  }

  get furthestChapter(): ChapterCatalogData {
    return this.story?.chapterForPoint(this.furthestPoint);
  }

  // when current chapter is before furthest chapter
  get inReviewMode(): boolean {
    return !this.furthestPoint.matchesChapter(this.currentPoint);
  }

  get notEmpty(): boolean {
    return (
      (this.storyState && this.storyState !== StoryState.UNQUEUED) ||
      this.currentPoint?.played ||
      this.furthestPoint?.played ||
      (this.vocabMap.size || 0) > 0 ||
      (this.vocabs?.length || 0) > 0 ||
      this.lastListened > 0
    );
  }

  get isEmpty(): boolean {
    return !this.notEmpty;
  }

  get hasVolumeSlug(): boolean {
    const { storyManager } = this.root;
    return !!storyManager.story(this.slug);
  }

  // probably not useful
  get hasUnitSlug(): boolean {
    const { storyManager } = this.root;
    return !!storyManager.storyForUnitSlug(this.slug);
  }

  // progress record with a unit slug not matching a story slug.
  // legacy data which needs merging into story level progress record
  get needsMerge(): boolean {
    const { storyManager } = this.root;
    const story = storyManager.storyForUnitSlug(this.slug);
    return story && story.slug !== this.slug;
  }

  get needsVocabMigration(): boolean {
    return hasBogotaVocabSlugs(this.vocabs); // || hasBogotaVocabSlugs(this.vocabList);
  }

  get hasBogotaVocabSlugs(): boolean {
    return (
      hasBogotaVocabSlugs(this.vocabs) || hasBogotaVocabSlugs(this.vocabList)
    );
  }

  get valid(): boolean {
    return this.hasVolumeSlug; // || this.hasUnitSlug;
  }

  get orphaned(): boolean {
    return !this.valid;
  }

  updateCurrentPoint(point: LocationPointer, updateLastListened = false) {
    log.info(
      `updateCurrentPoint - new point: ${point.sortableString}, old furthest: ${this.furthestPoint.sortableString}}`
    );
    runInAction(() => {
      applySnapshot(this.currentPoint, point);
      if (this.furthestPoint.isLessThan(point)) {
        log.info(`new furthest point: ${point.sortableString}`);
        applySnapshot(this.furthestPoint, point);
      }
      if (updateLastListened) {
        this.lastListened = Date.now(); // millis since epoch
      }
    });
  }

  // fetched at start of session
  get nextSessionCounter(): number {
    return (this.lastRecordedSessionCounter || 0) + 1;
  }

  // confirms that we have the expected session counter to record
  isNextSessionCounter(counter: number) {
    return counter === this.nextSessionCounter;
  }

  // invoked when session recorded
  incrementNextSessionCounter() {
    this.lastRecordedSessionCounter = this.nextSessionCounter;
  }

  recordSession({
    chapterRef,
    sessionCounter,
    completionReached,
    furthestMillis,
  }: // assumes vocab and player settings transiently updated along the way, will get persisted now
  {
    chapterRef: ChapterRef;
    sessionCounter: number;
    completionReached: boolean;
    furthestMillis: number;
  }) {
    log.debug(
      `recordSession chap: ${chapterRef.unit}-${chapterRef.chapter}, sessionCounter: ${sessionCounter}, completed: ${completionReached}, furthestMillis: ${furthestMillis}; lrsc: ${this.lastRecordedSessionCounter}`
    );
    const { userManager } = this.root;

    // log.info(`old cp: ${this.currentPoint.sortableString}`);
    if (!this.isNextSessionCounter(sessionCounter)) {
      log.error(`recordSession w/ mismatched sessionCounter - mostly ignoring`);
      userManager.persistUserData().catch(bugsnagNotify); // make sure latest vocab and settings are saved out
      return;
    }
    this.incrementNextSessionCounter(); // prevents double counting session

    if (this.storyState !== StoryState.STARTED) {
      log.warn(`unexpected storyState: ${this.storyState}`);
      this.setStoryState(StoryState.STARTED);
    }

    log.trace(
      `current chapter: ${this.currentChapter?.unitNumber}/${this.currentChapter?.position}`
    );
    // can currently happen when listening after story completed
    log.warn('updateProgress - no currentChapter');

    // @jason, better way to clone?
    const newPoint = LocationPointer.create(this.currentPoint.snapshot);

    if (!this.currentChapter || !this.currentChapter.matchesPoint(chapterRef)) {
      // can happen when deep linking to a particular chapter
      log.warn('progress unexpectedly recorded against non-current chapter');
      // do our best to repair the unexpected state
      const presumedIteration =
        this.currentChapter && this.currentChapter.isBefore(chapterRef) ? 1 : 3;
      newPoint.setChapterRef(chapterRef);
      newPoint.setIteration(presumedIteration);
    }

    let automaticallyMarkComplete = false;

    if (completionReached) {
      userManager.userData.addListeningLog({
        storySlug: this.slug,
        millis: furthestMillis,
      });

      newPoint.incrementIteration();

      // only automatically advance when in review mode. otherwise, explicit user action needed
      if (newPoint.completedIteration && this.inReviewMode) {
        automaticallyMarkComplete = true;
      }
    } else {
      newPoint.setMillisPlayed(furthestMillis);
    }

    this.updateCurrentPoint(newPoint, true /* updateLastListened */);

    // could be optimized into a single operation
    if (automaticallyMarkComplete) {
      log.debug('automatically marking complete');
      this.markCurrentChapterComplete(); // persists
    } else {
      userManager.persistUserData().catch(bugsnagNotify); // async
    }
  }

  get vocabList(): string[] {
    return Array.from(this.vocabMap.keys());
  }

  vocabExists(slug: string): boolean {
    return this.vocabMap.has(slug);
  }

  addVocab(slug: string): void {
    if (!this.vocabExists(slug)) {
      this.vocabMap.set(slug, true);
    }
  }

  addVocabs(slugs: string[] = []): void {
    log.info(`addVocabs[${slugs}]`);
    for (const slug of slugs) {
      this.addVocab(slug);
    }
  }

  removeVocab(slug: string): void {
    log.info(`removeVocab(${slug})`);
    // should we check it exists?
    // (this.vocabs as IObservableArray).remove(slug);
    this.vocabMap.delete(slug);
  }

  clearVocabs(): void {
    log.info('clearVocabs');
    // (this.vocabs as IObservableArray).clear();
    // applySnapshot(this.vocabMap, {});
    this.vocabMap.clear();
  }

  removeVocabs(slugs: string[] = []) {
    log.info(`removeVocabs[${slugs}]`);
    runInAction(() => {
      for (const slug of slugs) {
        this.removeVocab(slug);
      }
    });
  }

  /**
   * remove any vocabs now found in current story data
   */
  pruneOrphanVocabs(): void {
    const vocabLookupData = this.story.vocabLookupData;
    const orphans = this.vocabList.filter(slug => {
      return !(vocabLookupData as any)[slug]; // TODO
    });
    if (orphans.length > 0) {
      log.info(`removing orphaned vocabs: ${JSON.stringify(orphans)}`);
      this.removeVocabs(orphans);
    }
  }

  markStoryComplete() {
    const location = {
      unit: END_OF_VOLUME_UNIT,
      chapter: END_OF_STORY_CHAPTER,
    };
    this.storyState = StoryState.STARTED;
    this.currentPoint = LocationPointer.create(location);
    this.furthestPoint = LocationPointer.create(location);
    // this.setStoryStatus(StoryStatus.COMPLETED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
    this.story.ensureCacheState().catch(bugsnagNotify); // async
  }

  resetStory() {
    this.setStoryState(StoryState.UNQUEUED);
    this.currentPoint = LocationPointer.create({});
    this.furthestPoint = LocationPointer.create({});
    this.clearVocabs();
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
    this.story.ensureCacheState().catch(bugsnagNotify); // /async
  }

  unlockChapter(chapterRef: ChapterRef): void {
    this.markCompleteChapter(this.story.priorChapterRef(chapterRef));
  }

  reviewChapter(chapterRef: ChapterRef): void {
    this.currentPoint = LocationPointer.create({
      unit: chapterRef.unit,
      chapter: chapterRef.chapter,
      iteration: 3,
    });
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  restartAtChapter(chapterRef: ChapterRef): void {
    this.currentPoint = LocationPointer.create({ ...chapterRef, iteration: 1 });
    this.furthestPoint = this.currentPoint;
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  resumeStudy(): void {
    this.currentPoint = this.furthestPoint; // should we clone it?
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  markCurrentChapterComplete(): void {
    this.markCompleteChapter(this.currentPoint);
  }

  // should probably only ever be used with current chapter
  // also used by "skip to chapter"
  markCompleteChapter(chapterRef: ChapterRef): void {
    const nextChapterRef = this.story.nextChapterRef(chapterRef);
    if (!nextChapterRef) {
      this.markStoryComplete();
      return;
    }

    const iteration = this.furthestPoint.beforeChapter(nextChapterRef) ? 1 : 3;
    const locationData = { ...nextChapterRef, iteration }; // default values are fine for the rest of the properties

    if (this.furthestPoint.matchesChapter(nextChapterRef)) {
      log.info(`advancing to furthest chapter - resuming from furthest point`);
      this.currentPoint = this.furthestPoint;
    } else {
      runInAction(() => {
        this.currentPoint = LocationPointer.create(locationData);
        if (this.furthestPoint.isLessThan(this.currentPoint)) {
          this.furthestPoint = LocationPointer.create(locationData);
        }
      });
    }
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  get unplayed(): boolean {
    return !this.played;
  }

  get played(): boolean {
    return this.furthestPoint?.played;
  }

  get unstarted(): boolean {
    return !this.started;
  }

  // spans both 'in progress' and 'completed' status
  get started(): boolean {
    return this.storyState === StoryState.STARTED;
  }

  get queued(): boolean {
    return this.storyState === StoryState.QUEUED;
  }

  get unqueued(): boolean {
    return this.storyState === StoryState.UNQUEUED || !this.storyState;
  }

  get completed(): boolean {
    return this.furthestPoint?.chapter === END_OF_STORY_CHAPTER;
  }

  get currentlyAtEnd(): boolean {
    return this.currentPoint?.chapter === END_OF_STORY_CHAPTER;
  }

  get unitCount(): number {
    return this.story?.unitCount ?? 0; // don't barf if missing story
  }

  get inProgress(): boolean {
    // return this.started && !this.completed;
    /// tentatively change to include "In review" stories also
    return this.started && !this.currentlyAtEnd;
  }

  get completedChapters(): number {
    // const chaptersCompleted = progress.storyProgress?.furthestPoint ? progress.storyProgress.furthestPoint.chapter + progress.storyProgress.furthestPoint.iteration - 2 : null;
    // if (this.furthestPoint?.chapter && this.furthestPoint?.iteration) {
    //   return this.furthestPoint.chapter + this.furthestPoint.iteration - 2;
    // } else {
    //   return 'n/a';
    // }
    // return this.furthestPoint?.completedChapters;
    return this.story?.countChaptersBefore(this.furthestPoint) || 0; // don't barf if classroom points to missing story
  }

  get displayProgress(): string {
    // todo: localize
    // todo: figure out why this.story is sometimes undefined
    return `${this.completedChapters}/${this.story?.chapterCount} chapters complete`;
  }

  setStoryState(state: StoryState) {
    log.debug(`setStoryState: ${state}`);
    this.storyState = state;
  }

  // todo: reconsider if this state should be denormalized or not
  // migrate bogota progress data structure
  migrateStoryState(): boolean {
    if (this.storyState) {
      // don't touch if we already have an explicit status value
      return;
    }

    const deduced = this.deducedStoryState;
    if (this.storyState !== deduced) {
      log.info(`migrateStoryState: ${this.slug} -> ${deduced}`);
      this.setStoryState(deduced);
      return true;
    } else {
      return false;
    }
  }

  get deducedStoryState(): StoryState {
    if (this.furthestPoint.played) {
      return StoryState.STARTED;
    } else {
      return StoryState.UNQUEUED;
    }
  }

  async transformUnitProgress() {
    const { storyManager } = this.root;
    // this.transformVocabListToMap(); // if we do this first, then we need to remove the old slugs after mapped to new slugs

    const unit = storyManager.unitForSlug(this.slug);
    if (unit) {
      this.currentPoint.unit = unit.unitNumber;
      this.furthestPoint.unit = unit.unitNumber;

      if (this.needsVocabMigration) {
        const newSlugs = await unit.fetchDataAndMigrateVocabSlugs(
          this.vocabs // use old schema as source data
        );
        this.addVocabs(newSlugs);
      }
    } else {
      log.error(
        `transformUnitProgress - failed to match unit for slug: ${this.slug}`
      );
    }
    // this.migrateStoryState(); // handled when merged into main record
  }

  mergeProgressData(data: StoryProgress) {
    log.info(`mergeProgressData: ${JSON.stringify(data.snapshot)}`);
    this.addVocabs(data.vocabList); // assume already transformed to new schema
    const { unitCount, slug } = this.story;
    data.currentPoint.normalizePointer({
      unitCount,
      slug,
    });
    data.furthestPoint.normalizePointer({
      unitCount,
      slug,
    });
    if (this.currentPoint.isLessThan(data.currentPoint)) {
      // @jason, what's the cleanest way to copy this data over?
      applySnapshot(this.currentPoint, data.currentPoint.snapshot);
    }
    if (this.furthestPoint.isLessThan(data.furthestPoint)) {
      applySnapshot(this.furthestPoint, data.furthestPoint.snapshot);
    }
    if (data.lastListened ?? -1 > this.lastListened ?? 0) {
      this.lastListened = data.lastListened;
    }
    this.migrateStoryState();
  }

  // transformVocabListToMap() {
  //   if (this.vocabs) {
  //     runInAction(() => {
  //       applySnapshot(this.vocabMap, {});
  //       for (const slug of this.vocabs) {
  //         this.vocabMap.set(slug, true);
  //       }
  //       this.vocabs = undefined;
  //     });
  //   } else {
  //     log.error(`transformVocabListToMap - 'vocabs' prop unexpectedly missing`);
  //   }
  // }

  beginStudying() {
    this.setStoryState(StoryState.STARTED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async - rethink this
    this.story.ensureCacheState().catch(bugsnagNotify); // async
  }

  toggleStudyLater() {
    if (this.unqueued) {
      this.markStudyLater();
    } else {
      this.unmarkStudyLater();
    }
  }

  markStudyLater() {
    this.setStoryState(StoryState.QUEUED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async - rethink this
    this.story.ensureCacheState().catch(bugsnagNotify); // async
  }

  unmarkStudyLater() {
    this.setStoryState(StoryState.UNQUEUED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async - rethink this
    this.story.ensureCacheState().catch(bugsnagNotify); // async
  }

  get vocabCount(): number {
    return this.vocabMap.size;
  }

  get hasVocab(): boolean {
    return this.vocabCount > 0;
  }

  /**
   * data needed by vocab view
   */
  get vocabViewData() {
    const story = this.story;
    if (!story) return null;

    const { vocabLookupData } = story;
    const list = compact(
      this.vocabList.map(slug => {
        return (vocabLookupData as any)[slug]; // TODO
      })
    ).sort((vocabA, vocabB) => {
      if (vocabA.chapterPosition !== vocabB.chapterPosition) {
        return vocabA.chapterPosition - vocabB.chapterPosition;
      }
      return vocabA.address.localeCompare(vocabB.address);
    });
    const chapterMap: { [index: number]: any } = {}; // TODO
    list.forEach(row => {
      let chapterData = chapterMap[row.chapterPosition];
      if (!chapterData) {
        chapterData = pick(row, ['chapterPosition', 'chapterTitle']);
        chapterData.data = [];
        chapterMap[row.chapterPosition] = chapterData;
      }
      chapterData.data.push(row);
    });
    return Object.values(chapterMap);
  }

  get listeningStats(): ListeningStats {
    const { userManager } = this.root;
    if (!userManager) return null;
    return userManager.userData.storyListeningStats(this.slug);
  }

  get listeningLogs(): ListeningLog[] {
    const { userManager } = this.root;
    if (!userManager) return null;
    return userManager.userData.storyListeningLogs(this.slug);
  }
}
