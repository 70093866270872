import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { ChapterListItemPresentation } from '../chapter-list-item-presentation';

type ChapterListItemPresentationProps = React.ComponentProps<
  typeof ChapterListItemPresentation
>;

interface ChapterProp {
  chapter: ChapterCatalogData;
}

export const ChapterListItemPresentationWrapper = (
  props: ChapterListItemPresentationProps & ChapterProp
) => {
  const { chapter, ...rest } = props;
  return (
    <ChapterListItemPresentation
      hideLeadingLine={!chapter.hasPreviousUnitChapter}
      hideTrailingLine={!chapter.hasNextUnitChapter}
      {...rest}
    />
  );
};
