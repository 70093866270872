import { createLogger } from 'app/logger';
import { RedactionMode } from 'player/models/redaction-modes';

import { applySnapshot, ModelTreeNode } from 'ts-state-tree/tst-core';

const log = createLogger('um:assist-settings');

/**
 * BEWARE: obsolete bogota settings. only used during initial user data imkport
 *
 * AssistSettings
 *
 * holds the user's last selection assist mode configuration choices
 */

const offSettings = {
  hints: false,
  vocab: false,
  trickyBits: false,
  allText: false,
  speed: 1.0,
};

export class AssistSettings extends ModelTreeNode {
  static CLASS_NAME = 'AssistSettings' as const;

  static create(snapshot: any) {
    return super.create(AssistSettings, snapshot) as AssistSettings;
  }

  assist: boolean = false; // it's not clear to me if this should be persisted
  hints: boolean = true;
  vocab: boolean = true;
  trickyBits: boolean = true;
  allText: boolean = true;
  speed: number = 0.8;

  get caliRedactionMode(): RedactionMode {
    if (this.allText) return RedactionMode.SHOW_ALL;
    if (this.vocab || this.trickyBits) return RedactionMode.SHOW_SOME;
    return RedactionMode.SHOW_NONE;
  }

  updateEverything(settings: AssistSettings) {
    log.info('Syncing assist-settings into mst');
    applySnapshot(this, settings);
  }

  toggleAssistMode() {
    this.assist = !this.assist;
  }

  toggleSetting(setting: string) {
    (this as any)[setting] = !(this as any)[setting];
  }

  toggleShowHints() {
    this.showHints = !this.showHints;
  }

  toggleShowVocab() {
    this.showVocab = !this.showVocab;
  }

  toggleShowTrickyBits() {
    this.showTrickyBits = !this.showTrickyBits;
  }

  toggleShowAllText() {
    this.showAllText = !this.showAllText;
  }

  setPlaybackSpeed(value: number) {
    this.playbackSpeed = value;
  }

  getSetting = (setting: string): any =>
    this.assist ? (this as any)[setting] : (offSettings as any)[setting];

  setSetting = (setting: string, newVal: any) => {
    if (this.assist) {
      (this as any)[setting] = newVal;
    }
  };

  get showHints() {
    return this.getSetting('hints');
  }

  set showHints(newValue) {
    this.setSetting('hints', newValue);
  }

  get showVocab() {
    return this.getSetting('vocab');
  }

  set showVocab(newValue) {
    this.setSetting('vocab', newValue);
  }

  get showTrickyBits() {
    return this.getSetting('trickyBits');
  }

  set showTrickyBits(newValue) {
    this.setSetting('trickyBits', newValue);
  }

  get showAllText() {
    return this.getSetting('allText');
  }

  set showAllText(newValue) {
    this.setSetting('allText', newValue);
  }

  get playbackSpeed() {
    return this.getSetting('speed');
  }

  set playbackSpeed(newValue) {
    this.setSetting('speed', newValue);
  }
}
