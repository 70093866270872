import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { ResumeStudyButton } from '../chapter-buttons';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';

export const FurthestNotCurrent = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
        durationText={chapter.prettyDuration}
        buttons={<ResumeStudyButton chapter={chapter} />}
      />
    );
  }
);
