import React from 'react';
import * as Dialog from '@naan/primitives/modals';
import { observer } from 'mobx-react';
import { ExcerptData } from 'tikka/client/catalog-types';
import { Story } from '@core/models/story-manager/story';
import { styled } from '@naan/stitches.config';
import { MarkSimple, Text } from '@naan/primitives/text';
import __ from '@core/lib/localization';

import { VSpacer } from '@naan/primitives/spacer';
import { Link, useNavigate } from 'react-router-dom';
import { learnStoryPath } from 'components/nav/path-helpers';

const SmallDialogHeading = styled('div', {
  textStyle: 'small-text',
  color: '$textSecondary',
  margin: '16px 16px 0',
  paddingBottom: 16,
  borderBottom: '1px solid $colors$gray-100',
  display: 'flex',
  justifyContent: 'center',
});

const StoryHeaderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gridGap: 16,
  '& .thumb': {
    '& > img': {
      display: 'block',
      width: 80,
      height: 80,
      borderRadius: 8,
    },
    marginRight: 16,
  },
  '& .info': {
    '& .title': {
      textStyle: 'small-heading',
      display: 'block',
    },
    '& .meta': {
      textStyle: 'small-text',
      color: '$textSecondary',
      display: 'block',
    },
  },
});

const LinkToStory = styled(Link, {
  display: 'block',
  textDecoration: 'none',
  color: '$colors$teal-500',
  '&:hover': {
    color: '$colors$teal-600',
  },
});

export const SoundbiteStoryInfoModal = observer(
  ({
    excerpt,
    story,
    onDismiss,
  }: {
    excerpt: ExcerptData;
    story: Story;
    onDismiss: () => void;
  }) => {
    const navigate = useNavigate();
    return (
      <Dialog.Container onDismiss={onDismiss} open>
        <SmallDialogHeading>
          {__(
            'This Soundbite was selected from',
            'soundbite.thisSoundbiteWasSelectedFrom'
          )}
        </SmallDialogHeading>
        <Dialog.Body>
          <Dialog.Scroller>
            <StoryHeaderWrapper>
              <div className="thumb">
                <img src={story.listImageUrl} alt={story.title} />
              </div>
              <div className="info">
                <div className="title">
                  <MarkSimple source={story.title} />
                </div>
                <div className="meta">
                  {story.countries.join(', ')} • {story.durationDescription}
                </div>
              </div>
            </StoryHeaderWrapper>
            <VSpacer size={4}></VSpacer>
            <Text textStyle={'body-bold'}>
              <MarkSimple source={story.tagline} />
            </Text>
            <VSpacer size={4}></VSpacer>
            <Text textStyle={'body'} color="textSecondary">
              <MarkSimple source={story.description} />
            </Text>
            <VSpacer size={4}></VSpacer>
            <LinkToStory
              to={learnStoryPath(story)}
              onClick={e => {
                e.preventDefault();
                onDismiss();
                requestAnimationFrame(() => {
                  navigate(learnStoryPath(story));
                });
              }}
            >
              {__('View the full story', 'soundbites.viewTheFullStory')}
            </LinkToStory>
            <VSpacer size={2}></VSpacer>
          </Dialog.Scroller>
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            label={'Close'}
            presentation="grayLight"
            type="button"
            onClick={onDismiss}
            fullWidth
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
