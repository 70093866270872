import * as React from 'react';
import classNames from 'classnames';

import { BackToFurthestButton } from './back-to-furthest-button';
import { CancelPendingPauseButton } from './cancel-pending-pause-button';
import { ForwardButton } from './forward-button';
import { NextSentenceButton } from './next-sentence-button';
import { observer } from 'mobx-react';
import { paletteKey } from '../../../study/views/study-palette';
import { PlaybackRateControl } from './playback-rate-control';
import { PlayerMode } from 'common/misc-types';
import { PlayPauseButton } from './play-pause-button';
import { PrevSentenceButton } from './prev-sentence-button';
import { ProgressBar } from './progress-bar';
import { Responsive } from '@naan/primitives/responsive';
import { RewindButton } from './rewind-button';
import {
  ListenFromHereButton,
  StudyFromHereButton,
} from './study-from-here-button';
import { styled } from '@naan/stitches.config';
import { TranslationButton } from './translation-button';
import { usePlayerKeyboardControls } from './use-keyboard-controls';
import { usePlayerModel } from '../player-model-context';
import { TranslationButtonState } from 'player/models/base-player-model';
// import { lineScrollObserver } from 'study/views/fx/scrolling';
// import { useReaction } from '@common/hooks/use-reaction';
// import { StudyModel } from 'study/models/study-model';
// import { SoundbiteModel } from 'soundbite/models/soundbite-model';
// import { MaximizeButton } from 'study/views/notations/maximize-button';
import { FloatingUi } from './floating-ui';
import { HSpacer } from '@naan/primitives/spacer';
import { KeyboardHelpButton } from 'components/ui/keyboard-help-overlay';
// import { RecenterButton } from './recenter-button';
import { isStudyModel } from 'player/models/player-model-handle';
import { AppFactory } from '@app/app-factory';
import { UncontrolledTooltip } from '@naan/primitives/tooltip';
import { Box } from '@naan/primitives/box';
import __ from '@core/lib/localization';

// const TransportSpacer = styled('div', {
//   width: 64,
//   height: 64,
// });

const PlayerControlsWrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  position: 'relative',
  background: paletteKey('$$transport__backgroundColor'),
  transition: 'background-color 0.2s ease-in',
  paddingTop: 4,
  height: '$$playerControlsInnerHeight',

  width: '100%',

  '& > .help-button': {
    position: 'absolute',
    left: 20,
    top: 20,
    zIndex: 10,
    display: 'none',
    '@medium': {
      '@media (hover: hover) and (pointer: fine)': {
        display: 'block',
      },
    },
  },

  '& > .front': {
    background: paletteKey('$$transport__backgroundColor'),
    display: 'flex',
    padding: '0 16',
    height: '$$playerControlsTotalHeight',
    paddingBottom: 'var(--sab)',
    zIndex: 4,
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s ease-in',
    width: '100%',

    '@playerSmallAndUp': {
      margin: '0 auto',
      width: 616,
      position: 'relative',

      '.playback-rate': {
        position: 'absolute',
        left: 0,
      },

      '.translation-button': {
        position: 'absolute',
        right: -10, // visually align button with script
      },
    },
  },
  '& > .back': {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    position: 'absolute',
    inset: 0,
    zIndex: 1,
    width: '100%',

    '@playerSmallAndUp': {
      margin: '0 auto',
      width: 616,
    },

    '& > .cancel-auto-pause': {
      paddingBottom: 16,
      transform: 'translateY(-100%)',
    },

    '& .from-here-buttons': {
      left: '16px',
      position: 'absolute',
      translate: '0 calc(-100% - 16px)',
      '@medium': {
        left: 0,
      },
    },

    // '& > .study-from-here': {
    //   paddingBottom: 16,
    //   transform: 'translateY(-100%)',
    //   transition: 'transform 0.2s ease-out',
    //   '&.hidden': {
    //     transform: 'translateY(0)',
    //   },
    // },

    '& > .back-to-furthest-button': {
      position: 'absolute',
      top: -64,
      right: 0,
      transition: 'translate 0.2s ease-out',
      '&.hidden': {
        translate: '0 200%',
      },
      // transform: 'translateY(50%)',
    },
  },
  '& > .floating-ui': {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'absolute',
    // inset: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    width: '100%',
    background: '$yellow-500',
    // translate: '0 -100%',

    '& > .inner': {
      margin: '0 auto',
      background: '$yellow-300',
      position: 'relative',
      width: 'min( 616px, 100% )',
      padding: 16,

      '& > .control': {
        translate: '0 -100%',
      },
    },
  },

  '& > .bar': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
});

const TranslationButtonContainer = observer(() => {
  const { translationButtonState } = usePlayerModel();

  if (translationButtonState === TranslationButtonState.enabled) {
    return <TranslationButton />;
  }

  if (translationButtonState === TranslationButtonState.disabled) {
    return (
      <>
        <UncontrolledTooltip
          event="click"
          placement={'top'}
          offset={[0, 0]}
          tipContent={
            <Box css={{ minWidth: 300, textAlign: 'left' }}>
              {__(
                'Translation will be available after answer is shown',
                'soundbite.translationWillBeAvailableAfterAnswerIsShown'
              )}
            </Box>
          }
        >
          <TranslationButton disabled />
        </UncontrolledTooltip>
      </>
    );
  }

  // assuming hidden
  return null;
});

const ProgressBarContainer = observer(() => {
  const model = usePlayerModel();
  const ref = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    function update() {
      if (!ref.current) {
        return;
      }
      ref.current?.style.setProperty(
        '---furthest',
        model.furthestPercentage + '%'
      );
      ref.current?.style.setProperty(
        '---current',
        model.currentPercentage + '%'
      );
      requestAnimationFrame(update);
    }
    update();
  }, [model]);
  if (model.progressBarUI) {
    return (
      <div className="bar">
        <ProgressBar ref={ref} />
      </div>
    );
  } else {
    return null;
  }
});

// const useSentenceVisibilityReaction = (
//   ref: React.MutableRefObject<HTMLDivElement>
// ) => {
//   useReaction(
//     () => lineScrollObserver.isCurrentSentenceVisible,
//     () => {
//       if (ref.current) {
//         if (lineScrollObserver.isCurrentSentenceVisible) {
//           ref.current.classList.add('sentence-visible');
//         } else {
//           ref.current.classList.remove('sentence-visible');
//         }
//       }
//     }
//   );
// };

export const PlayerControls = observer(
  ({ mode = 'player' }: { mode?: 'soundbite' | 'player' }) => {
    // const model = ({} = usePlayerModel()) as StudyModel | SoundbiteModel;
    const model = AppFactory.playerModel;
    usePlayerKeyboardControls(model);

    const listenMode = model.playerMode === PlayerMode.FLUENT_LISTEN;
    const renderComplexPlayPauseButton = model.complexPlayActionEnabled;

    const studyFromHereRef = React.useRef<HTMLDivElement>(null);

    // useSentenceVisibilityReaction(studyFromHereRef);

    return (
      <PlayerControlsWrapper className="players-control-wrapper">
        <ProgressBarContainer />
        {mode === 'player' ? (
          <span className="help-button">
            <KeyboardHelpButton />
          </span>
        ) : null}
        <div className="front">
          {listenMode ? null : (
            <div className="playback-rate">
              <PlaybackRateControl />
            </div>
          )}
          <Responsive renderMediumAndUp={() => <PrevSentenceButton />} />
          <RewindButton />
          <HSpacer size="3" />
          <PlayPauseButton complex={renderComplexPlayPauseButton} />
          <HSpacer size="3" />
          <ForwardButton />
          <Responsive renderMediumAndUp={() => <NextSentenceButton />} />
          <div className="translation-button">
            <TranslationButtonContainer />
          </div>
        </div>
        <div className="back">
          <div className="cancel-auto-pause">
            <CancelPendingPauseButton />
          </div>
          {/* // will revisit this post lupa-web-demo milestone */}
          {isStudyModel(model) ? (
            <div className="from-here-buttons" ref={studyFromHereRef}>
              <StudyFromHereButton />
              <ListenFromHereButton />
            </div>
          ) : null}

          {model.backToFurthestUI ? (
            <div
              className={classNames('back-to-furthest-button', {
                hidden: !model.canSeekToFurthest,
              })}
            >
              <BackToFurthestButton />
            </div>
          ) : null}
        </div>
        <FloatingUi />
      </PlayerControlsWrapper>
    );
  }
);
