import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { IconButtonStyled } from '@naan/primitives/button/icon-button-styles';
import { Responsive } from '@naan/primitives/responsive';
import __ from '@core/lib/localization';

const GlobeIcon = ({ color = 'currentColor' }) => (
  <svg
    width={36}
    height={36}
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.5976 24.3414C32.4974 22.4153 33 20.2663 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33C20.2663 33 22.4153 32.4974 24.3414 31.5976L34.5 34.5L31.5976 24.3414Z"
      fill={color}
    />
  </svg>
);

const GlobeWrapper = styled('div', {
  $$iconColor: '$colors$green-700',
  position: 'relative',
  '.icon': {
    color: '$$iconColor',
  },
  '.n': {
    position: 'absolute',
    inset: 0,
    // top: 2,
    textAlign: 'center',
    color: 'white',
    textStyle: 'body-bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // padding: 4,
  },
  variants: {
    empty: {
      true: {
        $$iconColor: '$colors$gray-400',
      },
    },
  },
});

const Globe = ({ n = 0 }) => (
  <GlobeWrapper empty={n === 0}>
    <span className="icon">
      <GlobeIcon />
      <span className="n">{n}</span>
    </span>
  </GlobeWrapper>
);

const IconButtonWrapper = styled(IconButtonStyled, {
  background: '$white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  width: 48,
  height: 48,
  borderRadius: 24,
  borderBottomRightRadius: 8,
  '& > .button-inner': {
    borderBottomRightRadius: 8,
  },
});

const MobileButton = ({ n, onClick }: { n: number; onClick: () => void }) => {
  return (
    <IconButtonWrapper
      onClick={onClick}
      size="large"
      presentation="greenTransparent"
    >
      <span className="button-inner">
        <Globe n={n} />
      </span>
    </IconButtonWrapper>
  );
};

const PillButton = styled('button', {
  $$labelColor: '$colors$green-600',
  all: 'unset',
  display: 'flex',
  background: '$white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  height: 48,
  padding: '0 16px',
  // width: 96,
  borderRadius: 24,
  alignItems: 'center',
  justifyContent: 'center',
  '& > .globe-icon': {
    marginRight: 8,
    marginLeft: -8,
  },
  '& > .label': {
    color: '$$labelColor',
    textStyle: 'body-bold',
  },

  variants: {
    empty: {
      true: {
        $$labelColor: '$colors$gray-300',
      },
    },
  },
});

const DesktopButton = ({ n, onClick }: { n: number; onClick: () => void }) => {
  return (
    <PillButton onClick={onClick} empty={n === 0}>
      <span className="globe-icon">
        <Globe n={n} />
      </span>
      <span className="label">{__('Vocab', 'common.vocab')}</span>
    </PillButton>
  );
};

export const MaximizeButton = ({
  n,
  onClick,
}: {
  n: number;
  onClick: () => void;
}) => {
  return (
    <Responsive
      renderDefault={() => <MobileButton n={n} onClick={onClick} />}
      renderMediumAndUp={() => <DesktopButton n={n} onClick={onClick} />}
    />
  );
};
