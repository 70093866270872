import React from 'react';
import { styled } from '@naan/stitches.config';
import { SectionHeading } from 'components/learn/dashboard/dashboard-section-heading';
import { VSpacer } from '@naan/primitives/spacer';

const StatsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space$4',
  '@medium': {
    flexDirection: 'row',
    gap: '$space$6',
  },
});

export const StatGroup = ({
  title,
  children,
}: {
  title?: string;
  children: React.ReactNode;
}) => (
  <>
    {title ? (
      <>
        <VSpacer size={8} />
        <SectionHeading title={title} />
      </>
    ) : null}
    <StatsWrapper>{children}</StatsWrapper>
  </>
);
