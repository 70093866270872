import * as React from 'react';
import { styled } from '@naan/stitches.config';
// import { useNavigate } from 'react-router-dom';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { VocabListModal } from 'components/story-detail/vocab-list';
// import { VocabListModal } from 'components/story-detail/vocab-list';

const Badge = styled('button', {
  unset: 'all',
  position: 'relative',
  backgroundColor: '$colors$white',
  height: 'fit-content',
  minWidth: '20px',
  margin: '10px',
  borderRadius: '50%',
  textStyle: 'tiny-text',
  textAlign: 'center',
  px: '6px',
  '&::after': {
    $$size: '8px',
    position: 'absolute',
    right: '-2px',
    bottom: '-2px',
    borderTop: '$$size solid black',
    borderTopColor: 'inherit',
    borderLeft: 'calc($$size/2) solid transparent',
    borderRight: 'calc($$size/2) solid transparent',
    transform: 'rotate(-50deg)',
    content: '',
    color: '$colors$white',
  },
});

type VocabListButtonProps = {
  textColor?: string;
  vocabCount: number;
};

export const VocabListButton = ({
  textColor = 'currentColor',
  vocabCount,
}: VocabListButtonProps) => {
  const showingModal = useSwitch2(false);

  return (
    <>
      <Badge onClick={showingModal.toggle} css={{ color: textColor }}>
        {vocabCount}
      </Badge>
      {showingModal.value && <VocabListModal onDismiss={showingModal.toggle} />}
    </>
  );
};
