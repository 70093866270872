import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';

import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { Assignment } from '@core/models/user-manager';
import { Text } from '@naan/primitives/text';
import { isEmpty } from 'lodash';

import __ from '@core/lib/localization';

const HeadingWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const AssignmentDetailsDialog = observer(
  ({
    assignment,
    onDismiss,
  }: {
    assignment: Assignment;
    onDismiss: () => void;
  }) => {
    const assignmentDateText = !isEmpty(assignment.displayDueDateShort)
      ? __('Due %{date}', 'assignments.dueOnDate', {
          date: assignment.displayDueDateShort,
        })
      : null;

    return (
      <Dialog.Container onDismiss={onDismiss} open={true}>
        <Dialog.Heading>
          <HeadingWrapper>
            <Text textStyle={'body-bold'}>{assignment.classroom?.label}</Text>
            {assignmentDateText ? <Text>{assignmentDateText}</Text> : null}
          </HeadingWrapper>
        </Dialog.Heading>
        <Dialog.Body>
          {isEmpty(assignment.details) ? (
            <Text textStyle="body" css={{ fontStyle: 'italic' }}>
              {__(
                'Please check with your teacher if you need more details about this story assignment.',
                'common.pleaseCheckWithYourTeacher'
              )}
            </Text>
          ) : (
            assignment.details
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Close', 'common.close')}
            presentation="yellow"
            fullWidth
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);
