import Bugsnag from '@bugsnag/browser';
import { appConfig } from 'app/env';
import { fail, alertLevels } from './errors';

// limit dependencies and just use the console directly
// import { createLogger } from '@common/log';
// const log = createLogger('invariant');

// const applyFormat = (format: string, args: string[]) => {
//   let argIndex = 0;
//   return format.replace(/%s/g, () => args[argIndex++]);
// };

const logInvariants = appConfig.invariants.log;
const reportInvariants = appConfig.invariants.report;
const alertInvariants = appConfig.invariants.alert;
const throwInvariants = appConfig.invariants.throw;

// const invariant = (condition: boolean, format: string, ...args: string[]) => {

// todo: consider renaming our own invariant flavor. i keep importing other flavors inadvertently
// maybe name 'assert' (which is what i my java muscle memory sometimes still types anyways)
const invariant = (condition: boolean, message: string) => {
  if (condition) {
    return;
  }

  if (!message) {
    message = 'Failed invariant';
  }

  // eslint-disable-next-line no-console
  console.error(`***** ${message}`); // always at least show something in the console

  /// TODO: we need to document our error extensions.
  /// We've been adding arbitrary properties (which works on js, but not ts)
  // const error = new Error(applyFormat(format, args));
  const error = new Error(message);
  error.name = 'Invariant Violation';
  //@ts-ignore
  error.report = true;
  // @ts-ignore
  error.alertLevel = alertLevels.WARN;

  if (logInvariants) {
    // eslint-disable-next-line no-console
    console.error(error.stack);
  }

  if (reportInvariants) {
    Bugsnag.notify(error);
  }

  if (alertInvariants) {
    const failOptions = {
      alert: true,
      report: false, // already reported above
      level: 'error',
    };
    fail(error, { context: 'Failed Invariant', ...failOptions } as any);
  }

  if (throwInvariants) {
    throw error;
  }

  // const failOptions = throwInvariants
  //   ? {
  //       alert: true,
  //       report: reportInvariants,
  //       level: 'error',
  //     }
  //   : {
  //       alert: false,
  //       report: reportInvariants,
  //       level: 'warning',
  //     };

  // fail(error, { context: 'Failed Invariant', ...failOptions } as any);
};

export default invariant;
