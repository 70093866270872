import { styled } from 'naan/stitches.config';

export const Scroller = styled('div', {
  overflowY: 'auto',
  // background: 'red',
  maxHeight: 'clamp( 200px, 100vh - 240px, 600px)',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&:hover': {
    '&::-webkit-scrollbar-track': {
      position: 'relative',
      left: '-14px',
      borderRadius: '4px',
      background: '$colors$black-alpha-06',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      background: '$colors$black-alpha-10',
    },
  },
});

export const AdaptiveScroller = styled(Scroller, {
  maxHeight: '70vh',
  overflowY: 'auto',
  paddingBottom: '56px',
});
