import * as React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { CloseControl } from './controls/close-control';
import { HeaderContainer } from './study-layout';
import { lineScrollObserver } from './fx/scrolling';
import { useReaction } from '@common/hooks/use-reaction';
import { ModeToggler } from './controls/mode-toggler';
import { toggleClassname } from '@utils/toggle-class';
import { GlobalRedactionButton } from './global-redaction-button';
import { setThemeColor } from '@common/pwa/set-theme-color';
import { darkPalette, palette } from './study-palette';
import { StudyModel } from 'study/models/study-model';
import { colors } from '@naan/tokens/colors';

const StudyGlobalRedactionHeader = observer(() => {
  if (lineScrollObserver.isGlobalRedactionButtonVisible === true) {
    return null;
  }

  return <GlobalRedactionButton presentation="whiteTransparent" responsive />;
});

const ID = 'STUDY_HEADER_CONTAINER';

const headerElement = () => document.getElementById(ID);

/**
 * Sets the correct status bar color for the current model state
 * @param model
 */
const setThemeHeaderColor = (model: StudyModel) => {
  const currentPalette: any = model.studyMode ? palette : darkPalette;

  let paletteKey = model.isPlaying
    ? '$$script__backgroundColor_playing'
    : '$$header__backgroundColor';

  const colorKey = currentPalette[paletteKey].replace(/^\$colors\$/, '');
  const color = colors[colorKey as keyof typeof colors];

  setThemeColor(color);
};

export const StudyHeader = React.memo(() => {
  // log.debug('ObservingStudyUI - render');

  const model = AppFactory.studyModel;

  React.useEffect(() => {
    setThemeHeaderColor(model);
  }, [model]);

  useReaction(
    () => model.isPlaying,
    () => {
      toggleClassname(headerElement(), 'playing', model.isPlaying);
      setThemeHeaderColor(model);
    }
  );

  useReaction(
    () => model.studyMode,
    () => {
      setThemeHeaderColor(model);
    }
  );

  return (
    <HeaderContainer id={ID}>
      <div className="left">
        <CloseControl />
      </div>
      <ModeToggler />
      <div className="right">
        <StudyGlobalRedactionHeader />
      </div>
    </HeaderContainer>
  );
});
