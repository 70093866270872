import NoSleep from 'nosleep.js';
import { embeddedMode, postNativeMessage } from './app-util';

// Can be enabled for the study player and left disabled for the soundbite player
// Allows the low-level audio transport to signal play/pauses and only honor for the study player
// expected to be used as a singleton
export class WakeLock {
  // screen level state
  handlerEnabled: boolean = false;

  noSleepHandler: NoSleepHandler = null;

  ensureNoSleepHandler() {
    if (!this.noSleepHandler) {
      this.noSleepHandler = createNoSleepHandler();
    }
  }

  // screen level controls

  enableHandler(): void {
    this.ensureNoSleepHandler();
    this.handlerEnabled = true;
  }

  disableHandler(): void {
    this.deactivate(); // just in case not paused before player exited
    this.handlerEnabled = false;
  }

  // transport level controls - only honored when enabled at screen level

  // nosleep.js enable() will only work when triggered in response to an input event handler
  // but that seems to be naturally the case for our 'play' actions
  activate(): void {
    if (this.handlerEnabled) {
      this.noSleepHandler.enable();
      // } else {
      //   console.log('wakelock.activate ignored');
    }
  }

  deactivate(): void {
    if (this.handlerEnabled) {
      this.noSleepHandler.disable();
      // } else {
      //   console.log('wakelock.deactivate ignored');
    }
  }
}

interface NoSleepHandler {
  enable: () => void;
  disable: () => void;
}

const createNoSleepHandler = () => {
  if (embeddedMode()) {
    return createEmbeddedNoSleep();
  } else {
    return new NoSleep();
  }
};

const createEmbeddedNoSleep = () => {
  return {
    enable() {
      postNativeMessage({
        // @nuwan, sorry i didn't give this more thought earlier. this feels like a weird convention.
        // what do you think about something along the lines of:
        //   { type: 'NO_SLEEP', data: 'enable' /*or maybe 'true'*/ }
        // this is the convention used for service worker messages.
        // (not worth updating the 5.5.3 release, but lets sync about supporting either
        // in the 5.6.0 code base)
        type: 'string',
        payload: 'WEB-VIEW-NO-SLEEP-ENABLE',
      });
    },

    disable() {
      postNativeMessage({
        type: 'string',
        payload: 'WEB-VIEW-NO-SLEEP-DISABLE',
      });
    },
  };
};
