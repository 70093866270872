import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
// import { track } from 'app/track';
import { AnswerState } from 'soundbite/models/soundbite-model';
import { StreakIcon } from '@naan/icons/streak-icon';
import __ from '@core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';

export const FINAL_CARD_ID = 'final-card;';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '$space$10',
  paddingBottom: '$space$12',
  backgroundColor: '$white',
  '& > .streak-widget': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > .icon svg': {
      width: '40px',
      height: '40px',
      color: '$colors$orange-500',
    },
    '& > .stat': {
      textStyle: 'medium-heading',
      marginTop: '$space$2',
      marginBottom: '$space$1',
    },
    '& > .label': {
      textStyle: 'small-caps',
      color: '$black-alpha-50',
    },
  },
  '& > .text-content': {
    marginTop: '$space$6',
    textStyle: 'body-bold',
    maxWidth: '440px',
  },
  '& .link-to-group': {
    textStyle: 'small-text',
    color: '$teal-500',
    textDecoration: 'underline',
  },
  '@widerThanScript': {
    marginTop: '$space$10',
  },
});

// const trackFormUrlClicked = () => {
//   track('soundbite__form_url_clicked');
// };

export const SbFinalCard = observer(() => {
  const { answerState: state } = AppFactory.soundbiteModel;

  if (state !== AnswerState.revealed) {
    return null;
  }

  //todo: replace this hardcoded value with the actual
  const { currentStreak } =
    AppFactory.root.userManager.userData.soundbiteUserData;

  return (
    <Wrapper id={FINAL_CARD_ID}>
      <div className="streak-widget">
        <div className="icon">
          <StreakIcon />
        </div>
        {/* @armando, i'll let you sort out the pluralization */}
        <div className="stat">
          {__(
            { one: '1 day', other: '%{count} days' },
            'subscribe.streakDays',
            { count: currentStreak }
          )}
        </div>
        <div className="label">{'Current streak'}</div>
      </div>
      <div className="text-content">New Soundbite coming tomorrow</div>
      <ExternalLink
        className="link-to-group"
        href={'https://www.facebook.com/groups/jiveworldbetatestcommunity'}
      >
        Share your feedback in the community
      </ExternalLink>
    </Wrapper>
  );
});
