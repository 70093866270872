import { initializeApp } from 'firebase/app';
import {
  enableMultiTabIndexedDbPersistence,
  initializeFirestore,
} from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { appConfig } from '@app/env';
import { createLogger } from 'app/logger';

const log = createLogger('firebase-init');

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL?: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

//
// new firestore project setup notes:
// https://jiveworld.slite.com/app/docs/O5pOSrB0A8lKZD/Steps-I-took-to-create-new-firestore-project
//

const apiEnvConfigs: { [index: string]: FirebaseConfig } = {
  devtest: {
    apiKey: 'AIzaSyDBwD67tSHAfB-xHqbnf4XG9eIMKxhVM28',
    authDomain: 'spa-devtest.firebaseapp.com',
    projectId: 'spa-devtest',
    storageBucket: 'spa-devtest.appspot.com',
    messagingSenderId: '303189643717',
    appId: '1:303189643717:web:16b90357527f3c3c92fbac',
  },
  jfedev: {
    apiKey: 'AIzaSyDp6BZQMoDeaP7cW7O8RZuaZvAwszDhaQA',
    authDomain: 'jw-spa-jfedev.firebaseapp.com',
    projectId: 'jw-spa-jfedev',
    storageBucket: 'jw-spa-jfedev.appspot.com',
    messagingSenderId: '547867913156',
    appId: '1:547867913156:web:1884d1239b3aa827a11291',
  },
  preprod: {
    apiKey: 'AIzaSyB473P9IPNA9W7txIBOB_tHP15Znr8L3sQ',
    authDomain: 'jw-spa-preprod.firebaseapp.com',
    projectId: 'jw-spa-preprod',
    storageBucket: 'jw-spa-preprod.appspot.com',
    messagingSenderId: '608600634108',
    appId: '1:608600634108:web:433cadf1de80752d6c9d79',
  },
  beta: {
    apiKey: 'AIzaSyDxI-y9PWimY8FYXqpkVfbbjP37d1Wn5F4',
    authDomain: 'jw-spa-beta.firebaseapp.com',
    projectId: 'jw-spa-beta',
    storageBucket: 'jw-spa-beta.appspot.com',
    messagingSenderId: '78586605525',
    appId: '1:78586605525:web:3829c17da2552cab8f175c',
  },
  prod: {
    apiKey: 'AIzaSyAT1wdPrLV700U9vIXNuwBpbHiEdeF6DnI',
    authDomain: 'jw-spa-prod.firebaseapp.com',
    projectId: 'jw-spa-prod',
    storageBucket: 'jw-spa-prod.appspot.com',
    messagingSenderId: '417461938670',
    appId: '1:417461938670:web:6f52d94fe514d1dea086ff',
  },
};

const apiEnv = appConfig.forcedApiEnv;

const config: FirebaseConfig = apiEnvConfigs[apiEnv];

log.info(`firebase init`);
const app = initializeApp(config);
export const firestoreDb = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

enableMultiTabIndexedDbPersistence(firestoreDb).catch(err => {
  if (err.code === 'failed-precondition') {
    log.info('cannot enable persistence because other tab is not multi tab');
  } else if (err.code === 'unimplemented') {
    log.info('cannot enable persistence because environment does not support');
  }
});

//
// v9 api docs example
// (will need to figure out how to avoid poisoning our json schema gen)

// import { doc, updateDoc, deleteField } from "firebase/firestore";
// const cityRef = doc(db, 'cities', 'BJ');
// // Remove the 'capital' field from the document
// await updateDoc(cityRef, {
//     capital: deleteField()
// });

//
// old v8 api example from masala
// export const fieldValueDelete = () => firebase.firestore.FieldValue.delete();
// export const fieldValueArrayUnion = (...elements: any[]) =>
//   firebase.firestore.FieldValue.arrayUnion(...elements);
