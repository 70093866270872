import { styled } from '@naan/stitches.config';

export const HelpletsProgressBar = styled('div', {
  $$furthest: '50%',
  $$current: '40%',
  $$backgroundColor: '$colors$black-alpha-10',
  $$currentColor: '$colors$teal-500',
  height: 6,
  width: '100%',
  transition: 'background 0.12s ease-in',
  borderRadius: 3,
  background: `linear-gradient(
        90deg,
        $$currentColor $$current,
        $$backgroundColor $$current
      )`,
});
