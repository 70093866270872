import * as React from 'react';
import type { VideoGuide } from '@core/models/catalog/video-guide';
import { observer } from 'mobx-react';
import { AppFactory } from '@app/app-factory';
import { namedStyled } from '@naan/stitches.config';
import { PlayVideoIcon } from './play-video-icon';
import { VideoMetaDescription } from './video-meta-description';
import { HelpletsProgressBar } from './helplets-progress-bar';
import { IconButton } from '@naan/primitives/button';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';

import __ from '@core/lib/localization';

// import { createLogger } from '@common/log';
// const log = createLogger('video-preview');

const Wrapper = namedStyled('videoPreviewWrapper', 'div', {
  $$image: '',
  background: '$gray-100',
  width: '100%',
  height: '240px',
  borderRadius: '$space$3',
  overflow: 'hidden',
  position: 'relative',
  backgroundImage: '$$image',
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  '@small': {
    height: '280px',
  },

  '& > .play-btn': {
    '--play-video-button-bg': '$colors$teal-500',
    all: 'unset',
    position: 'absolute',
    inset: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    svg: {
      width: '64px',
    },
    '&:hover': {
      '--play-video-button-bg': '$colors$teal-600',
    },
  },

  '& > .skip-btn-container': {
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 4,
    opacity: 0.4,
  },

  '& > .description-container': {
    position: 'absolute',
    left: 16,
    bottom: 16,
    zIndex: 2,
    '@medium': {
      left: 12,
      bottom: 12,
    },
  },

  '& > .progress': {
    all: 'unset',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    padding: '16px 16px',
    zIndex: 2,
    '@medium': {
      padding: '16px 24px',
    },
    '& .label': {
      display: 'block',
      textStyle: 'small-caps',
      color: '$textPrimary',
      // textTransform: 'uppercase',
      marginBottom: '12px',
    },
  },
});

export const HelpletsWidget = observer(
  ({
    video,
    onPlay,
    onSkip,
  }: {
    video: VideoGuide;
    onPlay: () => void;
    onSkip?: () => void;
  }) => {
    const { videoGuideUserData } = AppFactory.root.userManager.userData;
    const readablePercentage = videoGuideUserData.onboardingProgressPercentage;

    if (!video) {
      return null;
    }

    return (
      <Wrapper
        className="video-preview"
        css={{
          $$image: `url(${video.imageUrlLandscape})`,
        }}
      >
        {video.position > 1 ? (
          <div className="progress">
            <span className="label">
              {__(
                'You are %{readablePercentage}% enlightened',
                'dashboard.youArePercentEnlightened',
                { readablePercentage }
              )}
            </span>
            <HelpletsProgressBar
              css={{
                $$current: `${readablePercentage}%`,
              }}
            />
          </div>
        ) : null}
        <div className="description-container">
          <VideoMetaDescription>{video.title}</VideoMetaDescription>
        </div>
        <button onClick={onPlay} className="play-btn">
          <PlayVideoIcon />
        </button>
        <div className="skip-btn-container">
          <IconButton
            presentation={'grayTransparent'}
            icon={<CheckmarkCircleIcon />}
            onClick={onSkip}
          />
        </div>
      </Wrapper>
    );
  }
);
