import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { Button } from 'naan/primitives/button';
import { appConfig } from '@app/env';
import { AppFactory } from '@app/app-factory';
import { VSpacer } from '@naan/primitives/spacer';
import {
  // presentSimpleConfirmation,
  presentSimpleAlert,
} from 'components/ui/simple-dialogs';
import { bugsnagNotify, sanitizeErrorMessage } from '@app/notification-service';
import __ from '@core/lib/localization';

// import __ from 'core/lib/localization';

const ResetConfirmation = observer(
  ({ onDismiss, onOk }: { onDismiss: () => void; onOk: () => void }) => {
    const { importApiEnv } = appConfig;
    const sourceQualifier = importApiEnv === 'prod' ? '' : ` (${importApiEnv})`;

    return (
      <Dialog.Container onDismiss={onDismiss} open>
        <Dialog.Heading type="warning">Warning</Dialog.Heading>
        <Dialog.Body>
          <p>
            This action deletes all listening progress and vocabulary lists for
            all your stories, and replaces it with your current progress and
            vocabulary from the original Lupa app{sourceQualifier}.
          </p>
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'common.cancel')}
            presentation="grayTransparent"
          />
          <Dialog.Button
            onClick={onOk}
            label={__('Import', 'common.import')}
            presentation="redLight"
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );
  }
);

export const MergeProgressButton = observer(() => {
  if (!appConfig.showImportAction) {
    return null;
  }

  const { userManager } = AppFactory.root;

  const importButtonLabel = userManager.userData.destructiveImportPerformed
    ? 'Re-import my progress'
    : 'Import my progress';

  const importData = React.useCallback(async () => {
    AppFactory.dialogPresenter.present(onDismiss => {
      return (
        <ResetConfirmation
          onDismiss={onDismiss}
          onOk={async function onOk() {
            try {
              const success = await userManager.importUserData({
                merge: false,
              });
              if (success) {
                await userManager.userData.updateImportPerformed(true);
                presentSimpleAlert(<>Import complete.</>);
              } else {
                presentSimpleAlert(
                  <>
                    Matching account not found for{' '}
                    {userManager.accountData.email}. (Tip, you can change your
                    email to match if needed, and remember to confirm the email
                    change verification email.)
                  </>
                );
              }
            } catch (error) {
              const message = sanitizeErrorMessage(error as Error);
              bugsnagNotify(error as Error);
              presentSimpleAlert(<>Import failed - {message}</>);
            }
          }}
        />
      );
    });
  }, [userManager]);

  return (
    <>
      <VSpacer size={10} />
      <Button
        onClick={importData}
        label={importButtonLabel}
        size={'small'}
        presentation="redLight"
      />
    </>
  );
});
