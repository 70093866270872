import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { createLogger } from '@common/log';

const log = createLogger('use-sync-on-foreground');

export const useOfflineAwareness = () => {
  log.debug('hook body');
  const root = AppFactory.root;

  React.useEffect(() => {
    // log.debug('useEffect');

    const offlineListener = () => {
      log.debug(`event: 'offline'`);
      root.offline = true;
    };
    window.addEventListener('offline', offlineListener);

    const onlineListener = () => {
      log.debug(`event: 'online'`);
      root.offline = false;
      AppFactory.assetCacher.attemptReenable();
    };
    window.addEventListener('online', onlineListener);

    return () => {
      log.debug('cleaning up');
      document.removeEventListener('offline', offlineListener);
      document.removeEventListener('online', onlineListener);
      root.userManager.stopListen();
    };
  }, [root]);
};
