import { ModelTreeNode } from 'ts-state-tree/tst-core';

export const enum SoundbiteEngagementStatus {
  // eslint-disable-next-line no-unused-vars
  VIEWED = 'VIEWED',
  // eslint-disable-next-line no-unused-vars
  STARTED = 'STARTED',
  // eslint-disable-next-line no-unused-vars
  FINISHED = 'FINISHED',
  // eslint-disable-next-line no-unused-vars
  REVEALED = 'REVEALED',
}

const orderedStatus = [
  SoundbiteEngagementStatus.VIEWED,
  SoundbiteEngagementStatus.STARTED,
  SoundbiteEngagementStatus.FINISHED,
  SoundbiteEngagementStatus.REVEALED,
];

const statusOrdinal = (status: SoundbiteEngagementStatus): number => {
  return orderedStatus.indexOf(status);
};

export const maxStatus = (
  a: SoundbiteEngagementStatus,
  b: SoundbiteEngagementStatus
) => {
  const aOrdinal = statusOrdinal(a);
  const bOrdinal = statusOrdinal(b);

  return bOrdinal > aOrdinal ? b : a;
};

export class SoundbiteEngagement extends ModelTreeNode {
  static CLASS_NAME = 'SoundbiteEngagement' as const;

  static create(snapshot: any) {
    return super.create(SoundbiteEngagement, snapshot) as SoundbiteEngagement;
  }

  soundbiteDate: string; // iso8601 date
  soundbiteSlug: string;
  engagementStatus: SoundbiteEngagementStatus;
  timestamp: string; // local timestamp - iso8601 date time; last time status progressed

  get completed(): boolean {
    return this.engagementStatus === SoundbiteEngagementStatus.REVEALED;
  }
}
