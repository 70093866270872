import React from 'react';
import { isEmpty } from 'lodash';

// copied from masala-console.
// @armando, better home for this?

export const prevent =
  (action: () => void) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    action();
  };

export const ActionLink = ({
  onPress = () => {},
  onClick,
  className = '',
  title = '',
  label,
  children,
}: {
  onPress?: () => any;
  onClick?: () => any; // alias to align with Button props
  className?: string;
  title?: string;
  label?: string; // alias to align with Button props
  children?: React.ReactNode;
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className={className} onClick={prevent(onClick ?? onPress)}>
      {/* fontSize={1} mr={2}> */}
      {label ?? title}
      {children}
    </a>
  );
};

export const A = ({
  href,
  className = '',
  children = null,
}: {
  href: string;
  className?: string;
  children?: React.ReactNode;
}) => {
  if (isEmpty(href)) {
    return null; //children;
  }

  const body = isEmpty(children) ? href : children;
  return (
    <a href={href} className={className} target="_blank" rel="noreferrer">
      {body}
    </a>
  );
};
