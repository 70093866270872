import * as React from 'react';
import { VStack } from 'naan/primitives/stack';
import { AppFactory } from 'app/app-factory';
import { observer } from 'mobx-react';
import { getSnapshot } from '@ts-state-tree/tst-core';
import { UserManager } from '@core/models/user-manager';
import { VideoListCard } from 'components/learn/dashboard/helplets-widget/video-list-card';
import { MediumHeading } from '@naan/primitives/text';
import { Definition } from './components/definition';
import { ConfigArea } from './dev-data-dump';

export const path = 'videoguides';
export const label = 'Video guides';

export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager, storyManager } = root;
  const { userData } = userManager;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <MediumHeading>Video guide stuff</MediumHeading>
      <h4>video guide list card example</h4>
      <VideoListCard video={storyManager.videoGuides[0]} />
      <p>
        {storyManager.videoGuides.map(video => (
          <React.Fragment key={video.slug}>
            {video.slug}
            {video.completed ? '✓' : '∅'}
            {/*({video.engagementStatus})*/}
          </React.Fragment>
        ))}
      </p>
      <Definition
        label={'video guide user data'}
        value={
          <ConfigArea
            cols={30}
            rows={6}
            value={JSON.stringify(
              getSnapshot(userData.videoGuideUserData),
              null,
              2
            )}
            readOnly
          />
        }
      />
      <Definition
        label={'featuredVideoGuide catalog data'}
        value={
          <ConfigArea
            cols={30}
            rows={12}
            value={JSON.stringify(
              getSnapshot(storyManager.videoGuides),
              null,
              2
            )}
            readOnly
          />
        }
      />
    </VStack>
  );
});
