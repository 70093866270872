import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { VStack } from 'naan/primitives/stack';
import { VSpacer } from 'naan/primitives/spacer';
import { AppFactory } from 'app/app-factory';
import { devMode } from 'app/platform';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { appConfig } from '@app/env';
import { UserManager } from '@core/models/user-manager';
import { agePretty } from '@core/lib/pretty-duration';
import { Definition } from './components/definition';

export const ConfigArea = styled('textarea', {
  fontFamily: 'monospace',
  background: '$gray-50',
  padding: '$space$2',
  width: '100%',
  borderRadius: '$space$2',
});

export const path = 'data-dump';
export const label = 'Data dump';
export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;
  const { accountData, userData } = userManager;

  // strip down for easier browsing
  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <p>
        <ActionLink onPress={() => root.resetInviteCode()}>
          [Reset invite code]
        </ActionLink>
        <br />
        <ActionLink
          onPress={() => userManager.userData.soundbiteUserData.resetAllData()}
        >
          [Reset all soundbite engagement data]
        </ActionLink>
        <br />
        <ActionLink
          onPress={() => /*async*/ userData.videoGuideUserData.resetAllData()}
        >
          [Reset video guide user data]
        </ActionLink>
      </p>
      <Definition label={'devMode'} value={String(devMode)} />
      <Definition
        label={'meta.env'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(import.meta.env, null, 2)}
            readOnly
          />
        }
      />
      <Definition
        label={'config'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(appConfig, null, 2)}
            readOnly
          />
        }
      />
      {/* // todo: sanitize sensitive keys so we can always show this */}
      {/* // for now, just access via js console: window.jw_config */}
      {/* <Definition
            label={'Current config'}
            value={
              <ConfigArea cols={30} rows={10}>
                {JSON.stringify(config, null, 2)}
              </ConfigArea>
            }
          /> */}
      <ProgressInfo />
      <p>
        {accountData.email}
        <br />
        User token: {userManager.token}
      </p>
      <Definition
        label={'userManager - other'}
        value={
          <ConfigArea
            cols={30}
            rows={7}
            value={JSON.stringify(userManagerOtherData, null, 2)}
            readOnly
          />
        }
      />
      <Definition
        label={'accountData'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(JSON.parse(accountData.stringify), null, 2)}
            readOnly
          />
        }
      />
      <Definition
        label={'userData'}
        value={
          <ConfigArea
            cols={30}
            rows={10}
            value={JSON.stringify(JSON.parse(userData.stringify), null, 2)}
            readOnly
          />
        }
      />
      <VSpacer size={2} />
      <ActionLink
        onPress={async () => {
          // eslint-disable-next-line no-alert
          if (window.confirm('Are you sure?')) {
            await userData.resetAllData();
          }
        }}
      >
        [Reset ALL UserData]
      </ActionLink>
      {' - '}
      <ActionLink onPress={() => userManager.importUserData({ merge: true })}>
        [Merge in listening progress]
      </ActionLink>
      {' - '}
      <ActionLink onPress={() => userManager.importUserData({ merge: false })}>
        [Destructively import]
      </ActionLink>{' '}
      (from: {appConfig.importApiEnv} / {accountData.email})
      <VSpacer size={2} />
      {appConfig.showImportAction && userData.destructiveImportPerformed ? (
        <ActionLink onPress={() => userData.updateImportPerformed(false)}>
          [Reset 'destructiveImportPerformed' flag which influences the account
          page merge button label]
        </ActionLink>
      ) : null}
    </VStack>
  );
});

const ProgressInfo = observer(() => {
  const { userManager } = AppFactory.root;
  const { userData, accountData } = userManager;

  return (
    <>
      <p>
        User data - lastSyncedVersion: {userData.lastSyncedVersion}, ad.lsv:{' '}
        {accountData.lastSyncedVersion}; refreshed:{' '}
        {agePretty(userManager.userDataRefreshedAt)}
        {/* ( {agePretty(userManager.lastSyncCheck)},{' '}
        {Date.now() - userManager.lastSyncCheck}ms)-{' '} */}
        {' - '}
        <ActionLink
          onPress={async () => {
            await userManager.fetchLegacyUserData();
          }}
        >
          [Fetch legacy user data from rails server]
        </ActionLink>{' '}
        {/* -{' '}
        <ActionLink
          onPress={async () => {
            await userManager.syncToServer();
          }}
        >
          [Sync to server]
        </ActionLink>{' '} */}
      </p>

      <p>
        Total progress records: {userData.storyProgressList.length} -{' '}
        <ActionLink
          onPress={async () => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              await userData.resetAllProgresses();
            }
          }}
        >
          [Reset ALL progress records]
        </ActionLink>
      </p>
      <p>
        Empty progress records: {userData.emptyProgresses.length} -{' '}
        <ActionLink onPress={async () => await userData.pruneEmptyProgresses()}>
          [Prune empty]
        </ActionLink>
      </p>
      <p>
        Orphaned progress records: {userData.orphanedProgresses.length} -{' '}
        <ActionLink
          onPress={async () => await userData.pruneOrphanedProgresses()}
        >
          [Prune orphaned]
        </ActionLink>
      </p>
      <p>Unit progress records: {userData.unitProgresses.length}</p>
      <p>
        Merge needed records: {userData.mergeNeededProgresses.length} -{' '}
        <ActionLink onPress={() => /*async*/ userData.migrateBogotaUserData()}>
          [Migrate legacy data]
        </ActionLink>
      </p>
      <p>
        Records with bogota vocab slugs:{' '}
        {userData.progressesWithBogotaVocab.length}
        {/* -{' '}
        <ActionLink onPress={() => userData.migrateVocabSlugs()}>
          [Migrate vocab slugs]
        </ActionLink> */}
      </p>
      <h4>Orphaned</h4>
      {userData.orphanedProgresses.map(progress => (
        <p key={progress.slug}>
          {progress.slug}, state: {progress.storyState}, vocab count:{' '}
          {progress.vocabCount}, furthest:{' '}
          {progress.furthestPoint?.sortableString}
        </p>
      ))}
      <h4>Relevant</h4>
      {userData.relevantProgresses.map(progress => (
        <p key={progress.slug}>
          {progress.slug}, state: {progress.storyState}, vocab count:{' '}
          {progress.vocabCount}, furthest:{' '}
          {progress.furthestPoint?.sortableString}
        </p>
      ))}
      <p>
        <ActionLink
          onPress={async () => {
            // eslint-disable-next-line no-alert
            if (window.confirm('Are you sure?')) {
              await userData.resetAllListeningLogs();
            }
          }}
        >
          [Reset ALL listening logs]
        </ActionLink>
        {' - '}
        <ActionLink
          onClick={() => {
            userData.createRandomListeningLog();
          }}
          label={'Spoof Listening Data'}
        />
      </p>
    </>
  );
});
