import { frozen, ModelTreeNode } from 'ts-state-tree/tst-core';
import { appConfig } from 'app/env';

export const apiEnvChoices = [
  'prod',
  'bolero',
  'preprod',
  'staging',
  'devtest',
  'beta',
  'eslbeta',
  'jfedev',
];

export class GlobalConfig extends ModelTreeNode {
  static CLASS_NAME = 'GlobalConfig' as const;

  locale: string = 'en'; // ln.systemDefaultLocale;

  @frozen
  appInstallAttribution: object = {}; //TODO types.maybeNull(types.map(types.string)),

  static create(snapshot: any = {}): GlobalConfig {
    return super.create(GlobalConfig, snapshot) as GlobalConfig;
  }

  /**
   * this is simple now. just a static endpoint for any particular deployment
   */
  get apiEnv(): string {
    return appConfig.forcedApiEnv;
  }

  /**
   * returns the valid choices for apiEnv
   *
   * @returns {string[]}
   */
  get availableChoices(): string[] {
    return apiEnvChoices;
  }
}
