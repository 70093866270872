import * as React from 'react';
import { HStack, VStack } from 'naan/primitives/stack';
import * as loggly from 'legacylib/loggly';

import { AppFactory } from 'app/app-factory';
import { ActionLink } from 'common/ui/action-link';
import { observer } from 'mobx-react';
import { createLogger } from '@common/log';
import { CurrentDateDevUI } from 'components/admin/current-date-dev-ui';
import { bugsnagNotify } from 'app/notification-service';
import { UserManager } from '@core/models/user-manager';
import {
  ServerAuthCookieUI,
  StorageCanaryUI,
} from 'components/admin/storage-canary-ui';
import { logLevel, setLogLevel } from '@common/log/logger-config';
import { LogLevel } from '@common/log/logger-types';
import { MediumHeading } from '@naan/primitives/text';

const log = createLogger('dev-tools');

const MasalaAdminToggle = observer(() => {
  const { userManager } = AppFactory.root;
  // intentionally skipping the await
  const toggle = () => userManager.userData.toggleMasalaAdmin();
  return (
    <HStack gap={'small'}>
      Masala admin (ugly debug) mode: {String(userManager.showUglyDevUI)}
      &nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

const LogglyToggle = () => {
  const [dirty, setDirty] = React.useState(0);
  const { root } = AppFactory;

  if (!loggly.enabled) return null;

  const updateLevel = (level: LogLevel) => {
    setLogLevel(level);
    setDirty(Date.now());
  };

  log.info(`LogglyToggle dirty: ${dirty}`);
  return (
    <HStack gap={'small'}>
      Loggly activated:&nbsp;
      {loggly.isActivated() ? <strong>true</strong> : 'false'}
      &nbsp;
      <ActionLink
        onPress={() => {
          loggly.toggleActivation();
          root
            .storeLogglyEnabled(loggly.isActivated())
            .catch(error => bugsnagNotify(error));
          setDirty(Date.now());
        }}
      >
        [toggle]
      </ActionLink>
      {' - '}
      Level: {logLevel()}{' '}
      <ActionLink onPress={() => updateLevel(0)} label="[off-0]" />{' '}
      <ActionLink onPress={() => updateLevel(1)} label="[trace-1]" />{' '}
      <ActionLink onPress={() => updateLevel(2)} label="[debug-2]" />{' '}
      <ActionLink onPress={() => updateLevel(3)} label="[info-3]" />{' '}
      <ActionLink onPress={() => updateLevel(4)} label="[warn-4]" />{' '}
      <ActionLink onPress={() => updateLevel(5)} label="[warn-5]" />
    </HStack>
  );
};

const ClassroomToggle = observer(() => {
  const { userManager } = AppFactory.root;
  const toggle = () => userManager.toggleClassroomActivation();
  return (
    <HStack gap={'small'}>
      Teacher mode: {String(userManager.classroomEnabled)}&nbsp;
      <ActionLink onPress={toggle}>[toggle]</ActionLink>
    </HStack>
  );
});

export const path = 'toggles';
export const label = 'Toggles';
export const Component = observer(() => {
  const { root } = AppFactory;
  const { userManager } = root;

  const { accountData, userData } = userManager;

  const userManagerOtherData = userManager.snapshot as UserManager;
  userManagerOtherData.accountData = undefined;
  userManagerOtherData.userData = undefined;

  return (
    <VStack>
      <MediumHeading>Toggles</MediumHeading>
      <ClassroomToggle />
      <MasalaAdminToggle />
      <LogglyToggle />
      <CurrentDateDevUI />
      <StorageCanaryUI />
      <ServerAuthCookieUI />
      <p>
        Mailing list - opt-in: {String(accountData.mailingListOptIn)}{' '}
        <ActionLink onPress={() => userManager.toggleMailingListOptIn()}>
          [toggle]
        </ActionLink>
        {' - '}
        prompt needed: {String(accountData.mailingListPromptNeeded)}, prompt
        enabled: {String(userData.mailingListPromptEnabled)}
      </p>
    </VStack>
  );
});
