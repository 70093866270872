import React from 'react';
import { styled } from 'naan/stitches.config';
import { Button as NaanButton } from 'naan/primitives/button';

const DialogButton = styled(NaanButton, {
  width: '100%',
});

type ButtonProps = React.ComponentProps<typeof DialogButton>;

export const Button = (props: Omit<ButtonProps, 'size'>) => {
  return <DialogButton size={'large'} {...props} css={{ width: '30' }} />;
};
