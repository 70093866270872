import React from 'react';
import { observer } from 'mobx-react';
import __ from 'core/lib/localization';
import { MediumHeading } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { SectionBreak } from '@naan/primitives/spacer/gaps';
import { formatDate } from 'utils/format-date';
import { AppFactory } from '@app/app-factory';

export const GroupAccess = observer(() => {
  const { userManager } = AppFactory.root;
  const { accountData } = userManager;

  return (
    <>
      <MediumHeading>
        {__('My membership', 'membershipTable.myMembership')}
      </MediumHeading>
      <VSpacer size="4" />
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipTable.membershipType')}
            </TableLabelCell>
            <TableValueCell>{accountData.membershipDisplay}</TableValueCell>
          </TableRow>
          {/* <TableRow>
            <TableLabelCell>
              {__('Recurring billing', 'membershipTable.recurringBilling')}
            </TableLabelCell>
            <TableValueCell>
              {accountData.recurringBillingDisplay}
            </TableValueCell>
          </TableRow> */}
          <TableRow>
            <TableLabelCell>
              {__('Classroom name', 'membershipTable.classroomName')}
            </TableLabelCell>
            <TableValueCell>
              {accountData.licensedClassroomLabel}
            </TableValueCell>
          </TableRow>
          <TableRow>
            <TableLabelCell>
              {__('Expires', 'membershipTable.expires')}
            </TableLabelCell>

            <TableValueCell>
              {formatDate(accountData.fullAccessUntil)}
            </TableValueCell>
          </TableRow>
        </tbody>
      </Table>
      <SectionBreak />
    </>
  );
});
