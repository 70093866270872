import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { colors } from '@naan/tokens/colors';
import { observer } from 'mobx-react';
import { Story } from '@core/models/story-manager';
import { LockSmallIcon } from '@naan/icons/lock-icon';
import { ProgressPie } from 'components/ui/progress-pie';
import { ClockSmallIcon } from '@naan/icons/clock-icon';
import { CheckmarkCircleSmallIcon } from '@naan/icons/checkmark-circle-icon';
import { MarkSimple } from '@naan/primitives/text';

const Title = styled('h2', {
  textStyle: 'small-heading',
  color: '$colors$textPrimary',
  marginBottom: '$1',
  '&:hover': {
    color: '$colors$teal-500',
  },
});

const TitleStatusWrapper = styled('span', {
  marginRight: -2,
  display: 'inline-block',
  position: 'relative',
  top: 3,
  left: -2,
});
const ProgressPieWrapper = styled('span', {
  marginRight: 2,
  display: 'inline-block',
  position: 'relative',
  top: 1,
});

const TitleStatus = observer(({ story }: { story: Story }) => {
  const { locked, studyProgressPercentage } = story;
  if (locked) {
    return (
      <TitleStatusWrapper css={{ color: colors['gray-800'] }}>
        <LockSmallIcon />
      </TitleStatusWrapper>
    );
  }
  const { inProgress, queued, completed } = story;
  if (inProgress) {
    return (
      <ProgressPieWrapper
        css={{
          color: '$teal-500',
        }}
      >
        <ProgressPie current={studyProgressPercentage / 100.0} size={16} />
      </ProgressPieWrapper>
    );
  }
  if (queued) {
    return (
      <TitleStatusWrapper css={{ color: '$orange-500' }}>
        <ClockSmallIcon />
      </TitleStatusWrapper>
    );
  }

  if (completed) {
    return (
      <TitleStatusWrapper css={{ color: '$gray-400' }}>
        <CheckmarkCircleSmallIcon />
      </TitleStatusWrapper>
    );
  }

  return null;
});

export const StoryTitle = observer(({ story }: { story: Story }) => (
  <Title>
    <TitleStatus story={story} />
    <MarkSimple source={story.title} />
  </Title>
));
