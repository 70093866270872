import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';
import { homePath } from 'components/nav/path-helpers';
import { LupaLogo } from 'components/ui/lupa-logo';
import { HSpacer } from 'naan/primitives/spacer';
import { styled } from '@naan/stitches.config';
import largeBackground from './bg.svg';

const Wrapper = styled('div', {
  minHeight: '100%',
  backgroundColor: 'tan',
  backgroundImage: `url(${largeBackground})`,
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '96px 0 48px 0',

  '@medium': {
    padding: '80px 0',
  },

  '& .content': {
    width: 'min( 632px, calc(100% - 32px) )',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& .title': {
      color: '$colors$white',
      textStyle: 'medium-heading',
      marginBottom: 32,
      '@medium': {
        textStyle: 'extra-large-heading',
        marginBottom: 48,
      },
    },
  },
});

const BackButton = styled('div', {
  all: 'unset',
  color: 'white',
  position: 'absolute',
  top: '16px',
  left: '16px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',

  '@medium': {
    top: '28px',
    left: '24px',
  },
});

const Back = () => {
  const navigate = useNavigate();

  return (
    <BackButton
      tabIndex={-1}
      onClick={() => {
        if (ReturnNavState.canPopFromSubscribe()) {
          navigate(-1);
        } else {
          navigate(homePath());
        }
      }}
    >
      <ArrowLeftSmallIcon />
      <HSpacer size={1} />
      <LupaLogo />
    </BackButton>
  );
};

export const SubscriptionLayout: React.FC = observer(({ children }) => {
  return (
    <Wrapper>
      <Back />
      <div className="content">{children}</div>
    </Wrapper>
  );
});
