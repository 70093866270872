import React from 'react';
import { Text } from '@naan/primitives/text';
import { MiddleColumn } from '../account-layout';

export const PurchaseFlowDisabled = () => {
  return (
    <MiddleColumn>
      <Text>
        Please visit our website for access to all content (placeholder copy)
      </Text>
    </MiddleColumn>
  );
};
