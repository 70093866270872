import { AppFactory } from '@app/app-factory';
import { appConfig } from '@app/env';
import { bugsnagNotify } from '@app/notification-service';
import { Notation } from '@tikka/client/client-aliases';
import { extractDomainName, notEmptyOrNA } from '@utils/string-utils';
import { createLogger } from 'app/logger';
// import invariant from 'core/lib/invariant';
import __ from 'core/lib/localization';
import {
  millisToMinutes,
  millisToPrettyDuration,
  minutesToPrettyDuration,
} from 'core/lib/pretty-duration';
import { deburr, isEmpty, lowerCase } from 'lodash';
import { computed } from 'mobx';
import moment from 'moment'; // TODO: remove 'moment' usage in favor of dayjs
import { ModelTreeNode, identifier, volatile } from 'ts-state-tree/tst-core';
import { getBaseRoot } from '../app-root';
import { ActivityGuide, ChapterCatalogData } from '../catalog';
import { Credit } from '../catalog/credit';
import { Speaker } from '../catalog/speaker';
import { UnitCatalogData } from '../catalog/unit-catalog-data';
import { Root } from '../root';
import { Assignment } from '../user-manager';
import { ChapterRef, LocationPointer } from '../user-manager/location-pointer';
import { StoryProgress } from '../user-manager/story-progress';
// import invariant from '@core/lib/invariant';

const log = createLogger('story');

// correlates to VolumeCaliData masala schema
export class Story extends ModelTreeNode {
  static CLASS_NAME = 'Story' as const;

  @identifier
  slug: string = '';
  version: number;
  ingestedAt: string;

  volumeDataUrl: string = null;

  title: string = '';
  tagline: string = '';
  description: string = '';
  weblink: string;
  // seasonNumber: string;
  originalBroadcastDate: string = null; // iso date

  releaseDate: string = null; // iso date
  trial: boolean = false;

  topics: string[] = [];
  countries: string[] = [];
  ibTags?: string[] = [];
  apTags?: string[] = [];

  // todo: figure out sorting
  speakers: Speaker[] = [];
  credits: Credit[] = [];

  activityGuideData?: ActivityGuide = null;
  // activityGuideUrl?: string = null;

  // unitSlugs: string[] = [];
  totalDurationMinutes: number = 0; // todo: consider removing this field and only using chapter level sum

  listImageUrl: string; // imageThumbUrl
  themeColor: string = '#8A60AB'; // default guaranteed during ingestion

  units: UnitCatalogData[] = [];

  @volatile
  vocabLookupData: Object = {};

  // @jason/@armando is there utility i can leverage to lazy cache an async calculated value?
  @volatile
  isCachedMemoizedState: boolean;

  //
  // old
  //

  // @identifier
  // slug: string = '';

  // units: UnitCatalogData[] = [];

  // volumeData: VolumeCatalogData = snap({});

  static create(snapshot: any = {}) {
    return super.create(Story, snapshot) as Story;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  // // todo: remove usages, but needs to be dealt with carefully because heavily used by spa.
  // // should perhaps wait until after 6.1.0 is releasead, or at least perform first in the spa
  // get catalogData(): UnitCatalogData {
  //   return this.firstUnitData;
  // }

  get firstUnitData(): UnitCatalogData {
    return this.units[0];
  }

  unitDataByNumber(unitNumber: number) {
    return this.units.find(unit => unit.unitNumber === unitNumber);
  }

  unitDataBySlug(slug: string) {
    return this.units.find(unit => unit.slug === slug);
  }

  get unitCount() {
    return this.units.length;
  }

  get multiUnit() {
    return this.unitCount > 1;
  }

  get singleUnit() {
    return !this.multiUnit;
  }

  includesUnit(slug: string): boolean {
    return this.units.some(unit => unit.slug === slug);
  }

  matchesVolumeOrUnitSlug(slug: string): boolean {
    return this.slug === slug || this.includesUnit(slug);
  }

  get hasProgress(): boolean {
    return !!this.progressMayBeNull;
  }

  get progress(): StoryProgress {
    const match = this.progressMayBeNull;
    if (match) {
      return match;
    } else {
      log.info(`creating new progress data for ${this.slug}`);
      return this.root.userManager.userData.ensureStoryProgress(this.slug);
      // todo: consider persisting immediately, but probably not needed
    }
  }

  get progressMayBeNull(): StoryProgress {
    return this.root.userManager.userData.storyProgress(this.slug);
  }

  get inProgress(): boolean {
    return this.progressMayBeNull?.inProgress || false;
  }

  get completed(): boolean {
    return this.progressMayBeNull?.completed || false;
  }

  get unqueued(): boolean {
    return this.hasProgress ? this.progress.unqueued : true;
  }

  get queued(): boolean {
    return this.progressMayBeNull?.queued || false;
  }

  get started(): boolean {
    return this.progressMayBeNull?.started || false;
  }

  get unstarted(): boolean {
    return !this.started;
  }

  get lastListened(): number {
    return this.hasProgress ? this.progress.lastListened : 0;
  }

  updateCurrentPoint(point: LocationPointer) {
    const { userManager } = this.root;
    const progress = userManager.userData.ensureStoryProgress(this.slug);
    progress.updateCurrentPoint(point);
    userManager.persistUserData().catch(bugsnagNotify); // note, persistance runs async
  }

  // get atEndOfStory() {
  //   return this.progress?.currentPoint?.atEndOfStory;
  // }

  // count of chapters across all units
  // unsure if this should really be used or not
  get chapterCount() {
    return this.chapters.length;
  }

  /// Too verbose? Wanted to be super clear
  get firstChapterOfFirstUnitData() {
    return this.firstUnitData?.chapters[0];
  }
  // get currentUnitChapterCount(): number {
  //   return this.progress?.currentUnit?.chapterCount;
  // }

  // get currentUnitIsLast(): boolean {
  //   return this.progress?.currentUnit?.isLastUnit;
  // }

  async exportVocab() {
    if (this.vocabCount) {
      await this.root.userManager?.exportVocab(this.slug);
    } else {
      log.info(`story[${this.slug}.exportVocab - no vocab saved, skipping`);
    }
  }

  async toggleClassroomFavorite() {
    return this.root.userManager.userData.classroom.toggleFavorite(this.slug);
  }

  get searchableText() {
    if (!this.firstUnitData) return '';
    return deburr(
      [
        this.title,
        this.description,
        this.tagline,
        ...this.countries,
        ...this.topics,
        ...this.ibTags,
        ...this.apTags,
      ]
        .join(' ')
        .toLowerCase()
    );
  }

  // get topics() {
  //   return this.volumeData.topics;
  // }

  // get countries() {
  //   return this.volumeData.countries;
  // }

  // // todo: should probably remove usages
  // get themes() {
  //   return this.pedagogicalThemes;
  // }

  get pedagogicalThemes() {
    return [...this.apTags, ...this.ibTags].sort();
  }

  // get apTags() {
  //   return this.volumeData.apTags;
  // }

  // get ibTags() {
  //   return this.volumeData.ibTags;
  // }

  // get allTags() {
  //   return this.volumeData.allTags;
  // }
  get allTags() {
    return [
      ...tagMapper(this.countries, 'country', 'countries'),
      ...tagMapper(this.topics, 'topic', 'topics'),
      // ...tagMapper(this.apTags, 'ap', 'ap'),
      // ...tagMapper(this.ibTags, 'ib', 'ib'),
    ];
  }

  // get listImageUrl() {
  //   return this.volumeData.imageThumbUrl;
  // }

  // // get bannerImageUrl() {
  // //   return this.volumeData.bannerImageUrl;
  // // }

  // get weblink() {
  //   return this.volumeData.weblink;
  // }

  // todo: remove usages
  get activityGuideUrl() {
    return this.activityGuideData?.resourceUrl;
  }

  get promoAudioUrl() {
    return this.firstChapter?.normalAudioUrl || '';
  }

  get hasWeblink(): boolean {
    return notEmptyOrNA(this.weblink);
  }

  get weblinkDomain(): string {
    return extractDomainName(this.weblink);
  }

  // get trial() {
  //   return this.volumeData.trial;
  // }

  // get version() {
  //   return this.volumeData.version;
  // }

  // for the learn view, we assume there's a single relevant assignment for a story
  // the if user has joined multiple classrooms with the same story assigned
  // then we'll accept the confusing experience of the wrong assignment details
  // being potentially shown
  get joinedClassroomAssignment(): Assignment {
    return this.root.userManager.accountData?.joinedClassroomAssignmentForStory(
      this
    );
  }

  // applied to managed classrooms
  // needed by teacher (classroom) story list view
  get assignCount() {
    const assignmentMap =
      this.root.userManager.accountData?.assignmentMap ?? {};
    if (assignmentMap && assignmentMap[this.slug]) {
      return assignmentMap[this.slug];
    }
    return 0;
  }

  // get unplayed(): boolean {
  //   return this.progress?.unplayed;
  // }

  // get played() {
  //   return this.progress?.played;
  // }

  // get inProgress() {
  //   return this.progress?.inProgress;
  // }

  // // substate of 'completed'
  // get relistening() {
  //   return this.progress?.relistening;
  // }

  // // drives the "continue" CTA label
  // get listening() {
  //   return this.progress?.listening;
  // }

  // get completed() {
  //   return this.progress?.completed;
  // }

  // deprecated
  get progressBar() {
    return this.studyProgressRatio;
  }

  // todo: revisit this
  get minutesRemaining() {
    // const { catalogData, progress } = this;
    // if (!catalogData || !progress) return 0;

    // const { chapter: chapterPosition, millisPlayed } = progress.furthestPoint;
    // const pastMillisPlayed =
    //   this.catalogData.chapters
    //     .slice(0, chapterPosition - 1)
    //     .reduce((sum, chapter) => sum + chapter.durationMillis, 0) +
    //   millisPlayed;

    // const oneMinuteInMillis = 60 * 1000;

    // const minutesRemaining =
    //   (catalogData.durationMinutes * oneMinuteInMillis - pastMillisPlayed) /
    //   oneMinuteInMillis;
    // return Math.round(minutesRemaining);
    return millisToMinutes(this.timeLeftMillis);
  }

  get chapters(): ChapterCatalogData[] {
    return this.units.map(unit => unit.chapters).flat();
  }

  get blobUrls(): string[] {
    const result = [this.volumeDataUrl];
    for (const chapter of this.chapters) {
      result.push(chapter.playerDataUrl);
      result.push(chapter.normalAudioUrl);
    }
    return result;
  }

  get shouldCache(): boolean {
    if (this.completed) return false;
    return this.trial || this.inProgress || this.queued;
  }

  async ensureCacheState() {
    const cached = await this.isCached();
    if (cached !== this.shouldCache) {
      if (this.shouldCache) {
        await this.ensureCached();
      } else {
        await this.removeFromCache();
      }
    }
  }

  async ensureCached() {
    log.info(`${this.slug} - ensureCached`);
    const blobUrls = this.blobUrls;
    const cached = await AppFactory.assetCacher.addAll(blobUrls);
    this.setIsCachedMemoizedState(cached);
  }

  async removeFromCache() {
    log.info(`${this.slug} - removeFromCache`);
    const blobUrls = this.blobUrls;
    for (const url of blobUrls) {
      await AppFactory.assetCacher.remove(url);
    }
    this.setIsCachedMemoizedState(false);
  }

  async isCached() {
    let result = true;
    const blobUrls = this.blobUrls;
    for (const url of blobUrls) {
      const cached = await AppFactory.assetCacher.isCached(url);
      if (!cached) {
        result = false;
      }
    }
    this.setIsCachedMemoizedState(result);
    return result;
  }

  get isCachedMemoized(): boolean {
    log.debug(
      `isCachedMemoized - memoized state: ${String(this.isCachedMemoizedState)}`
    );
    if (this.isCachedMemoizedState === undefined) {
      // asynchronously resolve and trigger rerender on observed value
      this.isCached()
        .then(value => this.setIsCachedMemoizedState(value))
        .catch(bugsnagNotify);
    }

    return this.isCachedMemoizedState;
  }

  setIsCachedMemoizedState(value: boolean) {
    log.debug(`setIsCachedMemoizedState(${String(value)})`);
    this.isCachedMemoizedState = value;
  }

  priorChapterRef(chapterRef: ChapterRef) {
    if (chapterRef.chapter > 1) {
      return { unit: chapterRef.unit, chapter: chapterRef.chapter - 1 };
    } else {
      if (chapterRef.unit > 1) {
        const unit = chapterRef.unit - 1;
        const unitData = this.unitDataByNumber(unit);
        const chapter = unitData.chapterCount;
        return { unit, chapter };
      } else {
        return null;
      }
    }
  }

  nextChapterRef(chapterRef: ChapterRef) {
    const unitData = this.unitDataByNumber(chapterRef.unit);
    if (chapterRef.chapter >= unitData.chapterCount) {
      const nextUnitData = this.unitDataByNumber(chapterRef.unit + 1);
      if (nextUnitData) {
        return { unit: chapterRef.unit + 1, chapter: 1 };
      } else {
        return null;
      }
    } else {
      return { unit: chapterRef.unit, chapter: chapterRef.chapter + 1 };
    }
  }

  chapterForPoint(point: ChapterRef) {
    return this.chapters.find(ch => ch.matchesPoint(point));
  }

  // the number of chapters strictly before the referenced location
  countChaptersBefore(chapterRef: ChapterRef): number {
    return this.chapters.filter(ch => ch.isBefore(chapterRef)).length;
  }

  get firstChapter(): ChapterCatalogData {
    return this.chapterForPoint({ unit: 1, chapter: 1 });
  }

  get durationMillis() {
    //TODO return this.units.reduce((sum, unit) => sum + unit.durationMillis, 0);
    // return this.totalDurationMinutes * 60 * 1000;
    return this.chapters.reduce((sum, ch) => sum + ch.durationMillis, 0);
  }

  get progressMillis(): number {
    return this.chapters.reduce((sum, ch) => sum + ch.progressMillis, 0);
  }

  get timeLeftMillis(): number {
    return this.durationMillis - this.progressMillis;
  }

  get durationMinutes() {
    // return this.units.reduce((sum, unit) => sum + unit.durationMinutes, 0);
    // return millisToMinutes(this.durationMillis);
    return this.totalDurationMinutes;
  }

  get timeLeftMinutes() {
    return millisToMinutes(this.timeLeftMillis);
  }

  // reflects furthestPoint on progress bars
  get studyProgressRatio(): number {
    return this.progressMillis / this.durationMillis;
  }

  get studyProgressPercentage() {
    return Math.round(this.studyProgressRatio * 100);
  }

  get totalPoints() {
    return this.progressMayBeNull?.listeningStats?.totalPoints;
  }

  get totalListenedInWords() {
    return millisToPrettyDuration(
      this.progressMayBeNull?.listeningStats?.totalMillis
    );
  }

  get vocabCount() {
    return this.progressMayBeNull?.vocabCount;
  }

  get vocabCountDescription() {
    return __('%{count} items', 'vocab.itemsCount', {
      count: this.vocabCount,
    });
  }

  get listeningStats() {
    return this.progressMayBeNull?.listeningStats;
  }

  get locked() {
    const { accountData } = this.root.userManager;
    if (accountData?.fullAccess) {
      return false;
    }
    const unlockedSlugs = accountData?.unlockedStorySlugs;
    if (unlockedSlugs && unlockedSlugs.includes(this.slug)) {
      return false;
    }
    // if (this.volume?.isUnlocked) {
    //   return false;
    // }
    return !this.trial;
  }

  get vocabViewData() {
    return this.progressMayBeNull?.vocabViewData;
  }

  get showResetStory() {
    return !this.progressMayBeNull?.unplayed;
  }

  get showMarkComplete() {
    return !this.completed && !this.locked;
  }

  get isNew() {
    const { newThisWeek } = this.root.storyManager;
    return newThisWeek.includes(this);
  }

  get isReleased() {
    const { currentDate } = this.root.storyManager;
    const today = moment(currentDate);
    const storyDate = moment(this.releaseDate);
    return storyDate.isSameOrBefore(today);
  }

  // links to the matching classroom assignment if this story is included in any joined classrooms
  // if story included in multiple classrooms, last one wins
  // (not going to worry about fully handling that edge case for now)
  get assignment(): Assignment {
    let result = null;
    const { accountData } = this.root.userManager;
    accountData.joinedClassrooms.forEach(classroom => {
      const match = classroom.assignmentForSlug(this.slug);
      if (match) {
        result = match;
      }
    });
    return result;
  }

  get voices(): Speaker[] {
    return this.speakers.filter(speaker => speaker.includeInVoices);
  }

  get chapterNotes() {
    return this.chapters.map(chapter => chapter.chapterNotes).flat();
  }

  //
  // sort keys
  //

  // todo: confirm with daniel/frank if these flavors need to be distinct or can just be unified
  get durationDescription() {
    return this.buildDurationDescription({ includeChapters: true });
  }

  get classroomDurationDescription() {
    return this.buildDurationDescription({ includeChapters: true });
  }

  buildDurationDescription({ includeChapters }: { includeChapters: boolean }) {
    const segments: string[] = [];
    // if (this.unitCount > 1) {
    //   segments.push(
    //     __('%{count} parts', 'story.parts', { count: this.unitCount })
    //   );
    // }

    segments.push(minutesToPrettyDuration(this.durationMinutes));

    if (includeChapters) {
      segments.push(
        __('%{chapterCount} chapters', 'story.chapters', {
          chapterCount: this.chapters.length,
        })
      );
    }

    return segments.join(__(', ', 'common.listSeparator'));
  }

  // should be redundant now with 'durationMinutes' once the legacy unit based discover view is retired
  get sortDurationMinutes() {
    // return this.catalogData.sortDurationMinutes;
    return this.totalDurationMinutes;
  }

  // not sure why compareLocale didn't properly handle sorting accents
  get sortTitle(): string {
    return lowerCase(deburr(this.title));
  }

  // get releaseDate() {
  //   return this.volumeData.releaseDate;
  // }

  // get originalBroadcastDate() {
  //   return this.volumeData.originalBroadcastDate;
  // }

  // get thumbImagePath() {
  //   return this.download?.listImagePath;
  // },

  // get bannerImagePath() {
  //   if (this.volume) {
  //     return this.volume.thumbImagePath;
  //   } else {
  //     return this.download?.bannerImagePath;
  //   }
  // },

  get isClassroomFavorited() {
    return this.root.userManager.userData.classroom.isFavorited(this.slug);
  }

  // // legacy
  // resolveSpeakerData(label: string) {
  //   return this.firstUnitData?.resolveSpeakerData(label);
  // }

  resolveSpeaker(label: string): Speaker {
    const result = this.speakers.find(speaker => speaker.matches(label));
    if (!result) {
      // todo: make this an assert
      // currently happens because of the order of resolving the web mode
      // and initializing the story manager data
      log.warn(`missing speaker data for label: ${label}`);
      return Speaker.create({});
    }
    return result;
  }

  // todo: consider memoizing or performing during ingestion.
  // (but not heavily used)
  get sortedCredits(): Credit[] {
    const result = Credit.sort(this.credits);
    return result;
  }

  // todo: figure out sorting
  get voicesList(): Speaker[] {
    return this.speakers.filter(speaker => speaker.includeInVoices);
  }

  // get themeColor(): string {
  //   return this.themeColor || '#8A60AB'; // tmp default until data filled in
  // }

  // get volumeDataUrl(): string {
  //   return this.volumeData.volumeDataUrl;
  // }

  // @jason,is this a safe use of computed?
  @computed
  get notations(): Notation[] {
    const result: Notation[] = this.units.map(unit => unit.notations).flat();
    log.debug('notations count: ', result.length);
    return result;
  }

  vocab(slug: string): Notation {
    const notations = this.notations;
    const result = notations.find(notation => notation.id === slug);
    return result;
  }

  get masalaVolumeDetailUrl(): string {
    return `${appConfig.masalaBaseUrl}/volumes/slug/${this.slug}`;
  }

  //
  // bogota vocab data migration support
  //

  // async fetchDataAndMigrateVocabSlugs(vocabSlugs: string[]): Promise<string[]> {
  //   try {
  //     const fullData = await this.root.storyManager.loadVolumeDataUrl(
  //       this.volumeDataUrl
  //     );
  //     return fullData.migrateBogotaVocabSlugs(vocabSlugs);
  //   } catch (error) {
  //     log.error(
  //       `fetchDataAndMigrateVocabSlugs - error fetching data for story: ${this.slug}`
  //     );
  //     bugsnagNotify(error as Error);
  //     return vocabSlugs;
  //   }
  // }

  // migrateBogotaVocabSlugs(vocabSlugs: string[]): string[] {
  //   const result = vocabSlugs.map(slug => this.bogotaToCaliVocabSlug(slug));
  //   return result;
  // }

  // bogotaToCaliVocabSlug(slug: string) {
  //   if (isBogotaVocabSlug(slug)) {
  //     for (const unit of this.units) {
  //       const candidate = unit.bogotaToCaliVocabSlug(slug);
  //       if (candidate) {
  //         return candidate;
  //       }
  //     }
  //     // todo: migrate with unit context before merging slugs to story progress for tigher matching pool
  //     const candidates: string[] = [];
  //     for (const unit of this.units) {
  //       candidates.push(...unit.fuzzyMatchedVocabSlugs(slug));
  //     }
  //     if (candidates.length === 1) {
  //       const candidate = candidates[0];
  //       log.warn(
  //         `fuzzy matching unique for bogota slug: ${slug}, matched: ${candidate}`
  //       );
  //       return candidate;
  //     } else {
  //       log.warn(
  //         `fuzzy matching non-unique for bogota slug: ${slug}, matches: ${candidates.length} - ignoring`
  //       );
  //     }
  //   }
  //   return slug;
  // }
}

export const hasBogotaVocabSlugs = (vocabSlugs: string[]): boolean => {
  if (!vocabSlugs) return false;
  return vocabSlugs.some(slug => isBogotaVocabSlug(slug));
};

export const isBogotaVocabSlug = (slug: string) => {
  return !isEmpty(slug) && !slug.startsWith('NOTATION:');
};

export type TagType = 'topic' | 'country'; // | 'ap' | 'ib';

export type MappedTag = {
  type: TagType; //'topic' | 'country';
  label: string;
  url: string;
};

const tagMapper = (
  tags: string[],
  type: TagType,
  filterKey: string
): MappedTag[] =>
  tags.map(tag => ({
    type,
    label: tag,
    url: `/discover/?${filterKey}[]=${tag}`,
  }));
