import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { Button } from '@naan/primitives/button';
import { ClockIcon } from '@naan/icons/clock-icon';
import { Story } from '@core/models/story-manager';
import { CheckmarkIcon } from '@naan/icons/checkmark-icon';
import { HSpacer } from '@naan/primitives/spacer';
import { Responsive } from '@naan/primitives/responsive';
import { observer } from 'mobx-react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { notNil } from '@utils/conditionals';
import { Sticky } from 'components/story-detail/sticky.styles';
import { AppFactory } from '@app/app-factory';
import { presentSimpleAlert } from 'components/ui/simple-dialogs';
import __ from '@core/lib/localization';

const Outer = styled(Sticky, {
  borderBottom: '1px solid $gray-100',
  backgroundColor: '$white',
  bottom: 0,
  top: '$$smallHeaderHeight',
  '@medium': {
    top: '$$mediumHeaderHeight',
  },
  variants: {
    inAssignment: {
      true: {
        top: 'calc($$smallHeaderHeight + $$assignmentBannerHeight)',
        '@medium': {
          top: 'calc($$mediumHeaderHeight + $$assignmentBannerHeight)',
        },
      },
    },
  },
});

const StoryActionStyles = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: '$space$4 0',
  '@medium': {
    justifyContent: 'flex-start',
  },
});

type ButtonProps = React.ComponentProps<typeof Button>;

export const StoryAction = observer(({ story }: { story: Story }) => {
  if (story.locked) return null;

  const { unstarted, unqueued, joinedClassroomAssignment: assignment } = story;

  const handleBeginStudying = React.useCallback(() => {
    const { offline } = AppFactory.root;
    if (offline && !story.isCachedMemoized) {
      presentSimpleAlert(
        <>
          {__(
            'You appear to be offline. Please try again later.',
            'common.offlineTryLater'
          )}
        </>
      );
    } else {
      story.progress.beginStudying();
    }
  }, [story]);

  if (!unstarted) {
    return null;
  }

  const ActionButton = (props: ButtonProps) => (
    <Responsive
      renderDefault={() => <Button css={{ flex: 1 }} {...props} />}
      renderMediumAndUp={() => <Button {...props} />}
    />
  );

  return (
    <Outer inAssignment={notNil(assignment)}>
      <CenterColumnLayout>
        <StoryActionStyles>
          <ActionButton
            label="Begin studying"
            presentation={'teal'}
            size="large"
            onClick={handleBeginStudying}
          />
          <HSpacer size={2} />
          <ActionButton
            label="Study later"
            presentation={'secondary'}
            size="large"
            leftIcon={unqueued ? <ClockIcon /> : <CheckmarkIcon />}
            onClick={() => story.progress.toggleStudyLater()}
          />
        </StoryActionStyles>
      </CenterColumnLayout>
    </Outer>
  );
});
