import React from 'react';
import { StoryMeta } from './story-meta';
import { MarkSimple } from 'naan/primitives/text';
import { formatDate } from 'utils/format-date';
import { Story } from '@core/models/story-manager/story';
import { styled } from '@naan/stitches.config';
import __ from 'core/lib/localization';

const Wrapper = styled('section', {
  '@media print, screen and (min-width: 900px)': {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    '& .cover-image': {
      width: '33.333%',
      paddingLeft: 'calc(64px / 3)',
      marginBottom: '56px',
    },
    '& .cover-text': {
      width: '50%',
    },
    '& .credits': {
      columnCount: '3',
    },
  },

  '& .cover-image': {
    margin: '8px 0 24px',
    maxWidth: '360px',
    width: '100%',
  },
  '& .cover-heading': {
    font: 'var(--large-heading-font)',
    marginBottom: '16px',
  },
  '& .cover-tagline': {
    font: 'var(--medium-heading-font)',
    marginBottom: '24px',
  },
  '& .cover-description': {
    font: 'var(--body-font)',
    marginBottom: '16px',
  },
  '& .cover-release': {
    font: 'var(--body-font)',
    marginBottom: '16px',
    color: 'var(--text-secondary)',
  },
});

// const formatDate = (date: string | number | Date | dayjs.Dayjs) =>
//   dayjs(date).format('MMM D, YYYY');

export const StoryCover = ({ story }: { story: Story }) => {
  return (
    <Wrapper>
      <img
        className="cover-image"
        src={story.listImageUrl}
        alt={story.title}
        crossOrigin="anonymous"
      />
      <div className="cover-text">
        <h2 className="cover-heading">
          <MarkSimple source={story.title} />
        </h2>
        <h2 className="cover-tagline">
          <MarkSimple source={story.tagline} />
        </h2>
        <div className="cover-description">
          <MarkSimple source={story.description} />
        </div>
        <div className="cover-release">
          {__('Lupa release on %{date}', 'storyGuide.lupaReleaseOn', {
            date: formatDate(story.releaseDate),
          })}
          <br />
          {__(
            'Originally broadcast on %{date}',
            'storyGuide.originallyBrodcast',
            {
              date: formatDate(story.originalBroadcastDate),
            }
          )}
          <br />
        </div>
        <StoryMeta story={story} />
      </div>
    </Wrapper>
  );
};
