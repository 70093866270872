import {
  doc,
  DocumentSnapshot,
  Firestore,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { makeObservable, observable } from 'mobx';
import { createLogger } from '@common/log';

const log = createLogger('firestore-invoker');

// todo: rename this to something user data specific
export class FirestoreInvokerImpl {
  db: Firestore;

  @observable
  unsub: () => void;

  userDataUuid: string; // server provided uuid to use as doc id

  // // todo: confirm if this is still relevant
  // @observable
  // loadingData: boolean = false;

  constructor({ db }: { db: Firestore }) {
    this.db = db;
    makeObservable(this);
  }

  setUserDataUuid(uuid: string) {
    if (this.userDataUuid !== uuid) {
      if (this.isListening) {
        log.info(
          `setUserDataUuid(${uuid}) - changed, automatically stopping listen`
        );
        this.stopListen();
      }
      this.userDataUuid = uuid;
    }
  }

  get isListening(): boolean {
    return !!this.unsub;
  }

  get userDataDocRef() {
    const docRef = doc(this.db, 'UserData', this.userDataUuid);
    return docRef;
  }

  async getUserDataSnapshot(): Promise<any> {
    if (!this.userDataUuid) {
      log.error(`getUserDataSnapshot - ignored when anonymous`);
      return null;
    }
    const docRef = this.userDataDocRef;
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  }

  async saveUserDataSnapshot(snapshot: any) {
    if (!this.userDataUuid) {
      log.error(`saveUserDataSnapshot - ignored when anonymous`);
      return;
    }
    const docRef = this.userDataDocRef;
    // note not merging so will overwrite with last client wins
    await setDoc(docRef, snapshot);
  }

  async updateUserData(path: string, value: any): Promise<void> {
    if (!this.userDataUuid) {
      log.warn(`updateUserData(${path}) - ignored when anonymous`);
      return;
    }
    const docRef = this.userDataDocRef;
    await updateDoc(docRef, { [path]: value });
  }

  startListen(cb: (data: any) => void) {
    if (!this.userDataUuid) {
      log.debug(`startListen - ignored when anonymous`);
      return;
    }
    log.debug('startListen');
    if (this.unsub) {
      log.info('firestore-invoker - already listening');
      return;
    }
    const docRef = this.userDataDocRef;
    const sendData = (snapshot: DocumentSnapshot<any>) => {
      log.debug('userData snapshot received');
      const data = snapshot.data(); // ?? {}; @jason, fyi, this was deviously nuking the anonymous progress data during the signup flow
      if (data) {
        cb(data);
      } else {
        log.info('empty snapshot, ignoring');
      }
    };

    this.unsub = onSnapshot(docRef, sendData);
  }

  stopListen() {
    if (!this.userDataUuid) {
      log.debug(`stopListen - ignored when anonymous`);
      return;
    }
    if (this.unsub) {
      this.unsub();
      this.unsub = null;
    }
  }
}
