import React from 'react';
import { observer } from 'mobx-react';
import { Classroom } from '@core/models/user-manager';
import { StoryCard } from 'components/stories/story-card';
import { storiesPrimaryFiltered } from 'components/nav/path-helpers';
import {
  SectionHeading,
  SectionHeadingLink,
} from './dashboard-section-heading';
import __ from '@core/lib/localization';
import { Divider } from '@naan/primitives/divider';

export const ClassroomWidget = observer(
  ({ classroom }: { classroom: Classroom }) => {
    const assignment = classroom.featuredAssignment;
    const { story } = assignment;

    const storiesPath = storiesPrimaryFiltered(classroom.filterKey);

    return (
      <>
        <SectionHeading
          title={classroom.label}
          renderLinks={() => (
            <SectionHeadingLink to={storiesPath}>
              {__('Show all', 'common.showAll')}
            </SectionHeadingLink>
          )}
        />
        <Divider />
        {story ? (
          <StoryCard story={story} assignment={assignment} />
        ) : (
          <>
            assignment.episodeSlug: {assignment.episodeSlug} (missing from
            current catalog)
          </>
        )}
      </>
    );
  }
);
