import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { getFirstScrollingParent } from 'lib/scroll-utils';
import { STORY_TITLE_ID } from 'components/story-detail/story-detail-card';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { createLogger } from '@common/log';

const log = createLogger('learn/scrolling-fx');

const CURRENT_CHAPTER_ID = 'current-chapter';
const CHAPTER_LIST_ID = 'chapter-list';

export function maybeCurrentId(chapter: ChapterCatalogData) {
  if (chapter.isCurrent) {
    return { id: CURRENT_CHAPTER_ID };
  }

  return {};
}

export function scrollToCurrentChapter(smooth = false) {
  const scrollingContainer = getFirstScrollingParent(
    document.getElementById('scroll-beacon')
  );
  if (!scrollingContainer) {
    log.warn(`scrollToCurrentChapter - scrollingContainer not found, ignoring`);
    return;
  }

  const currentChapter = document.getElementById(CURRENT_CHAPTER_ID);

  if (!currentChapter) {
    scrollingContainer.scrollTo({ top: 0, behavior: 'instant' });
  }

  const currentChapterTop = currentChapter?.offsetTop;
  const topCompensation = window.matchMedia('(max-width: 550px)').matches
    ? 112
    : 56;
  const windowHeight = scrollingContainer.clientHeight;
  const chapterList = document.getElementById(CHAPTER_LIST_ID);
  const chapterListTop = chapterList?.offsetTop ?? 0;

  scrollingContainer.scrollTo({
    top: Math.max(
      currentChapterTop - topCompensation - windowHeight / 2,
      chapterListTop - topCompensation
    ),
    left: 0,
    behaviour: smooth ? 'smooth' : 'instant',
  });
}

export function useStoryDetailScrollingFx() {
  React.useLayoutEffect(() => {
    const scrollingContainer = getFirstScrollingParent(
      document.getElementById('scroll-beacon')
    );
    if (scrollingContainer) {
      scrollToCurrentChapter();
      // scrollingContainer.scrollTo({ top: 0, behavior: 'instant' });
    }

    const intersectionObserver = new IntersectionObserver(
      entries => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          storyDetailUiModel.setShowTitleInHeader(false);
        } else {
          storyDetailUiModel.setShowTitleInHeader(true);
        }
      },
      {
        root: scrollingContainer,
      }
    );

    const titleElement = document.getElementById(STORY_TITLE_ID);
    if (!titleElement) {
      log.error('missing titleElement');
      return;
    }

    intersectionObserver.observe(titleElement);

    return () => {
      intersectionObserver.unobserve(titleElement);
      intersectionObserver.disconnect();
    };
  }, []);
}
