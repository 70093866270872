import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { HomeIcon } from 'naan/icons/home-icon';
import { AvatarIcon } from 'naan/icons/avatar-icon';
import {
  ExternalItemWrapper,
  LinkNavItem,
  NavArrow,
  NavBottomAnchor,
  NavContainer,
  NavItem,
  NavLogoWrapper,
  PopoverNavHeader,
  PopoverNavTrigger,
} from 'components/ui/navigation/responsive-navigation';
import { LupaLogo } from 'components/ui/lupa-logo';
import { MenuContent } from 'naan/primitives/menus/menu-content';
import { MenuItem } from 'naan/primitives/menus';
import { CouponIcon } from 'naan/icons/coupon-icon';
import { RetryIcon } from '@naan/icons/retry-icon';
import { LifebuoyIcon } from '@naan/icons/lifebuoy-icon';
import { SignInIcon } from '@naan/icons/sign-in-icon';
import { GiftIcon } from 'naan/icons/gift-icon';
import { SignOutIcon } from 'naan/icons/sign-out-icon';
import { PlayIcon } from 'naan/icons/play-icon';
import { ProgressIcon } from 'naan/icons/progress-icon';
import {
  ArrowTopRightExtraSmallIcon,
  ArrowTopRightIcon,
} from 'naan/icons/arrow-top-right-icon';
import { Text } from 'naan/primitives/text';
import { useNavigate } from 'react-router-dom';
import { useCouponDialog } from 'components/coupons/use-coupon-dialog';
import { Box } from 'naan/primitives/box';
import {
  createAccountPath,
  learnHomePath,
  learnProgressPath,
  learnStoriesPath,
  signInPath,
  teachHomePath,
} from './path-helpers';
import { Button } from 'naan/primitives/button';
import { Responsive } from '@naan/primitives/responsive';
import {
  useAnonymousCouponDialog,
  useAnonymousGiftCouponDialog,
} from 'components/coupons/use-anonymous-coupon-dialog';

import __ from 'core/lib/localization';
import { AppFactory } from '@app/app-factory';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ReactNativeWebView: any;
    embeddedPlatform: string;
  }
}
export const SharedMenuItems = () => {
  const navigate = useNavigate();
  return (
    <>
      <PurchaseFlowItems />
      <MenuItem
        label={__('Video guides and support', 'menu.videoGuidesAndSupport')}
        leftIcon={<LifebuoyIcon />}
        // rightIcon={<ArrowTopRightExtraSmallIcon />}
        presentation="navItem"
        action={() => {
          navigate('/app/support');
        }}
      />
      <MenuItem
        label={'Reload'}
        leftIcon={<RetryIcon />}
        presentation="navItem"
        action={() => {
          window.location.reload();
        }}
      />{' '}
    </>
  );
};

const PurchaseFlowItems = () => {
  const { purchaseFlowDisabled, authenticated } = AppFactory.root.userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => navigate(to),
    [navigate]
  );

  const handleApplyCode = useCouponDialog();
  const showInterstitialCouponDialog = useAnonymousCouponDialog();
  const showInterstitialGiftCouponDialog = useAnonymousGiftCouponDialog();

  // todo: better factor this with teach-navigation.tsx
  if (purchaseFlowDisabled) {
    return null;
  }

  return (
    <>
      <MenuItem
        label={'Redeem a code'}
        leftIcon={<CouponIcon />}
        presentation="navItem"
        action={authenticated ? handleApplyCode : showInterstitialCouponDialog}
      />
      <MenuItem
        label={'Gift coupons'}
        leftIcon={<GiftIcon />}
        presentation="navItem"
        action={
          authenticated
            ? goto('/app/profile/gift-coupons')
            : showInterstitialGiftCouponDialog
        }
      />
    </>
  );
};

const AuthMenuContent = () => {
  const { accountData, classroomEnabled } = AppFactory.root.userManager;

  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => navigate(to),
    [navigate]
  );

  const handleLogout = React.useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  return (
    <MenuContent sideOffset={-20} css={{ minWidth: '300px', marginLeft: 8 }}>
      <PopoverNavHeader>
        <Text textStyle="small-heading">{accountData.name}</Text>
        <Text color="textSecondary" textStyle="small-text">
          {accountData.email}
        </Text>
      </PopoverNavHeader>

      <MenuItem
        label={'Manage my account'}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto('/app/profile/account')}
      />
      <SharedMenuItems />
      <MenuItem
        label={'Sign out'}
        leftIcon={<SignOutIcon />}
        presentation="navItem"
        separatorAfter={false}
        action={handleLogout}
      />
      {classroomEnabled ? (
        <Responsive
          renderDefault={() => (
            <Box css={{ padding: 16 }}>
              <Button
                label={__('Go to Classrooms', 'common.goToClassrooms')}
                onClick={() => navigate(teachHomePath())}
                rightIcon={<ArrowTopRightIcon width={20} height={20} />}
                presentation={'grayLight'}
                size={'small'}
              />
            </Box>
          )}
          renderLargeAndUp={() => null}
        />
      ) : null}
      <DropdownMenu.Arrow offset={16} asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

const AnonMenuContent = () => {
  const navigate = useNavigate();

  const goto = React.useCallback(
    (to: string) => () => navigate(to),
    [navigate]
  );

  return (
    <MenuContent sideOffset={-20} css={{ minWidth: '300px', marginLeft: 8 }}>
      <MenuItem
        label={'Create account'}
        leftIcon={<AvatarIcon />}
        presentation="navItem"
        action={goto(createAccountPath())}
      />
      <MenuItem
        label={'Sign in'}
        leftIcon={<SignInIcon />}
        presentation="navItem"
        action={goto(signInPath())}
      />
      <SharedMenuItems />
      <DropdownMenu.Arrow offset={16} asChild>
        <NavArrow />
      </DropdownMenu.Arrow>
    </MenuContent>
  );
};

const NavBottom = () => {
  const { accountData, authenticated } = AppFactory.root.userManager;

  return (
    <NavBottomAnchor>
      <DropdownMenu.Root>
        <PopoverNavTrigger>
          <NavItem
            icon={<AvatarIcon />}
            label={
              <>
                <Box
                  css={{
                    display: 'none',
                    '@large': { display: 'block' },
                  }}
                >
                  {authenticated
                    ? accountData.name
                    : __('Account', 'nav.account')}
                </Box>
                <Box css={{ '@large': { display: 'none' } }}>
                  {authenticated
                    ? __('Me', 'nav.me')
                    : __('Account', 'nav.account')}
                </Box>
              </>
            }
          />
        </PopoverNavTrigger>
        {authenticated ? <AuthMenuContent /> : <AnonMenuContent />}
      </DropdownMenu.Root>
    </NavBottomAnchor>
  );
};

export const LearnNavigation = () => {
  // const { userManager: { classroomEnabled } } = AppFactory.root;
  const { classroomEnabled } = AppFactory.root.userManager;

  const navigate = useNavigate();

  return (
    <NavContainer>
      <NavLogoWrapper>
        <LupaLogo />
      </NavLogoWrapper>
      {classroomEnabled ? (
        <Responsive
          renderLargeAndUp={() => (
            <ExternalItemWrapper>
              <Button
                label={__('Go to Classrooms', 'common.goToClassrooms')}
                onClick={() => navigate(teachHomePath())}
                rightIcon={<ArrowTopRightExtraSmallIcon />}
                size={'small'}
                presentation={'grayLight'}
              />
            </ExternalItemWrapper>
          )}
        />
      ) : null}
      <LinkNavItem to={learnHomePath()} icon={<HomeIcon />} label={'Home'} />
      <LinkNavItem
        to={learnStoriesPath()}
        icon={<PlayIcon />}
        label={__('Stories', 'nav.stories')}
      />
      <LinkNavItem
        to={learnProgressPath()}
        icon={<ProgressIcon />}
        label={__('My stats', 'nav.myStats')}
      />
      <NavBottom />
    </NavContainer>
  );
};
