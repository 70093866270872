import * as React from 'react';
import { observer } from 'mobx-react';
import { ElementNode } from '@tikka/client/client-aliases';
import { AppFactory } from 'app/app-factory';
import { NotationsListPanel } from './notations';
import { PassageHintPresentation } from './elements-presentations/passage-hint-presentation';
import {
  // ChapterTitlePresentation,
  StartOfChapter,
} from './elements-presentations/chapter-title-presentation';
import { /*Chapter,*/ Passage } from '@tikka/client/client-aliases';
import { PlayerControls } from 'player/views/player-controls';
import {
  LayoutContainer,
  ScriptOuterContainer,
  ScriptInnerContainer,
  ControlsContainer,
} from './study-layout';
import { NotationsDetailsPanel } from './notations/notation-details-panel';
import {
  scrollToCurrentLine,
  lineScrollObserver,
  throttledScrollToCurrentLine,
} from './fx/scrolling';
import { Sentence, SpeakerLabel } from 'player/views/player-ui-elements';
import {
  PlayerUIConfig,
  usePlayerUIConfig,
} from 'player/views/player-ui-config';
import {
  useLineReconciler,
  useModelSetup,
} from 'player/views/player-ui-model-setup';

import {
  getScriptScrollingContainer,
  PlayerUIBody,
  PLAYER_CONTROLS_ID,
  SCRIPT_SCROLLING_CONTAINER_ID,
} from 'player/views/player-ui-components';
// import { GlobalRedactionButton } from './global-redaction-button';
import { useReaction } from '@common/hooks/use-reaction';
import { EndOfChapter } from './end-of-chapter';
import { elementIdToDomId } from '@tikka/elements/element-id-utils';
import { DebugOverlay } from 'player/views/player-ui-elements/debug-overlay';
import { computed } from 'mobx';
import { useOldIosScrollFix } from 'player/views/use-old-ios-scroll-fix';
import { createLogger } from 'app/logger';
import { toggleClassname } from '@utils/toggle-class';
import { KeyboardHelpOverlay } from 'components/ui/keyboard-help-overlay';
import { bugsnagNotify } from 'app/notification-service';
import { useAudioPauseOnBackground } from '@common/hooks/use-audio-pause-on-background';
import { StudyHeader } from './study-header';

const log = createLogger('study-ui');

const PassageHintUI = observer(({ node }: { node: ElementNode }) => {
  const reconciler = useLineReconciler();
  const passage = node.element as Passage;

  const passageId = passage.id;
  if (!reconciler) {
    bugsnagNotify('PassageHintUI - reconciler undefined');
    return null;
  }
  const className = reconciler.getJoinedMembershipStringForElement(passageId);
  const elementDomId = elementIdToDomId(null, passageId);

  return (
    <PassageHintPresentation
      id={elementDomId}
      element={passage}
      className={className}
    />
  );
});

const StudySentence = observer(({ node }: { node: ElementNode }) => {
  const model = AppFactory.studyModel;
  const elementId = node.element.id;
  // const selected = model.currentSentenceId === node.element.id;
  const showingNotationsPanel = computed(
    () => model.showingNotationsPanel && model.currentSentenceId === elementId
  ).get();

  return (
    <>
      <Sentence node={node} />
      {showingNotationsPanel ? <NotationsListPanel model={model} /> : null}
    </>
  );
});

const elementTypeMap: { [index: string]: any } = {
  // CHAPTER: ChapterTitleUI,
  PASSAGE: PassageHintUI,
  PARAGRAPH: SpeakerLabel,
  SENTENCE: StudySentence,
};

const config: PlayerUIConfig = { elementTypeMap };

const ScriptInner = React.memo(() => {
  const ref = React.useRef<HTMLDivElement>(null);

  const model = AppFactory.studyModel;

  useReaction(
    () => model.translationsShown,
    () => {
      toggleClassname(
        ref.current!,
        'showing-translation',
        model.translationsShown
      );
    }
  );

  return (
    <ScriptInnerContainer ref={ref}>
      <StartOfChapter />
      <PlayerUIBody />
      <EndOfChapter />
    </ScriptInnerContainer>
  );
});

export const ObservableKeyboardOverlay = observer(() => {
  log.debug('StudyUI - render');
  const { fluentListenMode } = AppFactory.studyModel;

  return <KeyboardHelpOverlay mode={fluentListenMode ? 'listen' : 'study'} />;
});

function usePauseOnScroll() {
  const SWIPE_PAUSE_THRESHOLD = 80;
  React.useEffect(() => {
    const model = AppFactory.playerModel;
    const scrolling = getScriptScrollingContainer();
    if (scrolling) {
      let y = 0;

      function onTouchStart(e: TouchEvent) {
        y = e.touches[0].clientY;
      }

      function onTouchMove(e: TouchEvent) {
        const y2 = e.touches[0].clientY;
        const delta = Math.abs(y2 - y);
        if (delta > SWIPE_PAUSE_THRESHOLD && model.isPlaying) {
          model.pause();
        }
      }

      function onTouchEnd(e: TouchEvent) {
        y = 0;
      }

      scrolling.addEventListener('touchstart', onTouchStart);
      scrolling.addEventListener('touchmove', onTouchMove);
      scrolling.addEventListener('touchend', onTouchEnd);

      return () => {
        scrolling.removeEventListener('touchstart', onTouchStart);
        scrolling.removeEventListener('touchmove', onTouchMove);
        scrolling.removeEventListener('touchend', onTouchEnd);
      };
    }
  }, []);
}

// function useNoSleep(containerRef: React.MutableRefObject<HTMLDivElement>) {
//   const model = AppFactory.studyModel;

//   React.useEffect(() => {
//     function enableNoSleep() {
//       containerRef.current.removeEventListener('click', enableNoSleep, false);
//       log.debug('enabling no sleep');
//       noSleep.enable();
//     }

//     containerRef.current?.addEventListener('click', enableNoSleep, false);

//     return () => {
//       log.debug('disabling no sleep');
//       noSleep.disable();
//     };
//   }, [containerRef, model]);
// }

function useWakeLock() {
  const wakeLock = AppFactory.wakeLock;

  React.useEffect(() => {
    log.debug('enabling wake lock handler');
    wakeLock.enableHandler();

    return () => {
      log.debug('disabling wake lock handler');
      wakeLock.disableHandler();
    };
  }, [wakeLock]);
}

export const StudyUI = React.memo(() => {
  log.debug('StudyUI - render');
  const model = AppFactory.studyModel;

  usePlayerUIConfig(config); // injects flavor specific behavior into player-ui-components
  useModelSetup(); // setups up membership reconcilers and reaction behaviors shared with both players
  useOldIosScrollFix();

  const containerRef = React.useRef<HTMLDivElement>(null);

  usePauseOnScroll();
  // useNoSleep(containerRef);
  useWakeLock();

  React.useEffect(() => {
    lineScrollObserver.connect();
    scrollToCurrentLine();
    /// I think this solves the weirdly placed notation panel after resizing
    window.addEventListener('resize', throttledScrollToCurrentLine);
  }, [model]);

  useReaction(
    () => model.player.transportState.isPlaying,
    () => {
      if (model.player.transportState.isPlaying) {
        scrollToCurrentLine();
      }
    }
  );

  useReaction(
    () => model.isPlaying,
    () => {
      const scriptContainer = document.getElementById(
        SCRIPT_SCROLLING_CONTAINER_ID
      );
      toggleClassname(scriptContainer, 'playing', model.isPlaying);
    }
  );

  useReaction(
    () => model.fluentListenMode,
    () => {
      toggleClassname(
        containerRef.current,
        'listen-mode',
        model.fluentListenMode
      );
    }
  );

  useAudioPauseOnBackground();

  return (
    <>
      <LayoutContainer ref={containerRef}>
        <StudyHeader />
        <ScriptOuterContainer id={SCRIPT_SCROLLING_CONTAINER_ID}>
          <ScriptInner />
        </ScriptOuterContainer>
        <ControlsContainer
          className="controls-container"
          id={PLAYER_CONTROLS_ID}
        >
          <PlayerControls />
        </ControlsContainer>
        <NotationsDetailsPanel />
        <DebugOverlay />
        <ObservableKeyboardOverlay />
      </LayoutContainer>
    </>
  );
});
