import * as React from 'react';
import { SnailIcon } from '@naan/icons/snail-icon';
import { styled } from '@naan/stitches.config';
import { ReplayIcon } from '@naan/icons/replay-icon';

const Wrapper = styled('div', {
  display: 'flex',
  background: '$white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: 24,
  '& > * + *': {
    borderLeft: '1px solid $gray-100',
  },
});

const PillButton = styled('button', {
  all: 'unset',
  height: 48,
  width: 96,
  borderRadius: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    side: {
      left: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      right: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
});

export const NotationControls = ({
  replay,
  snailReplay,
}: {
  replay: () => void;
  snailReplay: () => void;
}) => {
  return (
    <Wrapper>
      <span>
        <PillButton onClick={snailReplay} side="left">
          <SnailIcon width={28} height={28} />
        </PillButton>
      </span>
      <span>
        <PillButton onClick={replay} side="right">
          <ReplayIcon width={24} height={24} />
        </PillButton>
      </span>
    </Wrapper>
  );
};
