import React from 'react';
import { observer } from 'mobx-react';
import { FullAutoRenew } from './full-auto-renew';
import { FullNoRenew } from './full-no-renew';
import { GroupAccess } from './group-access';
import { AppFactory } from '@app/app-factory';

export const MembershipTable = observer(() => {
  const { membershipState } = AppFactory.root.userManager.accountData;

  switch (membershipState) {
    case 'trial':
      return null;

    case 'full-auto-renew':
    case 'suspended':
      return <FullAutoRenew />;

    case 'full-no-renew':
      return <FullNoRenew />;

    case 'group-access':
      return <GroupAccess />;

    default:
      return null;
  }
});
