import React from 'react';
import { observer } from 'mobx-react';
import { ExcerptData } from 'tikka/client/catalog-types';
import { styled } from '@naan/stitches.config';
import __ from '@core/lib/localization';
import { QuestionMarkdown } from '@naan/primitives/text/soundbite-question-markdown';
import { useReaction } from '@common/hooks/use-reaction';
import { usePlayerModel } from 'player/views/player-model-context';

const Wrapper = styled('div', {
  background: '$colors$white',
  padding: '16px 16px 4px 16px',
  transition: 'background-color 0.3s ease-out',

  '&.playing': {
    background: '$colors$white-alpha-10',
  },

  '& > .heading': {
    unset: 'all',
    textStyle: 'small-heading',
    color: '$colors$orange-500',
    marginBottom: 16,
    mixBlendMode: 'multiply',
  },

  '@widerThanScript': {
    maxWidth: 600,
    margin: '16px auto',
    borderRadius: 12,
  },
});

export const Question = observer(({ excerpt }: { excerpt: ExcerptData }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const model = usePlayerModel();

  useReaction(
    () => model.isPlaying,
    () => {
      if (model.isPlaying) {
        ref.current?.classList.add('playing');
      } else {
        ref.current?.classList.remove('playing');
      }
    }
  );

  if (!excerpt.prompt) {
    return null;
  }

  return (
    <Wrapper ref={ref}>
      <h2 className="heading ">{__('Question', 'soundbite.question')}</h2>
      <QuestionMarkdown source={excerpt.prompt} />
    </Wrapper>
  );
});
