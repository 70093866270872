import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { LupaLogo } from 'components/ui/lupa-logo';
import { HSpacer } from 'naan/primitives/spacer';
import { Link } from 'react-router-dom';
import { learnHomePath } from 'components/nav/path-helpers';
import { ArrowLeftSmallIcon } from '@naan/icons/arrow-left-icon';

const Wrapper = styled('div', {
  marginTop: 'env(safe-area-inset-top)',
  marginBottom: 'env(safe-area-inset-bottom)',
  position: 'relative',
  height: '100%',
  display: 'grid',
  placeItems: 'center',
  '& > .back-link': {
    position: 'absolute',
    top: '16px',
    left: '16px',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    '@medium': {
      top: '28px',
      left: '24px',
    },
  },
  '& > .inner': {
    paddingTop: 104,
    paddingBottom: 104,
    width: 'min( 400px, calc(100% - 32px) )',
    '@medium': {
      paddingTop: 80,
      paddingBottom: 88,
    },
  },
});

export const AuthLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper>
      <Link to={learnHomePath()} className="back-link">
        <ArrowLeftSmallIcon />
        <HSpacer size={1} />
        <LupaLogo />
      </Link>

      <div className="inner">{children}</div>
      {/* <WideInnerWrapper>
        <GlobalFooter />
      </WideInnerWrapper> */}
    </Wrapper>
  );
};
