import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { ProgressButton } from './progress-button';
import { VocabListButton } from './vocab-list-button';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { observer } from 'mobx-react';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@medium': {
    justifyContent: 'flex-end',
  },
  '& .right': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@medium': {
      marginLeft: '16px',
    },
  },
});

type StoryStatusBarSimpleProps = {
  progressPercentage: number;
  points: number;
  vocabCount: number;
};

// const ListeningNotesButton = () => (
//   <IconButton
//     onClick={() => {}}
//     size={'large'}
//     icon={<BookFilledLargeIcon color={'white'} />}
//   />
// );

export const StoryStatusBarSimple = ({
  progressPercentage,
  points,
  vocabCount,
}: StoryStatusBarSimpleProps) => {
  return (
    <Container>
      <ProgressButton progressPercentage={progressPercentage} points={points} />
      <div className="right">
        {/* <ListeningNotesButton /> */}
        <VocabListButton vocabCount={vocabCount} />
      </div>
    </Container>
  );
};

export const StoryStatusBar = observer(() => {
  const { studyProgressPercentage, totalPoints, vocabCount, unstarted } =
    useCurrentStory();

  if (unstarted) return null;

  return (
    <StoryStatusBarSimple
      progressPercentage={studyProgressPercentage}
      points={totalPoints}
      vocabCount={vocabCount}
    />
  );
});
