import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { getBaseRoot } from '../app-root';

export class Plan extends ModelTreeNode {
  static CLASS_NAME = 'Plan' as const;

  static create(snapshot: any) {
    return super.create(Plan, snapshot) as Plan;
  }

  slug: string = ''; // identifier
  price?: number;
  applePrice?: number;
  normalPrice?: number;
  pricingDescription?: string;
  pricingDescriptionKey?: string;
  discountPercentage?: number;
  hasSpecialPricing: boolean = false;

  // these are only relevant for gift coupon plans
  gift: boolean = false;
  cardDescriptionText?: string;
  cardDescriptionKey?: string;
  cardFootnoteText?: string;
  cardFootnoteKey?: string;

  async select(options?: string[] /*urls*/) {
    const { userManager } = getBaseRoot(this);
    return await userManager.initiateCheckout(this, options);
  }
}
