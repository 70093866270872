import React from 'react';
import { observer } from 'mobx-react';
import { SpinnerIcon } from 'components/ui/icons/spinner-icon';
import { Modal } from 'components/ds/modals/modal';
import { styled } from '@naan/stitches.config';

const Container = styled('div', {
  background: '$colors$gray-50',
  borderRadius: '8px',
  padding: '16px',
});

export const FullScreenLoader = observer(() => {
  return (
    <Modal>
      <Container>
        <SpinnerIcon />
      </Container>
    </Modal>
  );
});
