export const colors = {
  inherit: 'inherit',
  transparent: 'transparent',
  textPrimary: '#383838',
  textSecondary: '#909090',
  white: '#ffffff',
  'gray-20': '#fafafa',
  'gray-25': '#f9f9f9',
  'gray-50': '#f2f2f2',
  'gray-75': '#ececec',
  'gray-100': '#e6e6e6',
  'gray-200': '#cccccc',
  'gray-300': '#b3b3b3',
  'gray-400': '#999999',
  'gray-500': '#808080',
  'gray-600': '#666666',
  'gray-700': '#4d4d4d',
  'gray-800': '#383838',
  'gray-900': '#292929',
  'blue-50': '#ebf2f7',
  'blue-100': '#d9e5f0',
  'blue-200': '#b3cce1',
  'blue-300': '#8cb2d2',
  'blue-400': '#6699c3',
  'blue-500': '#3e7bae',
  'blue-600': '#3971a0',
  'blue-700': '#346792',
  'blue-800': '#2d597e',
  'blue-900': '#264c6c',
  'teal-50': '#e8f8fb',
  'teal-100': '#d1f1f6',
  'teal-200': '#a3e4ee',
  'teal-300': '#75d6e5',
  'teal-400': '#47c9dd',
  'teal-500': '#19bbd4',
  'teal-600': '#17acc3',
  'teal-700': '#1496aa',
  'teal-800': '#128395',
  'teal-900': '#0f707f',
  'green-50': '#ecf9ec',
  'green-100': '#daf3da',
  'green-200': '#b4e8b6',
  'green-300': '#8fdc91',
  'green-400': '#69d16d',
  'green-500': '#44c548',
  'green-600': '#3fb642',
  'green-700': '#39a53c',
  'green-800': '#308a33',
  'green-900': '#29762b',
  'yellow-50': '#fff9e5',
  'yellow-100': '#fef3cc',
  'yellow-150': '#feedb2',
  'yellow-200': '#fde899',
  'yellow-300': '#fddc66',
  'yellow-400': '#f9d23b',
  'yellow-500': '#fbc500',
  'yellow-600': '#e7b600',
  'yellow-700': '#c69f08',
  'yellow-800': '#ad8c07',
  'yellow-900': '#947706',
  'orange-50': '#fef2e9',
  'orange-100': '#fde6d5',
  'orange-200': '#fbcdaa',
  'orange-300': '#f9b580',
  'orange-400': '#f79c55',
  'orange-500': '#f5832b',
  'orange-600': '#dd7627',
  'orange-700': '#c46922',
  'orange-800': '#ac5c1e',
  'orange-900': '#934f1a',
  'red-50': '#ffeded',
  'red-70': '#ffe5e5',
  'red-100': '#ffdddc',
  'red-200': '#ffbab9',
  'red-300': '#ff9a9a',
  'red-400': '#ff7878',
  'red-500': '#ff534f',
  'red-600': '#eb4c49',
  'red-700': '#cc4545',
  'red-800': '#b33c3c',
  'red-900': '#993434',
  'pink-300': '#e9a4d6',
  'white-alpha-03': 'rgba(255, 255, 255, 0.03)',
  'white-alpha-06': 'rgba(255, 255, 255, 0.06)',
  'white-alpha-10': 'rgba(255, 255, 255, 0.1)',
  'white-alpha-20': 'rgba(255, 255, 255, 0.2)',
  'white-alpha-30': 'rgba(255, 255, 255, 0.3)',
  'white-alpha-40': 'rgba(255, 255, 255, 0.4)',
  'white-alpha-50': 'rgba(255, 255, 255, 0.5)',
  'white-alpha-60': 'rgba(255, 255, 255, 0.6)',
  'white-alpha-70': 'rgba(255, 255, 255, 0.7)',
  'white-alpha-80': 'rgba(255, 255, 255, 0.8)',
  'white-alpha-90': 'rgba(255, 255, 255, 0.9)',
  'black-alpha-06': 'rgba(0, 0, 0, 0.06)',
  'black-alpha-10': 'rgba(0, 0, 0, 0.1)',
  'black-alpha-15': 'rgba(0, 0, 0, 0.15)',
  'black-alpha-20': 'rgba(0, 0, 0, 0.2)',
  'black-alpha-40': 'rgba(0, 0, 0, 0.4)',
  'black-alpha-30': 'rgba(0, 0, 0, 0.3)',
  'black-alpha-50': 'rgba(0, 0, 0, 0.5)',
  'black-alpha-60': 'rgba(0, 0, 0, 0.6)',
  'black-alpha-70': 'rgba(0, 0, 0, 0.7)',
  'black-alpha-80': 'rgba(0, 0, 0, 0.8)',
  'black-alpha-90': 'rgba(0, 0, 0, 0.9)',
  'gray-800-alpha-90': 'rgba(56, 56, 56, 0.9)',
  'blue-500-alpha-50': 'rgba(62, 123, 174, 0.5)',
  'red-500-alpha-50': 'rgba(255, 83, 79, 0.5)',
  'teal-500-alpha-30': 'rgba(25, 187, 212, 0.3)',
  'teal-500-alpha-50': 'rgba(25, 187, 212, 0.5)',
} as const;
