import * as React from 'react';
import ReactDOM from 'react-dom';
import { configure } from 'mobx';
import { bugsnagEnabled } from './bugsnag-init'; // make sure to init bugsnag as early as possible
import App from './app';
import { BugReportingBoundary } from 'common/bugsnag/bug-reporting-boundary';
import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
// import { globalErrorHandler } from './global-error-handler';
import { GlobalStyles } from 'naan/global-styles';
import './root-listeners';

import 'lib/services/localization-service';
// import { ReloadPrompt } from 'pwa/window/reload-prompt-attic';
import { createLogger } from '@common/log';
import { UpdateManager } from 'pwa/window/update-manager';

const log = createLogger('index');

/// hack to mock window.process, for some reason our markdown library requires it
(window as any).process = { cwd: () => '' };
(window as any).Bugsnag = Bugsnag;

// always attempt to listen for updates except for dev build
const updateManagerEnabled = import.meta.env.PROD;

log.debug(
  `updateManagerEnabled: ${String(
    updateManagerEnabled
  )}, bugsnagEnabled: ${String(bugsnagEnabled)}`
);

// disable mobx strict-mode
configure({
  enforceActions: 'never',
});

ReactDOM.render(
  <BugReportingBoundary bugsnagStatic={Bugsnag} bugsnagEnabled={bugsnagEnabled}>
    <GlobalStyles />
    <App />
    {updateManagerEnabled ? <UpdateManager /> : null}
  </BugReportingBoundary>,
  document.getElementById('root')
);
