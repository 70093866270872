import * as React from 'react';
import { AppFactory } from 'app/app-factory';
import { ToastService } from 'common/notifications/toast-service';
import { Toast, ToastTarget } from '../../components/ui/toasts';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react';
import { usePageTracker } from 'lib/hooks/use-page-tracker';
import { InviteGate } from 'routes/auth/invite-gate';
import { embeddedMode, postNativeMessage } from '@app/app-util';
import { createLogger } from '@common/log';
import { FullScreenLoader } from 'components/ds/modals/full-screen-loader';
import { PiMenu } from 'components/pi-menu.tsx';

const log = createLogger('app-wide-layout');

export const AppWideRouteLayout = observer(() => {
  const toast = AppFactory.toastService.current;
  usePageTracker(); // don't track non-authenticated pages until anonymous metrics revisited

  const { authenticated } = AppFactory.root.userManager;
  const anonymousEmbedded = !authenticated && embeddedMode();

  React.useEffect(() => {
    if (anonymousEmbedded) {
      log.info(
        'embedded anonymous access detected - signally sign-out to wrapper'
      );
      postNativeMessage({
        type: 'string',
        payload: 'WEB-VIEW-NATIVE-SIGN-OUT',
      });
    }
  }, [anonymousEmbedded]);

  if (anonymousEmbedded) {
    return <FullScreenLoader />;
  }

  const { inviteNeeded } = AppFactory.root;
  if (inviteNeeded) {
    return <InviteGate />;
  }

  return (
    <>
      <Outlet />
      <PiMenu />
      <ToastTarget
        showNotification={!!toast}
        css={{ '@large': { left: '$$sidebarWidth' }, padding: '$space$2' }}
      >
        {toast ? (
          <Toast
            type={toast.type}
            label={toast.message}
            onDismiss={ToastService.clear}
            action={toast.action}
          />
        ) : null}
      </ToastTarget>
      {AppFactory.dialogPresenter.element}
    </>
  );
});
