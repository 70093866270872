import { reportException } from 'common/error-reporting';
import { action, observable } from 'mobx';

import Bugsnag from '@bugsnag/browser';
// import Event from '@bugsnag/core/types';
import {
  GenericError,
  GENERIC_SEVERE_ERROR_MESSAGE,
  HandleableError,
} from '@core/lib/errors';
import { alertLevels } from '@core/lib/errors/alert-levels';
import { createLogger } from '@common/log';
import { appConfig } from '@app/env';
import { isNetworkError } from '@core/lib/error-handling';
import __ from '@core/lib/localization';
const log = createLogger('notification-service');

const { NONE } = alertLevels;

// include raw error message in toasts for generically handled exceptions
const { showErrorDetails } = appConfig;

const notificationTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
} as const;

type TNotificationType =
  typeof notificationTypes[keyof typeof notificationTypes];

export type TNotificationAction = {
  label: string;
  callback: () => void;
};

export type TNotification = {
  message: string;
  type: TNotificationType;
  action?: TNotificationAction;
  timeout?: number;
};

export type TNotificationService = ReturnType<typeof createNotificationService>;

const DEFAULT_NOTIFICATION_TIMEOUT = 5000;

/**
 * Creates an observable notification object.
 *
 * TODO: sometime, consider using a queue instead.
 */
export function createNotificationService() {
  const current = observable.box<TNotification | null>(null);
  let timeoutId: number | null = null;

  const open = action((notification: TNotification) => {
    if (timeoutId) {
      window.clearTimeout(timeoutId);
    }

    const newNotification = {
      timeout: DEFAULT_NOTIFICATION_TIMEOUT,
      ...notification,
    };

    /// If we have a notification already set, we need to close it first.
    if (current.get() !== null) {
      clear();
      ///… and then open the new one.
      timeoutId = window.setTimeout(() => {
        current.set(newNotification);
      }, 300);
    } else {
      /// open the new one right away.
      current.set(newNotification);
    }
  });

  const clear = action(() => {
    current.set(null);
  });

  return { open, clear, current };
}

// todo: consider using the getInstance pattern here

// Singleton.
export const NotificationService = createNotificationService();

(window as any).NotificationService = NotificationService;

// not sure the best home for this. need to jump some hops to be able to log some meessages
// directly to bugsnag without triggering our red toast UI

const bugsnagEnabled = appConfig.bugsnag.enabled;

export const bugsnagNotify = (errorOrString: Error | string) => {
  log.error(errorOrString);
  if (!bugsnagEnabled) {
    return;
  }
  if (errorOrString instanceof Error) {
    bugsnagNotifyError(errorOrString);
  } else {
    bugsnagNotifyMessage(errorOrString);
  }
};

const bugsnagNotifyMessage = (message: string) =>
  Bugsnag.notify(createBugsnagNotifyOnlyError(message));

const createBugsnagNotifyOnlyError = (message: string) =>
  new HandleableError(message, {
    alertLevel: NONE,
    alert: false, // probably not relevant
    report: true,
    level: 'bugsnag-only',
  });

const bugsnagNotifyError = (error: Error) => {
  const decorated = error as HandleableError;
  decorated.alertLevel = NONE;
  decorated.alert = false; // probably not relelvant
  decorated.report = true;
  Bugsnag.notify(decorated);
};

// @deprecated
export const notifySuccess = (message: string) => {
  // log.info(`success: ${message}`);
  NotificationService.open({
    type: notificationTypes.SUCCESS,
    message,
  });
};

// @deprecated
export const alertWarning = (message: string) => {
  // log.info(`warn: ${message}`);
  NotificationService.open({
    type: notificationTypes.WARNING,
    message,
  });
};

export const alertWarningError = (data = {}) => {
  const { error, ...restOfData } = data as any; // todo: typing
  log.error(`alertWarningError - ${JSON.stringify(restOfData)}`);
  log.error(error.stack);
  const forwardData = { report: true, exception: error, ...restOfData };
  const message = showErrorDetails
    ? `${error} [unexpected]`
    : 'Unexpected error';
  forwardException(message, forwardData);
  alertWarning(message);
};

// todo: refactor this with error-handling.safelyHandleError
export const sanitizeErrorMessage = (error: Error): string => {
  if (isNetworkError(error)) {
    return __('Network request failed', 'errors.networkError');
  }

  if (error instanceof GenericError) {
    if (error.userMessage) {
      return error.userMessage;
    }

    // not sure if this is relevant, but feels reasonable
    if (error.expected) {
      return error.message;
    }
  }

  const result = showErrorDetails
    ? `${error} [unexpected]`
    : GENERIC_SEVERE_ERROR_MESSAGE;
  return result;
};

// @deprecated
export const alertSevereError = (data = {}) => {
  const { error, ...restOfData } = data as any; // todo: typing
  log.error(`alertSevereError - ${JSON.stringify(restOfData)}`);
  log.error(error.stack);
  const forwardData = { report: true, exception: error, ...restOfData };
  const message = sanitizeErrorMessage(error);
  alertError(message, forwardData);
};

// @deprecated
export const alertError = (message: string, data = {}) => {
  /// FIXME: In retrospective, this is a bad pattern. Notifications should not have side effects.
  /// this saves us keystrokes, but makes things fragile.
  /// @armando, lets discuss a better pattern soon (once the dust settles)
  forwardException(message, data);
  NotificationService.open({
    type: notificationTypes.ERROR,
    message,
  });
};

// @deprecated
const forwardException = (message: string, data: any = {}) => {
  const { report, exception, level = 'error', handlerTag, ...debugData } = data;

  if (report === false) {
    return;
  }

  if (exception) {
    reportException(exception, { ...debugData }, { handlerTag, level });
  } else {
    // Sentry.captureMessage(message, level);
    // console.log(`TODO Sentry.captureMessage(${message}, ${level})`);
  }
};

// export const NotificationsTarget = observer(() => {
//   const notification = NotificationService.current.get();
//   if (notification === null) {
//     return null;
//   }
//   return (
//     <Notification {...notification} onDismiss={NotificationService.clear} />
//   );
// });
