import * as React from 'react';
import { Story } from '@core/models/story-manager';
import { Button } from '@naan/primitives/button';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { ReplayIcon } from '@naan/icons/replay-icon';
import { CheckmarkCircleIcon } from '@naan/icons/checkmark-circle-icon';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { ResetStoryDialog } from './reset-story-dialog';
import { AppFactory } from '@app/app-factory';
import { useNavigateToStudy } from 'lib/hooks/use-navigate-to-study';
import { PlayerMode } from '@common/misc-types';
import { scrollToCurrentChapter } from 'routes/learn/scrolling-fx';
import { notNil } from '@utils/conditionals';
import { Sticky } from './sticky.styles';
import { CheckmarkHeavyIcon } from '@naan/icons/checkmark-heavy-icon';
import __ from '@core/lib/localization';

const Outer = styled(Sticky, {
  borderBottom: '1px solid $gray-100',
  backgroundColor: '$white',
  bottom: 0,
  top: 'calc($$smallHeaderHeight + $$storyStatusHeight)',
  '@medium': {
    top: '$$mediumHeaderHeight',
  },
  variants: {
    inAssignment: {
      true: {
        top: 'calc($$smallHeaderHeight + $$storyStatusHeight + $$assignmentBannerHeight)',
        '@medium': {
          top: 'calc($$mediumHeaderHeight + $$assignmentBannerHeight)',
        },
      },
    },
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 80,
  '> * + *': {
    marginLeft: 8,
  },
  '& > .complete-label': {
    display: 'none',
    '@small': {
      display: 'flex',
      marginRight: 'auto',
      textStyle: 'small-heading',
      '& > .icon': {
        color: '$gray-400',
        marginRight: 8,
      },
    },
  },
});

export const StoryCompleteControls = observer(({ story }: { story: Story }) => {
  const navigateToStudy = useNavigateToStudy();

  const handleResetStory = React.useCallback(() => {
    AppFactory.dialogPresenter.present(onDismiss => (
      <ResetStoryDialog
        onReset={() => {
          story.progress.resetStory();
          scrollToCurrentChapter();
          onDismiss();
        }}
        onDismiss={onDismiss}
      />
    ));
  }, [story]);

  const handleReviewStory = React.useCallback(() => {
    const chapter = story.firstChapterOfFirstUnitData;
    chapter.review();
    navigateToStudy(chapter, PlayerMode.FLUENT_LISTEN);
  }, [navigateToStudy, story]);

  const handleExitReview = React.useCallback(() => {
    story.progress.markStoryComplete();
  }, [story]);

  if (!story.completed) {
    return null;
  }

  const assignment = story.joinedClassroomAssignment;

  return (
    <Outer inAssignment={notNil(assignment)}>
      <CenterColumnLayout>
        <Wrapper>
          <div className="complete-label">
            <span className="icon">
              <CheckmarkCircleIcon />
            </span>
            {__('Complete', 'story.complete')}
          </div>
          <div>
            <Button
              label={'Reset story'}
              presentation={'grayLight'}
              size={'medium'}
              onClick={handleResetStory}
            />
          </div>
          <div>
            {story.inProgress ? (
              <Button
                label={'Exit review'}
                presentation={'grayLight'}
                size={'medium'}
                leftIcon={<CheckmarkHeavyIcon />}
                onClick={handleExitReview}
              />
            ) : (
              <Button
                label={'Review'}
                presentation={'grayLight'}
                size={'medium'}
                leftIcon={<ReplayIcon />}
                onClick={handleReviewStory}
              />
            )}
          </div>
        </Wrapper>
      </CenterColumnLayout>
    </Outer>
  );
});
