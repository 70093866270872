import React from 'react';
import { observer } from 'mobx-react';
import { AppFactory } from 'app/app-factory';
import { ClientNotation } from '@tikka/client/client-types';
import { styled } from '@naan/stitches.config';
import { InVocabLargeIcon } from '@naan/icons/in-vocab-icon';
import { IconButton } from '@naan/primitives/button/icon-button';
import { AddToVocabLargeIcon } from '@naan/icons/add-to-vocab-icon';
import { PlainMarkdown } from '@naan/primitives/text';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 28px',
  padding: '8px 0 12px',

  '@playerShort': {
    padding: '6px 0',
  },

  '& .head': {
    cursor: 'default',
    '& .single-line': {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
    '& dt': {
      textStyle: 'serif',
      // color: 'blue',
      '@playerShort': {
        textStyle: 'serif-small',
        // color: 'red',
      },
    },
    '& dd': {
      textStyle: 'small-text',
      color: '$colors$textSecondary',
    },
  },
  '& .actions': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const NotationsListItem = observer(
  ({
    notation,
    addVocab,
    removeVocab,
    vocabExists,
  }: {
    notation: ClientNotation;
    addVocab: (slug: string) => void;
    removeVocab: (slug: string) => void;
    vocabExists: (slug: string) => boolean;
  }) => {
    const model = AppFactory.studyModel;

    // todo: factor with vocab review and notatil detail
    const text = !!notation.usageText
      ? `${notation.usageText} (${notation.headword})`
      : notation.headword;

    return (
      <Wrapper>
        <div
          className="head"
          onClick={() => model.selectNotationId(notation.id)}
        >
          <dl>
            <dt className="single-line">
              <PlainMarkdown source={text} />
            </dt>
            {notation.note ? (
              <dd className="single-line">
                <PlainMarkdown source={notation.note} />
              </dd>
            ) : null}
          </dl>
        </div>
        <div className="actions">
          {vocabExists(notation.id) ? (
            <IconButton
              presentation={'grayTransparent'}
              onClick={() => removeVocab(notation.id)}
              icon={<InVocabLargeIcon />}
            />
          ) : (
            <IconButton
              presentation={'grayTransparent'}
              onClick={() => addVocab(notation.id)}
              icon={<AddToVocabLargeIcon />}
            />
          )}
        </div>
      </Wrapper>
    );
  }
);
