import React from 'react';
import * as PricingCard from '../account/pricing-cards/pricing-card';
import { useStripeCheckout } from 'common/stripe-context';
import { Plan } from '@core/models/user-manager';
import { VSpacer } from '@naan/primitives/spacer';
import { formatMoney } from '@utils/format-money';
import { styled } from '@naan/stitches.config';

import __ from 'core/lib/localization';
import { Button } from '@naan/primitives/button';
import { alertSevereError } from '@app/notification-service';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

const Footnote = styled('div', {
  width: '100%',
  textStyle: 'small-text',
  color: '$textSecondary',
  textAlign: 'center',
  marginTop: '16px',
});

export const Ecoupon = ({ plan }: { plan: Plan }) => {
  const {
    price,
    // normalPrice,
    cardDescriptionText,
    discountPercentage,
    hasSpecialPricing,
    pricingDescription,
    cardFootnoteText,
  } = plan;

  const isDiscounted = discountPercentage > 0;

  const stripeCheckout = useStripeCheckout();
  const handleCheckout = React.useCallback(() => {
    plan
      .select()
      .then(result => {
        return stripeCheckout(result.stripeSessionId);
      })
      .catch(error => {
        alertSevereError({ error, note: 'Ecoupon.handleCheckout' });
      });
  }, [plan, stripeCheckout]);

  let ribbonTitle = pricingDescription;

  if (hasSpecialPricing && isDiscounted) {
    ribbonTitle = __(
      '%{pricingDescription}: save %{discountPercentage}%',
      'plans.discountedRibbonTitle',
      {
        pricingDescription,
        discountPercentage,
      }
    );
  } else if (isDiscounted) {
    ribbonTitle = __(
      'Save %{discountPercentage}%',
      'plans.savePercentageRibbonTitle',
      {
        discountPercentage,
      }
    );
  }

  const showRibbon = hasSpecialPricing || isDiscounted;

  return (
    <Wrapper>
      <PricingCard.Container
        presentation={'coupon'}
        data-test-id={cardDescriptionText}
      >
        {showRibbon ? <PricingCard.Ribbon ribbonTitle={ribbonTitle} /> : null}

        <PricingCard.Heading asterisk>
          {cardDescriptionText}
        </PricingCard.Heading>

        <PricingCard.PriceContainer>
          <PricingCard.PricePrefix>US</PricingCard.PricePrefix>
          <PricingCard.PriceAmount>
            {formatMoney(price)}{' '}
          </PricingCard.PriceAmount>
        </PricingCard.PriceContainer>

        <PricingCard.Description>
          {__('All Lupa stories', 'plans.allLupaStories')}
        </PricingCard.Description>

        <VSpacer size={10} />

        <Button
          onClick={handleCheckout}
          label={__('Purchase', 'account.purchase')}
          fullWidth
          size="large"
          presentation="teal"
        />
      </PricingCard.Container>
      <Footnote>* {cardFootnoteText}</Footnote>
    </Wrapper>
  );

  // return (
  //   <PricingCard
  //     cta={__('Purchase', 'account.purchase')}
  //     // discountPercentage={discountPercentage}
  //     featureTitle={__('All Lupa stories', 'plans.allLupaStories')}
  //     isDiscounted={discountPercentage > 0}
  //     normalPrice={normalPrice}
  //     price={price}
  //     ribbonTitle={ribbonTitle}
  //     showRibbon={hasSpecialPricing || isDiscounted}
  //     title={cardDescriptionText}
  //     titleAsterisk={true}
  //     type={'coupon'}
  //     onCtaClick={handleCheckout}
  //     footNote={cardFootnoteText}
  //     // className={'e-coupon'}
  //   />
  // );
};
