import React from 'react';
import { observer } from 'mobx-react';
import useSWR from 'swr';
import { HStack, VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { HSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
import { Select } from 'naan/primitives/input/select';
import { AppFactory } from 'app/app-factory';
import { A } from 'common/ui/action-link';
import { createLogger } from '@common/log';
import { notEmpty } from '@utils/conditionals';
import { agePretty } from '@core/lib/pretty-duration';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('catalog-selection');

const fetcher = (/*userId: string*/) =>
  AppFactory.root.userManager.fetchCatalogSlugs();

export const CatalogSelection = observer(() => {
  const { userManager, storyManager } = AppFactory.root;
  const { accountData } = userManager;

  const { data, error } = useSWR<string[], any, any>(
    accountData.userId,
    fetcher
  );
  const catalogSlugs = data || [];
  log.info(`cs.data: ${JSON.stringify(data)}`);

  const handleSelection = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const slug = form.get('slug').toString();
    if (notEmpty(slug)) {
      userManager.updateCatalogSlug(slug).catch(bugsnagNotify);
    }
  };

  if (error) {
    return <div className="App">{String(error)}</div>;
  }

  return (
    <>
      <form onSubmit={handleSelection} noValidate>
        <VStack>
          <HStack align={'center'}>
            <Text>Update v4 catalog slug</Text>
            <HSpacer size={1} />
            {/* // @armando, any idea why the 'defaultValue' doesn't seem to work here? */}
            <Select name="slug" defaultValue={accountData.catalogSlug}>
              <option key="not-selected" value=""></option>
              {catalogSlugs.map(slug => (
                <option key={slug} value={slug}>
                  {slug}
                </option>
              ))}
            </Select>
            <HSpacer size={1} />
            <Button label="Select" />
          </HStack>
          Currently active v4 catalog:{' '}
          <A href={accountData.catalogUrl}>{accountData.catalogSlug} &rarr;</A>{' '}
          v{storyManager.version}, {agePretty(storyManager.generatedAt)}
        </VStack>
      </form>
    </>
  );
});
