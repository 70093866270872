/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSwitch } from '@naan/hooks/use-switch';
import { styled } from '@naan/stitches.config';
import { AlertTriangleIcon } from '@naan/icons/alert-triangle-icon';
import { Tooltip } from '@naan/primitives/tooltip';
import __ from 'core/lib/localization';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$colors$textSecondary',
  display: 'flex',
  marginLeft: '8px',

  '&.with-tip': {
    cursor: 'pointer',
  },

  '& > span': {
    color: '$colors$red-500',
    marginLeft: '4px',
  },
});

export const LicenseAccessWarningTip: React.FC = ({ children }) => {
  const [showTip, { toggle }] = useSwitch(false);
  return (
    <Wrapper className="with-tip" onClick={toggle}>
      {children}
      <Tooltip
        tipContent={
          __(
            'Some assigned stories in this class require full access',
            'classroom.someAssignedStoriesRequireFullAccess'
          ) as any
        }
        showTip={showTip}
        placement={'top'}
      >
        <span className="icon">
          <AlertTriangleIcon />
        </span>
      </Tooltip>
    </Wrapper>
  );
};
