import { snakeCase } from 'lodash';
import fetch from 'cross-fetch';
import { makeObservable, observable } from 'mobx';

import { NetworkError, createError } from 'core/lib/errors';
import { getLocale } from 'core/lib/localization';
import invariant from 'core/lib/invariant';
import { sleep } from 'utils/util';

import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';

const log = createLogger('api-invoker');

const createQueryString = (obj: any = null) => {
  if (!obj) return '';
  const string = Object.keys(obj)
    .map(key => {
      return `${snakeCase(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');

  return `?${string}`;
};

// must be true for endpoints which support cross-domain cookies
// (and are restricted the fixed list of origin hosts)
// must be false for endpoints which allow requests from any host (i.e. devtest)
const includeCredentialsEnabled = (targetApiEnv: string) => {
  const { promiscuousApiEnvs } = appConfig;
  return !promiscuousApiEnvs.includes(targetApiEnv);
};

const decorateFetchOptions = (options = {}) => {
  // so we can override the headers
  const {
    apiEnv,
    apiKey,
    appSlug,
    platform,
    manifestVersion,
    headers: optionHeaders = {},
    token,
    appInstallAttribution,
    ...restOfOptions
  }: any = options;

  invariant(apiKey, `apiKey configuration is required for API requests`);

  const locale = getLocale();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${apiKey}`,
    // the normal User-Agent can't be overriden for web, so the rails
    // server uses X-User-Agent for the app/platform/version logic
    'X-User-Agent': `${appSlug}/${platform}/${manifestVersion}`,
    'Accept-Language': locale,
    ...optionHeaders,
  };

  if (token) {
    headers['X-User-Token'] = token;
  }

  if (appInstallAttribution) {
    headers['X-App-Install-Attribution'] = JSON.stringify(
      appInstallAttribution
    );
  }

  if (includeCredentialsEnabled(apiEnv)) {
    // credentials option needed for cross-site cookies
    return { headers, ...restOfOptions, credentials: 'include' };
  } else {
    // credentials option must be omitted when accessing devtest
    return { headers, ...restOfOptions };
  }
};

interface AuxParams {
  bodyData?: object; // relevant for post, put
  networkIndicator?: boolean;
}

/**
 * Helper object to provide an abstraction to access the api
 * with baked-in loading flag.
 */
export class ApiInvoker {
  // root: Root;

  apiEnv: string;

  authToken: string; // auth token, assuming updated as needed by userManager

  // todo: confirm if this is still relevant
  appInstallAttribution: object = {};

  @observable
  loadingData: boolean = false;

  @observable
  simulateNetworkFailure: boolean = false;

  constructor({
    apiEnv,
    authToken,
    appInstallAttribution = {},
  }: {
    apiEnv: string;
    authToken: string;
    appInstallAttribution?: object;
  }) {
    // this.root = root;
    this.apiEnv = apiEnv;
    this.authToken = authToken;
    this.appInstallAttribution = appInstallAttribution;
    makeObservable(this);
  }

  setAuthToken(token: string) {
    this.authToken = token;
  }
  // /**
  //  * alias for window.fetch
  //  * @param  {...any} args
  //  */
  // async fetch(...args): Promise<Response> {
  //   // const { context = { addAction: () => {} } } = getEnv(self);

  //   // const root = getRoot(self);
  //   // const { simulateNetworkFailure = false } = root || {};
  //   if (!this.simulateNetworkFailure) {
  //     // // this is useful for testing purposes
  //     // const overrideEndpoint = env.get('overrideEndpoint', false);
  //     // if (overrideEndpoint) {
  //     //   args[0] = overrideEndpoint;
  //     // }

  //     // this was breaking the ts schema gen for some reason
  //     return window.fetch?.apply(null, args);
  //     // return fetch(...args);
  //   }

  //   // context.addAction({
  //   //   name: 'fetch-offline',
  //   //   path: 'ApiAccess',
  //   //   type: 'mobx',
  //   //   args,
  //   // });

  //   // temporal measure.
  //   // console.log('DOING NOTHING');
  //   return Promise.reject(new TypeError('Disconnected'));
  // }

  /**
   * make a GET request to the API
   * @param {*} endpoint
   * @param {*} query
   */
  get<T = any>(
    endpoint: string,
    query: object,
    params: AuxParams = {}
  ): Promise<T> {
    log.info(`apiGet ${endpoint}`);
    return this.api(endpoint, query, params);
  }

  /**
   * make a POST request to the API
   * @param {*} endpoint
   * @param {*} query
   */
  post<T = any>(
    endpoint: string,
    query: object,
    // bodyData: object
    params: AuxParams = {}
  ): Promise<T> {
    const { bodyData, ...remainderParams } = params;
    log.info(`apiPost ${endpoint}`, JSON.stringify(remainderParams));
    const options: any = { ...remainderParams, method: 'POST' };

    if (bodyData) {
      options.body = JSON.stringify(bodyData);
      // console.log('BODY', options.body);
    }
    return this.api(endpoint, query, options);
  }

  put<T = any>(
    endpoint: string,
    query: object,
    // bodyData: object = null
    params: AuxParams = {}
  ): Promise<T> {
    const { bodyData, ...remainderParams } = params;
    log.info(`apiPut ${endpoint}`);
    const options: any = { ...remainderParams, method: 'PUT' };

    if (bodyData) {
      options.body = JSON.stringify(bodyData);
    }
    return this.api(endpoint, query, options);
  }

  /**
   * utility function to make API requests
   *
   * It automatically starts and stop loading,
   * maybe we should add error catching here too.
   *
   * todo: support generically stuffing the 'X-User-Token' header
   */
  async api(
    endpoint: string,
    query: object = null,
    options: object
  ): Promise<any> {
    // console.log(`api: ${endpoint}`);
    // const token = this.userManager.token || null;
    const token = this.authToken;
    // marketing cookie data
    const appInstallAttribution = this.appInstallAttribution;
    // needed for dynamic CORS credentials handling
    const apiEnv = this.apiEnv;

    // get platform-related stuff
    const platformName = 'website'; //TODO platform.getPlatform();
    const apiKey = appConfig.jiveworldApiKey[platformName];
    const manifestVersion = 'TODO'; // platform.getProductVersion();
    const { appSlug } = appConfig;

    const { networkIndicator, ...restOfOptions }: any = options;
    // don't clear at end if already set.
    // allows indicator to handled at outer layer if wished
    const applyNetworkIndicator = networkIndicator && !this.loadingData;
    log.info('networkIndicator', networkIndicator);

    const fetchOptions = decorateFetchOptions({
      ...restOfOptions,
      apiEnv,
      apiKey,
      appSlug,
      platform: platformName,
      manifestVersion,
      token,
      appInstallAttribution,
    });

    try {
      if (query) {
        endpoint += createQueryString(query);
      }
      log.debug(
        `api: ${fetchOptions.method || 'GET'} ${this.baseUrl}${endpoint}`
      );
      if (applyNetworkIndicator) {
        this.startLoading(); // sets the loading flag on the root
      }

      if (this.simulateNetworkFailure) {
        await sleep(1000);
        // todo: figure out what a real network error looks like
        throw new NetworkError('simulated network failure');
      }

      const url = `${this.baseUrl}${endpoint}`;
      const response = await fetch(url, fetchOptions); // TODO: support disconnected mode testing

      // todo: any other statuses to let through?
      if (response.status && response.status >= 500) {
        throw new NetworkError(`Server error: ${response.status}`, {
          endpoint,
          query,
          fetchOptions,
        });
      }
      let data = null;
      try {
        data = await response.json();
      } catch (error) {
        log.warn(`json parse failure: ${error}`);
        // treat html response same as a 500 or other network failure
        throw new NetworkError(error as Error, {
          endpoint,
          query,
          fetchOptions,
        });
      }

      const { error, result } = data;
      if (error) {
        // throwing here triggers the catch below
        // the createError factory takes care of setting the correct one.
        throw error;
      }

      // expecting this to be handled by the UI layer moving forward
      //
      // // save the result of the last api call
      // // if it has a title, which means it will be used for message
      // if (result.title) {
      //   this.setApiResult(result);
      // }

      // if (result.messageKey) {
      //   // if the result has a messageKey, use it to display a flash
      //   this.root.setFlash(result);
      // }

      // console.log(`api - result: ${JSON.stringify(result)}`);
      return result;
    } catch (error) {
      log.warn(
        'API ERROR',
        JSON.stringify({ error, endpoint, query /*, fetchOptions*/ })
      );
      if (error instanceof TypeError) {
        throw new NetworkError(error, { endpoint, query, fetchOptions });
      } else {
        throw createError(error);
      }
    } finally {
      if (applyNetworkIndicator) {
        this.stopLoading(); // removes the loading flag on the root
      }
    }
  }

  startLoading(): void {
    this.loadingData = true;
  }

  stopLoading(): void {
    this.loadingData = false;
  }

  // get globalConfig(): GlobalConfig {
  //   const root = this.root;
  //   if (root.globalConfig) {
  //     return root.globalConfig;
  //   } else {
  //     throw Error(`unexpectedly missing root.globalConfig`);
  //   }
  // }

  // get userManager(): UserManager {
  //   const root = this.root;
  //   if (root.userManager) {
  //     return root.userManager;
  //   } else {
  //     throw Error(`unexpectedly missing root.userManager`);
  //   }
  // }

  get baseUrl() {
    return apiUrlForEnv(this.apiEnv);
  }
}

const apiHosts: { [index: string]: string } = {
  jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://service.devtest.jiveworld.com',
  staging: 'https://service.staging.jiveworld.com',
  beta: 'https://service.beta.jiveworld.com',
  eslbeta: 'https://service-esl-beta.jiveworld.com',
  bolero: 'https://service.bolero.jiveworld.com',
  prod: 'https://service.lupa.app',
  preprod: 'https://service.preprod.jiveworld.com',
};

export const apiUrlForEnv = (apiEnv: string): string => {
  return `${apiHosts[apiEnv]}/api/v3/`;
};
