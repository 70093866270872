import { EMPTY_USER_ID } from '@common/analytics/analytics-manager';
import { CLASSROOM_FILTER_KEY_PREFIX } from 'core/lib/constants/vars';
import __ from 'core/lib/localization';
import { pick } from 'lodash';
import { ModelTreeNode } from 'ts-state-tree/tst-core';
import { Story } from '../story-manager/story';
import { Assignment } from './assignment';
import { Classroom } from './classroom';
import { PaymentData } from './payment-data';
import { Plan } from './plan';
import { PurchasedCoupon } from './purchased-coupon';

export class AccountData extends ModelTreeNode {
  static CLASS_NAME = 'AccountData' as const;

  static create(snapshot: any = {}): AccountData {
    return super.create(AccountData, snapshot) as AccountData;
  }

  userId: string = EMPTY_USER_ID;
  userDataUuid: string; // firestore user data doc id
  email: string = ''; // displayed in the account screen if changed after first registration and not yet reconfirmed.does not block login ?? string
  name: string = '';
  schoolName?: string = null;
  isClassroomActivated: boolean = false;
  // not currently relevant. was only true when an account was not active until the initial email was confirmed
  // emailConfirmationRequired: boolean = false;
  unconfirmedEmail?: string = null;
  mailingListOptIn: boolean = false;
  mailingListPromptNeeded: boolean = false; // set by rails server during account creation. reset when opt in pref set
  catalogV4Url: string = null; // the server can potentially return null for this
  catalogV4Slug: string = null;
  // accountSiteBaseUrl: string = null; // obsolete
  accountPageUrl: string = null; // fully formed URL
  accountPageLinkName: string = null;
  memberSinceYear: number = null;
  lastSyncedVersion: number = 0; // incremented by server last when ever sync'ed in or out
  group: string = null; // nullable - metrics tracking group
  membershipState: string = 'trial';
  fullAccess: boolean = false; // if true, then has access to all stories, if false, then only 'trial == true' stories,
  canAccessAllStoryScripts: boolean = false;
  autoRenew: boolean = false;
  // anonymous: boolean = false;
  fullAccessUntil: string = null; // TODO: how to best handle dates?

  appStoreBuildNumber: string = null;
  playStoreBuildNumber: string = null;
  debugBuildNumber: string = null; // apple review
  betaMode: boolean = false; // rails server flag. drives deltas to dashboard banners

  daysLeft: number = 0;
  unlockedStorySlugs: string[] = [];
  unlockedVolumeSlugs: string[] = [];
  hasEverPaid: boolean = false;
  promoteEnrollment: boolean = false;
  promoteDownload: boolean = false;
  hasAffiliatePricing: boolean = false;
  affiliateWelcomeHeading: string = null;
  welcomeUrl: string = null;
  whatsNewUrl: string = null;
  showFutureStories: boolean = false; // if true, then forward dated stores will be not be omitted from story list views
  // applePaymentsDisabled: boolean = true;
  // this is now only build time flag
  // inviteCodeRequired: boolean = false; // triggers beta website invitation gate
  hideIosCouponUi: boolean = false;
  hasApplePaidAccess: boolean = false;

  paymentData: PaymentData = null;

  plans: Plan[] = [];
  // applePlans: Plan[] = [];
  giftPlans: Plan[] = [];
  purchasedCoupons: PurchasedCoupon[] = [];

  // used for server driven debugging of startup failure flows
  debugStatus: string = null;
  classroomPortalWelcomePending: boolean = false;
  joinedClassrooms: Classroom[] = [];
  // drives teacher classroom portal view within spa
  managedClassrooms: Classroom[] = [];
  hasManagedClassrooms: boolean = false;
  licensedClassroomLabel: string = null; // name to display on account page if group-access

  get catalogSlug() {
    return this.catalogV4Slug;
  }

  get catalogUrl() {
    return this.catalogV4Url;
  }

  get reportingContextData(): object {
    return pick(this, [
      'userId',
      'email',
      'name',
      'schoolName',
      'group',
      'fullAccess',
      'fullAccessUntil',
      'autoRenew',
      'membershipState',
      'memberSinceYear',
      'accountPageUrl',
      'isClassroomActivated',
      'canAccessAllStoryScripts',
      'hasManagedClassrooms',
      'licensedClassroomLabel',
      'mailingListOptIn',
      'mailingListPromptNeeded',
      'resolvedCatalogUrl',
    ]);
  }

  classroom(id: string) {
    return this.managedClassrooms.find(classroom => classroom.id === id);
  }

  get membershipDisplay() {
    // @elliottjf
    // maybe membershipState should be a string enum?
    switch (this.membershipState) {
      case 'trial':
        return __('Trial', 'account.membership.trial');
      case 'suspended':
        return __(
          'Suspended (monthly subscription)',
          'account.membership.suspended'
        );
      case 'full-no-renew':
        return __('Full access', 'account.membership.fullAccess');
      case 'full-auto-renew':
        return __('Full access', 'account.membership.fullAccess');
      case 'group-access':
        return __('Group access', 'account.membership.groupAccess');
      default:
        return this.membershipState; // unexpected, should probably log error
    }
  }

  get showRecurringBilling() {
    return (
      this.membershipState === 'full-no-renew' ||
      this.membershipState === 'full-auto-renew'
    );
  }

  get recurringBillingDisplay() {
    if (this.membershipState === 'full-auto-renew') {
      return __('On', 'common.on');
    } else {
      return __('Off', 'common.off');
    }
  }

  get showClassroomLicenseNag() {
    return !!this.managedClassrooms?.find(c => c.license?.isOverSubscribed);
  }

  // this should be used now instead of the raw server 'isClassroomActivated' flag
  get classroomEnabled() {
    return this.isClassroomActivated && this.schoolName !== 'n/a'; // hack around current server logic
  }

  // get hasJoinedClassrooms() {
  //   return this.joinedClassrooms && this.joinedClassrooms.length > 0;
  // }

  get joinedClassroomsWithAssignments(): Classroom[] {
    return this.joinedClassrooms.filter(
      classroom => classroom.assignmentCount > 0
    );
  }

  joinedClassroomForFilterKey(key: string) {
    const id = key.replace(CLASSROOM_FILTER_KEY_PREFIX, '');
    return this.joinedClassrooms.find(classroom => classroom.id === id);
  }

  get assignmentMap() {
    return this.managedClassrooms
      .map(c => c.assignments)
      .flat()
      .map(a => a.episodeSlug)
      .reduce((acc, slug) => {
        if (!acc[slug]) {
          acc[slug] = 1;
        } else {
          acc[slug] = acc[slug] + 1;
        }
        return acc;
      }, {} as { [key: string]: number });
  }

  joinedClassroomAssignmentForStory(story: Story): Assignment {
    for (const classroom of this.joinedClassrooms) {
      for (const assignment of classroom.assignments) {
        if (story.matchesVolumeOrUnitSlug(assignment.episodeSlug)) {
          return assignment;
        }
      }
    }
    return null;
  }

  // moved to userManager.hasAdminAccess
  // get showDevTools() {
  //   return this.showFutureStories;
  // }

  //
  // TODO
  //

  get currentPlan(): any {
    return null;
  }

  get remainingFullAccessInDays() {
    if (this.fullAccessUntil) {
      const now = new Date();
      const fullAccessUntil = new Date(this.fullAccessUntil);
      const diff = fullAccessUntil.getTime() - now.getTime();
      const days = diff / (1000 * 3600 * 24);
      return days;
    }
    return 0;
  }

  get shouldPromoteRenewal() {
    if (this.autoRenew) {
      return false;
    }

    const daysOfFullAccess = this.remainingFullAccessInDays;

    if (daysOfFullAccess < 30) {
      return true;
    }

    return false;
  }
}
