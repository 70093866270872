import * as React from 'react';
import * as RichMessage from 'components/ui/rich-message';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { VSpacer } from '@naan/primitives/spacer';
import { Button } from '@naan/primitives/button';

export const pathname = '/rich-message';
export const navlabel = 'Rich Message';
export const naanReady = true;

const onDismiss = () => {
  // eslint-disable-next-line no-alert
  alert('would dismiss');
};

export const Screen = () => {
  return (
    <CenterColumnLayout>
      <RichMessage.Container>
        <RichMessage.CloseButton onClick={onDismiss} />
        <RichMessage.Body>
          <RichMessage.Title>A text-only message</RichMessage.Title>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
            volutpat.
          </p>
          <RichMessage.ButtonContainer>
            <Button label="Close" presentation={'teal'} onClick={onDismiss} />
          </RichMessage.ButtonContainer>
        </RichMessage.Body>
      </RichMessage.Container>
      <VSpacer size="8" />
      <RichMessage.Container>
        <RichMessage.CloseButton onClick={onDismiss} />
        <RichMessage.Body>
          <RichMessage.Title>An image with default padding</RichMessage.Title>
          <p>
            Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
            suscipit lobortis nisl ut aliquip ex ea commodo consequat.
          </p>
          <RichMessage.ButtonContainer>
            <Button label="Close" presentation={'teal'} onClick={onDismiss} />
          </RichMessage.ButtonContainer>
        </RichMessage.Body>
        <RichMessage.Figure>
          <RichMessage.Image
            src="https://jw-component-protos.netlify.app/rich-message/assets/test-image-d.png"
            alt=""
          />
        </RichMessage.Figure>
      </RichMessage.Container>
      <VSpacer size="8" />
      <RichMessage.Container>
        <RichMessage.CloseButton onClick={onDismiss} />
        <RichMessage.Body>
          <RichMessage.Title>An image with bleed-to-bottom</RichMessage.Title>
          <p>
            Browse stories by country and theme, and dive into anything that
            catches your eye. Maybe start with a shorter story, and try longer
            ones as your confidence increases.
          </p>
          <RichMessage.ButtonContainer>
            <Button
              label="Start listening"
              presentation={'teal'}
              onClick={onDismiss}
            />
          </RichMessage.ButtonContainer>
        </RichMessage.Body>
        <RichMessage.Figure bleed="bottom">
          <RichMessage.Image
            src="https://jw-component-protos.netlify.app/rich-message/assets/rich-message-party-d.png"
            alt=""
          />
        </RichMessage.Figure>
      </RichMessage.Container>
      <VSpacer size="8" />
      <RichMessage.Container>
        <RichMessage.CloseButton onClick={onDismiss} />
        <RichMessage.Body>
          <RichMessage.Title>An image with bleed-to-top</RichMessage.Title>
          <p>
            This is the Lupa dashboard your students will see. Use the Go to
            Classrooms link to access the teacher resources and classroom
            management features. Hint: on mobile it's under the "Me" menu.
          </p>
          <RichMessage.ButtonContainer>
            <Button label="Got it" presentation={'teal'} onClick={onDismiss} />
          </RichMessage.ButtonContainer>
        </RichMessage.Body>
        <RichMessage.Figure bleed="top">
          <RichMessage.Image
            src="https://jw-component-protos.netlify.app/rich-message/assets/rich-message-classrooms-link-m.png"
            alt=""
            size="small"
          />
          <RichMessage.Image
            src="https://jw-component-protos.netlify.app/rich-message/assets/rich-message-classrooms-link-d.png"
            alt=""
            size="medium"
          />
        </RichMessage.Figure>
      </RichMessage.Container>
    </CenterColumnLayout>
  );
};

export const ShowcaseVolumePage = Screen;
