import * as React from 'react';

import { useTitle } from 'common/hooks/use-title';
import { observer } from 'mobx-react';

import __ from 'core/lib/localization';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { LearnDashboard } from 'components/learn/dashboard/learn-dashboard';
import { AppFactory } from '@app/app-factory';
import { TrialDashboard } from 'components/learn/dashboard/trial-dashboard';
import { embeddedMode, postNativeMessage } from '@app/app-util';

export const DashboardScreen = observer(() => {
  useTitle(__('Dashboard', 'nav.dashboard'));

  // track when it's safe to pop back from detail or study view
  ReturnNavState.storyListVisited = true;

  const { userManager } = AppFactory.root;
  const { userId } = userManager.accountData;

  React.useEffect(() => {
    if (embeddedMode()) {
      // let the native wrapper know that we've successfully loaded the dashboard.
      // expectation is that the native wrapper will automatically signout if
      // restarted without receiving this message.
      // if there's a userId mismatch from what the wrapper expects. the wrapper should
      // also report an error to bugsnag and log the user out.
      postNativeMessage({ type: 'APP_LOADED', data: { userId } });
      // to test a hard startup failure, append '&force-death=t' to your account url override
      // in the raw admin.
      // to test the okay message flow from the browser, append '?force-embedded=t'
    }
    // not expecting userId to change, but if ever does for any reason
    // let the wrapper know about it.
  }, [userId]);

  if (userManager.accountData.fullAccess) {
    return <LearnDashboard />;
  } else {
    return <TrialDashboard />;
  }
});
