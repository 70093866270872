import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';

import { objectFromUrlQuery } from 'common/object-from-url-query';
import { getMessages } from './account-message-target/get-account-messages';
import { AppFactory } from 'app/app-factory';
import { get } from 'lodash';
import { devMode } from 'app/platform';
import { PlainMarkdown } from '@naan/primitives/text';
import { appConfig } from '@app/env';
import { ExternalLink } from '@naan/primitives/text/external-link';
import { Box } from '@naan/primitives/box';

import __ from 'core/lib/localization';

const { emails } = appConfig.website;

const cleanAddressBar = () => {
  var yourCurrentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', yourCurrentUrl);
};

type MessageKey = keyof ReturnType<typeof getMessages>;

export function useQueryMessageDispatcher() {
  const { accountData } = AppFactory.root.userManager;

  React.useEffect(() => {
    type MessageQueryType = {
      messageKey: MessageKey;
      checkout: string;
    };

    const query = objectFromUrlQuery<MessageQueryType>();
    if (!devMode) {
      cleanAddressBar();
    }

    if (query.checkout === 'error') {
      AppFactory.dialogPresenter.present(onDismiss => (
        <Dialog.Container onDismiss={onDismiss} open={true}>
          <Dialog.Heading type="error">
            {__('Payment unsuccessful', 'account.paymentUnsuccessful')}
          </Dialog.Heading>
          <Dialog.Body>
            <Box css={{ '& a': { color: '$teal-500' } }}>
              <PlainMarkdown
                source={__(
                  'Your credit card payment was not processed. Please [let us know](%{supportLink}) if you encountered an unexpected error.',
                  'dialogs.paymentUnsuccessful',
                  {
                    supportLink: `mailto:${emails.support}`,
                  }
                )}
                components={{
                  a: ({ node, ...props }) => (
                    <ExternalLink {...(props as any)} />
                  ),
                }}
              />
            </Box>
          </Dialog.Body>
          <Dialog.ButtonsContainer>
            <Dialog.Button
              presentation="secondary"
              label={__('Close', 'account.close')}
              onClick={onDismiss}
              fullWidth
            />
          </Dialog.ButtonsContainer>
        </Dialog.Container>
      ));
    } else {
      if (accountData) {
        const messageKey = query.messageKey;
        const messages = getMessages(accountData);
        let message = get(messages, messageKey);

        if (!message) {
          if (accountData?.paymentData?.autoRenewFailed) {
            message = get(messages, 'auto-renew-failure-with-access');
          }
        }
        // typeof messageKey === 'string' ? messages[messageKey] : null;

        if (message) {
          AppFactory.dialogPresenter.present(onDismiss => (
            <Dialog.Container onDismiss={onDismiss} open={true}>
              <Dialog.Heading type={message.type}>
                {message.title}
              </Dialog.Heading>
              <Dialog.Body>{message.body}</Dialog.Body>
              <Dialog.ButtonsContainer>
                {message.type === 'success' ? (
                  <Dialog.Button
                    presentation="teal"
                    label={__('Done', 'common.done')}
                    onClick={onDismiss}
                    fullWidth
                  />
                ) : (
                  <Dialog.Button
                    presentation="secondary"
                    label={__('Close', 'account.close')}
                    onClick={onDismiss}
                    fullWidth
                  />
                )}
              </Dialog.ButtonsContainer>
            </Dialog.Container>
          ));
        }

        // } else {
        //   if (accountData?.paymentData?.autoRenewFailed) {
        //     MessageService.showMessage(
        //       messages['auto-renew-failure-with-access']
        //     );
        //   }
        // }
      }
    }
  }, [accountData]);
}
