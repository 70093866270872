import * as React from 'react';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';
import { PageHeading } from 'components/page-heading';
import { SupportMessage } from 'components/support/support-message';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { MediumHeading } from '@naan/primitives/text/medium-heading';
import { VideoGuidesList } from 'components/support/video-guides-list';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';

export const SupportScreen = () => {
  return (
    <CenterColumnLayout>
      <PageHeading
        title={__('Video guides and support', 'support.videoGuidesAndSupport')}
      />
      <SupportMessage />
      <MediumGap />
      <MediumHeading>
        {__('Jiveworld essentials', 'support.jiveworldEssentials')}
      </MediumHeading>
      <VSpacer size={5} />
      <VideoGuidesList />
      <MediumGap />
    </CenterColumnLayout>
  );
};
