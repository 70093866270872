import dayjs from 'dayjs';

/**
 * Returns date in format "Aug 27, 2020"
 */
export const formatDate = (
  dateStr: string | number | Date | dayjs.Dayjs | null | undefined
) => {
  if (!dateStr) {
    return '';
  }
  const date = dayjs(dateStr);
  if (date.isValid()) {
    return date.format('MMM D, YYYY');
  }
  return dateStr;
};
