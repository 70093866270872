import { createLogger } from 'app/logger';
import { makeObservable, observable } from 'mobx';
import { bugsnagNotify } from './services/notification-service';

const log = createLogger('base-cacher');

export abstract class BaseCacher {
  name: string;
  cache: Cache;

  @observable
  disabled: boolean = false;

  constructor(name: string) {
    this.name = name;
    makeObservable(this);
  }

  disable() {
    this.disabled = true;
  }

  // called by online state listener, will attempt to reenable if able to init
  attemptReenable() {
    if (this.disabled) {
      log.info('re-enabling');
      this.disabled = false;
      this.init().catch(bugsnagNotify);
    }
  }

  get enabled() {
    return !this.disabled;
  }

  toggleDisabled() {
    this.disabled = !this.disabled;
  }

  async init() {
    try {
      log.debug(`init(${this.name})`);
      this.cache = await caches.open(this.name);
      await this.verifySupport();
    } catch (error) {
      this.disable();
      // const wrapped = new Error(
      //   'Cache init failed' /*, { cause: error as Error }*/
      // );
      // todo: figure out how to properly wrap errors
      bugsnagNotify(error as Error);
      bugsnagNotify('Cache init failed');
    }
  }

  async verifySupport(): Promise<boolean> {
    // need to guard against multiple tabs colliding on the canary test
    const request = `http://cache/canary-${Math.random()}`;
    const value = 'tweet';
    const response = new Response(value);
    await this.cache.put(request, response);

    const checkResponse = await this.cache.match(request);
    if (checkResponse) {
      const check = await checkResponse.text();
      await this.cache.delete(request);
      if (value === check) {
        log.info('support verified');
        return true;
      }
    }
    log.warn('verifySupport - failed');
    this.disable();
    bugsnagNotify(new Error('Cache verify failed'));
    return false;
  }

  async purge(): Promise<void> {
    await caches.delete(this.name);
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/StorageManager/estimate
  static async estimateStorage(): Promise<StorageEstimate> {
    if (navigator.storage.estimate) {
      const estimate = await navigator.storage.estimate();
      return estimate;
    } else {
      log.warn('estimateStorage - api not supported');
    }
    return {};
  }

  static async purgeAllCacheStorage(): Promise<void> {
    log.warn('purgeAllCacheStorage');
    const keys = await caches.keys();
    for (const key of keys) {
      log.warn(`deleting ${key}`);
      await caches.delete(key);
    }
  }
}
