import * as React from 'react';
import { ChapterCatalogData } from '@core/models/catalog';
import { observer } from 'mobx-react';
import { ChapterListItemPresentationWrapper } from './chapter-list-item-presentation-wrapper';

// when story not yet started
export const Locked = observer(
  ({ chapter }: { chapter: ChapterCatalogData }) => {
    return (
      <ChapterListItemPresentationWrapper
        chapter={chapter}
        number={chapter.position}
        title={chapter.title}
      />
    );
  }
);
