import * as React from 'react';
import { ContentGrid } from 'components/global-layout';
import { AccountForm } from 'components/account/account-form';
// import { AccountFlash } from 'components/account/account-flash';
import { MembershipTable } from 'components/account/membership-table';
import { CheckoutResultTarget } from 'components/account/checkout-result-target';
import { AccountCta } from 'components/account/account-cta';
import { useTitle } from 'common/hooks/use-title';
import { PageHeading } from 'components/page-heading';
import { observer } from 'mobx-react';
import { JoinedClassrooms } from './joined-classrooms';
import { TableLayoutWrapper } from 'components/account/account-layout';
import { Button } from 'naan/primitives/button';
import { SignOutExtraSmallIcon } from 'naan/icons/sign-out-icon';
import { QuickLinks } from 'components/account/quick-links';
import { LargeGap, SectionEnds } from '@naan/primitives/spacer/gaps';
import { Navigate, useNavigate } from 'react-router-dom';

import __ from 'core/lib/localization';
import { MergeProgressButton } from 'components/account/merge-progress-button';
import { AppFactory } from '@app/app-factory';
import { signInPath } from 'components/nav/path-helpers';

export const AccountScreen = observer(() => {
  useTitle(__('My account', 'account.myAccount'));
  const navigate = useNavigate();

  // @armando, do you want to take pass at better factoring this and applying to more screens that don't
  // make sense to access anonymously.
  const { authenticated } = AppFactory.root.userManager;
  if (!authenticated) {
    const after = window.location.pathname;
    return <Navigate to={signInPath({ after })} replace />;
  }

  return (
    <>
      <ContentGrid>
        <PageHeading
          title={__('My account', 'account.myAccount')}
          showAccessoryInSmall
          renderAccessory={() => {
            /// Added so we can logout form a non-teacher account.
            return (
              // nuwan,
              <Button
                size={'small'}
                presentation={'grayLight'}
                leftIcon={<SignOutExtraSmallIcon />}
                label={__('Sign out', 'common.signOut')}
                onClick={() => navigate('/logout')}
              />
            );
          }}
        />
        {/* <AccountFlash /> */}
        <CheckoutResultTarget />
        <AccountCta />
        <TableLayoutWrapper>
          <JoinedClassrooms />
        </TableLayoutWrapper>
        <TableLayoutWrapper>
          <AccountForm />
        </TableLayoutWrapper>
        <TableLayoutWrapper>
          <MembershipTable />
        </TableLayoutWrapper>
      </ContentGrid>
      <QuickLinks />
      <MergeProgressButton />
      <LargeGap />
      <SectionEnds />
    </>
  );
});

export default AccountScreen;
