import * as React from 'react';
import * as Dialog from 'naan/primitives/modals/dialog';
import { styled } from 'naan/stitches.config';
import { observer } from 'mobx-react';
import { Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { useNavigate } from 'react-router-dom';
import { appConfig } from 'app/env';
import { openUrl } from '@naan/primitives/text/external-link';
import __ from 'core/lib/localization';
import { discoverPath } from 'components/nav/path-helpers';
import { clearSavedScrollPosition } from 'lib/scroll-utils/deep-scroll-restoration';
// import { Dialog } from '@radix-ui/react-dialog';

const StackedDialogButtonWrapper = styled('button', {
  all: 'unset',
  textStyle: 'body-bold',

  padding: '$space$4',
  textAlign: 'center',
  cursor: 'pointer',
  variants: {
    presentation: {
      primary: {
        color: '$teal-500',
        '&:hover': {
          color: '$teal-600',
        },
      },
      secondary: {
        color: '$gray-400',
        '&:hover': {
          color: '$gray-500',
        },
      },
    },
  },
  defaultVariants: { presentation: 'primary' },
});

type StackedDialogButtonProps = React.ComponentProps<
  typeof StackedDialogButtonWrapper
> & {
  label: string;
  testId?: string;
};

export const StackedDialogButton = React.forwardRef(
  (
    {
      label,
      disabled = false,
      testId,
      ...restOfProps
    }: StackedDialogButtonProps,
    ref
  ) => {
    return (
      <StackedDialogButtonWrapper
        disabled={disabled}
        aria-label={label}
        data-testid={testId}
        ref={ref as any}
        {...restOfProps}
      >
        {label}
      </StackedDialogButtonWrapper>
    );
  }
);

const salesEmail = appConfig.website.emails.educationalSales;
const emailSubject = __(
  'About teaching with Lupa and Lupa Classroom',
  'email.aboutTeachingWithLupaAndLupaClassroom'
);
const emailBody = __(
  "Tell us what you're interested in. If you're inquiring about a group license for your Spanish class let us know the best method and time to contact you.",
  'email.aboutTeachingWithLupaAndLupaClassroom'
);

export const LockedResourceDialog = observer(
  ({ onDismiss }: { onDismiss: () => void }) => {
    const navigate = useNavigate();

    const goToDiscover = React.useCallback(() => {
      const path = discoverPath();
      clearSavedScrollPosition(path);
      navigate(path);
      onDismiss();
    }, [navigate, onDismiss]);

    const contactUs = React.useCallback(() => {
      // TODO: replace with form
      const emailLink = `mailto:${salesEmail}?subject=${emailSubject}&body=${emailBody}`;
      openUrl(emailLink);
      onDismiss();
    }, [onDismiss]);

    return (
      <Dialog.Container open={true} onDismiss={onDismiss}>
        <Dialog.Heading>
          {__(
            'Resources for this story are locked',
            'story.resourcesForThisStoryAreLocked'
          )}
        </Dialog.Heading>
        <Dialog.Body>
          <Text>
            {__(
              'If you are purchasing Lupa for your classroom, please contact us to unlock access to all resources.',
              'story.ifYouArePurchasingLupaForYourClassroom'
            )}
          </Text>
          <VSpacer size={4} />
          <Text>
            {__(
              'We also offer two free stories, with complete teacher resources.',
              'story.weAlsoOfferTwoFreeStories'
            )}
          </Text>
        </Dialog.Body>
        <Dialog.ButtonsContainer direction={'column'}>
          <Dialog.Button
            presentation={'teal'}
            label={'Contact us to unlock'}
            onClick={contactUs}
          />
          <Dialog.Button
            presentation={'secondary'}
            label={'View free stories'}
            onClick={goToDiscover}
          />
          <Dialog.Button
            presentation={'cancel'}
            label={'Close'}
            onClick={onDismiss}
          />
        </Dialog.ButtonsContainer>
      </Dialog.Container>
    );

    // return (
    //   <Window
    //     hideCloseButton={true}
    //     open={open}
    //     body={
    //       // <Box >
    //       <VStack
    //         css={{
    //           width: 'min(300px, calc(100vw - 72px))',
    //           marginBottom: '-$space$4',
    //         }}
    //       >
    //         <Text textStyle="body-bold">
    //           Resources for this story are locked
    //         </Text>
    //         <VSpacer size={4} />
    //         <Text>
    //           If you are purchasing Lupa for your classroom, please contact us
    //           to unlock access to all resources.
    //         </Text>
    //         <VSpacer size={4} />
    //         <Text>
    //           We also offer two free stories, with complete teacher resources.
    //         </Text>
    //         <VSpacer size={4} />
    //         <Divider />
    //         <StackedDialogButton
    //           label={'Contact us to unlock'}
    //           onClick={contactUs}
    //         />
    //         <Divider />
    //         <StackedDialogButton
    //           label={'View free stories'}
    //           onClick={goToDiscover}
    //         />
    //         <Divider />
    //         <StackedDialogButton
    //           presentation={'secondary'}
    //           label={'Close'}
    //           onClick={onDismiss}
    //         />
    //       </VStack>
    //     }
    //   />
    // );
  }
);
