import { bugsnagNotify } from '@app/notification-service';
import { createLogger } from 'app/logger';
const log = createLogger('wrapped-audio-element');

export class WrappedAudioElement {
  src: string;
  audio: HTMLAudioElement;

  constructor(src: string) {
    this.src = src;
    // log.debug(`src: ${src}`);
    this.audio = this.src ? new Audio(this.src) : new Audio();
    this.audio.onerror = () => this.resetAndPlay();
  }

  printDebug() {
    log.debug('src: ' + this.audio.src);
    log.debug('muted: ' + this.audio.muted);
    log.debug('volume: ' + this.audio.volume);
    log.debug('ended: ' + this.audio.ended);
    log.debug('seekable: ' + this.audio.seekable);
    log.debug('duration: ' + this.audio.duration);
    log.debug('currentTime: ' + this.audio.currentTime);
    log.debug('readyState: ' + this.audio.readyState);
    log.debug('networkState: ' + this.audio.networkState);
    const error = this.audio.error;
    if (error) {
      log.debug('error:', error);
    }
  }

  resetAndPlay() {
    if (
      this.audio.error &&
      (this.audio.error.code === 2 || this.audio.error.code === 3)
    ) {
      log.debug('resetAndPlay');
      const currentTime = this.audio.currentTime;
      const volume = this.audio.volume;
      const playbackRate = this.audio.playbackRate;
      this.audio = new Audio(this.audio.src);
      this.audio.currentTime = currentTime;
      this.audio.volume = volume;
      this.audio.playbackRate = playbackRate;
      this.audio.onerror = () => this.resetAndPlay();
      this.audio.play().catch(bugsnagNotify); // @jason should we pass this up instead?
      // console.log('*** RESET AUDIO');
      // console.log('RESET AUDIO');
      // console.log('RESET AUDIO ***');
    }
  }

  play() {
    // this.printDebug();
    return this.audio.play();
  }

  pause() {
    return this.audio.pause();
  }

  addEventListener(key: any, f: () => any) {
    return this.audio.addEventListener(key, f);
  }

  get currentTime() {
    return this.audio.currentTime;
  }

  set currentTime(val) {
    this.audio.currentTime = val;
  }

  get playbackRate() {
    return this.audio.playbackRate;
  }

  set playbackRate(val) {
    this.audio.playbackRate = val;
  }

  get duration() {
    return this.audio.duration;
  }

  get volume() {
    return this.audio.volume;
  }

  set volume(val) {
    this.audio.volume = val;
  }
}
