import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { VSpacer } from '@naan/primitives/spacer';

import __ from '@core/lib/localization';
import { ExternalLink } from '@naan/primitives/text/external-link';

const Wrapper = styled('div', {
  $$textColor: '$colors$white-alpha-70',
  textStyle: 'small-text',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: '$$textColor',
  '& .links': {
    display: 'flex',
    gap: '4px',
    '& a': {
      color: 'inherit',
    },
  },
  variants: {
    presentation: {
      light: {
        $$textColor: '$colors$textSecondary',
      },
    },
  },
});

type WrapperProps = React.ComponentProps<typeof Wrapper> & {
  showLinks?: boolean;
};

export const Footer = ({ showLinks = true, ...props }: WrapperProps) => {
  return (
    <Wrapper {...props}>
      <div className="copy">
        {__(
          `Monthly subscriptions automatically renew on the same day each month.
          When you cancel a monthly subscription your full access continues
          until the end of the current billing period.
          Annual purchases do not automatically renew.
        `,
          'subscriptions.monthlySubscriptionsAutomaticallyRenew'
        )}
      </div>
      <VSpacer size={2} />
      {showLinks ? (
        <div className="links">
          <ExternalLink href="https://www.jiveworld.com/terms-of-service">
            {__('Terms of service', 'auth.termsOfService')}
          </ExternalLink>
          <span>•</span>
          <ExternalLink href="https://www.jiveworld.com/privacy">
            {__('Privacy', 'auth.privacy')}
          </ExternalLink>
        </div>
      ) : null}
    </Wrapper>
  );
};
